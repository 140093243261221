import styled from 'styled-components'
import color from '../../../util/colors'

import { DesktopTimePicker } from '@mui/x-date-pickers-pro'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'

export const StyledTimePicker = styled(DesktopTimePicker)`
  svg {
    color: ${color.primary};
  }
  width: 100%;
  background: ${color.backgroundPrimary};
`

export const StyledLocalizationProvider = styled(LocalizationProvider)`
  width: 100%;
`

const addQuestionToPool = (activeQuestion, setQuestionsPool) => {
  setQuestionsPool((prevQuestions) => {
    const existingQuestionIndex = prevQuestions.findIndex(
      (question) => question.id === activeQuestion.id,
    )

    const updatedQuestions = [...prevQuestions]

    if (existingQuestionIndex !== -1) {
      updatedQuestions[existingQuestionIndex] = {
        ...updatedQuestions[existingQuestionIndex],
        question: activeQuestion.question,
        hint: activeQuestion.hint,
        type: activeQuestion.type,
      }
    } else {
      const newQuestion = {
        id: new Date().getTime(),
        question: activeQuestion.question,
        hint: activeQuestion.hint,
        type: activeQuestion.type,
      }

      updatedQuestions.push(newQuestion)
    }

    return updatedQuestions
  })
}

const removeQuestionFromPool = (questionId, setQuestionsPool, activeTicket) => {
  setQuestionsPool((prevQuestions) => {
    if (activeTicket) {
      let updatedQuestions = { ...prevQuestions }
      activeTicket.forEach((activeTicket) => {
        updatedQuestions[activeTicket.name] = prevQuestions[activeTicket.name].filter(
          (question) => question.id !== questionId,
        )
      })
      return updatedQuestions
    } else {
      const updatedQuestions = prevQuestions.filter((question) => question.id !== questionId)
      return updatedQuestions
    }
  })
}

const clearActiveQuestion = (setActiveQuestion) => {
  setActiveQuestion({
    id: '',
    question: '',
    hint: '',
    type: null,
  })
}

const makeQuestionActive = (question, setActiveQuestion) => {
  setActiveQuestion({
    id: question.id,
    question: question.question,
    hint: question.hint,
    type: question.type,
  })
}

export { addQuestionToPool, removeQuestionFromPool, clearActiveQuestion, makeQuestionActive }

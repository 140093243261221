/* eslint-disable no-unused-vars */
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { RowContainer, SecondRowContainer, StyledText, TextFieldArea } from './AddDetails.style'
import { useState, useEffect } from 'react'
import { useFormGatherings } from '../../util/zustant/store'
import { validateTitle } from '../../util/events.js'
import { logger } from '../../util/logger'
import { useEvents } from '../../hooks/useEvents'
import { Backdrop, CircularProgress } from '@mui/material'

const AddDetails = ({ firstRowLabel, secondRowLabel }) => {
  const { gatherings, loading } = useEvents()
  const { setFormInfo, formInfo } = useFormGatherings()
  const [gatheringDetails, setGatheringDetails] = useState(
    formInfo.gatheringDetails ? formInfo.gatheringDetails : {},
  )
  const [gatheringEmpty, setGatheringEmpty] = useState('')

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.gatheringDetails = gatheringDetails
    newFormInfo.title = gatheringDetails.title
    setFormInfo(newFormInfo)
  }, [gatheringDetails, formInfo, setFormInfo])

  function setTitleValue(value) {
    setGatheringDetails((info) => {
      info.title = value
      return { ...info }
    })
  }

  function setDescriptionValue(value) {
    setGatheringDetails((info) => {
      info.description = value
      return { ...info }
    })
  }

  return (
    <Box>
      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <RowContainer>
        <StyledText>{firstRowLabel}</StyledText>
        <TextFieldArea className="addDetailsTextBoxArea">
          <TextField
            sx={{ width: '100%' }}
            id="outlined-basic"
            label={'Title'}
            value={formInfo?.gatheringDetails?.title ? formInfo?.gatheringDetails?.title : ''}
            variant="outlined"
            onChange={(e) => {
              setTitleValue(e.target.value.toString())
              if (firstRowLabel.includes('Title')) {
                if (e.target.value === '') {
                  setGatheringEmpty('This field cannot be empty')
                  return
                } else {
                  setGatheringEmpty('')
                }
                if (!formInfo?.id) {
                  const validated = validateTitle(e.target.value, gatherings)
                  if (!validated) {
                    setGatheringEmpty('Title already exists.')
                  } else {
                    setGatheringEmpty('')
                  }
                }
              }
            }}
            error={
              (formInfo?.gatheringDetails?.checkFields && !formInfo?.gatheringDetails?.title) ||
              gatheringEmpty
            }
            helperText={
              formInfo?.gatheringDetails?.checkFields && !formInfo?.gatheringDetails?.title
                ? 'This field cannot be empty'
                : gatheringEmpty !== ''
                ? gatheringEmpty
                : ''
            }
          />
        </TextFieldArea>
      </RowContainer>
      <SecondRowContainer>
        <StyledText className="addDetailsTextArea">{secondRowLabel}</StyledText>
        <TextFieldArea className="addDetailsTextBoxArea">
          <TextField
            sx={{ width: '100%' }}
            id="outlined-basic"
            label="Description"
            value={gatheringDetails.description !== '' ? gatheringDetails.description : ''}
            variant="outlined"
            multiline
            rows={5}
            error={
              formInfo?.id && (gatheringDetails.description === '' || !gatheringDetails.description)
            }
            onChange={(e) => setDescriptionValue(e.target.value)}
          />
        </TextFieldArea>
      </SecondRowContainer>
    </Box>
  )
}

export default AddDetails

import { logger } from '../logger'
import { create } from 'zustand'

const loadPersistedState = (name, defaultValue) => {
  try {
    return JSON.parse(localStorage.getItem(name)) ?? defaultValue
  } catch (error) {
    logger.error(error)
    return {}
  }
}

const savePersistedState = (name, state) => {
  try {
    localStorage.setItem(name, JSON.stringify(state))
  } catch (error) {
    logger.error(error)
    return
  }
}

export const useLabel = create((set) => ({
  ...loadPersistedState('labels', { labelsStore: [{}, {}] }),

  setLabelsStore: (labelsStore) => {
    set({ labelsStore })
    savePersistedState('labels', { labelsStore: labelsStore ? labelsStore : [{}, {}] })
  },
}))

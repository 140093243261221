import { useState } from 'react'
import NavbarOutlinedButton from '../NavbarOutlinedButton/NavbarOutlinedButton'
import TableComponent from '../TableComponent/TableComponent'
import SearchComponent from '../SearchComponent/SearchComponent'
import {
  TemplatesFilterArea,
  FilterText,
  MainContainer,
  TextAndButtonsRow,
  RowTextWrapper,
  MainText,
  SecondaryText,
  ButtonRow,
  FooterText,
} from './AllExperiences.styled'
import { useEffect } from 'react'

const AllExperiences = ({ gatherings, experiences }) => {
  const [rowsPerPageFromChild, setRowsPerPageFromChild] = useState(5)
  const [pageFromChild, setPageFromChild] = useState(0)
  const [open, setOpen] = useState(false)
  const [publishedExperiences, setPublishedExperiences] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchData, setSearchData] = useState([])
  const [visibleRows, setVisibleRows] = useState([])

  useEffect(() => {
    if (experiences.length > 0) {
      setPublishedExperiences(experiences.filter((e) => e.status === 'published'))
    }
  }, [experiences])

  useEffect(() => {
    if (publishedExperiences.length > 0) {
      setSearchData(publishedExperiences)
      setFilteredData(publishedExperiences)
    }
  }, [publishedExperiences])

  return (
    <MainContainer>
      <TextAndButtonsRow>
        <RowTextWrapper>
          <MainText>All Experiences</MainText>
          <SecondaryText>
            Showing <span style={{ fontWeight: '700' }}>{visibleRows.length}</span> of{' '}
            {experiences.length} Experiences
          </SecondaryText>
        </RowTextWrapper>

        <ButtonRow>
          <NavbarOutlinedButton
            buttonText={'Filters'}
            iconName={open ? 'horizontal line' : 'filter'}
            onClickFunction={() => setOpen((o) => !o)}
          ></NavbarOutlinedButton>
          <NavbarOutlinedButton buttonText={'Options'} iconName={'pencil'}></NavbarOutlinedButton>
        </ButtonRow>
      </TextAndButtonsRow>
      {/* Gatherings tab and button area */}
      {/* Gatherings Grid Area */}

      <TemplatesFilterArea open={open}>
        <FilterText>Filter gathering templates by title:</FilterText>
        <div>
          <SearchComponent
            rowsPerPageFromChild={rowsPerPageFromChild}
            pageFromChild={pageFromChild}
            key={setFilteredData}
            searchData={searchData}
            sourceOfTruth={publishedExperiences}
            setSearchData={setSearchData}
            setFilteredData={setFilteredData}
            experiencesFilter
          ></SearchComponent>
        </div>
      </TemplatesFilterArea>

      <TableComponent
        tableBodyData={filteredData}
        tableDataVariant={'Experiences'}
        rowsPerPageArray={[5, 10, 25]}
        setVisibleRows={setVisibleRows}
        headerDataVariant={'Experiences'}
        rowsPerPageParentSetter={setRowsPerPageFromChild}
        parrentPageSetter={setPageFromChild}
      ></TableComponent>
      {/* Second Text Area */}

      <FooterText>
        <div>{`©${new Date().getFullYear()} Church.Ai. All Rights Reserved.`}</div>
      </FooterText>
    </MainContainer>
  )
}

export default AllExperiences

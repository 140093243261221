const generateString = (selectedCheckboxes) => {
  const generatedString = []
  if (selectedCheckboxes.firstName) {
    generatedString.push('First Name')
  }

  if (selectedCheckboxes.lastName) {
    generatedString.push('Last Name')
  }

  if (selectedCheckboxes.gathering) {
    generatedString.push('Gathering')
  }

  if (selectedCheckboxes.eventDate) {
    generatedString.push('Event Date')
  }

  if (selectedCheckboxes.eventTime) {
    generatedString.push('Event Time')
  }

  return `{${generatedString.join(', ')}}`
}

export { generateString }

import {
  LabelPreviewContainer,
  KidsLabelDetailsColumn,
  ParrentsLabelDetailsColumn,
  KidsLabelDetailGridColumn,
  ParentLabelQrColumn,
  KidsLabelDetailGridColumnItem,
  KidsLabelBarcodeAndImageColumn,
  ParentLabelBarcodeAndImageColumn,
  LabelMainText,
  LabelSubText,
  DetailTextWrapper,
  LabelDetailText,
  LabelDetailSubText,
  KidLabelQRCodeContainer,
  qrCodeKidStyle,
  qrCodeParentStyle,
  QrCodeSubText,
  ImageBox,
  ParentLabelImageBox,
  Image,
  stockImageIcon,
} from './LabelPreview.style'
import QRCode from 'react-qr-code'
import ImageIcon from '@mui/icons-material/Image'
import dummyData from '../../assets/label.json'

const dummyKidLabelData = dummyData.dummyKidLabel

const LabelPreview = ({ variant, labelImage }) => {
  switch (variant) {
    case 'kidLabel':
      return (
        <LabelPreviewContainer>
          <KidsLabelDetailsColumn>
            <LabelMainText>First Name</LabelMainText>
            <LabelSubText>Last Name</LabelSubText>
            <DetailTextWrapper>
              <LabelDetailText>{'Time:'}</LabelDetailText>
              <LabelDetailSubText>{'9:30 AM'}</LabelDetailSubText>
            </DetailTextWrapper>
            <DetailTextWrapper>
              <LabelDetailText>{'Room:'}</LabelDetailText>
              <LabelDetailSubText>{'Lorem Ipsum'}</LabelDetailSubText>
            </DetailTextWrapper>
            <DetailTextWrapper>
              <LabelDetailText>{'Notes:'}</LabelDetailText>
              <LabelDetailSubText>{'No/Photo Video'}</LabelDetailSubText>
            </DetailTextWrapper>
          </KidsLabelDetailsColumn>
          <KidsLabelDetailGridColumn>
            {dummyKidLabelData.map((item) => (
              <KidsLabelDetailGridColumnItem>{item.name}</KidsLabelDetailGridColumnItem>
            ))}
          </KidsLabelDetailGridColumn>
          <KidsLabelBarcodeAndImageColumn>
            <KidLabelQRCodeContainer>
              <QRCode value="Dummy String" style={qrCodeKidStyle}></QRCode>
              <QrCodeSubText>CODE: 12345</QrCodeSubText>
            </KidLabelQRCodeContainer>
            <ImageBox>
              {labelImage ? (
                <Image src={labelImage} alt=""></Image>
              ) : (
                <ImageIcon style={stockImageIcon} />
              )}
            </ImageBox>
          </KidsLabelBarcodeAndImageColumn>
        </LabelPreviewContainer>
      )
    case 'parentLabel':
      return (
        <LabelPreviewContainer>
          <ParrentsLabelDetailsColumn>
            <LabelMainText>Service Time</LabelMainText>
            <LabelSubText>9:30 AM</LabelSubText>
          </ParrentsLabelDetailsColumn>
          <ParentLabelQrColumn>
            <QRCode value="Dummy String" style={qrCodeParentStyle}></QRCode>
            <QrCodeSubText>CODE: 12345</QrCodeSubText>
          </ParentLabelQrColumn>
          <ParentLabelBarcodeAndImageColumn>
            <ParentLabelImageBox>
              <Image src={labelImage} alt=""></Image>
            </ParentLabelImageBox>
          </ParentLabelBarcodeAndImageColumn>
        </LabelPreviewContainer>
      )
    case 'stuffLabel':
      return (
        <LabelPreviewContainer>
          <KidsLabelDetailsColumn>
            <LabelMainText>First Name</LabelMainText>
            <LabelSubText>Last Name</LabelSubText>
            <DetailTextWrapper>
              <LabelDetailText>{'Time:'}</LabelDetailText>
              <LabelDetailSubText>{'9:30 AM'}</LabelDetailSubText>
            </DetailTextWrapper>
            <DetailTextWrapper>
              <LabelDetailText>{'Room:'}</LabelDetailText>
              <LabelDetailSubText>{'Lorem Ipsum'}</LabelDetailSubText>
            </DetailTextWrapper>
            <DetailTextWrapper>
              <LabelDetailText>{'Items:'}</LabelDetailText>
              <LabelDetailSubText>{'User Item 1'}</LabelDetailSubText>
            </DetailTextWrapper>
          </KidsLabelDetailsColumn>
          <KidsLabelBarcodeAndImageColumn>
            <KidLabelQRCodeContainer>
              <QRCode value="Dummy String" style={qrCodeKidStyle}></QRCode>
              <QrCodeSubText>CODE: 12345</QrCodeSubText>
            </KidLabelQRCodeContainer>
            <ImageBox>
              {labelImage ? (
                <Image src={labelImage} alt=""></Image>
              ) : (
                <ImageIcon style={stockImageIcon} />
              )}
            </ImageBox>
          </KidsLabelBarcodeAndImageColumn>
        </LabelPreviewContainer>
      )
    default:
      return <></>
  }
}

export default LabelPreview

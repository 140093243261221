import color from '../../util/colors'

const checkBoxStyle = {
  '&.Mui-checked': {
    color: color.primary,
  },
}

const customSlotProps = {
  desktopPaper: {
    sx: {
      borderRadius: '8px',
      border: `1px solid ${color.primary}`,
      padding: ' 24px 8px',
    },
  },
  mobilePaper: {
    sx: {
      borderRadius: '8px',
      border: `1px solid ${color.primary}`,
    },
  },
  leftArrowIcon: {
    sx: {
      color: color.primary,
    },
  },
  rightArrowIcon: {
    sx: {
      color: color.primary,
    },
  },
}

export { checkBoxStyle, customSlotProps }

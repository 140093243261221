import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import {
  DropdownContainer,
  DropdownStyle,
  DropdownItems,
  DropdownItem,
  DropdownItemsHolder,
} from './Dropdown.styles'

import MoreVertIcon from '@mui/icons-material/MoreVert'

const Dropdown = ({ items, onChange, setOverflowOff, isAdd = true, setPopuStatus, popuStatus }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (open) {
      setOverflowOff && setOverflowOff(true)
    }
  }, [open])

  useEffect(() => {
    if (open === 'preliminary true') {
      setOpen(true)
    }

    if (open === true) {
      setOpen(false)
    }
  }, [popuStatus])
  return (
    <DropdownStyle>
      <DropdownContainer
        onClick={() => {
          setOpen((e) => {
            return !e ? 'preliminary true' : false
          })
          setOverflowOff && setOverflowOff((o) => !o)
          setPopuStatus && setPopuStatus((p) => !p)
        }}
      >
        {isAdd ? (
          <>
            <AddIcon /> Add
          </>
        ) : (
          <MoreVertIcon />
        )}
      </DropdownContainer>
      {open && (
        <DropdownItemsHolder>
          <DropdownItems>
            {items.map((item) => (
              <DropdownItem
                onClick={() => {
                  onChange(item)
                  setOpen(false)
                }}
              >
                {item.label}
              </DropdownItem>
            ))}
          </DropdownItems>
        </DropdownItemsHolder>
      )}
    </DropdownStyle>
  )
}

export default Dropdown

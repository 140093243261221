import { Stack } from '@mui/material'
import DateFilter from '../../DateFilter/DateFilter'

import './TemplatesFilter.Styled.jsx'
import { mapper } from '../../../util/filter'
import { Gathering } from './TemplatesFilter.Styled.jsx'
const TemplatesFilter = ({ setFilterType, setFilter }) => {
  const mapArr1 = [
    {
      name: 'Filter by a label instead of a title',

      type: 'label',
      func: setFilterType,
      setFilter: setFilter,
    },
  ]
  const mapArr2 = [
    {
      name: 'Favorites',

      type: 'favorite',
      func: setFilterType,
      setFilter: setFilter,
    },
  ]

  return (
    <Stack
      sx={{ width: '100%' }}
      display={'flex'}
      direction={{
        md: 'row',
      }}
      justifyContent={'flex-start'}
      alignItems={'baseline'}
      gap={3}
      my={3}
    >
      <Gathering>
        <input type="checkbox" style={{ visibility: 'hidden', width: '0px' }} />
        <span>Filters:</span>
      </Gathering>

      <DateFilter
        filterSetter={setFilter}
        labels={['By date created']}
        label={'Filter by date'}
      ></DateFilter>

      <Stack justifyContent={'flex-start'}>{mapper(mapArr1)}</Stack>

      <Stack justifyContent={'flex-start'}>{mapper(mapArr2)}</Stack>
    </Stack>
  )
}

export default TemplatesFilter

import { MainContainer } from '../../util/ScreenWrapper.style'
import { ComponentArea } from '../GatheringsCreation/GatheringsCreationScreen.style'
import MediaCard from '../../components/Card/Card'
import { useEvents } from '../../hooks/useEvents'
import { useState } from 'react'
import { calculateEndDate, convertDateString } from '../../util/date'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Backdrop, Button, CircularProgress } from '@mui/material'
import { useAuth } from '../../hooks/useAuth'
import { approveGathering } from '../../util/gatherings'

const ApprovalScreen = () => {
  const { id } = useParams()
  const { token } = useAuth()
  const navigate = useNavigate()
  const { gatherings } = useEvents()
  const [gathering, setGathering] = useState()
  const [loading, setLoading] = useState(false)
  const [approved, setApproved] = useState(500)

  useEffect(() => {
    if (id && token) {
      approveGathering(id, token, setApproved)
    }
  }, [id, token])

  useEffect(() => {
    setLoading(true)
    if (gatherings.length > 0) {
      const selected = gatherings.find((g) => g?.id === id)
      setGathering(selected)
      setLoading(false)
    }
  }, [gatherings, id])

  if (loading || approved === 500) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <MainContainer style={{ height: '100vh' }}>
      <ComponentArea style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <h3>{`You have ${
          approved === 204 ? 'successfully' : 'already'
        } approved the following gathering`}</h3>
        <br></br>
        <div style={{ height: '198px', width: '248px' }}>
          <MediaCard
            id={gathering?.id}
            image={gathering?.imageUpload?.eventImage}
            isFavorite={gathering?.isFavourite}
            status={gathering?.status}
            title={gathering?.gatheringDetails?.title}
            dateFrom={convertDateString(gathering?.logisticsInfo?.event?.startDate)}
            //TODO: Temporary solution for daily recurrences, we will be implementing a fix for this in the next sprint
            dateTo={
              gathering?.logisticsInfo?.event?.recurres === 'daily'
                ? convertDateString(
                    calculateEndDate(
                      gathering?.logisticsInfo?.event?.startDate,
                      parseInt(gathering?.logisticsInfo?.event?.recurrencAmount),
                    ),
                  )
                : convertDateString(gathering?.logisticsInfo?.event?.startDate)
            }
          />
          <Button
            onClick={() => {
              navigate('/')
            }}
          >
            Return Home
          </Button>
        </div>
      </ComponentArea>
    </MainContainer>
  )
}

export default ApprovalScreen

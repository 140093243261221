import './AllGatherings.css'
import NavbarOutlinedButton from '../NavbarOutlinedButton/NavbarOutlinedButton'
import TableComponent from '../TableComponent/TableComponent'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SearchComponent from '../SearchComponent/SearchComponent'
import { TemplatesFilterArea, FilterText } from '../AllExperiences/AllExperiences.styled'

const AllGatherings = ({ gatherings, token, experiences }) => {
  const [rowsPerPageFromChild, setRowsPerPageFromChild] = useState(5)
  const [pageFromChild, setPageFromChild] = useState(0)
  const [open, setOpen] = useState(false)
  const [publishedGatherings, setPublishedGatherings] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchData, setSearchData] = useState([])
  const [visibleRows, setVisibleRows] = useState([])
  const { state } = useLocation()
  const experienceId = state?.experienceId
  const [enabledExperienceGatheringFilter, setEnabledExperienceGatheringFilter] = useState(
    experienceId ? true : false,
  )

  useEffect(() => {
    if (gatherings?.length > 0) {
      const published = gatherings.filter((e) => e.status === 'published')
      if (experienceId && enabledExperienceGatheringFilter) {
        const experienceFound = experiences.find((e) => e.id === experienceId)
        if (experienceFound) {
          const foundGatherings = []
          gatherings.forEach((gathering) => {
            if (experienceFound?.gatherings?.find((e) => e.id === gathering.id)) {
              foundGatherings.push(gathering)
            }
          })
          if (foundGatherings.length > 0) {
            setPublishedGatherings(foundGatherings)
          } else {
            setPublishedGatherings(published)
          }
        } else {
          setPublishedGatherings(published)
        }
      } else {
        setPublishedGatherings(published)
      }
    }
  }, [gatherings, enabledExperienceGatheringFilter])

  useEffect(() => {
    if (publishedGatherings?.length > 0) {
      setSearchData(publishedGatherings)
      setFilteredData(publishedGatherings)
    }
  }, [publishedGatherings])

  return (
    <div className="allGatheringsMainContentArea">
      <div className="allGatheringsTextandButtonsArea">
        <div className="allGatheringsTextArea">
          <div className="allGatheringsSectionMainText">All Gatherings</div>
          <div className="allGatheringsSectionSmallText">
            Showing <span style={{ fontWeight: '700' }}>{visibleRows.length}</span> of{' '}
            {publishedGatherings?.length} Gatherings
          </div>
        </div>

        <div className="allGatheringsButtonArea">
          {enabledExperienceGatheringFilter && (
            <div style={{ padding: '15px' }}>
              <NavbarOutlinedButton
                buttonText={'Remove Parent Experience Filter'}
                iconName={'experience disabled'}
                onClickFunction={() => setEnabledExperienceGatheringFilter(false)}
              ></NavbarOutlinedButton>
            </div>
          )}

          <NavbarOutlinedButton
            buttonText={'Filters'}
            iconName={open ? 'horizontal line' : 'filter'}
            onClickFunction={() => setOpen((o) => !o)}
          ></NavbarOutlinedButton>
          <NavbarOutlinedButton buttonText={'Options'} iconName={'pencil'}></NavbarOutlinedButton>
        </div>
      </div>

      <TemplatesFilterArea open={open}>
        <FilterText>Filter gathering templates by title:</FilterText>
        <div>
          <SearchComponent
            rowsPerPageFromChild={rowsPerPageFromChild}
            pageFromChild={pageFromChild}
            key={setFilteredData}
            searchData={searchData}
            sourceOfTruth={publishedGatherings}
            setSearchData={setSearchData}
            setFilteredData={setFilteredData}
            gatheringsFilter
          ></SearchComponent>
        </div>
      </TemplatesFilterArea>

      <TableComponent
        tableBodyData={filteredData}
        tableDataVariant={'Gatherings'}
        rowsPerPageArray={[5, 10, 25]}
        headerDataVariant={'Gatherings'}
        rowsPerPageParentSetter={setRowsPerPageFromChild}
        parrentPageSetter={setPageFromChild}
        setVisibleRows={setVisibleRows}
      ></TableComponent>
      {/* Second Text Area */}

      <div className="allGatheringsFooterText">
        <div>©2023 Church.Ai. All Rights Reserved.</div>
      </div>
    </div>
  )
}

export default AllGatherings

import styled from 'styled-components'
const SelectInfoContainer = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`

const AttendeesScreenContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
export { SelectInfoContainer, AttendeesScreenContainer }

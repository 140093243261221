/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import useProtectedRoute from '../../hooks/useProtectedRoute'

import AllTemplates from '../../components/AllTemplates/AllTemplates'
import CalendarScreen from '../CalendarScreen/CalendarScreen'
import { useEvents } from '../../hooks/useEvents'
import SubHeader from '../../components/SubHeader/SubHeader'
import { MainContainer, ComponentArea } from '../../util/ScreenWrapper.style'
import { useView } from '../../util/zustant/store'

const AllTemplatesScreen = () => {
  const [selectedTab, setSelectedTab] = useState('Templates')
  const [templateData, setTemplateData] = useState([])
  const { view } = useView()
  const { profileData, gatherings, experiences, searchData } = useEvents()
  const { authenticated, token } = useAuth()

  function filterDrafts(gatherings) {
    const filtered = gatherings.filter((g) => g.status === 'Templates')
    setTemplateData(filtered)
  }

  useEffect(() => {
    if (gatherings?.length > 0) {
      filterDrafts(gatherings)
    }
  }, [gatherings])

  useProtectedRoute()

  if (!authenticated || !profileData) return <></>

  return (
    <MainContainer>
      {/* Components Area*/}
      <ComponentArea>
        {/* Top Row Area 2*/}
        <SubHeader
          variant={'createGathering'}
          setSelectedTabParent={setSelectedTab}
          activeTabOnParent={selectedTab}
        ></SubHeader>

        {/* Screens Section Area */}
        {view === 1 ? (
          <AllTemplates
            drafts={templateData}
            token={token}
            setDrafts={setTemplateData}
          ></AllTemplates>
        ) : (
          <CalendarScreen eventData={templateData}></CalendarScreen>
        )}
      </ComponentArea>
    </MainContainer>
  )
}

export default AllTemplatesScreen

import styled from 'styled-components'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import color from '../../../util/colors'

const RosterInviteOnlyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const PrimaryText = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  padding: 1.5rem 0 0 0;
  color: var(--title);
  width: 100%;
`

const SecondaryText = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding-top: 1rem;
  color: var(--description);
  width: 100%;
`

const GroupGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 1.5rem 0;
  width: 97%;
`

const DataGridContainer = styled.div`
  width: 100%;
  padding: 3rem 0 3rem 0;
`

const RosterDataGrid = styled(DataGrid)({
  '&.MuiDataGrid-root': {
    borderStyle: 'none',
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButtonBase-root': {
        color: color.primary,
      },
    },
  },
  '&.MuiDataGrid-root .MuiDataGrid-booleanCell[data-value="true"]': {
    color: color.primary,
  },
})

const RosterDataGridToolbar = styled(GridToolbar)({})

export {
  RosterInviteOnlyContainer,
  GroupGridContainer,
  PrimaryText,
  SecondaryText,
  DataGridContainer,
  RosterDataGrid,
  RosterDataGridToolbar,
}

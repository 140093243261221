import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import color from '../../util/colors'
import TablePagination from '@mui/material/TablePagination'

const StyledHeaderTableRow = styled(TableRow)({
  backgroundColor: color.tableRowSecondary,
})

const StyledTablePagination = styled(TablePagination)({
  borderBottom: 0,
})

const StyledHeaderTableCell = styled(TableCell)({
  color: color.title,
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '140%',
  letterSpacing: ' 0.0715em',
  paddingLeft: '3rem',
})

const paginationDiv = {
  display: 'flex',
  marginTop: '3rem',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}

const BoxStyle = {
  width: '100%',
  display: 'table',
  tableLayout: 'fixed',
  '& .MuiTableContainer-root': { boxShadow: 'none' },
  '.MuiPaper-root': {
    backgroundColor: color.backgroundSecondary,
  },
}

const TableStyle = {
  minWidth: 700,

  thead: {
    transform: 'translateY(1px)',
  },
  tbody: {
    transform: 'translateY(-1px)',
    '.MuiTableRow-root:hover': {
      borderLeft: `5px solid ${color.tableCardSidebar}`,
      backgroundColor: color.tableCardHover,
      position: 'relative',
      boxShadow: `0px 8px 20px 0px ${color.boxShadow}`,
    },
  },

  marginBottom: '32px',
  '& .MuiTableRow-head': { borderLeft: `5px solid ${color.backgroundSecondary}` },
}

export {
  StyledHeaderTableRow,
  StyledHeaderTableCell,
  StyledTablePagination,
  paginationDiv,
  BoxStyle,
  TableStyle,
}

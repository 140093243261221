import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import color from '../../../util/colors'

const StyledCloseIcon = styled(CloseIcon)({
  cursor: 'pointer',
  marginTop: '0px',
  padding: '0px',
  marginBottom: '-2px',
  marginLeft: '10px',
  background: color.backgroundPrimary,
  color: color.primary,
  borderRadius: '50%',
  '&.MuiSvgIcon-root': {
    fontSize: 'inherit', // assuming smallest maps to inherit in your project
  },
})

const CustomCloseIcon = ({ removeOccurrence, i }) => {
  return <StyledCloseIcon onClick={() => removeOccurrence(i)} />
}

export default CustomCloseIcon

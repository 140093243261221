import { useState } from 'react'
import {
  StyledTemplateButton,
  createKioskButtonStyle,
  approvalReminderStyle,
  StyledTemplateButtonRecurrence,
  StyledCloseModalButton,
} from '../StyledButton/StyledButton.style'
import SaveIcon from '@mui/icons-material/Save'
import DifferenceIcon from '@mui/icons-material/Difference'
import ForwardIcon from '@mui/icons-material/Forward'
import CloseIcon from '@mui/icons-material/Close'
import PeopleIcon from '@mui/icons-material/People'
import CircularProgress from '@mui/material/CircularProgress'
import color from '../../util/colors'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ImageIcon from '@mui/icons-material/Image'
import DeleteIcon from '@mui/icons-material/Delete'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import { useAuth } from '../../hooks/useAuth'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { ReactComponent as Action } from '../../assets/actions.svg'
import { sendApprovalEmail } from '../../util/gatherings'

const TemplateButton = ({
  variant,
  label,
  onClick,
  saveLabel,
  questionSaved,
  setQuestionSaved,
  activeQuestion,
  setActiveQuestion,
  addQuestionToPool,
  setQuestionsPool,
  clearActiveQuestion,
  questionErrorHandler,
  loading,
  saveDraft,
  addTagToThePool,
  activeTag,
  setTagsPool,
  updateExistingTag,
  tagErrorHandler,
  setTagSaved,
  clearActiveTag,
  setActiveTag,
  tagSaved,
  setAllTags,
  disableSaveButton,
  setdisableSaveButton,
  setDuplicateAlert,
  setSecurity,
  insertNewLabel,
  uploadedImageIndex,
  removeUploadedImage,
  refFunc,
  setReminderDate,
  saveTemplate,
  disabled,
  active,
  formInfo,
  setReminderSentTo,
  templateStatus,
  activeTicket,
  questionsPool,
  paidEvent,
}) => {
  const [draftSaved, setDraftSaved] = useState(false)

  const { token } = useAuth()

  switch (variant) {
    case 'add':
      return (
        <StyledTemplateButton
          variant="contained"
          startIcon={<AddCircleIcon></AddCircleIcon>}
          onClick={() => {
            onClick()
          }}
          sx={{
            backgroundColor: !draftSaved ? color.draftButton : color.draftSaved,
            '&:hover': { backgroundColor: !draftSaved ? color.draftButton : color.draftSaved },
          }}
          disabled={disabled}
        >
          {!draftSaved ? `${label}` : `${saveLabel}`}
        </StyledTemplateButton>
      )
    case 'draft':
      return (
        <StyledTemplateButton
          variant="contained"
          startIcon={loading ? <CircularProgress size={24} /> : <SaveIcon />}
          onClick={() => {
            saveDraft()
            if (!loading) {
              setDraftSaved(true)
              setTimeout(() => {
                setDraftSaved(false)
              }, 3000)
            }
          }}
          sx={{
            backgroundColor: !draftSaved ? color.draftButton : color.draftSaved,
            '&:hover': { backgroundColor: !draftSaved ? color.draftButton : color.draftSaved },
            width: loading ? '150px' : 'auto',
          }}
          disabled={loading}
        >
          {loading ? 'Saving...' : !draftSaved ? `${label}` : `${saveLabel}`}
        </StyledTemplateButton>
      )
    case 'question':
      return (
        <StyledTemplateButton
          disabled={
            (paidEvent === 'Paid' && !activeTicket ? true : false) ||
            activeQuestion.question === '' ||
            activeQuestion.hint === '' ||
            activeQuestion.type === null
          }
          variant="contained"
          startIcon={<SaveIcon></SaveIcon>}
          onClick={() => {
            if (
              activeQuestion.question === '' ||
              activeQuestion.hint === '' ||
              activeQuestion.type === null
            ) {
              if (activeTag.name === '') {
                questionErrorHandler((prevState) => ({
                  ...prevState,
                  questionEmpty: true,
                }))
              }
              if (activeQuestion.hint === '') {
                questionErrorHandler((prevState) => ({
                  ...prevState,
                  hintEmpty: true,
                }))
              }
              if (activeQuestion.type === null) {
                questionErrorHandler((prevState) => ({
                  ...prevState,
                  type: true,
                }))
              }
            } else if (activeTicket) {
              activeTicket.forEach((activeTicket) => {
                if (!questionsPool.hasOwnProperty(activeTicket.name)) {
                  setQuestionsPool((prevState) => ({ ...prevState, [activeTicket.name]: [] }))
                }
                setQuestionSaved(true)
                setQuestionsPool((prevState) => {
                  const existingQuestions = prevState[activeTicket.name]
                  const existingQuestionIndex = existingQuestions.findIndex(
                    (question) => question.id === activeQuestion.id,
                  )

                  if (existingQuestionIndex !== -1) {
                    const updatedQuestions = [...existingQuestions]
                    updatedQuestions[existingQuestionIndex] = {
                      ...activeQuestion,
                      id: activeQuestion.id,
                    }

                    return {
                      ...prevState,
                      [activeTicket.name]: updatedQuestions,
                    }
                  } else {
                    return {
                      ...prevState,
                      [activeTicket.name]: [
                        ...prevState[activeTicket.name],
                        { ...activeQuestion, id: new Date().getTime() },
                      ],
                    }
                  }
                })
                clearActiveQuestion(setActiveQuestion)
              })
            } else {
              setQuestionSaved(true)
              addQuestionToPool(activeQuestion, setQuestionsPool)
              clearActiveQuestion(setActiveQuestion)
              questionErrorHandler((prevState) => ({
                ...prevState,
                questionEmpty: false,
                hintEmpty: false,
                type: false,
              }))
            }
          }}
          sx={{
            backgroundColor: !questionSaved ? color.draftButton : color.draftSaved,
            '&:hover': { backgroundColor: !questionSaved ? color.draftButton : color.draftSaved },
          }}
        >
          {!questionSaved ? `${label}` : `${saveLabel}`}
        </StyledTemplateButton>
      )

    case 'template':
      return (
        <StyledTemplateButton
          variant="contained"
          startIcon={loading ? <CircularProgress size={24} /> : <DifferenceIcon></DifferenceIcon>}
          onClick={async () => {
            await saveTemplate()
          }}
          disabled={loading}
          sx={{
            backgroundColor: color.basicInfoNoButton,
            '&:hover': { backgroundColor: color.basicInfoNoButton },
          }}
        >
          {loading ? 'Saving...' : label}
        </StyledTemplateButton>
      )
    case 'seeAll':
      return (
        <StyledTemplateButton
          variant="contained"
          startIcon={<ForwardIcon></ForwardIcon>}
          onClick={(v) => onClick(v)}
          sx={{
            backgroundColor: color.primary,
            '&:hover': { backgroundColor: color.primary },
          }}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'close':
      return (
        <StyledTemplateButton
          variant="contained"
          startIcon={<CloseIcon></CloseIcon>}
          sx={{
            backgroundColor: color.primary,
            '&:hover': { backgroundColor: color.primary },
          }}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'closeModal':
      return (
        <StyledCloseModalButton
          variant="contained"
          sx={{
            backgroundColor: color.primary,
            '&:hover': { backgroundColor: color.primary },
          }}
        >
          {label}
        </StyledCloseModalButton>
      )
    case 'sidebar':
      return (
        <StyledTemplateButton
          variant="contained"
          startIcon={<PeopleIcon></PeopleIcon>}
          sx={{
            backgroundColor: color.primary,
            '&:hover': { backgroundColor: color.primary },
          }}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'newKiosk':
      return (
        <StyledTemplateButton
          variant="outlined"
          startIcon={<AddCircleIcon></AddCircleIcon>}
          onClick={(v) => onClick(v)}
          sx={createKioskButtonStyle}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'newOnboarding':
      return (
        <StyledTemplateButton
          variant="outlined"
          startIcon={<AddCircleIcon></AddCircleIcon>}
          sx={createKioskButtonStyle}
          onClick={async () => await onClick()}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'saveTag':
      return (
        <StyledTemplateButton
          disabled={disableSaveButton}
          variant="outlined"
          startIcon={<SaveIcon></SaveIcon>}
          sx={createKioskButtonStyle}
          onClick={() => {
            setTagSaved(true)
            updateExistingTag(activeTag, setTagsPool, setDuplicateAlert, setAllTags, token)
            clearActiveTag(setActiveTag)
            setdisableSaveButton(true)
          }}
        >
          {!tagSaved ? `${label}` : `${saveLabel}`}
        </StyledTemplateButton>
      )
    case 'newTag':
      return (
        <StyledTemplateButton
          variant="outlined"
          startIcon={<AddCircleIcon></AddCircleIcon>}
          sx={createKioskButtonStyle}
          onClick={() => {
            setdisableSaveButton(true)
            if (activeTag.name === '' || activeTag.description === '') {
              if (activeTag.name === '') {
                tagErrorHandler((prevState) => ({
                  ...prevState,
                  tagEmpty: true,
                }))
              }
              if (activeTag.description === '') {
                tagErrorHandler((prevState) => ({
                  ...prevState,
                  descriptionEmpty: true,
                }))
              }
            } else {
              addTagToThePool(activeTag, setTagsPool, setDuplicateAlert, setAllTags, token)
              clearActiveTag(setActiveTag)
              tagErrorHandler((prevState) => ({
                ...prevState,
                tagEmpty: false,
                descriptionEmpty: false,
              }))
            }
          }}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'newLabel':
      return (
        <StyledTemplateButton
          variant="outlined"
          startIcon={<AddCircleIcon></AddCircleIcon>}
          sx={createKioskButtonStyle}
          onClick={() => {
            insertNewLabel(setSecurity)
          }}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'uploadImage':
      return (
        <StyledTemplateButton
          variant="outlined"
          startIcon={<ImageIcon></ImageIcon>}
          sx={createKioskButtonStyle}
          onClick={() => {
            refFunc()
          }}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'removeImage':
      return (
        <StyledTemplateButton
          variant="outlined"
          startIcon={<DeleteIcon></DeleteIcon>}
          sx={createKioskButtonStyle}
          onClick={() => {
            removeUploadedImage(uploadedImageIndex)
          }}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'approvalReminder':
      return (
        <StyledTemplateButton
          variant="outlined"
          startIcon={<AddAlertIcon></AddAlertIcon>}
          sx={approvalReminderStyle}
          onClick={async () => {
            const resp = await sendApprovalEmail(formInfo?.id, token)
            setReminderDate(resp.reminderSent)
            setReminderSentTo(resp.reminderSentTo)
          }}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'defaultDisplay':
      return (
        <StyledTemplateButtonRecurrence
          variant="contained"
          active={active}
          startIcon={<Action style={{ color: color.backgroundPrimary, padding: 0 }} />}
          onClick={(v) => onClick(v)}
        >
          {label}
        </StyledTemplateButtonRecurrence>
      )
    case 'listDisplay':
      return (
        <StyledTemplateButtonRecurrence
          variant="contained"
          active={active}
          startIcon={<FormatListBulletedIcon style={{ color: color.backgroundPrimary }} />}
          onClick={(v) => onClick(v)}
        >
          {label}
        </StyledTemplateButtonRecurrence>
      )
    default:
      return <>Use case not supported.</>
  }
}

export default TemplateButton

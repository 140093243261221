import color from '../util/colors'

export const paidChoices = [
  { title: 'Yes', value: true, color: color.basicInfoYesButton },
  { title: 'No', value: false, color: color.basicInfoNoButton },
]

export const typeChoices = [
  { title: 'A Ministry', value: 'ministry', color: color.basicInfoYesButton },
  { title: 'A Group', value: 'group', color: color.basicInfoGroup },
  { title: 'A Class', value: 'class', color: color.description },
]

import * as React from 'react'
import './NavbarButton.Styled.jsx'
import PropTypes from 'prop-types'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import Person2Icon from '@mui/icons-material/Person2'
import ForwardIcon from '@mui/icons-material/Forward'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { ButtonStyle } from './NavbarButton.Styled.jsx'

const iconSwitcher = (iconName) => {
  switch (iconName) {
    case 'person':
      return <PersonIcon />
    case 'person2':
      return <Person2Icon />
    case 'forward':
      return <ForwardIcon />
    case 'add':
      return <AddCircleIcon />
    default:
      return <PeopleAltIcon />
  }
}

const NavbarButton = ({ buttonText, iconName, onClickFunction, disabled }) => {
  return (
    <ButtonStyle
      sx={{ whiteSpace: 'nowrap' }}
      onClick={onClickFunction}
      className="Button"
      variant="contained"
      disabled={disabled ? true : false}
      startIcon={iconSwitcher(iconName)}
    >
      {buttonText}
    </ButtonStyle>
  )
}

NavbarButton.propTypes = {
  buttonText: PropTypes.string,
  iconName: PropTypes.string,
  onClickFunction: PropTypes.func,
}

export default NavbarButton

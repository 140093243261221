import { RowWrapper, RowLeftText, RowRightText } from '../Preview.style'

const MailReminders = ({ signUpBody, paymentBody, cancelationBody, promoBody, signUpSubject }) => {
  return (
    <>
      {signUpBody && (
        <RowWrapper>
          {/* Reminders - Sign Up Reminder*/}
          <RowLeftText>{'To Sign Up:'}</RowLeftText>
          <RowRightText>{''}</RowRightText>
        </RowWrapper>
      )}
      {paymentBody && paymentBody.length > 0 && (
        <RowWrapper>
          {/* Reminders - Sign Up Reminder*/}
          <RowLeftText>{'Payment: '}</RowLeftText>
          <RowRightText>{''}</RowRightText>
        </RowWrapper>
      )}
      {cancelationBody && cancelationBody.length > 0 && (
        <RowWrapper>
          {/* Reminders - Sign Up Reminder*/}
          <RowLeftText>{'Cancelation: '}</RowLeftText>
          <RowRightText>{''}</RowRightText>
        </RowWrapper>
      )}
      {promoBody && promoBody.length > 0 && (
        <RowWrapper>
          {/* Reminders - Sign Up Reminder*/}
          <RowLeftText>{'Promotion Alert: '}</RowLeftText>
          <RowRightText>{''}</RowRightText>
        </RowWrapper>
      )}
    </>
  )
}

export default MailReminders

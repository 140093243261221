/* eslint-disable no-unused-vars */
import { events, groupData, searchData, questionData } from '../../util/testData'
import { useState } from 'react'
import MediaCard from '../Card/Card'
import SearchComponent from '../SearchComponent/SearchComponent'
import { Route, Routes } from 'react-router-dom'
import TestingComponent from '../TestingComponent/TestingComponent'
import ViewButtonComponent from '../ViewButtonsComponent/ViewButtonsComponent'
import FilteringButtons from '../FilteringButtons/FilteringButtons'
import TabsComponent from '../TabsComponent/TabsComponent'
import NavbarButton from '../NavbarButton/NavbarButton'
import MainView from '../../screens/MainView/MainView'
import AdminLogin from '../../screens/AdminPortalLogin/AdminLogin'
import EventCreationModal from '../EventCreationModal/EventCreationModal'
import ExperienceListCard from '../ExperienceListCard/ExperienceListCard'
import ListAccordion from '../ListAccordion/ListAccordion'
import DateFilter from '../DateFilter/DateFilter'
import TableComponent from '../TableComponent/TableComponent'
import GatheringsFilter from '../GatheringsFilter/GatheringsFilter'
import ExperiencesFilter from '../ExperiencesFilter/ExperiencesFilter'
import Form from '../Form/Form'
import TemplateCard from '../TemplateCard/TemplateCard'
import AllGatheringsScreen from '../../screens/AllGatheringsScreen/AllGatheringsScreen'
import GatheringsTemplateScreen from '../../screens/GatheringsTemplateScreen/GatheringsTemplateScreen'
import AllExperiencesScreen from '../../screens/AllExperiencesScreen/AllExperiencesScreen'
import CreateFromScratchButton from '../CreateFromScratchButton/CreateFromScratchButton'
import Checklist from '../Checklist/Checklist'
import GatheringTemplatesFilter from '../GatheringTemplatesFilter/GatheringTemplatesFilter'
import GatheringsCreationScreen from '../../screens/GatheringsCreation/GatheringsCreationScreen'
import SelectBasicInfo from '../SelectBasicInfo/SelectBasicInfo'
import AddDetails from '../AddDetails/AddDetails'
import { paidChoices, typeChoices } from '../../constants/BasicInfoChoices'
import GroupSearch from '../GroupSearch/GroupSearch'
import QuestionCreation from '../QuestionCreation/QuestionCreation'
import AtendeesScreen from '../../components/GatheringCreation/Atendees/AtendeesScreen'
import FileUpload from '../FileUpload/FileUpload'
import GroupSidebar from '../GroupSidebar/GroupSidebar'
import AdditionalInfo from '../GatheringCreation/AdditionalInfo/AdditionalInfo'
import InputFieldModule from '../RegistrationConfirmation/components/InputFieldModule'
import RosterCheckin from '../GatheringCreation/RosterCheckin/RosterCheckin'
import CheckinKiosk from '../GatheringCreation/CheckinKiosk/CheckinKiosk'
import CheckinType from '../GatheringCreation/CheckinType/CheckinType'
import Onboarding from '../GatheringCreation/Onboarding/Onboarding'
import TemplatePreview from '../../screens/TemplatePreview/TemplatePreview'
import { ErrorScreen } from '../../screens/ErrorScreen/ErrorScreen'
import AllDraftsScreen from '../../screens/AllDraftsScreen/AllDraftsScreen'
import AllTemplatesScreen from '../../screens/AllTemplatesScreen/AllTemplatesScreen'
import AllArchivedDraftsScreen from '../../screens/AllArchivedDraftsScreen/AllArchivedDraftsScreen'
import { ModalPreviewConfirmation } from '../RegistrationConfirmation/components/ConfirmationModalComponent'
import ApprovalScreen from '../../screens/Approval/ApprovalScreen'
import ApprovalRedirectScreen from '../../screens/ApprovalRedirectScreen/ApprovalRedirectScreen'

const Router = () => {
  const data = events(10)
  const tableData = events(15)
  const gatheringData = events(1)[0]
  const groups = groupData(10)
  const questions = questionData(10)
  const [dummyValue, dummySetter] = useState(0)

  return (
    <Routes>
      <Route path="/" element={<MainView />}></Route>
      <Route path="/gatherings" element={<AllGatheringsScreen />}></Route>
      <Route path="/approval/:id" element={<ApprovalScreen />}></Route>
      <Route path="/approvalView/:id" element={<ApprovalRedirectScreen />}></Route>
      <Route path="/drafts" element={<AllDraftsScreen />}></Route>
      <Route path="/templates" element={<AllTemplatesScreen />}></Route>
      <Route path="/archivedDrafts" element={<AllArchivedDraftsScreen />}></Route>

      <Route path="/gatherings/templates" element={<GatheringsTemplateScreen />}></Route>

      <Route
        path="/components/gatheringsTemplatesFilter"
        element={<GatheringTemplatesFilter gatherings={data} />}
      ></Route>

      <Route path="/experiences" element={<AllExperiencesScreen />}></Route>
      <Route path="/createGathering" element={<GatheringsCreationScreen />}></Route>
      <Route path="/templatePreview" element={<TemplatePreview />}></Route>
      <Route path="/components/dateFilter" element={<DateFilter />}></Route>
      <Route path="/components/gatheringsFilter" element={<GatheringsFilter />}></Route>
      <Route path="/components/experiencesFilter" element={<ExperiencesFilter />}></Route>
      <Route path="/components/checklist" element={<Checklist></Checklist>}></Route>
      <Route path="/login" element={<AdminLogin></AdminLogin>}></Route>
      <Route
        exact
        path="/components/calendar/:limit?"
        element={<TestingComponent component={'calendar'} />}
      ></Route>
      <Route
        exact
        path="/components/listComponent/:limit?"
        element={
          <div style={{ backgroundColor: 'grey', width: '100%', height: '100%' }}>
            <TestingComponent component={'listComponent'} />
          </div>
        }
      ></Route>
      <Route
        exact
        path="/components/card"
        element={
          <div style={{ width: '400px' }}>
            <MediaCard
              id={data[0].id}
              image={data[0].image}
              isFavorite={data[0].isFavourite}
              status={data[0].status}
              title={data[0].title}
            />
          </div>
        }
      ></Route>
      <Route
        exact
        path="/components/profile/:limit?"
        element={<TestingComponent component={'profile'} />}
      ></Route>
      <Route
        exact
        path="/components/search"
        element={<SearchComponent searchData={searchData} />}
      ></Route>
      <Route
        exact
        path="/components/confirmation"
        element={<ModalPreviewConfirmation modalStatus={true} />}
      ></Route>
      <Route exact path="/components/viewButtons" element={<ViewButtonComponent />}></Route>
      <Route
        exact
        path="/components/sidebar/:limit?"
        element={<TestingComponent component={'sidebar'} />}
      ></Route>
      <Route exact path="/components/filteringButtons" element={<FilteringButtons />}></Route>
      <Route exact path="/components/tabs" element={<TabsComponent />}></Route>
      <Route
        exact
        path="/components/navbarButton"
        element={<NavbarButton buttonText={'Create new'} iconName={''}></NavbarButton>}
      ></Route>
      <Route
        exact
        path="/components/eventModal"
        element={<EventCreationModal modalStatus={true}></EventCreationModal>}
      ></Route>
      <Route
        exact
        path="/components/experienceListCard"
        element={
          <ExperienceListCard onClickEvent={() => {}} experienceData={data[0]}></ExperienceListCard>
        }
      ></Route>

      <Route
        exact
        path="/components/listAccordion"
        element={<ListAccordion gatheringData={data} experienceData={data[0]}></ListAccordion>}
      ></Route>
      <Route
        exact
        path="/components/experienceTableCard"
        element={<TestingComponent component={'experienceTableCard'}></TestingComponent>}
      ></Route>
      <Route
        exact
        path="/components/gatheringTableCard"
        element={<TestingComponent component={'gatheringTableCard'}></TestingComponent>}
      ></Route>
      <Route
        exact
        path="/components/tableComponent"
        element={
          <TableComponent
            tableBodyData={tableData}
            tableDataVariant={'Experiences'}
            rowsPerPageArray={[5, 10, 25]}
            headerDataVariant={'Experiences'}
            rowsPerPageParentSetter={dummySetter}
            parrentPageSetter={dummySetter}
          ></TableComponent>
        }
      ></Route>
      <Route
        exact
        path="/components/templatesTableComponent"
        element={
          <TableComponent
            tableBodyData={tableData}
            tableDataVariant={'Templates'}
            rowsPerPageArray={[5, 10, 25]}
            headerDataVariant={'Templates'}
            rowsPerPageParentSetter={dummySetter}
            parrentPageSetter={dummySetter}
          ></TableComponent>
        }
      ></Route>
      <Route
        exact
        path="/components/paidEvent"
        element={
          <Form title={'Basic Info'}>
            <SelectBasicInfo title={'Is this a paid event?'} choices={paidChoices} />
          </Form>
        }
      ></Route>
      <Route
        exact
        path="/components/paidEventType"
        element={
          <Form title={'Basic Info'}>
            <SelectBasicInfo title={'Select the type of your gathering:'} choices={typeChoices} />
          </Form>
        }
      ></Route>
      <Route
        exact
        path="/components/addDetails"
        element={
          <Form title={'Basic Info'}>
            <AddDetails
              firstRowLabel="Title of the gathering"
              secondRowLabel="Description"
            ></AddDetails>
          </Form>
        }
      ></Route>
      <Route
        exact
        path="/components/experiencesTable"
        element={
          <TableComponent
            experienceData={tableData}
            rowsPerPageArray={[5, 10, 25]}
          ></TableComponent>
        }
      ></Route>
      <Route
        exact
        path="/components/templateCard"
        element={<TemplateCard gatheringData={gatheringData}></TemplateCard>}
      ></Route>
      <Route
        exact
        path="/components/templateCardGrids/:limit?"
        element={<TestingComponent component={'templateCardGrids'}></TestingComponent>}
      ></Route>
      <Route
        exact
        path="/components/createFromScratchButton"
        element={<CreateFromScratchButton navigationRoute={''}></CreateFromScratchButton>}
      ></Route>
      <Route
        exact
        path="/components/templateButton"
        element={<TestingComponent component={'templateButton'}></TestingComponent>}
      ></Route>
      <Route
        exact
        path="/components/groupFrame"
        element={<TestingComponent component={'groupFrame'}></TestingComponent>}
      ></Route>
      <Route
        exact
        path="/components/groupGrid"
        element={<TestingComponent component={'groupGrid'}></TestingComponent>}
      ></Route>
      <Route
        exact
        path="/components/groupSearch"
        element={<GroupSearch groupData={groups} variant={'group'}></GroupSearch>}
      ></Route>
      <Route
        exact
        path="/components/tag"
        element={<TestingComponent component={'tag'}></TestingComponent>}
      ></Route>
      <Route
        exact
        path="/components/Atendees"
        element={
          <Form title={'Attendees'}>
            <AtendeesScreen groupData={groups}></AtendeesScreen>
          </Form>
        }
      ></Route>
      <Route
        exact
        path="/components/fileUpload"
        element={
          <Form title={'Additional Info'}>
            <FileUpload></FileUpload>
          </Form>
        }
      ></Route>
      <Route
        exact
        path="/components/questionCreation"
        element={
          <Form title={'Additional Info'}>
            <QuestionCreation></QuestionCreation>
          </Form>
        }
      ></Route>
      <Route
        exact
        path="/components/groupSidebar"
        element={<GroupSidebar group={groups[0]}></GroupSidebar>}
      ></Route>
      <Route
        exact
        path="/components/additionalInfo"
        element={<AdditionalInfo></AdditionalInfo>}
      ></Route>
      <Route exact path="/components/inputFieldModule" element={<InputFieldModule />} />
      <Route
        exact
        path="/components/pin"
        element={<TestingComponent component={'pin'}></TestingComponent>}
      ></Route>
      <Route
        exact
        path="/components/rosterInviteOnly"
        element={
          <Form title={'Checkins > Checkin via Roster > Invite-Only'}>
            <RosterCheckin groupData={groups} inviteOnly={true}></RosterCheckin>
          </Form>
        }
      ></Route>
      <Route
        exact
        path="/components/roster"
        element={
          <Form title={'Checkins > Checkin via Roster > Open'}>
            <RosterCheckin groupData={groups}></RosterCheckin>
          </Form>
        }
      ></Route>
      <Route
        exact
        path="/components/kiosk"
        element={<TestingComponent component={'kiosk'}></TestingComponent>}
      ></Route>
      <Route
        exact
        path="/components/checkinKiosk"
        element={
          <Form title={'Checkins > Kiosk'}>
            <CheckinKiosk groupData={groups}></CheckinKiosk>
          </Form>
        }
      ></Route>
      <Route
        exact
        path="/components/checkinType"
        element={
          <Form title={'Checkins'}>
            <CheckinType></CheckinType>
          </Form>
        }
      ></Route>
      <Route
        exact
        path="/components/onboarding"
        element={
          <Form title={'Onboarding'}>
            <Onboarding></Onboarding>
          </Form>
        }
      ></Route>
      <Route exact path="/components/errorScreen" element={<ErrorScreen></ErrorScreen>}></Route>
    </Routes>
  )
}

export default Router

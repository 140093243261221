import styled from 'styled-components'

const GroupSearchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  column-gap: 10px;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 25px;
  }
`

const GroupSearchText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  color: var(--title);
  width: 30%;
  @media (max-width: 1024px) {
    width: 100%;
    text-align: center;
  }
`
const SearchComponentContainer = styled.div`
  width: 70%;
  @media (max-width: 1024px) {
    width: 90%;
    padding-right: 0;
  }
`

export { GroupSearchContainer, GroupSearchText, SearchComponentContainer }

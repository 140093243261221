import {
  QuestionCreationContainer,
  TextFieldArea,
  QuestionCreationButtonArea,
  HeaderTextArea,
  TagGridArea,
} from './QuestionCreation.style'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useEffect, useState } from 'react'
import color from '../../util/colors'
import TemplateButton from '../../components/TemplateButton/TemplateButton'
import TagGrid from '../TagGrid/TagGrid'
import example from '../../assets/questionCreation.json'
import {
  addQuestionToPool,
  removeQuestionFromPool,
  clearActiveQuestion,
  makeQuestionActive,
} from '../../util/questionCreation'

const QuestionCreation = ({
  setUserQuestions,
  formInfo,
  activeTicket,
  addQuestionsToTicket,
  ticketInfo,
  paidEvent,
}) => {
  const [errorHandle, setErrorHandle] = useState({
    questionEmpty: false,
    hintEmpty: false,
    type: false,
  })

  useEffect(() => {
    if (paidEvent === 'Paid' && ticketInfo?.questions) {
      setQuestionsPool(ticketInfo?.questions)
    } else if (paidEvent !== 'Paid' && formInfo?.savedQuestions) {
      setQuestionsPool(formInfo?.savedQuestions)
    } else {
      setQuestionsPool([])
    }
  }, [])

  const [questionSaved, setQuestionSaved] = useState(false)
  const [activeQuestion, setActiveQuestion] = useState({
    id: '',
    question: '',
    hint: '',
    type: null,
  })

  const [questionsPool, setQuestionsPool] = useState()

  useEffect(() => {
    if (activeTicket && paidEvent === 'Paid') {
      addQuestionsToTicket(questionsPool)
    } else {
      setUserQuestions(questionsPool)
    }
  }, [questionsPool])

  return (
    <QuestionCreationContainer>
      <HeaderTextArea>
        {activeTicket
          ? `Add a question for attendees - ${activeTicket.name}`
          : 'Add a question for attendees'}
      </HeaderTextArea>
      <TextFieldArea>
        <TextField
          sx={{ width: '100%' }}
          id="outlined-basic"
          label={'Question'}
          variant="outlined"
          value={activeQuestion.question}
          onChange={(e) =>
            setActiveQuestion((prevState) => ({
              ...prevState,
              question: e.target.value,
            }))
          }
          onClick={() => setQuestionSaved(false)}
          error={errorHandle.questionEmpty}
          helperText={!errorHandle.questionEmpty ? '' : 'This field cannot be empty.'}
        />
        <TextField
          sx={{ width: '100%' }}
          id="outlined-basic"
          label={'Hint'}
          variant="outlined"
          value={activeQuestion.hint}
          onChange={(e) =>
            setActiveQuestion((prevState) => ({
              ...prevState,
              hint: e.target.value,
            }))
          }
          onClick={() => setQuestionSaved(false)}
          error={errorHandle.hintEmpty}
          helperText={!errorHandle.hintEmpty ? '' : 'This field cannot be empty.'}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={['Numeric', 'Textual']}
          sx={{ width: '100%' }}
          value={activeQuestion.type}
          onChange={(event, newValue) =>
            setActiveQuestion((prevState) => ({
              ...prevState,
              type: newValue,
            }))
          }
          onClick={() => setQuestionSaved(false)}
          renderInput={(params) => (
            <TextField
              error={errorHandle.type}
              helperText={!errorHandle.hintEmpty ? '' : 'Please select a valid question type.'}
              {...params}
              label="Type"
            />
          )}
          popupIcon={<ExpandMoreIcon sx={{ color: color.primary }} />}
        />
      </TextFieldArea>
      <QuestionCreationButtonArea>
        <TemplateButton
          variant={'question'}
          label={'Save Question'}
          saveLabel={'Question Saved'}
          questionSaved={questionSaved}
          setQuestionSaved={setQuestionSaved}
          activeQuestion={activeQuestion}
          setActiveQuestion={setActiveQuestion}
          addQuestionToPool={addQuestionToPool}
          setQuestionsPool={setQuestionsPool}
          clearActiveQuestion={clearActiveQuestion}
          questionErrorHandler={setErrorHandle}
          activeTicket={activeTicket}
          questionsPool={questionsPool}
          paidEvent={paidEvent}
        ></TemplateButton>
      </QuestionCreationButtonArea>
      {paidEvent === 'Paid' && activeTicket ? (
        activeTicket.map((ticket) => (
          <TagGridArea>
            {questionsPool[ticket.name] && questionsPool[ticket.name].length > 0 && (
              <TagGrid
                tagPool={questionsPool[ticket.name]}
                tagVariant={'question'}
                notifText={`Saved questions for Ticket: ${ticket.name}:`}
                makeQuestionActive={makeQuestionActive}
                setQuestionSaved={setQuestionSaved}
                removeQuestionFromPool={removeQuestionFromPool}
                setQuestionsPool={setQuestionsPool}
                setActiveQuestion={setActiveQuestion}
                activeTicket={activeTicket}
              ></TagGrid>
            )}
          </TagGridArea>
        ))
      ) : paidEvent !== 'Paid' ? (
        <TagGridArea>
          {questionsPool && questionsPool.length > 0 && (
            <TagGrid
              tagPool={questionsPool}
              tagVariant={'question'}
              notifText={'Saved questions (click to edit):'}
              makeQuestionActive={makeQuestionActive}
              setQuestionSaved={setQuestionSaved}
              removeQuestionFromPool={removeQuestionFromPool}
              setQuestionsPool={setQuestionsPool}
              setActiveQuestion={setActiveQuestion}
            ></TagGrid>
          )}
        </TagGridArea>
      ) : (
        <></>
      )}

      <TagGridArea>
        <TagGrid
          tagPool={example.ExampleQuestions}
          tagVariant={'exampleQuestion'}
          notifText={'Examples you can use (see below hints):'}
          makeQuestionActive={makeQuestionActive}
          setQuestionSaved={setQuestionSaved}
          setQuestionsPool={setQuestionsPool}
          setActiveQuestion={setActiveQuestion}
        ></TagGrid>
      </TagGridArea>
    </QuestionCreationContainer>
  )
}

export default QuestionCreation

import styled from 'styled-components'

const GroupGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 0 1.5rem 0;
  width: 100%;
`
const GroupGridFirstRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
`

const PrefixText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.004em;
  color: var(--title);
`

const MainText = styled(PrefixText)`
  font-weight: 700;
  margin-left: 4px;
`

const Divider = styled.hr`
  border: none;
  border-top: 1px dashed var(--dashLine);
  height: 1px;
  width: 100%;
  margin-top: 0.5rem;
  margin-left: 15px;
`

const GroupGridArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 1rem;
`

const GroupItem = styled.div`
  padding: 0.5rem;
`

export {
  GroupGridContainer,
  GroupGridFirstRow,
  PrefixText,
  MainText,
  Divider,
  GroupGridArea,
  GroupItem,
}

import styled from 'styled-components'
import colors from '../../util/colors'

const TextEditorFormContainer = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 100%;
  height: 340px;
  contain: size;

  border: 1px solid ${colors.borderRest};
  border-radius: 9px;
  overflow: hidden;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  .ql-container {
    border: none;
  }

  .ql-toolbar {
    background: ${colors.backgroundSecondary};
  }

  .ql-editor.ql-blank {
    width: 100%;
    text-indent: 10px;
    font-size: 16px;
    padding: 14px 0px;
  }

  /* Editor - text area placeholder styles */
  .ql-editor.ql-blank::before {
    font-size: 16px;
    width: 100%;
    font-style: normal;
    height: auto;
    text-indent: -4px;
    padding: 0px;
    color: ${colors.borderRest};
  }

  #creation,
  #confirmation,
  .quill {
    width: 100%;
  }

  .subject-input {
    font-size: 16px;
    border-bottom: 1px solid ${colors.borderRest} !important;
  }

  .subject-input::placeholder {
    color: ${colors.borderRest} !important;
  }

  .subject-input:focus {
    outline: none;
  }
`

const SubjectForm = styled.div`
  width: 100%;
  height: 30px;
  background-color: red;
`

export { TextEditorFormContainer, SubjectForm }

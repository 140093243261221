import SelectBasicInfo from '../../SelectBasicInfo/SelectBasicInfo'
import { ChosePaidContainer } from './ChosePaid.style'
import infoData from '../../../assets/chosePaid.json'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { useState, useEffect } from 'react'

const ChosePaid = () => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [paidEvent, setPaidEvent] = useState(formInfo?.paidEvent ? formInfo.paidEvent : {})

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.paidEvent = paidEvent
    if (paidEvent.eventIsPaid === 'Free') {
      newFormInfo.missingInformation = formInfo?.missingInformation?.filter((m) => m !== 'Payments')
      if (newFormInfo?.paymentsInfo) {
        delete newFormInfo.paymentsInfo
      }
      if (newFormInfo?.additionalInfo) {
        delete newFormInfo.additionalInfo
      }
    } else {
      if (
        !newFormInfo?.paymentsInfo &&
        formInfo?.activeStep !== formInfo?.highestStep &&
        formInfo?.highestStep >= 6
      ) {
        newFormInfo.missingInformation = [...formInfo?.missingInformation, 'Payments']
      }
    }
    setFormInfo(newFormInfo)
  }, [paidEvent])

  // This will store boolean value based on the event beeing paid or unpaid
  function setEvent(value) {
    setPaidEvent((info) => {
      info.eventIsPaid = value
      return { ...info }
    })
  }

  return (
    <ChosePaidContainer>
      <SelectBasicInfo
        title={infoData.question}
        choices={infoData.choices}
        formInfo={formInfo}
        setPaidEvent={setEvent}
        variant={'paidEvent'}
      ></SelectBasicInfo>
    </ChosePaidContainer>
  )
}

export default ChosePaid

import { useState } from 'react'
import './GatheringsTemplateScreen.css'
import TabsComponent from '../../components/TabsComponent/TabsComponent'
import ViewButtonComponent from '../../components/ViewButtonsComponent/ViewButtonsComponent'
import NavbarButton from '../../components/NavbarButton/NavbarButton'
import { useAuth } from '../../hooks/useAuth'
import useProtectedRoute from '../../hooks/useProtectedRoute'
import GatheringsTemplatesGrid from '../../components/GatheringsTemplatesGrid/GatheringsTemplatesGrid'
import { useEvents } from '../../hooks/useEvents'
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded'
import { useView } from '../../util/zustant/store'
import AllTemplatesGathering from '../../components/AllTemplatesGathering/AllTemplatesGathering'
const GatheringsTemplateScreen = () => {
  const [gatheringSelectedTab, setGatheringsSelectedTab] = useState('Published')

  const { profileData, gatherings, experiences } = useEvents()
  const { view } = useView()

  const { authenticated, token } = useAuth()

  useProtectedRoute()

  if (!authenticated || !profileData) return <></>

  return (
    <div className="allGatheringsScreenMainContainer">
      {/* Components Area*/}
      <section className="allGatherinsScreenComponentArea">
        {/* Top Row Area 2*/}
        <section className="allGatheringsScreenRowArea2">
          {/* Left Tab Component */}
          <div className="allGatheringsScreenTabsComponentArea">
            <TabsComponent
              tabVariant={'Header'}
              activeTabOnPage={gatheringSelectedTab}
              tabSelectorFunction={setGatheringsSelectedTab}
            />
          </div>
          {/* View and Navbar Button Components */}
          <div className="allGatheringsScreenNavbarAndViewButtonComponentArea">
            <ViewButtonComponent SecondIcon={TableRowsRoundedIcon} />
            <NavbarButton iconName={'add'} buttonText={'Experience'} disabled={true}></NavbarButton>
            <NavbarButton iconName={'add'} buttonText={'Gathering'} disabled={false}></NavbarButton>
          </div>
        </section>

        {/* Screens Section Area */}
        {view === 1 ? (
          <GatheringsTemplatesGrid gatherings={gatherings}></GatheringsTemplatesGrid>
        ) : (
          <AllTemplatesGathering
            gatherings={gatherings}
            experiences={experiences}
            token={token}
          ></AllTemplatesGathering>
        )}
      </section>
    </div>
  )
}

export default GatheringsTemplateScreen

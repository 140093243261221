import * as React from 'react'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import color from '../../util/colors'
import { convertDateString } from '../../util/date'
import {
  StyledTableRow,
  StyledTableCell,
  StyledTableCellButton,
  StyledTableImageCell,
} from './GatheringTableCard.style'
import { useFormGatherings } from '../../util/zustant/store'
import { tableRowColor } from '../../util/helpers'
import { useNavigate } from 'react-router-dom'
import { archiveGathering } from '../../util/events'
import { useAuth } from '../../hooks/useAuth'
import { useEvents } from '../../hooks/useEvents'

const GatheringTableCard = ({ gatheringData, indexPosition, recurring, parentExperience }) => {
  const [highlighted, setHighlighted] = useState(false)
  const { setFormInfo, formInfo } = useFormGatherings()
  const { token } = useAuth()
  const { allCollections, setAllCollections } = useEvents()
  const [gatheringObject, setGatheringObject] = useState()
  const navigate = useNavigate()

  function isRecurring(recurring) {
    if (recurring) {
      return 'Yes'
    } else {
      return 'No'
    }
  }

  useEffect(() => {
    if (!gatheringObject) {
      return
    }
    setFormInfo(gatheringObject)
  }, [gatheringObject])

  useEffect(() => {
    if (!gatheringObject) {
      return
    }

    if (formInfo) {
      setTimeout(() => navigate('/createGathering'), 100)
    }
  }, [gatheringObject])

  return (
    <StyledTableRow
      hover
      onClick={() => setHighlighted(!highlighted)}
      sx={{
        backgroundColor: tableRowColor(indexPosition),
        borderLeft: `5px solid ${tableRowColor(indexPosition)}`,
      }}
    >
      <StyledTableImageCell variant="body">
        <img
          src={gatheringData?.imageUpload?.eventImage}
          style={{ width: 79, height: 48, borderRadius: 5 }}
          alt="Gathering img"
        ></img>
      </StyledTableImageCell>
      <StyledTableCell variant="body">
        <div>{gatheringData.gatheringDetails?.title}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>{convertDateString(gatheringData?.logisticsInfo?.event?.startDate)}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>
          {gatheringData.created ? convertDateString(gatheringData.created) : 'Not updated'}
        </div>
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <StyledTableCellButton
          sx={{ textTransform: 'none', color: color.primary }}
          variant="text"
          startIcon={<OpenInNewIcon></OpenInNewIcon>}
          onClick={async () => {
            setGatheringObject(gatheringData)
          }}
        >
          Edit
        </StyledTableCellButton>
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <StyledTableCellButton
          sx={{ textTransform: 'none', color: color.primary }}
          variant="text"
          onClick={async () => {
            const archived = await archiveGathering(gatheringData?.id, token)
            if (archived) {
              const updatedGathering = allCollections.gathering.filter(
                (g) => g.id !== gatheringData?.id,
              )
              const newCollection = { ...allCollections, gathering: updatedGathering }
              setAllCollections(newCollection)
            }
          }}
          startIcon={<OpenInNewIcon></OpenInNewIcon>}
        >
          Archive
        </StyledTableCellButton>
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <div>{isRecurring(recurring)}</div>
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <div>{parentExperience}</div>
      </StyledTableCell>
    </StyledTableRow>
  )
}

GatheringTableCard.propTypes = {
  experienceData: PropTypes.object,
  indexPosition: PropTypes.number,
  recurring: PropTypes.bool,
  parrentExperience: PropTypes.string,
}

export default GatheringTableCard

import styled from 'styled-components'
import color from '../../../util/colors'

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
  ${(props) => props.background && `background: ${props.background};`}
`

const StyledText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--title);
  width: 30%;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding-bottom: 1.5rem;
  }
`
const TextFieldArea = styled.div`
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const ButtonsContainer = styled.div`
  box-sizing: border-box;
  width: 70%;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 2rem 0 3rem 30%;
  column-gap: 1rem;
  flex-wrap: wrap;
  @media (max-width: 950px) {
    flex-direction: column;
    gap: 1rem;
    width: 80%;
    align-items: flex-end;
    padding-left: 0;
    margin-left: auto;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`
const TagGridArea = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

const alertBoxStyle = {
  background: color.primary,
  color: color.backgroundPrimary,
}

const alertBoxIcon = {
  color: color.backgroundPrimary,
}

export {
  TagsContainer,
  RowContainer,
  StyledText,
  TextFieldArea,
  ButtonsContainer,
  TagGridArea,
  alertBoxStyle,
  alertBoxIcon,
}

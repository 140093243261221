import styled from 'styled-components'
import color from '../../../util/colors'

const SecuritySetupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const RowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`

const StyledText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--title);
  width: 30%;
  display: flex;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    padding-bottom: 1.5rem;
  }
`
const TextFieldArea = styled.div`
  width: 70%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

const ImageUploadRow = styled.div`
  display: flex;
  width: 100%;
  padding: 3rem 0 3rem 0;
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`
const ImageUploadText = styled.div`
  display: flex;
  width: 30%;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`

const ImageUploadArea = styled.div`
  display: flex;
  width: 70%;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`

const ImageBox = styled.div`
  width: 104px;
  height: 100px;
  left: 240.44px;
  top: 864px;
  border: 1px solid var(--borderLine2);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageUploadButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  row-gap: 15px;
  justify-content: center;
`
const ImageButtonWrapper = styled.label`
  width: 100%;
`

const Image = styled.img`
  width: 104px;
  height: 100px;
`
const stockImageIcon = {
  color: color.fieldColor,
  fontSize: 60,
}

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 3rem 0 4rem 0;
`

const DeleteIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const deleteIconStyle = {
  color: color.primary,
  cursor: 'pointer',
}

export {
  SecuritySetupContainer,
  RowContainer,
  StyledText,
  TextFieldArea,
  ImageUploadRow,
  ImageUploadText,
  ImageUploadArea,
  ImageUploadButtonSection,
  ImageBox,
  ImageButtonWrapper,
  Image,
  stockImageIcon,
  LabelWrapper,
  DeleteIconWrapper,
  deleteIconStyle,
}

import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import color from '../../../util/colors'
import {
  ConfirmationTitle,
  PreviewBodyConfirmation,
  ConfirmationButton,
  modalPreviewContainerConfirmation,
  ConfirmationSentBody,
  ConfirmationSentTitle,
  ConfirmationDivider,
} from './ConfirmationModalComponent.styles'

const ModalPreviewConfirmation = ({ modalStatus, setModalStatus, modalVariant, mail }) => {
  return (
    <Modal open={modalStatus}>
      <Box sx={modalPreviewContainerConfirmation}>
        <TaskAltIcon sx={{ color: color.checklistCompleteBadgeColor, fontSize: '64px' }} />
        <ConfirmationDivider marginTop="12px" />
        {mail?.paymentEmailSubject ? (
          <ConfirmationTitle>{mail?.paymentEmailSubject}</ConfirmationTitle>
        ) : (
          <ConfirmationSentTitle>Church Passes have been sent!</ConfirmationSentTitle>
        )}
        <ConfirmationDivider marginTop="12px" />
        {mail?.confirmationMessage?.length > 11 ? (
          <PreviewBodyConfirmation
            dangerouslySetInnerHTML={{ __html: mail?.confirmationMessage }}
          />
        ) : (
          <ConfirmationSentBody>
            Please have each atendee check their email to download their Church Pass.
          </ConfirmationSentBody>
        )}
        {/* TODO: remove after it is cleare that we don't this anymore */}
        <ConfirmationDivider marginTop="12px" />
        <ConfirmationButton
          onClick={() => {
            setModalStatus((prevState) => ({
              ...prevState,
              [modalVariant]: false,
            }))
          }}
        >
          Done
        </ConfirmationButton>
      </Box>
    </Modal>
  )
}

export { ModalPreviewConfirmation }

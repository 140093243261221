import { create } from 'zustand'
import { logger } from '../logger'

const loadPersistedState = (name, defaultValue) => {
  try {
    return JSON.parse(localStorage.getItem(name)) ?? defaultValue
  } catch (error) {
    logger.error(error)
    return {}
  }
}

const savePersistedState = (name, state) => {
  try {
    localStorage.setItem(name, JSON.stringify(state))
  } catch (error) {
    logger.error(error)
    return
  }
}

const resetPersistedState = (name) => {
  try {
    localStorage.removeItem(name)
  } catch (error) {
    logger.error(error)
    return
  }
}

export const useFormGatherings = create((set) => ({
  ...loadPersistedState('gatherings-form', { formInfo: {} }),
  setFormInfo: (formInfo) => {
    set({ formInfo })
    savePersistedState('gatherings-form', { formInfo })
  },
  resetForm: () => {
    set({ formInfo: {} })
    savePersistedState('gatherings-form', { formInfo: {} })
  },
}))

export const useView = create((set) => ({
  ...loadPersistedState('view-state', { view: 1 }),
  setView: (view) => {
    set({ view })
    savePersistedState('view-state', { view })
  },
}))

export const useTemplateId = create((set) => ({
  ...loadPersistedState('template-id', { templateId: null }),
  setTemplateId: (templateId) => {
    set({ templateId })
    savePersistedState('template-id', { templateId })
  },
}))
// temporary here - later I'll sync up with guys to talk about conventions

const saveToLocalStorage = (name, state) => {
  try {
    localStorage.setItem(name, JSON.stringify(state))
  } catch (error) {
    logger.error('Error saving state to localStorage:', error)
  }
}

const loadFromLocalStorage = (name) => {
  try {
    const storedState = localStorage.getItem(name)
    return storedState ? JSON.parse(storedState) : undefined
  } catch (error) {
    logger.error('Error loading state from localStorage:', error)
    return undefined
  }
}

export const initialState = {
  activeStep: 1,
}

const loadedState = loadFromLocalStorage('gatherings-steps')
const loadedState2 = loadFromLocalStorage('gathering-id')

export const useStepGatherings = create((set) => ({
  ...initialState,
  ...loadedState,
  setActiveStep: (value) => {
    set({ activeStep: value })
    saveToLocalStorage('gatherings-steps', { activeStep: value })
  },
  resetStep: () => {
    set(initialState)
    saveToLocalStorage('gatherings-steps', initialState)
  },
}))

export const initialGatheringId = {
  gatheringId: undefined,
}

export const useIdGathering = create((set) => ({
  ...initialGatheringId,
  ...loadedState2,
  setGatheringId: (value) => {
    set({ gatheringId: value })
    saveToLocalStorage('gathering-id', { gatheringId: value })
  },
  resetGatheringId: () => {
    set(initialGatheringId)
    resetPersistedState('gathering-id')
  },
}))

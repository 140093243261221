import './GatheringsTemplatesGrid.css'

import { useState } from 'react'
import GatheringTemplatesFilter from '../GatheringTemplatesFilter/GatheringTemplatesFilter'
import TemplateCardGrids from '../TemplateCardGrids/TemplateCardGrids'
import { useTemplates } from '../../util/zustant/templateStore'

const GatheringsTemplatesGrid = ({ gatherings }) => {
  const [filter, setFilter] = useState(null)
  const { templates } = useTemplates()

  return (
    <div className="allGatheringsMainContentArea">
      <GatheringTemplatesFilter filter={filter} searchData={templates} setSearchData={setFilter} />
      <TemplateCardGrids gatheringData={gatherings} filter={filter}></TemplateCardGrids>

      <div className="allGatheringsFooterText">
        <div>©2023 Church.Ai. All Rights Reserved.</div>
      </div>
    </div>
  )
}

export default GatheringsTemplatesGrid

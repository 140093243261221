import styled from 'styled-components'
import color from '../../../../util/colors'

const ApprovalActivityContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ApprovalActivityDropdownRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 15px;
  }
`

const DropdownRowIconAndText = styled.div`
  display: flex;
  width: fit-content;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  color: var(--title);
  column-gap: 10px;
  align-items: center;
`

const DropdownMenuWrapper = styled.div`
  width: 30%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

const ApprovalActivityBanner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: fit-content;
  padding: 0.8rem;
  background-color: var(--backgroundSecondary);
  margin-bottom: 2rem;
  position: relative;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
const ActivityBannerTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.001em;
  color: var(--title);
`
const ActivityBannerMessageRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.004em;
  color: var(--title);
  padding: 10px 0 10px 0;
  column-gap: 10px;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 15px;
    text-align: center;
  }
`

const bannerIconStyle = {
  color: color.draftButton,
}

const dropdownRowIconStyle = {
  color: color.primary,
  fontSize: '20px',
}

const bannerCloseIcon = {
  color: color.primary,
  fontSize: '20px',
  position: 'absolute',
  right: '0.8rem',
  cursor: 'pointer',
}

const ReminderButtonArea = styled.div`
  display: flex;
  width: 100%;
  padding-left: 2rem;
  align-items: center;
  column-gap: 20px;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 15px;
    justify-content: center;
    padding-left: 0;
  }
`

export {
  ApprovalActivityContainer,
  ApprovalActivityDropdownRow,
  DropdownRowIconAndText,
  DropdownMenuWrapper,
  ApprovalActivityBanner,
  ActivityBannerTitle,
  ActivityBannerMessageRow,
  bannerIconStyle,
  ReminderButtonArea,
  dropdownRowIconStyle,
  bannerCloseIcon,
}

import styled from '@emotion/styled'

export const ProfileRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 460px) {
    flex-direction: column;
    align-items: flex-end;
  }
`
export const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 460px) {
    ${(props) => props.order && `order: ${props.order};`}
  }
`

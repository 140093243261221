import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import color from '../../util/colors'

const StyledButton = styled(Button)({
  '&.MuiButtonBase-root': {
    lineHeight: 0,
  },
})

const FilterButton = styled(Button)({
  minWidth: 0,
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '16px',
  color: color.filterColor,
  textTransform: 'unset',
  whiteSpace: 'nowrap',
  '&.MuiButton-root:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiButton-root': {
    paddingTop: 7,
  },
})

const DatePickerButton = styled(Button)({
  minWidth: 0,
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '16px',
  color: color.filterColor,
  textTransform: 'unset',
  whiteSpace: 'nowrap',
  '&.MuiButton-root:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiButton-root': {
    paddingTop: 7,
  },
})

const StyledTemplateButton = styled(Button)({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '140%',
  textTransform: 'unset',
  whiteSpace: 'nowrap',
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.MuiButton-root:hover': {
    boxShadow: 'none',
  },
  '&.MuiButton-root': {
    padding: '8px 16px',
    gap: '8px',
    borderRadius: '100px',
  },
})

const StyledTemplateButtonRecurrence = styled(Button)(({ active }) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '140%',
  textTransform: 'unset',
  whiteSpace: 'nowrap',
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.MuiButton-root:hover': {
    boxShadow: 'none',
    backgroundColor: color.draftButton,
  },

  '&.MuiButton-root': {
    padding: '8px 16px',
    gap: '8px',
    borderRadius: '100px',
    backgroundColor: active ? color.draftButton : color.primary,
  },

  '.MuiButton-startIcon': {
    padding: '0px',
  },
}))

const StyledCloseModalButton = styled(StyledTemplateButton)({
  width: '155px',
})

const createKioskButtonStyle = {
  backgroundColor: 'transparent',
  '&:hover': { backgroundColor: 'transparent', borderColor: color.primary },
  textTransform: 'none',
  color: color.primary,
  borderColor: color.primary,
}

const approvalReminderStyle = {
  backgroundColor: color.backgroundPrimary,
  '&:hover': { backgroundColor: color.backgroundPrimary, borderColor: color.primary },
  color: color.primary,
  borderColor: color.primary,
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '140%',
  letterSpacing: '0.015em',
  height: '28px',
  width: '130px',
  padding: '6px 12px',
  border: 'none',
  '&.MuiButton-root': {
    padding: '6px 12px',
    gap: '0px',
  },
}

const templatePreviewHeaderStyle = {
  backgroundColor: color.primary,
  '&:hover': { backgroundColor: color.primary, borderColor: color.primary },
  color: color.backgroundPrimary,
  borderColor: color.primary,
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '140%',
  letterSpacing: '0.0125em',
  '&.MuiButton-root': {
    padding: '8px 16px',
    gap: '0px',
  },
}

const previewBannerEditButtonStyle = {
  backgroundColor: color.description,
  '&:hover': { backgroundColor: color.description, borderColor: color.description },
  color: color.backgroundPrimary,
  borderColor: color.description,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '140%',
  letterSpacing: '0.015em',
  '&.MuiButton-root': {
    padding: '8px 16px',
    gap: '0px',
    height: '24px',
  },
}

export {
  StyledButton,
  FilterButton,
  DatePickerButton,
  StyledTemplateButton,
  StyledTemplateButtonRecurrence,
  createKioskButtonStyle,
  approvalReminderStyle,
  templatePreviewHeaderStyle,
  previewBannerEditButtonStyle,
  StyledCloseModalButton,
}

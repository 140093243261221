function HandleKioskVariants(variant) {
  switch (variant) {
    case 'preview':
      return 'Preview'
    case 'kiosk':
      return 'Edit'
    default:
      return 'Edit'
  }
}

export { HandleKioskVariants }

import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  min-width: 248px;
  min-height: 198px;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--backgroundPrimary);
  box-sizing: content-box;
`

export const CardImage = styled.img`
  display: flex;
  width: inherit;
  height: 131px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`

export const CardContentArea = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: auto;
  box-sizing: border-box;
  padding: 0.5rem 0.5rem 1rem 1rem;
  justify-content: center;
`

export const CardTitleText = styled.div`
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.001em;
  color: var(--title);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
`

export const CardDateText = styled.div`
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.001em;
  color: var(--description);
`

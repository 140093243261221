import { Button, Stack } from '@mui/material'
import DateFilter from '../DateFilter/DateFilter'
import { FilterButton } from '../StyledButton/StyledButton.style'
import { useEffect, useState } from 'react'
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined'
import './ExperiencesFilter.css'
import { mapper } from '../../util/filter'
import { filterStart } from '../../util/date'
import color from '../../util/colors'

const ExperiencesFilter = ({ setFilteredData, searchData }) => {
  //TODO: This needs a complete rewrite once we start working with experiences
  const [filterState, setFilterState] = useState({
    free: false,
    paid: false,
    conferences: false,
    outreach: false,
    sunday: false,
    adult: false,
    youth: false,
    kids: false,
  })
  const [filterDate, setFilterDate] = useState()
  const [filterDateType, setFilterDateType] = useState('created')

  const { free, paid, conferences, outreach, sunday, adult, youth, kids } = filterState

  const handleFilterState = (name) => {
    setFilterState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }))
  }

  const priceTemplate = [
    { name: 'Free', var: free, func: () => handleFilterState('free') },
    { name: 'Paid', var: paid, func: () => handleFilterState('paid') },
  ]
  const typeTemplate = [
    { name: 'Conferences', var: conferences, func: () => handleFilterState('conferences') },
    { name: 'Outreach', var: outreach, func: () => handleFilterState('outreach') },
    { name: 'Sunday', var: sunday, func: () => handleFilterState('sunday') },
  ]
  const excludeTemplate = [
    { name: 'Adult', var: adult, func: () => handleFilterState('adult') },
    { name: 'Youth', var: youth, func: () => handleFilterState('youth') },
    { name: 'Kids', var: kids, func: () => handleFilterState('kids') },
  ]

  const filterPrice = (item) => {
    if (!free && !paid) {
      return true // Show all objects when both free and paid filters are false
    }

    return (free && item.price === 'free') || (paid && item.price === 'paid')
  }

  const filterType = (item) => {
    if (!conferences && !outreach && !sunday) {
      return true // Show all objects when all type filters are false
    }

    return (
      (conferences && item.type === 'conferences') ||
      (outreach && item.type === 'outreach') ||
      (sunday && item.type === 'sunday')
    )
  }

  const filterCategory = (item) => {
    if (
      (adult && item.category === 'adult') ||
      (youth && item.category === 'youth') ||
      (kids && item.category === 'kids')
    ) {
      return false
    }
    return true
  }

  const filterExperienceData = () => {
    const filtered = searchData.filter(
      (item) =>
        filterPrice(item) &&
        filterType(item) &&
        filterCategory(item) &&
        filterStart(item, filterDate, filterDateType),
    )

    setFilteredData(filtered)
  }

  useEffect(() => {
    filterExperienceData()
  }, [filterState, filterDate, filterDateType, searchData])

  return (
    <Stack
      sx={{ flexWrap: 'wrap' }}
      display={'flex'}
      direction={{
        xs: 'row',
      }}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      gap={3}
      my={3}
    >
      <Stack>
        <div className="experiencesFilterTextArea">Date: </div>
        <Stack alignItems={'flex-start'} justifyContent={'center'}>
          <DateFilter
            setFilterDateType={setFilterDateType}
            setFilterDate={setFilterDate}
            filterDate={filterDate}
          ></DateFilter>
          <Button
            style={{ color: color.primary }}
            onClick={() => {
              setFilterDate(undefined)
            }}
          >
            Reset date filter
          </Button>
        </Stack>
      </Stack>

      <Stack>
        <div className="experiencesFilterTextArea">Price: </div>
        <Stack justifyContent={'flex-start'}>{mapper(priceTemplate)}</Stack>
      </Stack>

      <Stack>
        <div className="experiencesFilterTextArea">Type: </div>
        <Stack justifyContent={'flex-start'}>{mapper(typeTemplate)}</Stack>
      </Stack>
      <Stack alignItems={'flex-start'} justifyContent={'center'}>
        <div className="experiencesFilterTextArea">Campus: </div>
        <FilterButton startIcon={<CottageOutlinedIcon />}>Select Campus</FilterButton>
      </Stack>
      <Stack>
        <div className="experiencesFilterTextArea">Exclude: </div>
        <Stack alignItems={'flex-start'}>{mapper(excludeTemplate)}</Stack>
      </Stack>
    </Stack>
  )
}

export default ExperiencesFilter

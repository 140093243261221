import { TextEditorFormContainer } from './TextEditorForm.style'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import infoData from '../../assets/textEditorForm.json'
import color from '../../util/colors'
import { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { ImageActions } from '@xeger/quill-image-actions'
import { ImageFormats } from '@xeger/quill-image-formats'

Quill.register('modules/imageActions', ImageActions)
Quill.register('modules/imageFormats', ImageFormats)

const TextEditorForm = forwardRef(
  (
    {
      id,
      subjectVariant,
      variant,
      mailBody,
      mailSubject,
      setMailSubject,
      placeholderText,
      onSelectionChange,
      error,
      previewStatus,
      setMailBody,
    },
    editorRef,
  ) => {
    useEffect(() => {
      const toolbar = document.querySelector(`#${id} .ql-toolbar`)
      const editor = document.querySelector(`#${id} .ql-editor`)
      let subjectInput = document.querySelector(`#${id} .subject-input`)

      if (toolbar && editor) {
        if (!subjectInput) {
          subjectInput = document.createElement('input')
          subjectInput.className = 'subject-input'
          subjectInput.type = 'text'
          subjectInput.placeholder = 'Subject...'
          subjectInput.style.textIndent = '10px'
          subjectInput.style.paddingTop = '12px'
          subjectInput.style.paddingBottom = '12px'
          subjectInput.style.paddingLeft = '5px'
          subjectInput.style.width = '100%'
          subjectInput.style.border = 'none'
          subjectInput.style.borderBottom = `1px solid ${color.border}`
          toolbar.parentNode.insertBefore(subjectInput, toolbar.nextSibling)
          subjectInput.value = mailSubject
        }
        subjectInput.oninput = (e) => setMailSubject(e.target.value, subjectVariant)
      }
    }, [])

    const innerRef = useRef()
    useImperativeHandle(editorRef, () => innerRef.current)

    useEffect(() => {
      setMailSubject(innerRef?.current?.value, variant)
    }, [previewStatus])

    const handleChange = (html) => {
      setTimeout(() => {
        setMailBody(html, variant)
      }, [500])
    }

    return (
      <>
        <TextEditorFormContainer style={{ border: error && '1px solid red' }} id={id}>
          <ReactQuill
            ref={innerRef}
            theme="snow"
            bounds=".app"
            style={{
              overflowY: 'auto',
              height: '100%',
              maxHeight: '500px',
            }}
            modules={{
              imageActions: {},
              imageFormats: {},
              toolbar:
                id === 'reminders-confirmationMessage'
                  ? infoData.toolbarConfigurationConfirmation
                  : infoData.toolbarConfiguration,
              clipboard: infoData.clipboard,
              history: {
                delay: 500,
                maxStack: 100,
                userOnly: true,
              },
            }}
            formats={[
              'align',
              'bold',
              'code-block',
              'color',
              'float',
              'height',
              'image',
              'italic',
              'link',
              'list',
              'placeholder',
              'calltoaction',
              'size',
              'underline',
              'width',
            ]}
            value={innerRef.current?.value || mailBody}
            placeholder={placeholderText}
            onChange={handleChange}
            onChangeSelection={(...data) => {
              if (innerRef.current?.editor?.hasFocus()) {
                onSelectionChange?.(...data)
              }
            }}
          ></ReactQuill>
        </TextEditorFormContainer>
      </>
    )
  },
)

export default TextEditorForm

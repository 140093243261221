/* eslint-disable no-unused-vars */
import './AllDrafts.css'
import NavbarOutlinedButton from '../NavbarOutlinedButton/NavbarOutlinedButton'
import TableComponent from '../TableComponent/TableComponent'
import { useEffect, useState } from 'react'
import SearchComponent from '../SearchComponent/SearchComponent'
import { TemplatesFilterArea, FilterText } from '../AllDrafts/AllDrafts.styles'

const AllDrafts = ({ drafts, token, setDrafts }) => {
  const [rowsPerPageFromChild, setRowsPerPageFromChild] = useState(5)
  const [pageFromChild, setPageFromChild] = useState(0)
  const [filteredData, setFilteredData] = useState([])
  const [visibleRows, setVisibleRows] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setFilteredData(drafts)
  }, [drafts])

  return (
    <div className="allDraftsMainContentArea">
      <div className="allDraftsTextandButtonsArea">
        <div className="allDraftsTextArea">
          <div className="allDraftsSectionMainText">All Drafts</div>
          <div className="allDraftsSectionSmallText">
            Showing <span style={{ fontWeight: '700' }}>{visibleRows?.length}</span> of{' '}
            {drafts.length} Drafts
          </div>
        </div>

        <div className="allDraftsButtonArea">
          <NavbarOutlinedButton
            buttonText={'Filters'}
            iconName={open ? 'horizontal line' : 'filter'}
            onClickFunction={() => setOpen((o) => !o)}
          ></NavbarOutlinedButton>
          <NavbarOutlinedButton buttonText={'Options'} iconName={'pencil'}></NavbarOutlinedButton>
        </div>
      </div>

      <TemplatesFilterArea open={open}>
        <FilterText>Filter by draft title:</FilterText>
        <div>
          <SearchComponent
            searchData={filteredData}
            sourceOfTruth={drafts}
            setSearchData={setFilteredData}
            setFilteredData={setFilteredData}
            draftFilter
            loading={drafts.length === 0}
          ></SearchComponent>
        </div>
      </TemplatesFilterArea>

      <TableComponent
        tableBodyData={filteredData}
        tableDataVariant={'Drafts'}
        rowsPerPageArray={[5, 10, 25]}
        setVisibleRows={setVisibleRows}
        headerDataVariant={'Drafts'}
        rowsPerPageParentSetter={setRowsPerPageFromChild}
        parrentPageSetter={setPageFromChild}
      ></TableComponent>
      {/* Second Text Area */}

      <div className="allDraftsFooterText">
        <div>©2023 Church.Ai. All Rights Reserved.</div>
      </div>
    </div>
  )
}

export default AllDrafts

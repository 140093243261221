import styled from 'styled-components'
import color from '../../../util/colors'

const UrlSlugContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 2.5rem 0 2.5rem 0;
`
const RowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`
const StyledText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--title);
  width: 30%;
  display: flex;
  padding-bottom: 2rem;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    padding-bottom: 1.5rem;
  }
`
const ContentArea = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
`
const UtilityButton = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0125em;
  color: var(--primary);
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
    padding-left: 0;
  }
`
const iconStyle = {
  color: color.primary,
  fontSize: 20,
}

const qrCodeStyle = {
  width: '204px',
  height: '200px',
}

export {
  UrlSlugContainer,
  RowContainer,
  StyledText,
  ContentArea,
  UtilityButton,
  iconStyle,
  qrCodeStyle,
}

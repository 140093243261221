import React from 'react'
import { Radio } from '@mui/material'

import { customRadioStyle, RadioWrapper, RadialButton } from './BooleanButton.styles'

function BooleanButton({ setValue, value, type, variant, order }) {
  if (variant === 'ticket') {
    return (
      <RadioWrapper
        type={type}
        onClick={() => {
          setValue(type === 'Yes' ? true : false, order)
        }}
      >
        <RadialButton
          checked={type === 'Yes' ? value === true : !value}
          control={<Radio sx={customRadioStyle} />}
          label={type}
        />
      </RadioWrapper>
    )
  } else {
    return (
      <RadioWrapper
        type={type}
        onClick={() => {
          setValue(type === 'Yes' ? true : false)
        }}
      >
        <RadialButton
          checked={type === 'Yes' ? value === true : !value}
          control={<Radio sx={customRadioStyle} />}
          label={type}
        />
      </RadioWrapper>
    )
  }
}

export default BooleanButton

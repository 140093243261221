import styled from '@emotion/styled'

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  justify-content: center;
  gap: 16px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`
export const ComponentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`
export const FooterText = styled.div`
  font-size: 16px;
  font-family: Poppins;
  line-height: 140%;
  color: var(--title);
`

export const FooterBox = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 18px;
  background: var(--backgroundSecondary);
  font-size: 14px;
  font-family: Poppins;
  line-height: 140%;
  letter-spacing: 0.035px;
  color: var(--title);
  border-radius: 10px;
`

import React, { useEffect, useState } from 'react'
import { InputLabel, Checkbox, Radio } from '@mui/material'
import { ExitInputStyle, Input } from './ExitInput.styles.jsx'
import Dropdown from './Dropdown.jsx'

const ExitInput = ({
  value,
  onChange,
  onExit,
  label,
  setOverflowOff,
  type,
  isSelected,
  onRadioSelect,
  setIsSelected,
  selectedCheckboxes,
  setSelectedCheckboxes,
  index,
  onDuplicate,
  setPopuStatus,
  popuStatus,
  values,
  indexField,
  onboardingIndex,
  setOnboardings,
  item,
  object,
  setChecked,
  setRequiredSetter,
  selectedRadio,
}) => {
  const [required, setRequired] = useState(false)
  useEffect(() => {
    setRequired(object?.required)
  }, [object])

  const options = [
    {
      value: () => {
        if (type === 'Select') {
          setChecked && setChecked(object?.checked ? false : true)
        } else {
          if (selectedCheckboxes[index]) {
            setSelectedCheckboxes({ ...selectedCheckboxes, [index]: false })
          } else {
            setSelectedCheckboxes({ ...selectedCheckboxes, [index]: true })
          }
          setChecked && setChecked(selectedCheckboxes[index] ? false : true)
        }
      },
      label: object.checked ? 'Set Checked*' : 'Set Checked',
    },
    {
      value: () => onDuplicate(),
      label: 'Duplicate',
    },
    { value: () => onExit(), label: 'Remove' },
    {
      value: () => {
        setRequiredSetter && setRequiredSetter(object?.required ? false : true)
        setRequired(object?.required ? false : true)
      },
      label: required ? 'Required*' : 'Required',
    },
  ]

  const options3 = [
    {
      value: () => onDuplicate(),
      label: 'Duplicate',
    },
    { value: () => onExit(), label: 'Remove' },
    {
      value: () => {
        setRequiredSetter && setRequiredSetter(object?.required ? false : true)
        setRequired(object?.required ? false : true)
      },
      label: required ? 'Required*' : 'Required',
    },
  ]

  const options2 = [
    {
      value: () => {
        onChange(value, 'Currency')
      },
      label: 'Currency',
    },
    {
      value: () => {
        onChange(value, 'Number')
      },
      label: 'Number',
    },
    { value: () => onExit(), label: 'Remove' },
  ]

  function CheckState() {
    setOnboardings &&
      setOnboardings((onboardings) => {
        onboardings[onboardingIndex].fields[indexField].values[index].checked =
          !onboardings[onboardingIndex].fields[indexField].values[index].checked
        return [...onboardings]
      })
  }

  return (
    <ExitInputStyle variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <Input
        type={type === 'Number' ? 'number' : type === 'Text' ? 'text' : 'text'}
        value={type === 'Currency' ? '$' + value : value}
        onChange={(event) => {
          onChange && onChange(event.target.value.replace('$', ''), type)
          setOnboardings &&
            setOnboardings((onboardings) => {
              onboardings[onboardingIndex].fields[indexField].values[index].text =
                event.target.value.replace('$', '')
              return [...onboardings]
            })
        }}
        startAdornment={
          type === 'Checkbox' ? (
            <Checkbox
              checked={values ? values?.checked || false : selectedCheckboxes[index] || false}
              onChange={(e) => {
                setSelectedCheckboxes &&
                  setSelectedCheckboxes({
                    ...selectedCheckboxes,
                    [index]: !selectedCheckboxes[index],
                  })

                CheckState()
              }}
            />
          ) : type === 'Select' ? (
            <Radio
              checked={object?.checked ? object?.checked : false}
              onClick={() => {
                !setChecked && CheckState()

                setChecked && setChecked(!object?.checked)
              }}
            />
          ) : null
        }
        endAdornment={
          <Dropdown
            key={index}
            items={
              type === 'Number' || type === 'Currency'
                ? options2
                : type === 'Checkbox' || type === 'Select'
                ? options
                : options3
            }
            onChange={(options) => {
              if (onChange)
                return typeof options.value === 'function' ? options.value() : options.value

              setOnboardings &&
                setOnboardings((onboardings) => {
                  if (options?.label === 'Remove') {
                    onboardings[onboardingIndex].fields[indexField].values = onboardings[
                      onboardingIndex
                    ].fields[indexField]?.values.filter((_, valueIndex) => valueIndex !== index)
                  } else if (options?.label.includes('Required')) {
                    onboardings[onboardingIndex].fields[indexField].values[index].required =
                      onboardings[onboardingIndex].fields[indexField].values[index]?.required
                        ? false
                        : true

                    setRequired(
                      onboardings[onboardingIndex].fields[indexField].values[index]?.required,
                    )
                  } else if (options?.label === 'Duplicate') {
                    const values = onboardings[onboardingIndex].fields[indexField].values
                    const duplicatedValue = { ...values[index] }

                    const updatedValues = [
                      ...values.slice(0, index + 1),
                      duplicatedValue,
                      ...values.slice(index + 1),
                    ]

                    onboardings[onboardingIndex].fields[indexField].values = updatedValues
                  } else if (options?.label.includes('Set Checked')) {
                    if (
                      onboardings[onboardingIndex].fields[indexField].values[index]?.checked !==
                        undefined ||
                      null
                    )
                      onboardings[onboardingIndex].fields[indexField].values[index].checked =
                        !onboardings[onboardingIndex].fields[indexField].values[index].checked
                  } else {
                    onboardings[onboardingIndex].fields[indexField].values[index].label =
                      options.label
                  }
                  return [...onboardings]
                })
            }}
            setOverflowOff={setOverflowOff}
            isAdd={false}
            setPopuStatus={setPopuStatus}
            popuStatus={popuStatus}
          />
        }
        label={label}
      />
    </ExitInputStyle>
  )
}

export default ExitInput

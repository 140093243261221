function preparePageNumberForPaginationComponent(page) {
  if (page === 0) {
    return 1
  } else {
    return page + 1
  }
}

const calculatePageCountForPaginationComponent = (rowLength, rowsPerPage) => {
  let pageCount = 1
  if (rowsPerPage > rowLength) {
    return pageCount
  } else {
    pageCount = Math.ceil(rowLength / rowsPerPage)
    return pageCount
  }
}
function checkDateString(value) {
  const date = new Date(value)
  return !isNaN(date)
}

function descendingComparator(a, b, orderBy) {
  if (checkDateString(a[orderBy])) {
    const dateA = new Date(a[orderBy])
    const dateB = new Date(b[orderBy])

    if (dateB < dateA) {
      return -1
    }
    if (dateB > dateA) {
      return 1
    }
    return 0
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function calculateGridItems(screenSize) {
  if (screenSize < 1512) {
    return 6
  } else if (screenSize >= 1512 && screenSize < 1680) {
    return 9
  } else if (screenSize >= 1680 && screenSize < 2048) {
    return 12
  } else if (screenSize >= 2048 && screenSize < 2560) {
    return 15
  } else if (screenSize >= 2560) {
    return 18
  }
}

export {
  preparePageNumberForPaginationComponent,
  calculatePageCountForPaginationComponent,
  stableSort,
  getComparator,
  calculateGridItems,
}

import color from '../../util/colors'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 500,
  bgcolor: '#FFFFFF',
  border: '1px solid #000',
  padding: '20px',
  borderRadius: '10px',
}

const buttonStyle = {
  color: color.loginPrimary,
  textTransform: 'none',
  fontSize: '20px',
  width: '100%',
  border: `1px solid black`,
}

const textFieldStyle = {
  '& label.Mui-focused': {
    color: color.primary,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: color.primary,
    },
    '&.Mui-focused fieldset': {
      borderColor: color.primary,
    },
  },
}

const modalButtonStyle = {
  alignSelf: 'center',
  color: color.backgroundPrimary,
  backgroundColor: color.primary,
  '&:hover': { backgroundColor: color.primary },
}

const cardStyle = {
  width: '100%',
  maxWidth: 345,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  px: 3,
  pb: 5,
  pt: 3,
  gap: 3,
  mt: 4,
}

export { modalStyle, modalButtonStyle, buttonStyle, textFieldStyle, cardStyle }

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import firebaseConfig from '../config/config'

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()

export const signInWithProvider = (name) => {
  let provider
  switch (name) {
    case 'google':
      provider = new firebase.auth.GoogleAuthProvider()
      break

    default:
      break
  }
  provider.setCustomParameters({ prompt: 'select_account' })
  auth.signInWithPopup(provider)
}

export default firebase

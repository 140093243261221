import styled from 'styled-components'

const CheckinSetupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;
`

const CheckinSetupMainText = styled.div`
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`

const CheckinSetupSubText = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  width: 100%;
  color: var(--title);
  padding-top: 3rem;
`
const CheckinSetupAltSubText = styled(CheckinSetupSubText)`
  padding-top: 0;
  width: 30%;
  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
`

const OptionalDivider = styled.div`
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.0152em;
  text-transform: uppercase;
  color: var(--title);
  padding-top: 3rem;
  padding-bottom: 3rem;
`
const Divider = styled.hr`
  border: none;
  border-top: 1px dashed var(--dashLine);
  height: 1px;
  margin-top: 0.5rem;
  width: 100%;
  margin-left: 30px;
`

const SelectIndividualsArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    text-align: center;
  }
`

const DateAndTimeSelectorArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 3rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 15px;
  }
`
const DateAndTimeWrapper = styled.div`
  width: 70%;
  display: flex;
  column-gap: 30px;
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    row-gap: 30px;
  }
`

const ButtonAndTextRow = styled.div`
  width: 100%;
  display: flex;
  padding: 2rem 0 2rem 0;
  align-items: center;
`
const RowTextArea = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-start;
`

const RowText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--title);
  display: flex;
  width: 80%;
`
const RowButtonArea = styled.div`
  width: 70%;
  display: flex;
  column-gap: 50px;
`
export {
  CheckinSetupContainer,
  CheckinSetupMainText,
  CheckinSetupSubText,
  CheckinSetupAltSubText,
  OptionalDivider,
  Divider,
  DateAndTimeSelectorArea,
  SelectIndividualsArea,
  DateAndTimeWrapper,
  ButtonAndTextRow,
  RowTextArea,
  RowText,
  RowButtonArea,
}

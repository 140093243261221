import styled from 'styled-components'
import color from '../../util/colors'

const GroupSidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 292px;
  ${(props) => props.background && `background: ${props.background};`}
`

const headerCloseButton = {
  position: 'absolute',
  color: color.backgroundPrimary,
  top: 20,
  right: 20,
  cursor: 'pointer',
}

const HeaderVariantText = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.116em;
  color: var(--backgroundPrimary);
  padding-left: 3rem;
  padding-top: 2rem;
  text-transform: uppercase;
`
const HeaderGroupNameText = styled.div`
  font-weight: 300;
  font-size: 34px;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: var(--backgroundPrimary);
  padding-left: 3rem;
`

const HeaderGroupBottomSection = styled.div`
  display: flex;
  padding-bottom: 2rem;
  height: 100%;
  align-items: flex-end;
  padding-left: 2.5rem;
`
const HeaderGroupAvatar = styled.img`
  width: 80px;
  height: 80px;
  border: 3px solid var(--backgroundPrimary);
  border-radius: 100px;
`
const headerAvatarIcon = {
  color: color.primary,
  background: color.backgroundPrimary,
  fontSize: 18,
  padding: '10px',
  borderRadius: '30px',
  marginBottom: '12px',
}

const HeaderEditButton = styled.button`
  color: var(--backgroundPrimary);
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding-right: 1.5rem;
  cursor: pointer;
`

const editButtonIcon = {
  fontSize: '20px',
  paddingRight: '10px',
}

const GroupSidebarMembersArea = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-bottom: 4rem;
  height: max-content;
`
const MembersAreaTextSection = styled.div`
  display: flex;
`

const MembersAreaMainText = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  align-items: center;
  padding-left: 3rem;
`

const MembersAreaLengthText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.004em;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 1.5rem;
`

const SidebarMemberFrame = styled.div`
  padding: 1.5rem 0 0 3rem;
  width: max-content;
`

const SidebarFooterArea = styled.div`
  display: flex;
  align-items: center;
  padding-left: 3rem;
  height: 50px;
  padding-bottom: 3rem;
`

export {
  GroupSidebarHeader,
  headerCloseButton,
  HeaderVariantText,
  HeaderGroupNameText,
  HeaderGroupBottomSection,
  HeaderGroupAvatar,
  headerAvatarIcon,
  HeaderEditButton,
  editButtonIcon,
  GroupSidebarMembersArea,
  MembersAreaTextSection,
  MembersAreaMainText,
  MembersAreaLengthText,
  SidebarMemberFrame,
  SidebarFooterArea,
}

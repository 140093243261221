import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { StyledLocalizationProvider, StyledDatePicker } from './DateInput.styles'

function DateInput({ value, onChange, label, format, error }) {
  const dateValue = value ? dayjs(value) : null

  return (
    <StyledLocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePicker
        label={label}
        slotProps={{
          textField: {
            variant: 'outlined',
            error: error,
          },
        }}
        value={dateValue}
        format={format ? format : 'MM/DD/YYYY'}
        onChange={(value) => {
          const formattedValue = dayjs(value).format('MM/DD/YYYY')
          onChange(formattedValue)
        }}
      />
    </StyledLocalizationProvider>
  )
}

export default DateInput

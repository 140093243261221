import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import GroupFrame from '../GroupFrame/GroupFrame'
import TemplateButton from '../TemplateButton/TemplateButton'
import {
  GroupSidebarHeader,
  headerCloseButton,
  HeaderVariantText,
  HeaderGroupNameText,
  HeaderGroupBottomSection,
  HeaderGroupAvatar,
  headerAvatarIcon,
  HeaderEditButton,
  editButtonIcon,
  GroupSidebarMembersArea,
  MembersAreaTextSection,
  MembersAreaMainText,
  MembersAreaLengthText,
  SidebarMemberFrame,
  SidebarFooterArea,
} from './GroupSidebar.style'
import { handleBackgroundColor } from '../../util/groupFrame.util'

const GroupSidebar = ({ group, sidebar, setSidebar }) => {
  const SidebarDrawer = () => (
    <Box
      sx={{
        width: '400px',
      }}
      role="presentation"
    >
      <GroupSidebarHeader background={handleBackgroundColor(group?.variant)}>
        <CloseIcon sx={headerCloseButton} onClick={() => setSidebar(false)} />
        <HeaderVariantText>{group?.variant?.toUpperCase()}</HeaderVariantText>
        <HeaderGroupNameText>{group?.name}</HeaderGroupNameText>
        <HeaderGroupBottomSection>
          <HeaderGroupAvatar src={group?.photo}></HeaderGroupAvatar>
          <EditIcon sx={headerAvatarIcon} />
          <HeaderEditButton>
            <EditIcon sx={editButtonIcon}></EditIcon>Edit
          </HeaderEditButton>
        </HeaderGroupBottomSection>
      </GroupSidebarHeader>
      <GroupSidebarMembersArea>
        <MembersAreaTextSection>
          <MembersAreaMainText>Members</MembersAreaMainText>
          <MembersAreaLengthText>{`${group?.members?.length} members`}</MembersAreaLengthText>
        </MembersAreaTextSection>
        {group?.members?.slice(0, 15).map((person, i) => {
          return (
            <SidebarMemberFrame key={i}>
              <GroupFrame group={person} forSearch={true} forSidebar={true}></GroupFrame>
            </SidebarMemberFrame>
          )
        })}
      </GroupSidebarMembersArea>
      <SidebarFooterArea>
        <TemplateButton variant={'sidebar'} label={'See all group members'}></TemplateButton>
      </SidebarFooterArea>
    </Box>
  )

  return (
    <div>
      <Drawer anchor={'right'} open={sidebar}>
        {SidebarDrawer()}
      </Drawer>
    </div>
  )
}

export default GroupSidebar

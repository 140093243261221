import './GatheringListCard.css'
import PropTypes from 'prop-types'
import { StyledButton } from '../StyledButton/StyledButton.style'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import color from '../../util/colors'

const GatheringListCard = ({ date, time, image, title }) => {
  return (
    <div className="gatheringListCardContainer">
      <div className="gatheringListCardDateAndTimeArea">
        <div className="gatheringListCardDate">{date}</div>
        <div className="gatheringListCardTime">{time}</div>
      </div>
      <div className="gatheringListCardImageAndTextArea">
        <img src={image} alt="Gathering Location" className="gatheringListCardImage"></img>
        <div className="gatheringListCardText">{title}</div>
      </div>
      <div className="gatheringListCardButtonArea">
        <div>
          <StyledButton
            className="gatheringListCardButton"
            variant="outlined"
            startIcon={<OpenInNewIcon></OpenInNewIcon>}
          >
            Edit gathering
          </StyledButton>
        </div>
      </div>
      <div className="gatheringListCardArchiveArea">
        <div className="gatheringListCardArchiveButton">
          <DeleteOutlineIcon sx={{ fontSize: 30, color: color.primary }}></DeleteOutlineIcon>
        </div>
      </div>
    </div>
  )
}

GatheringListCard.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  time: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  dateTo: PropTypes.instanceOf(Date),
}

export default GatheringListCard

import {
  GroupGridContainer,
  GroupGridFirstRow,
  PrefixText,
  MainText,
  Divider,
  GroupGridArea,
  GroupItem,
} from './GroupGrid.style'
import GroupFrame from '../../../GroupFrame/GroupFrame'

const GroupGrid = ({
  groupName,
  selectedGroups,
  setSelectedGroups,
  setSearchData,
  setSortedGroups,
}) => {
  return (
    <GroupGridContainer>
      <GroupGridFirstRow>
        <PrefixText>Selected</PrefixText>
        <MainText>{groupName}</MainText>
        <Divider></Divider>
      </GroupGridFirstRow>
      <GroupGridArea>
        {selectedGroups.map((group) => {
          return (
            <GroupItem>
              <GroupFrame
                key={group.id}
                group={group}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                searchDataSetter={setSearchData}
                setSortedGroups={setSortedGroups}
                setSearchData={setSearchData}
              ></GroupFrame>
            </GroupItem>
          )
        })}
      </GroupGridArea>
    </GroupGridContainer>
  )
}

export default GroupGrid

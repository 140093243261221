import styled from 'styled-components'

export const Row = styled.div`
  width: 100%;
  min-height: 0;
  box-sizing: border-box;
  transition: height 0.2s ease, padding 0.2s ease;
  height: max-content;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  padding: 32px 64px;

  ${(props) => !props.open && `height: 0;`}
  ${(props) => !props.open && `padding: 0;`}
`
export const Cell = styled.div``

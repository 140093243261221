import styled from 'styled-components'

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-x: hidden;
`
const ComponentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  @media (max-width: 768px) {
    justify-items: center;
    width: 100%;
  }
`
export { MainContainer, ComponentArea }

import React from 'react'
import { StyledTimePicker, StyledLocalizationProvider } from './TimeInput.styles.jsx'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'

function TimeInput({ value, onChange, label, error }) {
  const dateValue = value ? dayjs(value, 'h:mm A') : null

  return (
    <StyledLocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledTimePicker
        defaultValue={dayjs('2022-04-17T00:00')}
        label={label}
        value={dateValue}
        slotProps={{
          textField: {
            variant: 'outlined',
            error: error,
          },
        }}
        onChange={(value) => {
          const formattedValue = dayjs(value).format('hh:mm A')
          onChange(formattedValue)
        }}
      />
    </StyledLocalizationProvider>
  )
}

export default TimeInput

import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { modalPreviewContainer, modalTopIcon, DecriptionText, ButtonRow } from './ModalPrompt.style'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import { StyledTemplateButton } from '../StyledButton/StyledButton.style'
import color from '../../util/colors'

const ModalPrompt = ({
  modalStatus,
  setModalStatus,
  variant,
  descriptionText,
  onClickFunction,
}) => {
  return (
    <Modal open={modalStatus}>
      <Box sx={modalPreviewContainer}>
        <NewReleasesIcon style={modalTopIcon}></NewReleasesIcon>
        <DecriptionText>{descriptionText}</DecriptionText>
        <ButtonRow>
          <StyledTemplateButton
            variant="contained"
            sx={{ background: color.primary }}
            onClick={() => {
              if (
                onClickFunction &&
                (variant === 'archiveGathering' ||
                  variant === 'deleteTemplate' ||
                  variant === 'deleteLabel')
              ) {
                onClickFunction()
                setModalStatus(false)
              }
            }}
          >
            YES
          </StyledTemplateButton>
          <StyledTemplateButton
            variant="contained"
            sx={{ background: color.primary }}
            onClick={() => {
              setModalStatus(false)
            }}
          >
            NO
          </StyledTemplateButton>
        </ButtonRow>
      </Box>
    </Modal>
  )
}

export default ModalPrompt

/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import useProtectedRoute from '../../hooks/useProtectedRoute'
import AllArchived from '../../components/AllArchived/AllArchived'
import CalendarScreen from '../CalendarScreen/CalendarScreen'
import { useEvents } from '../../hooks/useEvents'
import SubHeader from '../../components/SubHeader/SubHeader'
import { MainContainer, ComponentArea } from '../../util/ScreenWrapper.style'
import { useView } from '../../util/zustant/store'

const AllArchivedDraftsScreen = () => {
  const [selectedTab, setSelectedTab] = useState('Archived')
  const [archivedData, setArchivedData] = useState([])
  const { view } = useView()
  const { profileData, gatherings, experiences, searchData } = useEvents()

  function filterArchived(gatherings) {
    const filtered = gatherings.filter((g) => g.status === 'archived')
    setArchivedData(filtered)
  }

  useEffect(() => {
    if (gatherings?.length > 0) {
      filterArchived(gatherings)
    }
  }, [gatherings])

  const { authenticated, token } = useAuth()

  useProtectedRoute()

  if (!authenticated || !profileData) return <></>

  return (
    <MainContainer>
      {/* Components Area*/}
      <ComponentArea>
        {/* Top Row Area 2*/}
        <SubHeader
          variant={'createGathering'}
          setSelectedTabParent={setSelectedTab}
          activeTabOnParent={selectedTab}
        ></SubHeader>

        {/* Screens Section Area */}
        {view === 1 ? (
          <AllArchived
            archives={archivedData}
            token={token}
            setArchives={setArchivedData}
          ></AllArchived>
        ) : (
          <CalendarScreen eventData={archivedData}></CalendarScreen>
        )}
      </ComponentArea>
    </MainContainer>
  )
}

export default AllArchivedDraftsScreen

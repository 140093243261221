import './FilteringButtons.css'
import { Avatar } from '@mui/material'
import GroupIcon from '@mui/icons-material/Group'
import { useState } from 'react'
import color from '../../util/colors'

const FilteringButtons = () => {
  const filters = ['EXPERIENCE', 'GATHERING']
  const [selected, setSelected] = useState('EXPERIENCE')
  return (
    <div className="container">
      {filters.map((filter, i) => (
        <div key={i} className="buttonContainer">
          <Avatar
            style={{
              backgroundColor: filter === selected ? color.lighterExperience : color.description,
            }}
            onClick={() => {
              setSelected(filter)
              //TODO: Change state for filtering events in main screen from filter object
            }}
            className="avatarContainer"
          >
            <GroupIcon style={{ fontSize: 16 }} />
          </Avatar>
          <p className="titleText">{filter}</p>
        </div>
      ))}
    </div>
  )
}

export default FilteringButtons

import React, { useState, createContext, useContext, useEffect } from 'react'
import { auth } from '../util/firebase'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult()
        if (idTokenResult.claims?.admin) {
          user.getIdToken(true).then((token) => {
            setToken(token)
            setAuthenticated(true)
            setUser(user)
          })
        }
      } else {
        setAuthenticated(false)
        setUser(false)
      }
    })
  }, [])
  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated, user, token }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

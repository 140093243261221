import styled from 'styled-components'

const ApprovalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const DateSAndTimeSlectorArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 2rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 15px;
  }
`

const DateAndTimeWrapper = styled.div`
  width: 70%;
  display: flex;
  column-gap: 20px;
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    row-gap: 15px;
  }
`

const DateAndTimeSubText = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  width: 30%;
  color: var(--title);
  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
`
export { ApprovalContainer, DateSAndTimeSlectorArea, DateAndTimeWrapper, DateAndTimeSubText }

const unifyData = (array) => {
  let data = array.map((item) => {
    if (item.data.title !== '') {
      return {
        ...item.data,
        id: item.id,
      }
    }
    return undefined
  })
  data = data.filter((item) => item)
  return data
}

const Pagination = ({ data, pageFromChild, rowsPerPageFromChild }) => {
  return data?.slice(
    pageFromChild * rowsPerPageFromChild,
    pageFromChild * rowsPerPageFromChild + rowsPerPageFromChild,
  )
}

const FilterTemplates = (data, filter, favoriteTemplates) => {
  let filtered = data?.filter((template) => {
    if (filter?.title) {
      if (template.gatheringDetails?.title?.toLowerCase()?.includes(filter?.title?.toLowerCase()))
        return template
    }

    if (filter?.label) {
      if (
        template.gatheringType?.typeOfGathering
          ?.toLowerCase()
          ?.includes(filter?.label?.toLowerCase())
      )
        return template
    }

    if (filter?.dates?.length > 1) {
      let firstDate = new Date(filter.dates[0]?.$d)
      let secondDate = new Date(filter.dates[1]?.$d)
      let end = new Date(template?.logisticsInfo?.event?.endDate)
      let start = new Date(template?.logisticsInfo?.event?.startDate)

      return start >= firstDate && end <= secondDate
    }
    return false
  })

  if (filter?.favorite) {
    filtered = favoriteTemplates
  }

  return filtered
}
function similarity(name, search) {
  let count = 0
  for (let i = 0; i < name?.length && i < search?.length; i++) {
    if (name[i] === search[i]) {
      count++
    }
  }
  return count
}

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0
}

function sortByNameSimilarity(objects, search) {
  return objects?.sort((a, b) => similarity(b.name, search) - similarity(a.name, search))
}

function checkMissingInformation(value, setError, activeStepLabel) {
  if (value) {
    setError((current) => {
      if (!current.includes(activeStepLabel)) {
        return [...current, activeStepLabel]
      }
      return current
    })
  } else {
    setError((current) => current.filter((f) => f !== activeStepLabel))
  }
}

function checkIfStepIsMissingData(itemLabel, formInfo) {
  return formInfo?.missingInformation?.includes(itemLabel)
}

export {
  unifyData,
  sortByNameSimilarity,
  Pagination,
  FilterTemplates,
  checkMissingInformation,
  checkIfStepIsMissingData,
  isEmptyObject,
}

import {
  GroupSearchContainer,
  GroupSearchText,
  SearchComponentContainer,
} from './GroupSearch.style'
import SearchComponent from '../../../SearchComponent/SearchComponent'

const GroupSearch = ({ groupData, setSearchData, groupHandlerFunction, setSortedGroups }) => {
  return (
    <>
      <GroupSearchContainer>
        <GroupSearchText>Select teams:</GroupSearchText>
        <SearchComponentContainer>
          <SearchComponent
            searchData={groupData}
            setSearchData={setSearchData}
            variant={'groupSearch'}
            placeholderText={'Search for groups'}
            groupHandlerFunction={groupHandlerFunction}
            setSortedGroups={setSortedGroups}
          ></SearchComponent>
        </SearchComponentContainer>
      </GroupSearchContainer>
    </>
  )
}

export default GroupSearch

import styled from 'styled-components'
import { Card, FormGroup } from '@mui/material'
import colors from '../../../util/colors'

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 34px;
  gap: 31px;

  position: relative;
  width: 196px;
  height: 391px;

  background: ${colors.backgroundPrimary};
  box-shadow: 0px 4px 19px -7px ${colors.boxShadow};
  border-radius: 10px;
`

export const CustomFormGroup = styled(FormGroup)`
  max-height: 391px;
  max-width: 196px;
  display: flex;
  flex-direction: column;
  gap: 31px;
`

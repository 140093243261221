import { logger } from './logger'

function convertDateString(dateString) {
  return new Date(dateString).toLocaleDateString()
}

function convertSecondsToDateString(sec) {
  const seconds = sec
  const milliseconds = seconds * 1000

  const date = new Date(milliseconds)
  return date.toDateString()
}

function calculateEndDate(startDate, numberOfDays) {
  const endDate = new Date(startDate)
  endDate.setDate(endDate.getDate() + numberOfDays)
  return endDate
}

function formatDateOccurencSmallPreview(dateString) {
  if (!dateString) return '-'

  const date = new Date(dateString)
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  const formattedDate = date.toLocaleDateString('en-US', options)

  return formattedDate
}

function formatTimeOccurencSmallPreview(timeString) {
  if (!timeString) return '-'
  return timeString
}

function generateFutureDates(dateString, interval, amount, order, days, occursOn) {
  const formattedDates = []
  const originalDate = new Date(dateString)

  const dayOfWeekMap = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  }
  const orderMap = { first: 1, second: 2, third: 3, fourth: 4 }

  const formattedOriginalDate = formatDateOccurencSmallPreview(originalDate)

  formattedDates.push(formattedOriginalDate)

  for (let i = 1; i < amount; i++) {
    let date
    switch (interval) {
      case 'daily':
        date = new Date(originalDate.getTime() + i * 24 * 60 * 60 * 1000)
        break
      case 'weekly':
        date = new Date(originalDate.getTime() + i * 7 * 24 * 60 * 60 * 1000)
        if (occursOn !== undefined) {
          const targetDay = occursOn > 7 ? 7 : occursOn
          const currentDay = date.getDay()
          const diff = targetDay - currentDay
          date.setDate(date.getDate() + diff)
        }
        break
      case 'monthly':
        date = new Date(originalDate.getFullYear(), originalDate.getMonth() + i, 1)

        if (!order || !days) {
          const targetDay = occursOn
          date.setDate(targetDay)
        } else if (days && order) {
          const dayOfWeek = dayOfWeekMap[days.toLowerCase()]
          const orderOfWeek = orderMap[order.toLowerCase()]

          if (dayOfWeek !== undefined && orderOfWeek !== undefined) {
            let count = 0
            while (true) {
              if (date.getDay() === dayOfWeek) {
                count++
              }

              if (
                count === orderOfWeek ||
                date.getMonth() !==
                  new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).getMonth()
              ) {
                // Breaks if it's the desired day of week in order or if it goes to the next month
                break
              }

              date.setDate(date.getDate() + 1)
            }
          }
        }

        break
      case 'yearly':
        date = new Date(
          originalDate.getFullYear() + i,
          originalDate.getMonth(),
          originalDate.getDate(),
        )
        break
      default:
        logger.error('Invalid interval')
        return formattedDates
    }

    const formattedDate = formatDateOccurencSmallPreview(date)
    formattedDates.push(formattedDate)
  }

  return formattedDates
}

function getNumberWithAttribute(number) {
  var attribute
  if (number % 100 >= 11 && number % 100 <= 13) {
    attribute = 'th'
  } else {
    var lastDigit = number % 10
    if (lastDigit === 1) {
      attribute = 'st'
    } else if (lastDigit === 2) {
      attribute = 'nd'
    } else if (lastDigit === 3) {
      attribute = 'rd'
    } else {
      attribute = 'th'
    }
  }
  return number + attribute
}

function reminderDateString() {
  const currentDate = new Date()

  const day = currentDate.getDate()
  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear()
  const hours = currentDate.getHours()
  const minutes = currentDate.getMinutes()

  const formattedDate = ('0' + day).slice(-2) + '/' + ('0' + month).slice(-2) + '/' + year
  const formattedTime = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2)

  let dateString = formattedDate + ' at ' + formattedTime
  dateString += hours >= 12 ? 'PM' : 'AM'

  return dateString
}

function convertTimeStamp(timestamp) {
  // Convert the given date string to a Date object
  const inputDate = new Date(timestamp)

  // Get the current date
  const currentDate = new Date()

  // Calculate the time difference in milliseconds between the current date and the input date
  const timeDiff = Math.abs(currentDate - inputDate)

  // Convert the time difference from milliseconds to days
  const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000))

  // Determine the appropriate string for the time
  const timeString = inputDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  // Generate the final string based on the time difference
  let dateString = ''
  if (daysDiff === 0) {
    dateString = 'Today'
  } else if (daysDiff === 1) {
    dateString = 'Yesterday'
  } else {
    dateString = `${daysDiff} days ago`
  }

  // Combine the date and time strings
  return `${dateString} at ${timeString}`
}

function checkDate(start, filterStart) {
  if (filterStart?.length > 0 && filterStart[0]?.$d && filterStart[1]?.$d && start) {
    const startDate = new Date(start).setHours(0, 0, 0, 0)
    const rangeStartDate = filterStart[0].$d
    const rangeEndDate = filterStart[1].$d
    return startDate >= rangeStartDate && startDate <= rangeEndDate
  }
}

function checkDateLastThrough(start, end, filterStart) {
  if (filterStart?.length > 0 && filterStart[0]?.$d && filterStart[1]?.$d && start && end) {
    const startDate = new Date(start).setHours(0, 0, 0, 0)
    const endDate = new Date(end).setHours(0, 0, 0, 0)
    const rangeStartDate = filterStart[0].$d
    const rangeEndDate = filterStart[1].$d
    return startDate <= rangeEndDate && endDate >= rangeStartDate
  }
}

const filterStart = (item, filterDate, filterDateType) => {
  if (!filterDate) return true
  if (filterDateType === 'lastThrough') {
    if (checkDateLastThrough(item.start, item.end, filterDate)) {
      return true
    }
  } else if (checkDate(item[filterDateType], filterDate)) {
    return true
  }
  return false
}

const filterStartGathering = (item, filterDate, filterDateType) => {
  if (!filterDate) return true
  if (filterDateType === 'lastThrough') {
    if (
      checkDateLastThrough(
        item?.logisticsInfo?.event?.startDate,
        item?.logisticsInfo?.event?.endDate ||
          calculateEndDate(
            item?.logisticsInfo?.event?.startDate,
            item?.logisticsInfo?.event?.recurrencAmount,
          ),
        filterDate,
      )
    ) {
      return true
    }
  } else if (
    filterDateType !== 'created' &&
    checkDate(item?.logisticsInfo?.event?.startDate, filterDate)
  ) {
    return true
  } else if (checkDate(item?.created, filterDate)) {
    return true
  }
  return false
}

export {
  convertDateString,
  formatDateOccurencSmallPreview,
  formatTimeOccurencSmallPreview,
  generateFutureDates,
  getNumberWithAttribute,
  reminderDateString,
  calculateEndDate,
  checkDate,
  checkDateLastThrough,
  filterStart,
  filterStartGathering,
  convertSecondsToDateString,
  convertTimeStamp,
}

import * as React from 'react'
import './ExperienceListCard.css'
import PropTypes from 'prop-types'
import { StyledButton } from '../StyledButton/StyledButton.style'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import color from '../../util/colors'
import { convertDateString } from '../../util/date'

const ExperienceListCard = ({ experienceData, onClickEvent, onClickState }) => {
  return (
    <div
      className="experienceListCardContainer"
      style={
        (onClickState ? { boxShadow: '0px 6px 34px -14px rgba(22, 12, 53, 0.16)' } : {},
        { backgroundColor: color.backgroundPrimary })
      }
    >
      {/* Image section */}
      <section className="experienceCardImageArea">
        <img
          src={experienceData.image}
          alt="On the left side"
          className="experienceCardImage"
        ></img>
        <div className="experienceCardImageBadgeArea">
          <StyledButton
            className="experienceCardImageBadge"
            variant="contained"
            startIcon={<OpenInNewIcon></OpenInNewIcon>}
          >
            Edit experience
          </StyledButton>
        </div>
      </section>
      {/* Title and description section */}
      <section
        className="experienceCardTitleAndDescriptionArea"
        onClick={() => onClickEvent(!onClickState)}
      >
        <div className="experienceCardTitleText">{experienceData.title}</div>
        <div className="experienceCardDescriptionText">{experienceData.description}</div>
      </section>
      {/* Date section */}
      <section className="experienceCardDateArea" onClick={() => onClickEvent(!onClickState)}>
        <div className="experienceCardTitleText">Date: </div>
        <div className="experienceCardDescriptionText">{`${convertDateString(
          experienceData.start,
        )} - ${convertDateString(experienceData.end)}`}</div>
      </section>
      {/* Accordeon icon section */}
      <section className="experienceCardAccordeonIconArea">
        <div
          className="experienceCardAccordeonTriggerIcon"
          onClick={() => onClickEvent(!onClickState)}
        >
          {!onClickState ? (
            <KeyboardArrowDownIcon sx={{ fontSize: 35, color: color.primary }} />
          ) : (
            <KeyboardArrowUpIcon sx={{ fontSize: 35, color: color.primary }} />
          )}
        </div>
      </section>
    </div>
  )
}

ExperienceListCard.propTypes = {
  experienceData: PropTypes.object,
  onClickEvent: PropTypes.func,
  onClickState: PropTypes.bool,
}

export default ExperienceListCard

import GroupSearch from './GroupSearch/GroupSearch'
import GroupGrid from './GroupGrid/GroupGrid'
import { GroupGridContainer } from './Teams.utils'
import { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { Label, Row } from './Payments.styles'
import Message from '../../Form/components/Message'
const Teams = forwardRef(
  ({ groupData, SetTicketProperty, order, ticketInfo, setOverflowOff }, ref) => {
    const [sortedGroup, setSortedGroups] = useState(
      ticketInfo?.sortedGroup
        ? ticketInfo?.sortedGroup
        : {
            group: [],
            team: [],
            individual: [],
            household: [],
          },
    )
    const [searchData, setSearchData] = useState(groupData)

    useEffect(() => {
      SetTicketProperty(sortedGroup, 'sortedGroup', order)
    }, [sortedGroup])

    const handleGroupVariants = (arrayName, newItem) => {
      setSortedGroups((prevState) => ({
        ...prevState,
        [arrayName]: [...prevState[arrayName], newItem],
      }))
    }

    const removeGroupFromArray = (arrayName, itemId) => {
      setSortedGroups((prevState) => {
        const updatedArray = prevState[arrayName].filter((item) => item.id !== itemId)
        const removedItem = prevState[arrayName].find((item) => item.id === itemId)
        setSearchData((filteredItem) => [...filteredItem, removedItem])
        return {
          ...prevState,
          [arrayName]: updatedArray,
        }
      })
    }

    //Function for storing data, will be implemented later when we connect all the screens
    function storeUserDataFromAtendees() {}

    useImperativeHandle(ref, () => ({
      handleButtonClick: storeUserDataFromAtendees,
    }))

    const GroupTypes = [
      { value: 'group', name: 'Groups' },
      { value: 'household', name: 'Households' },
      { value: 'team', name: 'Teams' },
      { value: 'individual', name: 'Individuals' },
    ]

    return (
      <>
        <GroupSearch
          groupData={searchData}
          setSearchData={setSearchData}
          groupHandlerFunction={handleGroupVariants}
          setSortedGroups={setSortedGroups}
          variant={'group'}
        ></GroupSearch>
        <Row>
          <Label width="200px" />
          <Message translateY={'-8px'} center={true}>
            Select people or group of people who you want associate this ticket with.
          </Message>
        </Row>
        <GroupGridContainer>
          {GroupTypes.map((groupType) => {
            return sortedGroup[groupType.value].length > 0 ? (
              <GroupGrid
                groupName={groupType.name}
                selectedGroups={sortedGroup[groupType.value]}
                setSelectedGroups={removeGroupFromArray}
                setSearchData={setSearchData}
                setSortedGroups={setSortedGroups}
              />
            ) : null
          })}
        </GroupGridContainer>
      </>
    )
  },
)
export default Teams

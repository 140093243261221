/* eslint-disable no-unused-vars */
import './AllTemplatesGathering.css'
import NavbarOutlinedButton from '../NavbarOutlinedButton/NavbarOutlinedButton'
import GatheringsFilter from '../GatheringsFilter/GatheringsFilter'
import TableComponent from '../TableComponent/TableComponent'
import { useEffect, useState } from 'react'
import SearchComponent from '../SearchComponent/SearchComponent'
import { useTemplates } from '../../util/zustant/templateStore'
import { unifyData } from '../../util/utility'
import { logger } from '../../util/logger'
import { getAllTemplates, getHeartedTemplates } from '../../util/templates'
import { useEvents } from '../../hooks/useEvents'
import { FilterTemplates } from '../../util/utility'
import GatheringTemplatesFilter from '../GatheringTemplatesFilter/GatheringTemplatesFilter'

const AllTemplatesGathering = ({ token, setDrafts }) => {
  const { templates, setTemplates, setTemplateHearted, templateHearted } = useTemplates()
  const [rowsPerPageFromChild, setRowsPerPageFromChild] = useState(5)
  const [pageFromChild, setPageFromChild] = useState(0)
  const [filter, setFilter] = useState(null)
  const { profileData } = useEvents()
  const userId = profileData.uid

  useEffect(() => {
    getAllTemplates(setTemplates, token)
    getHeartedTemplates(userId, token, setTemplateHearted)
  }, [])

  const favoriteTemplates = templates.filter((template) => {
    templateHearted.map((templateHearted) => {
      if (template.id === templateHearted.templateId) {
        template.isFavourite = templateHearted.isFavourite
      }
      return false
    })
    return template.isFavourite === true
  })

  let filtered = FilterTemplates(templates, filter, favoriteTemplates)

  return (
    <div className="allTemplatesMainContentArea">
      <GatheringTemplatesFilter filter={filter} searchData={templates} setSearchData={setFilter} />

      <TableComponent
        tableBodyData={filtered?.length === 0 || !filtered ? templates : filtered}
        tableDataVariant={'Templates'}
        rowsPerPageArray={[5, 10, 25]}
        headerDataVariant={'Templates'}
        rowsPerPageParentSetter={setRowsPerPageFromChild}
        parrentPageSetter={setPageFromChild}
      ></TableComponent>
      {/* Second Text Area */}

      <div className="allTemplatesFooterText">
        <div>©2023 Church.Ai. All Rights Reserved.</div>
      </div>
    </div>
  )
}

export default AllTemplatesGathering

import {
  PreviewContainer,
  kioskHeaderIcon,
  RowWrapper,
  RowWrapperForTag,
  RowLeftText,
  RowRightTextBold,
  AdditionalInfoWrapper,
  ImageRow,
} from './Preview.style'
import Kiosk from '../../Kiosk/Kiosk'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import Looks3Icon from '@mui/icons-material/Looks3'
import {
  PreviewDataObject,
  HandleRecurringEvent,
  HandleGatheringType,
  ExpandableListWithButton,
  CapitalizeString,
  Row,
  TagRow,
  Banner,
  QuestionsMap,
  TicketQuestionsMap,
  FileMap,
  TagMap,
  TicketFileMap,
} from './util/Preview.util'

import TicketInfo from './components/TicketInfo'
import GroupInfo from './components/GroupInfo'
import MailReminders from './components/MailReminders'
import AttendeeGroups from './components/AttendeeGroups'
import Tag from '../../Tag/Tag'
import GroupGrid from '../../GroupGrid/GroupGrid'
import { DateDisplay } from './util/Preview.util'
import { dummyReocurrence } from '../../../util/testData'
import { useFormGatherings } from '../../../util/zustant/store'
const Preview = () => {
  const previewData = PreviewDataObject()
  const reocurrenceData = dummyReocurrence

  const { formInfo } = useFormGatherings()

  return (
    <PreviewContainer>
      {/* Second Dropdown */}
      <Kiosk
        formInfo={formInfo}
        headerTitle={'Create a gathering'}
        headerImage={<LooksOneIcon style={kioskHeaderIcon} />}
        variant={'preview'}
      >
        <TagRow
          leftText={'Paid or free event?'}
          rightTagText={previewData.gatheringEventType}
          tagVaraint={'previewTagEventType'}
        ></TagRow>
        <TagRow
          leftText={'Gathering Type:'}
          rightTagText={
            previewData.gatheringType.length > 0
              ? previewData.gatheringType
              : 'Gathering type not selected'
          }
          tagVaraint={'previewTag'}
        ></TagRow>
      </Kiosk>

      {/* Second Dropdown */}
      <Kiosk
        formInfo={formInfo}
        headerTitle={'Add Details'}
        headerImage={<LooksTwoIcon style={kioskHeaderIcon} />}
        variant={'preview'}
      >
        <Banner>{'Basic Info'}</Banner>
        <Row bold leftText={'Title of the Gathering:'} rightText={previewData.gatheringTitle}></Row>
        <Row leftText={'Description:'} rightText={previewData.gatheringDescription}></Row>
        <Banner>{'Logistics'}</Banner>
        <Row bold leftText={'Location:'} rightText={previewData.gatheringLocation} />
        <Row bold leftText={'Capacity Limit:'} rightText={previewData.gatheringCapacityLimit} />
        <Row
          bold
          leftText={'Recurring event?'}
          rightText={HandleRecurringEvent(previewData.gatheringRecurringEvent)}
        />
        {HandleRecurringEvent(previewData.gatheringRecurringEvent) === 'Yes' && (
          <>
            <Row
              bold
              leftText={'How many occurences?'}
              rightText={previewData.gatheringOccurrencesNumber}
            ></Row>
            <Row
              bold
              leftText={'Repeat:'}
              rightText={CapitalizeString(previewData.gatheringRepeatCycle)}
            ></Row>
            <Row
              leftText={'Reocurrences:'}
              rightText={
                <ExpandableListWithButton array={reocurrenceData}></ExpandableListWithButton>
              }
            ></Row>
          </>
        )}
        <Row bold leftText={'Language:'} rightText={previewData.gatheringLanguage}></Row>
        <Banner>{'Attendees'}</Banner>
        <Row
          bold
          leftText={'Type of Gathering:'}
          rightText={HandleGatheringType(previewData.gatheringOpen)}
        ></Row>
        {/* Attendees - Type of attendees: - Selected Groups*/}
        {HandleGatheringType(previewData.gatheringOpen) === 'Invite-Only' && (
          <>
            <Row
              bold
              leftText={'Type of attendees:'}
              rightText={<GroupInfo groupData={previewData.gatheringSelectedAttendees}></GroupInfo>}
            ></Row>
          </>
        )}
        <Banner>{'Payments'}</Banner>
        <Row bold leftText={'Total Capacity:'} rightText={previewData.gatheringCapacityLimit}></Row>
        <Row
          bold
          leftText={'Ticket Types:'}
          rightText={<TicketInfo ticketData={previewData.gatheringTickets}></TicketInfo>}
        ></Row>
        <Banner>{'Additional Info'}</Banner>
        <Row
          leftText={'Questions for attendees:'}
          rightText={
            formInfo?.paidEvent?.eventIsPaid === 'Paid' &&
            formInfo?.paymentsInfo?.tickets &&
            formInfo?.paymentsInfo?.tickets.length > 0 ? (
              <TicketQuestionsMap
                ticketQuestionData={previewData.ticketQuestions}
              ></TicketQuestionsMap>
            ) : (
              <QuestionsMap
                questionData={previewData.gatheringAdditionalInfoQuestions}
              ></QuestionsMap>
            )
          }
        ></Row>
        <Row
          leftText={'Waiver Forms:'}
          rightText={
            formInfo?.paidEvent?.eventIsPaid === 'Paid' &&
            formInfo?.paymentsInfo?.tickets &&
            formInfo?.paymentsInfo?.tickets.length > 0 ? (
              <TicketFileMap ticketFileData={previewData.ticketFiles}></TicketFileMap>
            ) : (
              <FileMap fileData={previewData.gatheringAdditionalInfoUploadedData}></FileMap>
            )
          }
        ></Row>
      </Kiosk>

      {/* Third dropdown */}
      <Kiosk
        formInfo={formInfo}
        headerTitle={'Create a gathering'}
        headerImage={<Looks3Icon style={kioskHeaderIcon} />}
        variant={'preview'}
      >
        <Banner>{'Registration'}</Banner>
        <Row leftText={'Welcome email:'}></Row>
        <Row leftText={'Confirmation message:'}></Row>
        <Banner>{'Reminders'}</Banner>
        <MailReminders
          signUpBody={previewData.gatheringRemindersSignUp}
          paymentBody={previewData.gatheringRemindersPayment}
          cancelationBody={previewData.gatheringRemindersCancelation}
          promoBody={previewData.gatheringRemindersPromo}
        ></MailReminders>
        <Banner>{'Checkins'}</Banner>
        <Row bold leftText={'Checkin URL:'}></Row>
        <Row bold leftText={'PIN:'} rightText={previewData.gatheringCheckinViaKioskPin}></Row>
        <Row
          bold
          leftText={'Who will be checking in attendees?'}
          rightText={
            <AttendeeGroups
              selectedAttendeeGroups={previewData.gatheringSelectedAttendees}
              forSearch={true}
            ></AttendeeGroups>
          }
        ></Row>
        <Banner>{'Onboarding'}</Banner>
        <RowWrapperForTag>
          <RowLeftText>{'Onboarding process:'}</RowLeftText>
          <RowRightTextBold>
            <AdditionalInfoWrapper>
              <Tag
                variant={'previewTag'}
                previewTag={'noIcon'}
                previewDataLabel={'Onboarding'}
              ></Tag>
            </AdditionalInfoWrapper>
          </RowRightTextBold>
        </RowWrapperForTag>
        {previewData.gatheringSecurityCheck === 'Yes' && (
          <>
            <Banner>{'Security'}</Banner>
            <TagRow leftText={'Labels:'} tagMap={previewData.gatheringSecurityLabels}>
              <RowLeftText>{'Labels:'}</RowLeftText>
            </TagRow>
          </>
        )}
        <Banner>{'Publish'}</Banner>
        <Row
          bold
          leftText={'Publish Schedule:'}
          rightText={`${DateDisplay(previewData.gatheringPublishStartDate)} , ${
            previewData.gatheringPublishStartTime
          }`}
        ></Row>
        <Row
          bold
          leftText={'Viewable to everyone or hidden?'}
          rightText={CapitalizeString(previewData.gatheringPublishVariation)}
        ></Row>
        <Banner>{'Tags'}</Banner>
        <Row
          bold
          leftText={'Tags associated to this gatnering:'}
          rightText={<TagMap tagData={previewData?.gatheringTags}></TagMap>}
        ></Row>
        {previewData?.gatheringImage && (
          <>
            <Banner>{'Image Upload'}</Banner>
            {/* Preview Image*/}
            <RowWrapper>
              <ImageRow src={previewData?.gatheringImage}></ImageRow>
            </RowWrapper>
          </>
        )}
        <Banner>{'URL/Slug'}</Banner>
        {/* URL/Slug - Slug for gathering*/}
        <Row leftText={'Slug for your gathering:'}></Row>
        <Banner>{'Approval'}</Banner>
        <Row
          leftText={'Approver of the gathering:'}
          rightText={
            previewData?.gatheringApprover.length > 0 && (
              <GroupGrid
                groupName={'Individuals'}
                forSearch={true}
                selectedGroups={previewData?.gatheringApprover}
              />
            )
          }
        ></Row>
      </Kiosk>
    </PreviewContainer>
  )
}

export default Preview

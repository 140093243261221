import { uploadFileToCloudStorage } from './cloudStorage'

const handleImageUpload = async (event, setUploadedImage, onDrop, authToken) => {
  let image = {}
  if (onDrop) {
    image = event.dataTransfer.files[0]
  } else {
    image = event.target.files[0]
  }
  if (image) {
    const formData = new FormData()
    formData.append('files', image)

    const response = await uploadFileToCloudStorage(formData, authToken)
    if (response) {
      setUploadedImage(response.files[0].fileUrl)
      return response
    }
  }
}

const handleDrop = (event, setUploadedImage, authToken) => {
  event.preventDefault()
  handleImageUpload(event, setUploadedImage, true, authToken)
}

const handleDragOver = (event) => {
  event.preventDefault()
}

export { handleImageUpload, handleDrop, handleDragOver }

import {
  CheckinSetupContainer,
  CheckinSetupMainText,
  CheckinSetupSubText,
  CheckinSetupAltSubText,
  OptionalDivider,
  Divider,
  DateAndTimeSelectorArea,
  SelectIndividualsArea,
  GroupGridContainer,
  DateAndTimeWrapper,
} from './CheckinSetup.style'
import UrlComponent from '../../UrlComponent/UrlComponent'
import Pin from '../../Pin/Pin'
import TagGrid from '../../TagGrid/TagGrid'
import GroupSearch from '../../GroupSearch/GroupSearch'
import GroupGrid from '../../GroupGrid/GroupGrid'
import DateInput from '../../Form/components/DateInput'
import TimeInput from '../../Form/components/TimeInput'
import { useEffect, useState } from 'react'

const CheckinSetup = ({
  formInfo,
  kiosk,
  groupData,
  setKioskPin,
  setSearchingData,
  setSelectedIndividuals,
  setDate,
  setTime,
  kioskData,
}) => {
  const [searchData, setSearchData] = useState(
    formInfo?.searchData ? formInfo?.searchData : groupData,
  )
  const [sortedGroup, setSortedGroups] = useState(
    formInfo?.selectedIndividuals
      ? formInfo?.selectedIndividuals
      : {
          group: [],
          team: [],
          individual: [],
          household: [],
        },
  )

  useEffect(() => {
    setSelectedIndividuals(sortedGroup)
  }, [sortedGroup])

  useEffect(() => {
    setSearchingData(searchData)
  }, [searchData])

  function checkNotNull(array) {
    return array.some((element) => element !== null)
  }

  return (
    <CheckinSetupContainer>
      <CheckinSetupMainText>Setup</CheckinSetupMainText>
      <UrlComponent variant={'checkinUrl'} urlString={'https://www.google.com/'}></UrlComponent>
      <Pin
        pinText={`Kiosk's 4-digit PIN:`}
        kiosk={true}
        pinNumber={formInfo?.kioskPin ? formInfo?.kioskPin : ''}
        setKioskPin={setKioskPin}
      ></Pin>
      {kiosk && (
        <>
          <CheckinSetupSubText>Gatherings associated with this kiosk:</CheckinSetupSubText>
          <TagGrid
            tagPool={checkNotNull(kioskData?.relatedGatherings) ? kioskData?.relatedGatherings : []}
            tagVariant={'gathering'}
          ></TagGrid>
        </>
      )}
      <OptionalDivider>
        OPTIONAL:
        <Divider></Divider>
      </OptionalDivider>
      <SelectIndividualsArea>
        <GroupSearch
          firstRowText={'Select individual(s) to send the Checkin URL to:'}
          groupData={searchData}
          setSearchData={setSearchData}
          setSortedGroups={setSortedGroups}
          variant={'group'}
        ></GroupSearch>
        <GroupGridContainer>
          {sortedGroup.group.length > 0 && (
            <GroupGrid
              groupName={'Groups'}
              selectedGroups={sortedGroup.group}
              setSortedGroups={setSortedGroups}
              setSearchData={setSearchData}
            />
          )}
          {/* {sortedGroup.household.length > 0 && (
            <GroupGrid
              groupName={'Households'}
              selectedGroups={sortedGroup.household}
              setSortedGroups={setSortedGroups}
              setSearchData={setSearchData}
            />
          )} */}
          {sortedGroup.team.length > 0 && (
            <GroupGrid
              groupName={'Teams'}
              selectedGroups={sortedGroup.team}
              setSortedGroups={setSortedGroups}
              setSearchData={setSearchData}
            />
          )}
          {sortedGroup.individual.length > 0 && (
            <GroupGrid
              groupName={'Individuals'}
              selectedGroups={sortedGroup.individual}
              setSortedGroups={setSortedGroups}
              setSearchData={setSearchData}
            />
          )}
        </GroupGridContainer>
      </SelectIndividualsArea>
      <DateAndTimeSelectorArea>
        <CheckinSetupAltSubText>Date & Time to send Checkin link:</CheckinSetupAltSubText>
        <DateAndTimeWrapper>
          <DateInput onChange={setDate} value={formInfo?.selectedDate}></DateInput>
          <TimeInput onChange={setTime} value={formInfo?.selectedTime}></TimeInput>
        </DateAndTimeWrapper>
      </DateAndTimeSelectorArea>
    </CheckinSetupContainer>
  )
}

export default CheckinSetup

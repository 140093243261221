import React, { useEffect, useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { CopyStyle, ChecmarkStyle, Message, MessageHolder } from './Copy.style'

function Copy({ reference, align, setOverflowOff }) {
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (copied) {
      navigator.clipboard.writeText(
        document.getElementById(reference).value
          ? document.getElementById(reference).value
          : document.getElementById(reference).innerText,
      )
      setOverflowOff && setTimeout(() => setOverflowOff(false), 1000)
    }
  }, [copied, reference])

  return !copied ? (
    <CopyStyle
      onClick={() => {
        setCopied(true)
        setOverflowOff && setOverflowOff(true)
      }}
    >
      <ContentCopyIcon />
    </CopyStyle>
  ) : (
    <ChecmarkStyle>
      <CheckCircleIcon />
      <MessageHolder align={align}>
        <Message>Copied to clipboard</Message>
      </MessageHolder>
    </ChecmarkStyle>
  )
}

export default Copy

import {
  StyledTemplateButton,
  previewBannerEditButtonStyle,
} from '../../../StyledButton/StyledButton.style'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useFormGatherings } from '../../../../util/zustant/store'
import React, { useRef } from 'react'
import {
  expandListButtonContainer,
  expandListButtonStyle,
  RowWrapper,
  RowLeftText,
  RowRightTextBold,
  RowRightText,
  EditBanner,
  AdditionalInfoWrapper,
  RowWrapperForTag,
  TicketInfoWrapper,
  TagContainer,
} from '../Preview.style'
import Tag from '../../../Tag/Tag'
import { useState, useEffect } from 'react'
import {
  checklistConfiguration,
  getAllTitlesAndIndexes,
} from '../../../Checklist/util/Checklist.util'

const PreviewBannerEditButton = ({ label, navRoute }) => {
  const { formInfo, setFormInfo } = useFormGatherings()
  const checkinMethod = formInfo?.checkinType?.checkinMethod
  const openGathering = formInfo?.attendees?.openGathering
  const paidEvent = formInfo?.paidEvent?.eventIsPaid
  const [activeStep, setActiveStep] = useState(formInfo.activeStep)
  const checklistDataPool = checklistConfiguration(paidEvent, checkinMethod, openGathering)
  const checklistSteps = getAllTitlesAndIndexes(checklistDataPool)

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.activeStep = activeStep
    setFormInfo(newFormInfo)
  }, [activeStep])

  return (
    <StyledTemplateButton
      variant="contained"
      startIcon={<OpenInNewIcon style={{ fontSize: 14 }} />}
      sx={previewBannerEditButtonStyle}
      onClick={() => {
        const stepIndex = checklistSteps.find((s) => s.title === navRoute)?.index
        setActiveStep(stepIndex)
      }}
    >
      {label}
    </StyledTemplateButton>
  )
}

const Row = ({ leftText, rightText, bold }) => {
  return (
    <RowWrapper>
      <RowLeftText>{leftText}</RowLeftText>
      {bold ? (
        <RowRightTextBold>{rightText}</RowRightTextBold>
      ) : (
        <RowRightText>{rightText}</RowRightText>
      )}
    </RowWrapper>
  )
}

const TagRow = ({ leftText, rightTagText, tagVaraint, tagMap }) => {
  return (
    <RowWrapperForTag>
      <RowLeftText>{leftText}</RowLeftText>
      <RowRightText>
        <AdditionalInfoWrapper>
          {!tagMap && (
            <Tag variant={tagVaraint} previewTag={'noIcon'} previewDataLabel={rightTagText}></Tag>
          )}
          {tagMap &&
            tagMap.map((tag, i) => (
              <Tag
                key={i}
                variant={'previewTag'}
                previewTag={'noIcon'}
                previewDataLabel={tag.labelName}
              ></Tag>
            ))}
        </AdditionalInfoWrapper>
      </RowRightText>
    </RowWrapperForTag>
  )
}

const Banner = ({ children }) => {
  return (
    <EditBanner>
      {children}
      <PreviewBannerEditButton label={'Edit'} navRoute={children}></PreviewBannerEditButton>
    </EditBanner>
  )
}

const QuestionsMap = ({ questionData }) => {
  return (
    <AdditionalInfoWrapper>
      {questionData.map((question, i) => (
        <Tag
          key={i}
          variant={'previewTag'}
          previewTag={'question'}
          previewDataLabel={question?.question}
        ></Tag>
      ))}
    </AdditionalInfoWrapper>
  )
}

const TicketQuestionsMap = ({ ticketQuestionData }) => {
  const ticketNames = Object.keys(ticketQuestionData)
  return ticketNames.map((ticket, i) => (
    <TicketInfoWrapper style={{ rowGap: '25px', paddingBottom: '30px' }}>
      <RowRightTextBold>{`Ticket Name: ${ticket}`}</RowRightTextBold>
      {ticketQuestionData[ticket].map((question) => (
        <TagContainer>
          <Tag
            key={i}
            variant={'previewTag'}
            previewTag={'question'}
            previewDataLabel={question?.question}
          ></Tag>
        </TagContainer>
      ))}
    </TicketInfoWrapper>
  ))
}

const FileMap = ({ fileData }) => {
  return (
    <AdditionalInfoWrapper>
      {fileData.map((file, i) => (
        <Tag
          key={i}
          variant={'previewTag'}
          previewTag={'question'}
          previewDataLabel={file?.filename}
        ></Tag>
      ))}
    </AdditionalInfoWrapper>
  )
}

const TicketFileMap = ({ ticketFileData }) => {
  const ticketNames = Object.keys(ticketFileData)
  return ticketNames.map((ticket, i) => (
    <TicketInfoWrapper style={{ rowGap: '25px', paddingBottom: '30px' }}>
      <RowRightTextBold>{`Ticket Name: ${ticket}`}</RowRightTextBold>
      {ticketFileData[ticket].map((waiver) => (
        <TagContainer>
          <Tag
            key={i}
            variant={'previewTag'}
            previewTag={'question'}
            previewDataLabel={waiver?.filename}
          ></Tag>
        </TagContainer>
      ))}
    </TicketInfoWrapper>
  ))
}

const TagMap = ({ tagData }) => {
  return (
    <AdditionalInfoWrapper>
      {tagData.map((tag, i) => (
        <Tag key={i} variant={'previewTag'} previewTag={'noIcon'} previewDataLabel={tag.name}></Tag>
      ))}
    </AdditionalInfoWrapper>
  )
}

const PreviewDataObject = () => {
  const { formInfo } = useFormGatherings()
  const previewObjectData = {
    gatheringTitle: formInfo?.gatheringDetails?.title,
    gatheringDescription: formInfo?.gatheringDetails?.description,
    gatheringEventType: formInfo?.paidEvent?.eventIsPaid,
    gatheringType: formInfo?.gatheringType?.typeOfGathering,
    gatheringLocation: formInfo?.logisticsInfo?.location,
    gatheringCapacityLimit: formInfo?.logisticsInfo?.registration_limit,
    gatheringRecurringEvent: formInfo?.logisticsInfo?.recurring,
    gatheringOccurrencesNumber: formInfo?.logisticsInfo?.event?.recurrencAmount,
    gatheringRepeatCycle: formInfo?.logisticsInfo?.event?.recurres,
    gatheringReocurrences: formInfo?.logisticsInfo?.event?.occurences || [],
    gatheringLanguage: formInfo?.logisticsInfo?.language,
    gatheringOpen: formInfo?.attendees?.openGathering,
    gatheringSelectedAttendees: formInfo?.attendees?.selectedAttendees,
    gatheringTickets: formInfo?.paymentsInfo?.tickets || [],
    gatheringAdditionalInfoQuestions: formInfo?.additionalInfo?.savedQuestions || [],
    gatheringAdditionalInfoUploadedData: formInfo?.additionalInfo?.uploadedData || [],
    gatheringRemindersSignUp: formInfo?.reminders?.signUpEmailBody,
    gatheringRemindersPayment: formInfo?.reminders?.paymentEmailBody,
    gatheringRemindersCancelation: formInfo?.reminders?.cancellationEmailBody,
    gatheringRemindersPromo: formInfo?.reminders?.promoAlertEmailBody,
    gatheringCheckinViaKioskPin: formInfo?.checkinKioskInfo?.kioskPin,
    gatheringSecurityCheck: formInfo?.security?.peopleCheck,
    gatheringSecurityLabels: formInfo?.security?.labels || [],
    gatheringPublishVariation: formInfo?.publish?.gatheringVisibility,
    gatheringPublishStartDate: formInfo?.publish?.startDate,
    gatheringPublishStartTime: formInfo?.publish?.startTime,
    gatheringTags: formInfo?.tags?.tagPool || [],
    gatheringApprover: formInfo?.approval?.selectedApprovers?.individual || [],
    gatheringImage: formInfo?.imageUpload?.eventImage,
    ticketQuestions: formInfo?.paymentsInfo?.questions || [],
    ticketFiles: formInfo?.paymentsInfo?.waivers || [],
  }

  return previewObjectData
}

const HandleRecurringEvent = (recurring) => {
  if (recurring) {
    return 'Yes'
  } else {
    return 'No'
  }
}

const HandleGatheringType = (gatheringOpen) => {
  if (gatheringOpen === 'yes') {
    return 'Open'
  } else if (gatheringOpen === 'no') {
    return 'Invite-Only'
  }
}

const DateDisplay = (date) => {
  let dateString = date ? date : '01/01/2024'
  const dateParts = dateString.split('/')
  const month = parseInt(dateParts[0], 10)
  const day = parseInt(dateParts[1], 10)
  const year = parseInt(dateParts[2], 10)

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const formattedDate = `${months[month - 1]} ${day}, ${year}`
  return formattedDate
}

const ExpandableListWithButton = ({ array }) => {
  const listRef = useRef(null)
  const expandButtonRef = useRef(null)
  const limit = 10

  const expandList = () => {
    for (let i = limit; i < array.length; i++) {
      const listItem = document.createElement('li')
      listItem.textContent = array[i]
      listItem.style.paddingBottom = '10px'
      listRef.current.appendChild(listItem)
    }
    expandButtonRef.current.style.display = 'none'
  }

  const renderList = () => {
    const listItems = array.slice(0, limit).map((item, index) => (
      <li key={index} style={{ paddingBottom: 10 }}>
        {item}
      </li>
    ))

    return (
      <>
        <ul ref={listRef} style={{ paddingLeft: 15 }}>
          {listItems}
        </ul>
        {array.length > limit && (
          <>
            <div ref={expandButtonRef} style={expandListButtonContainer}>
              <div ref={expandButtonRef} onClick={expandList} style={expandListButtonStyle}>
                SHOW ALL
              </div>
            </div>
          </>
        )}
      </>
    )
  }

  return <div>{renderList()}</div>
}

const CapitalizeString = (string) => {
  const firstLetter = string?.charAt(0).toUpperCase()
  const capitalizedString = firstLetter + string?.slice(1)
  return capitalizedString
}

export {
  PreviewBannerEditButton,
  Row,
  TagRow,
  Banner,
  PreviewDataObject,
  HandleRecurringEvent,
  HandleGatheringType,
  DateDisplay,
  ExpandableListWithButton,
  CapitalizeString,
  QuestionsMap,
  TicketQuestionsMap,
  FileMap,
  TicketFileMap,
  TagMap,
}

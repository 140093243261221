import styled from 'styled-components'
import color from '../../util/colors'
import OutlinedInput from '@mui/material/OutlinedInput'

const CheckinURLContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const CheckinURLText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--title);
  width: 30%;
  display: flex;
  margin-top: 1rem;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    padding-bottom: 1rem;
  }
`

const CheckinLinkArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    padding-bottom: 1rem;
  }
`

const CheckinLinkBanner = styled.div`
  height: 43px;
  width: 100%;
  display: flex;
  border-radius: 6px;
  background: var(--backgroundSecondary);
  align-items: center;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.004em;
`
const bannerIcon = {
  background: color.noColor,
  borderRadius: '50px',
  color: color.backgroundPrimary,
  marginLeft: '1rem',
  marginRight: '1rem',
  cursor: 'pointer',
}

const formIcon = {
  color: color.primary,
  marginLeft: '1rem',
  cursor: 'pointer',
}

const alertBoxStyle = {
  background: color.primary,
  color: color.backgroundPrimary,
}

const alertBoxIcon = {
  color: color.backgroundPrimary,
}

const URLBox = styled(OutlinedInput)({})

URLBox.defaultProps = {
  inputProps: { style: { color: color.tabSelected } },
}

export {
  CheckinURLContainer,
  CheckinURLText,
  CheckinLinkArea,
  CheckinLinkBanner,
  URLBox,
  bannerIcon,
  formIcon,
  alertBoxStyle,
  alertBoxIcon,
}

import styled from 'styled-components'
import color from '../../../util/colors'
export const DropdownContainer = styled.div`
  display: relative;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${color.primary};
  svg {
    width: 20px;
    height: 20px;
    color: ${color.primary};
  }
  cursor: pointer;
  z-index: 300;
`
export const DropdownStyle = styled.div`
  display: flex;
  flex-direction: column;
  display: relative;
`
export const DropdownItems = styled.div`
  box-sizing: border-box;
  min-width: max-content;
  min-height: max-content;
  z-index: 300;
  background: ${color.backgroundPrimary};
  border-radius: 4px;
  border: 1px solid ${color.primary};
  padding: 0 16px;
  padding-top: 16px;
`

export const DropdownItem = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: ${color.title};
  min-width: 80px;
  margin-bottom: 16px;
  cursor: pointer;
  &:hover {
    color: ${color.primary};
  }
`

export const DropdownItemsHolder = styled.div`
  position: relative;
  top: -32px;

  left: 80px;
  max-width: 0;
  max-height: 0;
  overflow: visible;
  z-index: 300;
`

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import color from '../../util/colors'

const StyledTableRow = styled(TableRow)({
  '&.MuiTableRow-root:hover': {
    backgroundColor: color.backgroundPrimary,
    borderLeft: `5px solid ${color.tableHover}`,
  },
  width: '100%',
})

const StyledTableCell = styled(TableCell)({
  borderBottom: 0,
  color: color.description,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '140%',
  letterSpacing: ' 0.0015em',
  paddingLeft: '3rem',
})

const StyledTableImageCell = styled(TableCell)({
  borderBottom: 0,
  display: 'flex',
  paddingLeft: '3rem',
})

const StyledTableCellButton = styled(Button)({
  width: '10px',
  '&.MuiButton-root:hover': {
    color: color.tableHover,
    backgroundColor: 'transparent',
  },
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '140%',
  letterSpacing: ' 0.0015em',
})

const StyledTableCellIconButton = styled(Button)({
  padding: 0,
  minWidth: 0,
  '&.MuiButton-root:hover': {
    color: color.tableHover,
    backgroundColor: 'transparent',
  },
})

const highlightBorderStyle = {
  width: 5,
  height: 50,
  background: color.tableHover,
}

export {
  StyledTableRow,
  StyledTableCell,
  highlightBorderStyle,
  StyledTableCellButton,
  StyledTableCellIconButton,
  StyledTableImageCell,
}

/* eslint-disable no-unused-vars */
import {
  OnboardingSetupContainer,
  TextFieldArea,
  OnboardingDropdownRowArea,
  RowText,
  DefaultFieldContainer,
  AddButton,
  textFieldCloseIcon,
  AttendeeQuestionsArea,
  AttendeeQuestionField,
  DeleteIconWrapper,
} from './OnboardingSetup.style'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Dropdown from '../../Form/components/Dropdown'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { useEffect, useState } from 'react'
import { logger } from '../../../util/logger'
import { deleteOnboarding } from '../../../util/onboarding'
import ExitInput from '../../Form/components/ExitInput'
import Input from '../../Form/components/Input'
import { PromptFieldCloseIcon, PromptFieldCloseIconLabel } from './PromptField.styles'
import {
  RetrievedFieldsHolder,
  Field,
  FieldHeader,
  FieldLabel,
  FieldBody,
  DefualtField,
} from './Ticket.styles'
import { Label, Row } from './Payments.styles'
import ModalPrompt from '../../ModalPrompt/ModalPrompt'
const OnboardingSetup = ({ dropdownData, defaultFields, item, setOnboardings, index, token }) => {
  const [errorHandle, setErrorHandle] = useState({
    onboardingNameEmpty: false,
    descriptionEmpty: false,
    confirmationMesageEmpty: false,
    questionEmpty: false,
  })
  const [overflowOff, setOverflowOff] = useState(false)

  const [showInput, setShowInput] = useState(false)
  const [clickedFieldIndex, setClickedFieldIndex] = useState(null)
  const [popuStatus, setPopuStatus] = useState(false)
  const [modalStatus, setModalStatus] = useState(false)

  const handleClick = (index) => {
    setClickedFieldIndex(index)
    setShowInput(true)
  }

  const handleBlur = () => {
    setClickedFieldIndex(null)
    setShowInput(false)
  }

  const handleInputBlur = (index) => {
    handleBlur()
  }

  function RemoveField(index, indexField) {
    setOnboardings((onboardings) => {
      onboardings[index].fields[indexField] = onboardings[index].fields?.filter(
        (_, indexField) => indexField !== index,
      )
      return [...onboardings]
    })
  }

  async function removeOnboarding() {
    setOnboardings((onboardings) => {
      return [...onboardings.filter((t) => item.id !== t.id)]
    })
    await deleteOnboarding(token, item.id)
  }

  const PromptField = ({ indexField }) => {
    if (showInput && clickedFieldIndex === index) {
      return (
        <div style={{ position: 'relative' }}>
          <Input
            autoFocus={true}
            type="text"
            onBlur={() => handleInputBlur(index)}
            onChange={(value) => {
              setOnboardings((onboardings) => {
                onboardings[index].fields[indexField].prompt = value || ''
                return [...onboardings]
              })
            }}
            value={item?.fields[index]?.prompt}
          />
          <PromptFieldCloseIcon
            onMouseDown={(e) => {
              e.preventDefault()
              logger.log('remove field')
              RemoveField(index, indexField)
            }}
          />
        </div>
      )
    } else {
      return (
        <DefualtField onClick={() => handleClick(index)}>
          <FieldLabel>{item?.fields[index]?.prompt || 'Click to edit the prompt'}</FieldLabel>
          <PromptFieldCloseIconLabel
            onClick={(e) => {
              e.stopPropagation()
              RemoveField(index, indexField)
            }}
          />
        </DefualtField>
      )
    }
  }

  const fieldsList = [
    { type: 'New Question', value: 'New Question', label: 'Add Question' },
    { type: 'prompt', value: 'prompt', label: 'Add Prompt' },
  ]

  const fieldsQuestionList = [
    { type: 'Checkbox', value: 'Checkbox', label: 'Checkbox' },
    { type: 'Text', value: 'Text', label: 'Text' },
    { type: 'Select', value: 'Select', label: 'Select' },
    { type: 'Number', value: 'Number', label: 'Number' },
  ]

  return (
    <OnboardingSetupContainer>
      <TextFieldArea>
        <TextField
          sx={{ width: '100%' }}
          id="outlined-basic"
          label={'Onboarding process name'}
          variant="outlined"
          value={item.name}
          onChange={(e) =>
            setOnboardings((onboardings) => {
              onboardings[index].name = e.target.value
              onboardings[index].updated = true
              return [...onboardings]
            })
          }
          error={errorHandle.onboardingNameEmpty}
          helperText={!errorHandle.onboardingNameEmpty ? '' : 'This field cannot be empty.'}
        />
        <TextField
          sx={{ width: '100%' }}
          id="outlined-basic"
          label={'Question'}
          variant="outlined"
          value={item.question}
          onChange={(e) =>
            setOnboardings((onboardings) => {
              onboardings[index].question = e.target.value
              onboardings[index].updated = true
              return [...onboardings]
            })
          }
          error={errorHandle.questionEmpty}
          helperText={!errorHandle.questionEmpty ? '' : 'This field cannot be empty.'}
        />
        <TextField
          sx={{ width: '100%' }}
          id="outlined-basic"
          label={'Description'}
          variant="outlined"
          value={item.description}
          onChange={(e) =>
            setOnboardings((onboardings) => {
              onboardings[index].description = e.target.value
              onboardings[index].updated = true
              return [...onboardings]
            })
          }
          error={errorHandle.descriptionEmpty}
          helperText={!errorHandle.descriptionEmpty ? '' : 'This field cannot be empty.'}
          rows={5}
          multiline
        />
        <TextField
          sx={{ width: '100%' }}
          id="outlined-basic"
          label={'Confirmation Message'}
          variant="outlined"
          value={item.confirmationMesage}
          onChange={(e) =>
            setOnboardings((onboardings) => {
              onboardings[index].confirmationMesage = e.target.value
              onboardings[index].updated = true
              return [...onboardings]
            })
          }
          error={errorHandle.confirmationMesageEmpty}
          helperText={!errorHandle.confirmationMesageEmpty ? '' : 'This field cannot be empty.'}
          rows={5}
          multiline
        />
      </TextFieldArea>
      <OnboardingDropdownRowArea>
        <RowText>{'Fields:'}</RowText>
        <Dropdown
          items={fieldsList}
          setOverflowOff={setOverflowOff}
          onChange={(value) => {
            setOnboardings((onboardings) => {
              onboardings[index].fields = onboardings[index].fields ?? []
              onboardings[index].fields.push(value)
              onboardings[index].updated = true
              return [...onboardings]
            })
          }}
          popuStatus={popuStatus}
          setPopuStatus={setPopuStatus}
        ></Dropdown>
      </OnboardingDropdownRowArea>
      <DefaultFieldContainer>
        {defaultFields.map((value) => (
          <DefualtField>
            <FieldLabel>{value}</FieldLabel>
            <CloseIcon />
          </DefualtField>
        ))}

        {item.fields?.map((value, indexField) => {
          if (item?.fields[indexField]?.type === 'prompt') {
            return <PromptField indexField={indexField} />
          } else if (item?.fields[indexField]?.type === 'New Question') {
            return (
              <Field key={indexField}>
                <FieldHeader>
                  <FieldLabel>{item?.fields[indexField]?.value} </FieldLabel>
                  <CloseIcon onClick={() => RemoveField(index, indexField)} />
                </FieldHeader>
                <FieldBody>
                  <Input
                    type="text"
                    label="Click to edit the prompt"
                    value={item?.fields[indexField]?.prompt}
                  />
                  {item?.fields[indexField]?.values?.length > 0 &&
                    item?.fields[indexField]?.values.map((valueObject, valueIndex) => (
                      <ExitInput
                        type={valueObject.label}
                        object={valueObject}
                        key={valueIndex}
                        item={item}
                        setOverflowOff={setOverflowOff}
                        values={item?.fields[indexField]?.values[valueIndex]}
                        value={item.fields[indexField].values[valueIndex].text}
                        index={valueIndex}
                        indexField={indexField}
                        onboardingIndex={index}
                        setOnboardings={setOnboardings}
                        setPopuStatus={setPopuStatus}
                        popuStatus={popuStatus}
                      />
                    ))}
                  {(item?.fields[indexField]?.type === 'Select' ||
                    item?.fields[indexField]?.type === 'New Question') && (
                    <Row padding={true}>
                      <Label>Values</Label>
                      <Dropdown
                        items={fieldsQuestionList}
                        setOverflowOff={setOverflowOff}
                        onChange={(item) => {
                          setOnboardings((onboardings) => {
                            item.text = item.label
                            if (item.label === 'Checkbox' || item.label === 'Select') {
                              item.checked = false
                            }
                            onboardings[index].fields[indexField].values =
                              onboardings[index].fields[indexField].values ?? []
                            onboardings[index].fields[indexField].values.push({ ...item })
                            return [...onboardings]
                          })
                        }}
                        setPopuStatus={setPopuStatus}
                        popuStatus={popuStatus}
                      />
                    </Row>
                  )}
                </FieldBody>
              </Field>
            )
          }
          return null
        })}
      </DefaultFieldContainer>
      {/* TODO: remove this if not needed anymore  */}
      {/* <OnboardingDropdownRowArea>
        <RowText>{'Questions to ask attendees:'}</RowText>
        <AddButton
          onClick={() => {
            const newQuestion = { text: '', index: attendeeQuestions.length + 1 }
            setAttendeeQuestions([...attendeeQuestions, newQuestion])
          }}
        >
          <AddIcon /> Add
        </AddButton>
      </OnboardingDropdownRowArea>
      <AttendeeQuestionsArea>
        {attendeeQuestions.map((question) => (
          <AttendeeQuestionField
            key={question.id}
            id="outlined-basic"
            variant="outlined"
            value={question.text}
            onChange={(e) => {
              const updatedQuestions = [...attendeeQuestions]
              updatedQuestions[question.index - 1].text = e.target.value
              setAttendeeQuestions(updatedQuestions)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CloseIcon
                    style={textFieldCloseIcon}
                    onClick={() => {
                      const filteredQuestions = attendeeQuestions.filter(
                        (q) => q.index !== question.index,
                      )
                      setAttendeeQuestions(filteredQuestions)
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        ))}
      </AttendeeQuestionsArea> */}
      <DeleteIconWrapper>
        <DeleteIcon
          onClick={() => {
            setModalStatus(true)
          }}
        ></DeleteIcon>
      </DeleteIconWrapper>
      <ModalPrompt
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        descriptionText={`Delete Onboarding`}
        onClickFunction={removeOnboarding}
        variant="deleteLabel"
      />
    </OnboardingSetupContainer>
  )
}

export default OnboardingSetup

import {
  UrlSlugContainer,
  RowContainer,
  StyledText,
  ContentArea,
  UtilityButton,
  iconStyle,
  qrCodeStyle,
} from './UrlSlug.style'
import { saveQrCode } from '../../../util/urlSlug'
import Message from '../../Form/components/Message'
import UrlComponent from '../../UrlComponent/UrlComponent'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import QRCode from 'react-qr-code'

const UrlSlug = () => {
  const urlString = 'https://dev.churchai.krtech.dev/'
  return (
    <UrlSlugContainer>
      <Message translateY={'-8px'}>
        {
          'Church.AI automatically generate a dedicate URL, a link for your gathering. A slug is the unique title that you to identify your gathering and use it to share.'
        }
      </Message>
      <UrlComponent variant={'slugUrl'} urlString={urlString}></UrlComponent>
      <RowContainer>
        <StyledText>Test your slug</StyledText>
        <ContentArea>
          <UtilityButton
            onClick={() => {
              window.open(urlString, '_blank', 'noreferrer')
            }}
          >
            <OpenInNewIcon style={iconStyle}></OpenInNewIcon>
            Click Here to View
          </UtilityButton>
        </ContentArea>
      </RowContainer>
      <RowContainer>
        <StyledText>Gathering QR Code</StyledText>
        <ContentArea>
          <QRCode value={urlString} style={qrCodeStyle} id="qrCode"></QRCode>
          <UtilityButton
            onClick={() => {
              saveQrCode('qrCode')
            }}
          >
            <FileDownloadIcon style={iconStyle}></FileDownloadIcon>
            Download QR code
          </UtilityButton>
        </ContentArea>
      </RowContainer>
    </UrlSlugContainer>
  )
}

export default UrlSlug

import { unifyData } from './utility'
const addTagToThePoolDB = async (activeTag, setTagsPool, setDuplicateAlert, setAllTags, token) => {
  const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/tags/create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: activeTag.name,
      description: activeTag.description,
    }),
  })

  const data = await resp.json()

  setTagsPool((prevTags) => {
    const existingTagIndex = data?.error
    const updatedTags = [...prevTags]
    if (existingTagIndex) {
      setDuplicateAlert(true)
    } else {
      const newTag = {
        id: data?.id,
        created: data?.created,
        lastUpdated: data?.lastUpdated,
        name: activeTag.name,
        description: activeTag.description,
      }
      updatedTags.push(newTag)
    }

    return updatedTags
  })

  setAllTags((prevTags) => {
    const existingTagIndex = data?.error
    const updatedTags = [...prevTags]
    if (existingTagIndex) {
      setDuplicateAlert(true)
    } else {
      const newTag = {
        id: data?.id,
        created: data?.created,
        lastUpdated: data?.lastUpdated,
        name: activeTag.name,
        description: activeTag.description,
      }
      updatedTags.push(newTag)
    }

    return updatedTags
  })
}

const addTagToThePool = async (activeTag, setTagsPool) => {
  setTagsPool((prevTags) => {
    const updatedTags = [...prevTags]

    const newTag = {
      id: activeTag?.id,
      created: activeTag?.created,
      lastUpdated: activeTag?.lastUpdated,
      name: activeTag.name,
      description: activeTag.description,
    }

    updatedTags.push(newTag)

    return updatedTags
  })
}

const updateExistingTag = async (activeTag, setTagsPool, setDuplicateAlert, setAllTags, token) => {
  const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/tags/${activeTag?.id}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: activeTag.name,
      description: activeTag.description,
    }),
  })

  const data = await resp.json()

  if (!data?.error) {
    setTagsPool((prevTags) => {
      const existingTagIndex = prevTags.findIndex((tag) => tag.id === activeTag.id)
      const updatedTags = [...prevTags]
      updatedTags[existingTagIndex] = {
        ...updatedTags[existingTagIndex],
        name: activeTag.name,
        description: activeTag.description,
      }
      return updatedTags
    })

    setAllTags((prevTags) => {
      const existingTagIndex = prevTags.findIndex((tag) => tag.id === activeTag.id)
      const updatedTags = [...prevTags]
      updatedTags[existingTagIndex] = {
        ...updatedTags[existingTagIndex],
        name: activeTag.name,
        description: activeTag.description,
      }
      return updatedTags
    })
  } else {
    setDuplicateAlert(true)
  }
}

const removeTagFromPool = (tagId, setTagsPool) => {
  setTagsPool((prevTag) => {
    const updatedTags = prevTag.filter((tag) => tag.id !== tagId)
    return updatedTags
  })
}

const makeTagActive = (tag, setActiveTag) => {
  setActiveTag({
    id: tag.id,
    name: tag.name,
    description: tag.description,
  })
}

const clearActiveTag = (setActiveTag) => {
  setActiveTag({
    id: '',
    name: '',
    description: '',
  })
}

export {
  addTagToThePool,
  removeTagFromPool,
  makeTagActive,
  updateExistingTag,
  clearActiveTag,
  unifyData,
  addTagToThePoolDB,
}

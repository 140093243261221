import './Header.css'
import SearchComponent from '../SearchComponent/SearchComponent'
import ProfileComponent from '../ProfileComponent/ProfileComponent'
import { useEvents } from '../../hooks/useEvents'
const Header = () => {
  const { searchData, profileData } = useEvents()
  const spreadedData = [].concat(...Object.values(searchData))

  return (
    <div className="headerComponentArea">
      <div className="headerRowArea">
        {/* Search Component */}
        <div className="headerSearchComponentArea">
          <SearchComponent
            filters
            filterData={searchData}
            searchData={spreadedData}
            setFilteredData={() => {}}
            loading={searchData.length === 0}
          />
        </div>
        {/* Profile Component */}
        <div className="headerProfileComponentArea">
          <ProfileComponent profile={profileData}></ProfileComponent>
        </div>
      </div>
    </div>
  )
}

export default Header

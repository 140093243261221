import styled from 'styled-components'
import color from '../../../util/colors'
import TextField from '@mui/material/TextField'

const OnboardingSetupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const TextFieldArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 25px;
  padding: 3rem 0 3rem 0;
`

const OnboardingDropdownRowArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const RowText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: var(--title);
`
const DefaultFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 15px;
  padding: 2rem 0 3rem 0;
`

const DefualtField = styled.div`
  padding: 10px 8px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${color.fieldColor};
  border-radius: 4px;
  svg {
    color: ${color.groupFrameHousehold};
  }
`
const FieldLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: ${color.textLabel};
  display: flex;
  align-items: center;
`
const AddButton = styled.div`
  display: relative;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${color.primary};
  svg {
    width: 20px;
    height: 20px;
    color: ${color.primary};
  }
  cursor: pointer;
`
const textFieldCloseIcon = {
  color: color.primary,
  cursor: 'pointer',
}

const AttendeeQuestionsArea = styled(DefaultFieldContainer)`
  padding: 2rem 0 3rem 0;
`

const AttendeeQuestionField = styled(TextField)({})
AttendeeQuestionField.defaultProps = {
  inputProps: {
    style: { fontSize: '14px', lineHeight: '140%', fontWeight: 400, color: color.title },
  },
}

const DeleteIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  svg {
    color: ${color.primary};
    cursor: pointer;
  }
`

export {
  OnboardingSetupContainer,
  TextFieldArea,
  OnboardingDropdownRowArea,
  RowText,
  DefaultFieldContainer,
  DefualtField,
  FieldLabel,
  AddButton,
  textFieldCloseIcon,
  AttendeeQuestionsArea,
  AttendeeQuestionField,
  DeleteIconWrapper,
}

import * as React from 'react'
import './AdminLogin.css'
import Card from '@mui/material/Card'
import {
  Button,
  CardActions,
  TextField,
  Stack,
  Modal,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material'
import { useState } from 'react'
import EmailIcon from '@mui/icons-material/Email'
import CloseIcon from '@mui/icons-material/Close'
import isEmail from 'validator/lib/isEmail'
import { signInWithEmailLink, requestCode, verifyCode } from '../../util/adminLogin'
import { signInWithProvider } from '../../util/firebase'
import { logger } from '../../util/logger'
import {
  modalButtonStyle,
  buttonStyle,
  modalStyle,
  textFieldStyle,
  cardStyle,
} from './AdminLogin.styles'
import useProtectedRoute from '../../hooks/useProtectedRoute'
import GoogleIcon from '../../assets/google-icon.svg'

//pattern for validating the 6 digit code
const regexPattern = /^[0-9]{6}$/

const AdminLogin = () => {
  const [enterEmailModal, setEnterEmailModal] = useState(false)
  const [enterCodeModal, setEnterCodeModal] = useState(false)
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [isValidCode, setIsValidCode] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false)
  const [nonce, setNonce] = useState(null)
  useProtectedRoute()

  const handleCloseEmailModal = () => {
    setEnterEmailModal(false)
    setEmail('')
    setIsValid(true)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return
    setSnackbarOpen(false)
  }

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') return
    setErrorSnackbarOpen(false)
  }

  return (
    <div>
      <Card sx={cardStyle}>
        <p className="title">Welcome to Church.Ai.</p>
        <CardActions disableSpacing sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Button
            onClick={() => setEnterEmailModal(true)}
            startIcon={
              <EmailIcon
                sx={{
                  width: '34px',
                  height: '34px',
                }}
              />
            }
            sx={buttonStyle}
          >
            Log in with Email
          </Button>
          <Button
            onClick={async () => {
              signInWithProvider('google')
            }}
            startIcon={
              <img alt="google" src={GoogleIcon} style={{ width: '34px', height: '34px' }}></img>
            }
            sx={buttonStyle}
          >
            Log in with Google
          </Button>
        </CardActions>
      </Card>

      <Modal open={enterEmailModal} onClose={handleCloseEmailModal}>
        <Stack direction={'column'} sx={modalStyle} spacing={3}>
          <IconButton onClick={handleCloseEmailModal} sx={{ alignSelf: 'end' }}>
            <CloseIcon></CloseIcon>
          </IconButton>
          <p className="desc">Enter your email to continue.</p>
          <p className="desc2">We'll send you a code you can use to log in.</p>
          <TextField
            error={!isValid}
            helperText={isValid ? '' : 'The email you entered is incorrect.'}
            label="Email"
            value={email}
            sx={textFieldStyle}
            onChange={(e) => setEmail(e.target.value)}
          ></TextField>
          <Button
            sx={modalButtonStyle}
            onClick={async () => {
              if (isEmail(email)) {
                const temporaryNonce = Math.floor(100000 + Math.random() * 900000)
                setNonce(temporaryNonce)
                await requestCode(email, temporaryNonce)
                setIsValid(true)
                setEnterCodeModal(true)
                setEnterEmailModal(false)
              } else {
                setIsValid(false)
              }
            }}
            variant="contained"
          >
            NEXT
          </Button>
        </Stack>
      </Modal>

      <Modal open={enterCodeModal} onClose={() => {}}>
        <Stack direction={'column'} sx={modalStyle} spacing={3}>
          <IconButton
            onClick={() => {
              setEnterCodeModal(false)
              setIsValidCode(true)
              setEmail('')
              setCode('')
            }}
            sx={{ alignSelf: 'end' }}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
          <p className="desc2">Enter the six digit code you received.</p>
          <TextField
            error={!isValidCode}
            sx={textFieldStyle}
            helperText={isValidCode ? '' : 'The code you entered is incorrect.'}
            label="Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          ></TextField>
          <Stack direction={'row'} justifyContent={'space-evenly'}>
            <Button
              sx={modalButtonStyle}
              onClick={async () => {
                //resend code
                if (await requestCode(email, nonce)) {
                  setSnackbarOpen(true)
                }
              }}
              variant="contained"
            >
              RESEND
            </Button>
            <Button
              sx={modalButtonStyle}
              onClick={async (e) => {
                if (!regexPattern.test(code)) {
                  setIsValidCode(false)
                  return
                }
                try {
                  const res = await verifyCode(code, email, nonce)
                  if (res.status !== 'confirmed') throw new Error('Code has already been used')
                  setIsValidCode(true)
                  setEnterCodeModal(false)
                  const response = await signInWithEmailLink(email, res.verification_id)
                  if (!response.user) {
                    setErrorSnackbarOpen(true)
                  }
                } catch (error) {
                  logger.log(error)
                  setErrorSnackbarOpen(true)
                }
              }}
              variant="contained"
            >
              NEXT
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Code resent successfully!
        </Alert>
      </Snackbar>

      <Snackbar open={errorSnackbarOpen} autoHideDuration={4000} onClose={handleCloseErrorSnackbar}>
        <Alert onClose={handleCloseErrorSnackbar} severity="warning" sx={{ width: '100%' }}>
          There was an issue with your request, please try again.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default AdminLogin

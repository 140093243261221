function validatePinInput(input) {
  const isValid = /^[1-9]$/.test(input)
  if (!isValid) return ''
  else return input
}

function deconstructPin(pinNumber, setPinValues) {
  const digits = pinNumber.split('')
  setPinValues({
    first: digits[0],
    second: digits[1],
    third: digits[2],
    fourth: digits[3],
  })
}

const pinEnum = { 1: 'first', 2: 'second', 3: 'third', 4: 'fourth' }

export { validatePinInput, deconstructPin, pinEnum }

function handleSelectedChoice(variant, formInfo, choices) {
  switch (variant) {
    case 'paidEvent':
      return formInfo?.paidEvent?.eventIsPaid ? formInfo?.paidEvent?.eventIsPaid : ''
    case 'attendees':
      return formInfo?.attendees?.openGathering
        ? formInfo?.attendees?.openGathering
        : choices[0].value
    case 'publishTime':
      return formInfo?.publish?.publishGathering
        ? formInfo?.publish?.publishGathering
        : choices[0].value
    case 'publishVisibility':
      return formInfo?.publish?.gatheringVisibility
        ? formInfo?.publish?.gatheringVisibility
        : choices[0].value
    case 'security':
      return formInfo?.security?.peopleCheck ? formInfo?.security?.peopleCheck : choices[0].value
    case 'reminders':
      return formInfo?.reminders?.userReminders
        ? formInfo?.reminders?.userReminders
        : choices[0].value
    case 'checkinMethod':
      return formInfo?.checkinType?.checkinMethod
        ? formInfo?.checkinType?.checkinMethod
        : choices[0].value
    default:
      return []
  }
}

function handleSelectedType(variant, formInfo, choices) {
  switch (variant) {
    case 'gatheringType':
      return formInfo?.gatheringType?.typeOfGathering
        ? formInfo?.gatheringType?.typeOfGathering
        : ''
    default:
      return []
  }
}

export { handleSelectedChoice, handleSelectedType }

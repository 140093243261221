import {
  GroupSearchContainer,
  GroupSearchText,
  SearchComponentContainer,
} from './GroupSearch.style'
import SearchComponent from '../SearchComponent/SearchComponent'

const GroupSearch = ({
  firstRowText,
  groupData,
  setSearchData,
  setSortedGroups,
  variant,
  kioskData,
  onboardingData,
  tagsData,
  labelData,
  searchLabel,
  addTagToThePool,
  setTagPool,
  sortKiosksBySearch,
  sortLabelsBySearch,
  sortOnboardingsBySearch,
  loading,
  error,
  setSelectedApproversPool,
}) => {
  return (
    <GroupSearchContainer>
      <GroupSearchText>{firstRowText}</GroupSearchText>
      <SearchComponentContainer>
        {variant === 'group' && (
          <SearchComponent
            searchData={groupData}
            setSearchData={setSearchData}
            variant={'groupSearch'}
            placeholderText={'Search for groups'}
            label="name"
            error={error}
            setSortedGroups={setSortedGroups}
            loading={loading}
          ></SearchComponent>
        )}
        {variant === 'kiosk' && (
          <SearchComponent
            searchData={kioskData}
            setSearchData={setSearchData}
            placeholderText={'Search for kiosk'}
            sortKiosksBySearch={sortKiosksBySearch}
            label={'name'}
            variant={'kiosk'}
            loading={loading}
          ></SearchComponent>
        )}
        {variant === 'onboarding' && (
          <SearchComponent
            searchData={onboardingData}
            setSearchData={setSearchData}
            placeholderText={'Search onboarding process...'}
            sortOnboardingsBySearch={sortOnboardingsBySearch}
            label={'name'}
            variant={'onboarding'}
            loading={loading}
          ></SearchComponent>
        )}
        {variant === 'tags' && (
          <SearchComponent
            searchData={tagsData}
            setSearchData={setSearchData}
            placeholderText={'Search tags...'}
            variant={'tag'}
            label={searchLabel}
            addTagToThePool={addTagToThePool}
            setTagPool={setTagPool}
          ></SearchComponent>
        )}
        {variant === 'label' && (
          <SearchComponent
            searchData={labelData}
            placeholderText={'Search for labels'}
            sortLabelsBySearch={sortLabelsBySearch}
            label={'name'}
            variant={'label'}
            loading={loading}
          ></SearchComponent>
        )}
        {variant === 'gatheringApprover' && (
          <SearchComponent
            searchData={groupData}
            setSearchData={setSearchData}
            variant={'users'}
            placeholderText={'Search approver...'}
            setSortedGroups={setSortedGroups}
            label={'displayName'}
            setSelectedApproversPool={setSelectedApproversPool}
          ></SearchComponent>
        )}
      </SearchComponentContainer>
    </GroupSearchContainer>
  )
}

export default GroupSearch

import DescriptionIcon from '@mui/icons-material/Description'
import StarIcon from '@mui/icons-material/Star'
import ListAltIcon from '@mui/icons-material/ListAlt'
import HdrStrongIcon from '@mui/icons-material/HdrStrong'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import PersonIcon from '@mui/icons-material/Person'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import SoapOutlinedIcon from '@mui/icons-material/SoapOutlined'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import color from '../../../util/colors'

function HandleVariant(variant) {
  switch (variant) {
    case 'question':
      return <HelpOutlineIcon />
    case 'document':
      return <DescriptionIcon />
    case 'exampleWaiver':
      return <DescriptionIcon />
    default:
      return <HelpOutlineIcon />
  }
}

function HandleTemplateVariant(variant) {
  switch (variant) {
    case 'gatheringTemplateHighlight':
      return <StarIcon />
    case 'gatheringTemplateForms':
      return <ListAltIcon />
    case 'gatheringTemplatePartOfExp':
      return <HdrStrongIcon />
    default:
      return <></>
  }
}

function HandlePreviewTag(previewTag) {
  switch (previewTag) {
    case 'ageRange':
      return <HourglassEmptyIcon />
    case 'gender':
      return <PersonIcon />
    case 'groups':
      return <PeopleAltIcon />
    case 'deposit':
      return <MonetizationOnIcon />
    case 'sponsored':
      return <SoapOutlinedIcon />
    case 'discount':
      return <LocalOfferIcon />
    case 'question':
      return <HelpOutlineIcon />
    case 'noIcon':
      return <></>
    default:
      return <></>
  }
}

function HandleEventType(variant) {
  switch (variant) {
    case 'Paid':
      return color.draftButton
    case 'Free':
      return color.draftSaved
    default:
      return color.primary
  }
}

function TransferExampleWaivers(
  exampleArray,
  tagObject,
  setExampleArray,
  uploadArray,
  setUploadArray,
) {
  const index = exampleArray.indexOf(tagObject)
  if (index !== -1) {
    setExampleArray((prevArray) => {
      const updatedExampleArray = [...prevArray]
      updatedExampleArray.splice(index, 1)
      return updatedExampleArray
    })
    setUploadArray((prevArray) => [...prevArray, tagObject])
  }
}

function handleWaiversToTickets(tagObject, setUploadArray, activeTicket) {
  activeTicket.forEach((activeTicket) => {
    const newTagObject = { ...tagObject, id: new Date().getTime() }
    setUploadArray((prevState) => {
      if (!prevState.hasOwnProperty(activeTicket.name)) {
        return { ...prevState, [activeTicket.name]: [newTagObject] }
      }
      const existingArray = Array.isArray(prevState[activeTicket.name])
        ? prevState[activeTicket.name]
        : []
      const tagExists = existingArray.some((item) => item.filename === newTagObject.filename)

      if (!tagExists) {
        return {
          ...prevState,
          [activeTicket.name]: [...existingArray, newTagObject],
        }
      }
      return prevState
    })
  })
}

export {
  HandleVariant,
  HandleTemplateVariant,
  HandlePreviewTag,
  HandleEventType,
  TransferExampleWaivers,
  handleWaiversToTickets,
}

import styled from 'styled-components'

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;
`
const ComponentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 3rem;
  box-sizing: border-box;
  padding: 0 3rem 3rem 3rem;
  background-color: var(--backgroundSecondary);
  @media (max-width: 1200px) {
    justify-items: center;
  }
`
const TopRowArea = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 3rem 0 3rem 0;
  @media (max-width: 1366px) {
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
  }
`

const TitleText = styled.div`
  box-sizing: border-box;
  display: flex;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: var(--primary);
  justify-content: flex-start;
  @media (max-width: 1200px) {
    padding-left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`
const TopRowButtonArea = styled.div`
  display: flex;
  column-gap: 1.5rem;
  justify-content: flex-end;
  @media (max-width: 1200px) {
    padding-right: 0;
    row-gap: 1.5rem;
    justify-content: center;
    width: 100%;
  }
  @media (min-width: 670px) and (max-width: 1124px) {
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }
  @media (max-width: 670px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
`

const MainContentArea = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  column-gap: 50px;
  @media (max-width: 1124px) {
    flex-direction: column;
    row-gap: 1.5rem;
    justify-content: center;
    width: 90%;
  }
`

const GatheringScreensArea = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: 1024px) {
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 100%;
  }
`

const ChecklistComponentArea = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1124px) {
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }
`

export {
  MainContainer,
  ComponentArea,
  TopRowArea,
  MainContentArea,
  TitleText,
  TopRowButtonArea,
  GatheringScreensArea,
  ChecklistComponentArea,
}

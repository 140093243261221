const firebaseConfig = {
  apiKey: 'AIzaSyBQ04uM2aLSqiTZO_bVHVE1YLEoU_HcGjs',
  authDomain: 'churchai-labs.firebaseapp.com',
  databaseURL: 'https://churchai-labs.firebaseio.com',
  projectId: 'churchai-labs',
  storageBucket: 'churchai-labs.appspot.com',
  messagingSenderId: '301739847153',
  appId: '1:301739847153:web:e688880eb30f333251e850',
  measurementId: 'G-10ELVB88HL',
}

export default firebaseConfig

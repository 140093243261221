import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'

const StyledAccordionDetails = styled(AccordionDetails)({
  '&.MuiAccordionDetails-root': {
    paddingTop: 0,
    padding: 0,
  },
})

const StyledAccordionSummary = styled(AccordionSummary)({
  '&.MuiAccordionSummary-root': {
    padding: 0,
  },
})

const StyledAccordion = styled(Accordion)({
  '&.MuiAccordion-root:before': {
    top: 0,
  },
})

export { StyledAccordionDetails, StyledAccordionSummary, StyledAccordion }

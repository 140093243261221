import styled from 'styled-components'
import color from '../../util/colors'

export const MainContentArea = styled.div`
  background-color: ${color.backgroundSecondary};
  display: flex;
  flex-direction: column;
  width: 100%;
  div {
    box-sizing: border-box;
  }
`

export const TextandButtonsArea = styled.div`
  padding-bottom: 2rem;
  display: flex;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const TextArea = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SectionMainText = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${color.primary};
`

export const SectionSmallText = styled.div`
  padding-top: 1rem;
  color: color.filterText;
  font-weight: 400;
  font-size: 12px;
`

export const ButtonArea = styled.div`
  display: flex;
  margin-left: auto;

  align-items: center;
  gap: 20px;
`

export const CreateGathering = styled.div`
  width: 100%;
  height: 84px;
  background: ${color.backgroundPrimary};

  border: 0.5px solid ${color.borderRest};

  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 21px;
  margin-bottom: 27px;
  color: ${color.primary};
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    border: 2px solid ${color.primary};
  }
`

export const TemplatesFilterArea = styled.div`
  transition: height 0.5s ease-out, padding 0.7s ease-out;
  height: 288px;
  overflow: hidden;
  width: 100%;
  background-color: ${color.filterBackground};
  padding: 2.5rem 2.5rem 2.5rem 3rem;
  box-sizing: border-box;
  ${(props) =>
    props.open === false &&
    `
    padding-top: 0;
  padding-bottom: 0;
  height: 0;
  `}
  @media (max-width: 900px) {
    height: 468px;
  }
`

export const TemplatesCreateArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem;
`

export const TemplatesInfo = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.0152em;
  text-transform: uppercase;
  color: ${color.title};
`
export const FilterText = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 32px;
  color: ${color.description};
`

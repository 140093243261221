import React from 'react'
import { MessageHolder, IconHolder, MessageText, MessageRow } from './Message.styles'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'

function Message({ children, translateY, title, hideIcon, center, gap }) {
  return (
    <MessageHolder translateY={translateY} gap={gap}>
      {title && <MessageRow>{title}</MessageRow>}
      <MessageRow>
        {!hideIcon && (
          <IconHolder>
            <ErrorRoundedIcon />
          </IconHolder>
        )}
        <MessageText center={center}>{children}</MessageText>
      </MessageRow>
    </MessageHolder>
  )
}

export default Message

import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import './Calendar.css'
import GroupIcon from '@mui/icons-material/Group'
import { Avatar } from '@mui/material'
import PropTypes from 'prop-types'
import { calculateEndDate } from '../../util/date'
import color from '../../util/colors'

const localizer = momentLocalizer(moment)

function getCalendarBackgroundColor(event) {
  if (event?.campus) {
    return color.experience
  }
  if (!event?.gatheringType?.typeOfGathering) {
    return 'grey'
  }
  switch (event?.gatheringType?.typeOfGathering) {
    case 'Group':
      return color.group
    case 'Ministry':
      return color.ministry
    case 'Class':
      return color.class
    default:
      break
  }
}

const formats = {
  dateFormat: 'D',
}

const legendItems = [
  { title: 'Experience', color: color.experience },
  { title: 'Class', color: color.class },
  { title: 'Ministry', color: color.ministry },
  { title: 'Group', color: color.group },
  { title: 'Not selected', color: 'grey' },
]

const CalendarComponent = ({ events }) => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <div className="legendListContainer">
        {legendItems.map((legend) => {
          return (
            <div className="legendItemContainer">
              <div
                className="legendItem"
                style={{
                  background: legend.color,
                }}
              ></div>
              <div style={{ padding: '2px' }}>{legend.title}</div>
            </div>
          )
        })}
      </div>
      <Calendar
        formats={formats}
        localizer={localizer}
        events={events}
        onSelectEvent={(ev) => {
          //TODO: Implement what happens when event is opened
        }}
        startAccessor={(event) => {
          return new Date(event?.logisticsInfo?.event?.startDate || event?.start)
        }}
        endAccessor={(event) => {
          return new Date(
            event?.logisticsInfo?.event?.endDate ||
              event?.end ||
              (event?.logisticsInfo?.event?.recurres === 'daily' &&
                calculateEndDate(
                  event?.logisticsInfo?.event?.startDate,
                  parseInt(event?.logisticsInfo?.event?.recurrencAmount),
                )),
          )
        }}
        eventPropGetter={(event) => {
          const backgroundColor = getCalendarBackgroundColor(event)
          return { style: { backgroundColor } }
        }}
        titleAccessor={(ev) => (
          <div className="eventTitleRow">
            <Avatar
              style={{
                backgroundColor: ev?.gatheringDetails ? color.description : color.lighterExperience,
                height: '24px',
                width: '24px',
              }}
            >
              <GroupIcon style={{ fontSize: 16 }} />
            </Avatar>
            <p style={{ color: color.backgroundPrimary }} className="titleTextCalendar">
              {ev.title}
            </p>
          </div>
        )}
      />
    </div>
  )
}

CalendarComponent.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default CalendarComponent

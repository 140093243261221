import GroupSearch from '../../GroupSearch/GroupSearch'
import GroupGrid from '../../GroupGrid/GroupGrid'
import { AttendeesScreenContainer } from './AtendeesScreen.style'
import SelectBasicInfo from '../../SelectBasicInfo/SelectBasicInfo'
import infoData from '../../../assets/atendeesScreen.json'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { useState, useEffect } from 'react'
import { unifyData } from '../../../util/utility'
import { logger } from '../../../util/logger'
const AtendeesScreen = ({ token }) => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [attendees, setAttendees] = useState(formInfo?.attendees ? formInfo?.attendees : {})
  const [loading, setLoading] = useState(false)
  const [sortedGroup, setSortedGroups] = useState(
    formInfo?.attendees?.selectedAttendees
      ? formInfo?.attendees?.selectedAttendees
      : {
          group: [],
          team: [],
          individual: [],
          household: [],
        },
  )
  const [searchData, setSearchData] = useState([])

  const fetchData = async () => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/attendees`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await resp.json()
      const unify = unifyData(data)
      const pickedGroups = [
        ...sortedGroup.group,
        ...sortedGroup.team,
        ...sortedGroup.individual,
        ...sortedGroup.household,
      ]

      const updatedUnify = unify.filter((group) => {
        return !pickedGroups.some((pickedGroup) => group.id === pickedGroup.id)
      })
      setSearchData(updatedUnify)
    } catch (error) {
      logger.error(error)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [token])

  useEffect(() => {
    setLoading(false)
  }, [searchData])

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.attendees = attendees
    if (attendees.openGathering === 'yes') {
      newFormInfo.missingInformation = formInfo?.missingInformation?.filter(
        (m) => m !== 'Checkins via Roster > Invite only',
      )
      if (newFormInfo?.rosterCheckin?.selectedGroupsInviteOnly) {
        delete newFormInfo.rosterCheckin.selectedGroupsInviteOnly
      }
      if (
        !newFormInfo?.rosterCheckin?.selectedGroups &&
        formInfo?.activeStep !== formInfo?.highestStep &&
        formInfo?.highestStep >= 6
      ) {
        newFormInfo.missingInformation = [
          ...newFormInfo.missingInformation,
          'Checkins via Roster > Open',
        ]
      }
    } else {
      newFormInfo.missingInformation = formInfo?.missingInformation?.filter(
        (m) => m !== 'Checkins via Roster > Open',
      )
      if (newFormInfo?.rosterCheckin?.selectedGroups) {
        delete newFormInfo.rosterCheckin.selectedGroups
      }
      if (
        !newFormInfo?.rosterCheckin?.selectedGroupsInviteOnly &&
        formInfo?.activeStep !== formInfo?.highestStep &&
        formInfo?.highestStep >= 6
      ) {
        newFormInfo.missingInformation = [
          ...newFormInfo.missingInformation,
          'Checkins via Roster > Invite only',
        ]
      }
    }
    setFormInfo(newFormInfo)
  }, [attendees, formInfo])

  useEffect(() => {
    setAttendees((info) => {
      info.selectedAttendees = sortedGroup
      return { ...info }
    })
  }, [sortedGroup])

  // This will store the type of gathering
  function setGatheringType(value) {
    setAttendees((info) => {
      info.openGathering = value
      return { ...info }
    })
  }

  return (
    <AttendeesScreenContainer>
      <SelectBasicInfo
        title={infoData.question}
        choices={infoData.choices}
        formInfo={formInfo}
        setGatheringType={setGatheringType}
        variant={'attendees'}
      ></SelectBasicInfo>
      {formInfo?.attendees?.openGathering === 'no' && (
        <>
          <GroupSearch
            firstRowText={'Look for a group of attendees:'}
            groupData={searchData}
            setSearchData={setSearchData}
            setSortedGroups={setSortedGroups}
            variant={'group'}
            loading={loading}
            error={
              formInfo?.activeStep !== formInfo?.highestStep &&
              attendees?.selectedAttendees?.group?.length === 0 &&
              attendees?.selectedAttendees?.individual?.length === 0 &&
              attendees?.selectedAttendees?.team?.length === 0 &&
              attendees?.selectedAttendees?.household?.length === 0
            }
          ></GroupSearch>
          {sortedGroup.group.length > 0 && (
            <GroupGrid
              groupName={'Groups'}
              selectedGroups={sortedGroup.group}
              setSortedGroups={setSortedGroups}
              setSearchData={setSearchData}
            />
          )}
          {sortedGroup.household.length > 0 && (
            <GroupGrid
              groupName={'Households'}
              selectedGroups={sortedGroup.household}
              setSortedGroups={setSortedGroups}
              setSearchData={setSearchData}
            />
          )}
          {sortedGroup.team.length > 0 && (
            <GroupGrid
              groupName={'Teams'}
              selectedGroups={sortedGroup.team}
              setSortedGroups={setSortedGroups}
              setSearchData={setSearchData}
            />
          )}
          {sortedGroup.individual.length > 0 && (
            <GroupGrid
              groupName={'Individuals'}
              selectedGroups={sortedGroup.individual}
              setSortedGroups={setSortedGroups}
              setSearchData={setSearchData}
            />
          )}
        </>
      )}
    </AttendeesScreenContainer>
  )
}
export default AtendeesScreen

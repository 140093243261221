import {
  TagsContainer,
  RowContainer,
  StyledText,
  TextFieldArea,
  ButtonsContainer,
  TagGridArea,
  alertBoxStyle,
  alertBoxIcon,
} from './Tags.style'
import { useState, useEffect } from 'react'
import { useFormGatherings } from '../../../util/zustant/store.js'
import TextField from '@mui/material/TextField'
import Message from '../../Form/components/Message'
import TemplateButton from '../../TemplateButton/TemplateButton'
import GroupSearch from '../../GroupSearch/GroupSearch'
import TagGrid from '../../TagGrid/TagGrid'
import DifferenceIcon from '@mui/icons-material/Difference'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {
  addTagToThePool,
  removeTagFromPool,
  makeTagActive,
  updateExistingTag,
  clearActiveTag,
  unifyData,
  addTagToThePoolDB,
} from '../../../util/tags'
import dummyTags from '../../../assets/tags.json'
import { logger } from '../../../util/logger'
import { useAuth } from '../../../hooks/useAuth'

const Tags = () => {
  const { token } = useAuth()
  const { setFormInfo, formInfo } = useFormGatherings()
  const [allTags, setAllTags] = useState(dummyTags)
  const [searchTags, setSearchTags] = useState(dummyTags)
  const [tags, setTags] = useState(formInfo.tags ? formInfo.tags : {})
  const [tagPool, setTagPool] = useState(formInfo?.tags?.tagPool ? formInfo?.tags?.tagPool : [])
  const [tagSaved, setTagSaved] = useState(false)
  const [disableSaveButton, setdisableSaveButton] = useState(true)
  const [duplicateAlert, setDuplicateAlert] = useState(false)
  const [errorHandle, setErrorHandle] = useState({
    tagEmpty: false,
    descriptionEmpty: false,
  })
  const [activeTag, setActiveTag] = useState({
    id: '',
    name: '',
    description: '',
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/tags/`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const data = await resp.json()
        const unify = unifyData(data)
        setAllTags(unify)
      } catch (error) {
        logger.error(error)
      }
    }

    fetchData()
  }, [token])

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.tags = tags
    setFormInfo(newFormInfo)
  }, [tags])

  function handleTagPool(value) {
    setTags((info) => {
      info.tagPool = value
      return { ...info }
    })
  }

  useEffect(() => {
    handleTagPool(tagPool)
  }, [tagPool])

  useEffect(() => {
    setSearchTags(() => {
      const filteredTags = allTags.filter((tag) => {
        return !tagPool.some((poolTag) => poolTag.name === tag.name)
      })
      return filteredTags
    })
  }, [allTags, tagPool])

  return (
    <TagsContainer>
      <Message translateY={'-8px'}>
        {'These tags will be acquired on every profile that checks in for the gathering.'}
      </Message>
      <GroupSearch
        firstRowText={'Find tags for your gathering:'}
        variant={'tags'}
        tagsData={searchTags}
        searchLabel={'name'}
        setTagPool={setTagPool}
        addTagToThePool={addTagToThePool}
      ></GroupSearch>
      <TagGridArea>
        <TagGrid
          tagPool={tagPool}
          notifText={'TAGS ASSOCIATED WITH THIS GATHERING'}
          tagVariant={'tags'}
          removeTagFromPool={removeTagFromPool}
          setTagPool={setTagPool}
          makeTagActive={makeTagActive}
          setActiveTag={setActiveTag}
          setTagSaved={setTagSaved}
          handleSaveButton={setdisableSaveButton}
        ></TagGrid>
      </TagGridArea>
      <RowContainer>
        <StyledText>{'Tag name'}</StyledText>
        <TextFieldArea>
          <TextField
            sx={{ width: '100%' }}
            id="outlined-basic"
            label={'Tag Name'}
            variant="outlined"
            value={activeTag.name}
            onChange={(e) =>
              setActiveTag((prevState) => ({
                ...prevState,
                name: e.target.value,
              }))
            }
            onClick={() => setTagSaved(false)}
            error={errorHandle.tagEmpty}
            helperText={!errorHandle.tagEmpty ? '' : 'This field cannot be empty.'}
          />
        </TextFieldArea>
      </RowContainer>
      <RowContainer>
        <StyledText>{'Description'}</StyledText>
        <TextFieldArea>
          <TextField
            sx={{ width: '100%' }}
            id="outlined-basic"
            label={'Description'}
            variant="outlined"
            value={activeTag.description}
            onChange={(e) =>
              setActiveTag((prevState) => ({
                ...prevState,
                description: e.target.value,
              }))
            }
            onClick={() => setTagSaved(false)}
            error={errorHandle.descriptionEmpty}
            helperText={!errorHandle.descriptionEmpty ? '' : 'This field cannot be empty.'}
            multiline
            rows={5}
          />
        </TextFieldArea>
      </RowContainer>
      <ButtonsContainer>
        <TemplateButton
          variant={'saveTag'}
          label={'Save Tag'}
          saveLabel={'Tag Saved'}
          activeTag={activeTag}
          setTagsPool={setTagPool}
          updateExistingTag={updateExistingTag}
          setTagSaved={setTagSaved}
          clearActiveTag={clearActiveTag}
          setActiveTag={setActiveTag}
          tagSaved={tagSaved}
          disableSaveButton={disableSaveButton}
          setdisableSaveButton={setdisableSaveButton}
          setDuplicateAlert={setDuplicateAlert}
          setAllTags={setAllTags}
        ></TemplateButton>
        <TemplateButton
          variant={'newTag'}
          label={'Create new tag'}
          activeTag={activeTag}
          setTagsPool={setTagPool}
          addTagToThePool={addTagToThePoolDB}
          setAllTags={setAllTags}
          clearActiveTag={clearActiveTag}
          setActiveTag={setActiveTag}
          tagErrorHandler={setErrorHandle}
          setdisableSaveButton={setdisableSaveButton}
          setDuplicateAlert={setDuplicateAlert}
        ></TemplateButton>
      </ButtonsContainer>
      <Snackbar
        open={duplicateAlert}
        autoHideDuration={6000}
        onClose={() => setDuplicateAlert(false)}
      >
        <Alert
          severity="info"
          sx={alertBoxStyle}
          iconMapping={{
            info: <DifferenceIcon style={alertBoxIcon}></DifferenceIcon>,
          }}
        >
          Tag already exists
        </Alert>
      </Snackbar>
    </TagsContainer>
  )
}

export default Tags

const handleGroupVariants = (arrayName, newItem, setSortedGroups) => {
  setSortedGroups((prevState) => ({
    ...prevState,
    [arrayName]: [...prevState[arrayName], newItem],
  }))
}

const removeGroupFromArray = (arrayName, itemId, setSortedGroups, setSearchData) => {
  setSortedGroups((prevState) => {
    const updatedArray = prevState[arrayName].filter((item) => item.id !== itemId)
    const removedItem = prevState[arrayName].find((item) => item.id === itemId)
    setSearchData((filteredItem) => [...filteredItem, removedItem])
    return {
      ...prevState,
      [arrayName]: updatedArray,
    }
  })
}

export { handleGroupVariants, removeGroupFromArray }

import {
  KioskContainer,
  KioskAccordion,
  KioskAccordionSummary,
  KioskAccordionDetails,
  KioskHeader,
  KioskHeaderImageArea,
  KioskHeaderImage,
  KioskHeaderTitle,
  KioskHeaderIconAndText,
  IconBox,
  headerIconStyle,
} from './Kiosk.style'
import color from '../../util/colors'
import AddIcon from '@mui/icons-material/Add'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { HandleKioskVariants } from './util/Kiosk.util'
import { useEffect, useState } from 'react'
import { isEmptyObject } from '../../util/utility'

const Kiosk = ({
  children,
  headerImage,
  headerTitle,
  variant,
  labels,
  closeLabels,
  selectKiosk,
  selectedKiosk,
  formInfo,
  isOnboarding,
  isSecurity,
  isCheckinKiosk,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false)

  useEffect(() => {
    setAccordionOpen(false)
  }, [closeLabels])

  function checkError() {
    if (isOnboarding) {
      if (
        formInfo?.highestStep !== formInfo?.activeStep &&
        (!formInfo?.onboarding?.selectedKiosk || isEmptyObject(formInfo?.onboarding?.selectedKiosk))
      ) {
        return true
      } else {
        return false
      }
    } else if (isSecurity) {
      if (
        formInfo?.highestStep !== formInfo?.activeStep &&
        (!formInfo?.security?.selectedLabel || isEmptyObject(formInfo?.security?.selectedLabel))
      ) {
        return true
      } else {
        return false
      }
    } else if (isCheckinKiosk) {
      if (
        formInfo?.highestStep !== formInfo?.activeStep &&
        (!formInfo?.checkinKioskInfo?.selectedKiosk ||
          isEmptyObject(formInfo?.checkinKioskInfo?.selectedKiosk))
      ) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <KioskContainer>
      <KioskAccordion expanded={accordionOpen}>
        <KioskAccordionSummary
          style={{
            border: checkError() && '2px solid red',
          }}
        >
          <KioskHeader background={selectedKiosk ? color.draftSaved : color.primary}>
            <KioskHeaderImageArea>
              <KioskHeaderImage>{headerImage}</KioskHeaderImage>
              <KioskHeaderTitle>{headerTitle}</KioskHeaderTitle>
            </KioskHeaderImageArea>

            <KioskHeaderIconAndText>
              {variant !== 'preview' && (
                <IconBox onClick={() => selectKiosk()}>
                  {selectedKiosk ? (
                    <CheckCircleIcon sx={headerIconStyle} />
                  ) : (
                    <AddIcon sx={headerIconStyle} />
                  )}{' '}
                  Select
                </IconBox>
              )}
              <IconBox onClick={() => setAccordionOpen(!accordionOpen)}>
                {accordionOpen ? (
                  <ExpandLessIcon sx={headerIconStyle} />
                ) : (
                  <ExpandMoreIcon sx={headerIconStyle} />
                )}{' '}
                {HandleKioskVariants(variant)}
              </IconBox>
            </KioskHeaderIconAndText>
          </KioskHeader>
        </KioskAccordionSummary>
        <KioskAccordionDetails
          style={{
            border: selectedKiosk
              ? `1px solid ${color.checklistCompleteBadgeColor}`
              : `1px solid ${color.primary}`,
          }}
        >
          {children}
        </KioskAccordionDetails>
      </KioskAccordion>
    </KioskContainer>
  )
}

export default Kiosk

/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import TabsComponent from '../../components/TabsComponent/TabsComponent'
import NavbarButton from '../../components/NavbarButton/NavbarButton'
import MediaCard from '../../components/Card/Card'
import ListAccordion from '../../components/ListAccordion/ListAccordion'
import { calculateEndDate, convertDateString } from '../../util/date'
import { useNavigate } from 'react-router-dom'

import {
  RowSkeleton,
  skeletonCardStyle,
  MainContainer,
  SectionTitleText,
  SectionTabAndButtonRow,
  CardsGrid,
  CardWrapper,
  AccordeonArea,
  FooterText,
} from './GatheringsAndExperiencesScreen.styled'
import Skeleton from '@mui/material/Skeleton'

function filterGatherings(gatherings, selectedTab, setFilteredEvents) {
  const currentDate = new Date()
  switch (selectedTab) {
    case 'Starting Soon':
      const oneWeekAhead = new Date()
      oneWeekAhead.setDate(currentDate.getDate() + 7)
      const filteredStartingSoon = gatherings.filter((dateString) => {
        const startDate = new Date(dateString?.logisticsInfo?.event?.startDate || dateString?.start)
        return (
          startDate <= oneWeekAhead && startDate >= currentDate && dateString.status === 'published'
        )
      })
      setFilteredEvents(filteredStartingSoon)
      break
    case 'Newly Added':
      const oneWeekAgo = new Date()
      oneWeekAgo.setDate(currentDate.getDate() - 7)
      const filteredNewlyAdded = gatherings.filter((dateString) => {
        const creationDate = new Date(dateString?.created)
        return (
          creationDate >= oneWeekAgo &&
          creationDate <= currentDate &&
          dateString.status === 'published'
        )
      })
      setFilteredEvents(filteredNewlyAdded)
      break
    case 'Stand Alone':
      const filteredStandAlone = gatherings.filter(
        (g) => !g.parentExperience && g.status === 'published',
      )
      setFilteredEvents(filteredStandAlone)
      break
    case 'Recurring':
      const filteredRecurring = gatherings.filter(
        (g) => g.logisticsInfo?.event?.recurres && g.status === 'published',
      )
      setFilteredEvents(filteredRecurring)
      break

    default:
      break
  }
}

const GatheringsAndExperiencesScreen = ({ gatherings, experiences, token, loading }) => {
  const [gatheringSelectedTab, setGatheringsSelectedTab] = useState('Starting Soon')
  const [experiencesSelectedTab, setExperiencesSelectedTab] = useState('Starting Soon')
  const [filteredGatherings, setFilteredEvents] = useState([])
  const [filteredExperiences, setFilteredExperiences] = useState([])
  const skeletonArray = Array.from({ length: 4 }, (_, index) => index + 1)

  const navigate = useNavigate()

  useEffect(() => {
    if (gatherings?.length > 0) {
      filterGatherings(gatherings, gatheringSelectedTab, setFilteredEvents)
    }
  }, [gatheringSelectedTab, gatherings])

  useEffect(() => {
    if (experiences?.length > 0) {
      filterGatherings(experiences, experiencesSelectedTab, setFilteredExperiences)
    }
  }, [experiences, experiencesSelectedTab])

  return (
    <MainContainer>
      <SectionTitleText>Gatherings</SectionTitleText>
      <SectionTabAndButtonRow>
        <TabsComponent
          tabVariant={'Gatherings'}
          activeTabOnPage={gatheringSelectedTab}
          tabSelectorFunction={setGatheringsSelectedTab}
        />
        <NavbarButton
          buttonText={'See all Gatherings'}
          iconName={'forward'}
          onClickFunction={() => navigate('/gatherings')}
        ></NavbarButton>
      </SectionTabAndButtonRow>
      <CardsGrid>
        {filteredGatherings.slice(0, 8).map((event, i) => {
          return (
            <CardWrapper key={i}>
              <MediaCard
                id={event.id}
                image={event?.imageUpload?.eventImage}
                isFavorite={event?.isFavourite}
                status={event?.status}
                title={event?.gatheringDetails?.title}
                gatheringObject={event}
                dateFrom={convertDateString(event?.logisticsInfo?.event?.startDate)}
                dateTo={
                  event?.logisticsInfo?.event?.recurres === 'daily'
                    ? convertDateString(
                        calculateEndDate(
                          event?.logisticsInfo?.event?.startDate,
                          parseInt(event?.logisticsInfo?.event?.recurrencAmount),
                        ),
                      )
                    : convertDateString(event?.logisticsInfo?.event?.startDate)
                }
              />
            </CardWrapper>
          )
        })}
        {loading && (
          <CardsGrid>
            {skeletonArray.map((i) => (
              <Skeleton key={i} variant="rounded" sx={skeletonCardStyle} />
            ))}
          </CardsGrid>
        )}
      </CardsGrid>
      <SectionTitleText>Experiences</SectionTitleText>
      <SectionTabAndButtonRow>
        <TabsComponent
          tabVariant={'Experiences'}
          activeTabOnPage={experiencesSelectedTab}
          tabSelectorFunction={setExperiencesSelectedTab}
        />
        <NavbarButton
          buttonText={'See all Experiences'}
          iconName={'forward'}
          onClickFunction={() => navigate('/experiences')}
        ></NavbarButton>
      </SectionTabAndButtonRow>
      <AccordeonArea>
        {experiences.map((experience) => {
          return (
            <ListAccordion
              key={experience.id}
              gatheringData={experience.gatherings}
              experienceData={experience}
            ></ListAccordion>
          )
        })}
      </AccordeonArea>
      {loading && (
        <>
          {skeletonArray.map((i) => (
            <RowSkeleton>
              <Skeleton variant="image" width={298} height={162} />
              <Skeleton variant="rounded" width={'80%'} height={162} />
            </RowSkeleton>
          ))}
        </>
      )}
      <FooterText>{`©${new Date().getFullYear()} Church.Ai. All Rights Reserved.`}</FooterText>
    </MainContainer>
  )
}

export default GatheringsAndExperiencesScreen

import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import color from '../../util/colors'

const PinContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
  }
`
const PinTextBox = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--title);
  width: 30%;
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
  }
`
const PinEntryArea = styled.div`
  display: flex;
  column-gap: 30px;
  width: 70%;
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    padding-bottom: 1rem;
    align-items: center;
    row-gap: 15px;
  }
`

const PinBox = styled(TextField)({
  '&.MuiFormControl-root': {
    width: '60px',
    height: '60px',
  },
})

const PinBoxEditButton = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding-left: 2rem;
  cursor: pointer;
  font-weight: 700;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: var(--primary);
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
    padding-left: 0;
  }
`

const editIcon = {
  color: color.primary,
}
PinBox.defaultProps = {
  inputProps: { style: { textAlign: 'center', fontSize: '20px', fontWeight: 500 } },
}

export { PinContainer, PinTextBox, PinEntryArea, PinBox, PinBoxEditButton, editIcon }

import * as React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Chip, IconButton, Menu, MenuItem } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { useState } from 'react'
import PropTypes from 'prop-types'

const ListComponent = ({ listItems }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const typographyStyle = {
    style: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 14,
      lineHeight: '140%',
      color: '#333333',
    },
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'draft':
        return '#F9E8B5'
      case 'published':
        return '#D3EEFA'
      case 'cancelled':
        return '#E9EBEE'
      case 'archived':
        return '#ECE8FA'
      default:
        break
    }
  }

  return (
    <List style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
      {listItems.map((item, i) => {
        let color = getStatusColor(item.status)
        return (
          <ListItem
            key={i}
            style={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              marginBottom: '1rem',
              height: '92px',
            }}
          >
            <ListItemText
              style={{ width: 100 }}
              primary={item.title}
              secondary={item.description}
              primaryTypographyProps={{
                style: {
                  ...typographyStyle.style,
                  fontWeight: 600,
                  letterSpacing: '0.001em',
                },
              }}
              secondaryTypographyProps={{
                style: {
                  ...typographyStyle.style,
                  fontWeight: 400,
                  letterSpacing: '0.0025em',
                },
              }}
            />
            <ListItemText
              style={{
                width: 100,
                display: 'flex',
                justifyContent: 'flex-start',
                marginLeft: 15,
              }}
            >
              <Chip
                label={item.status.toUpperCase()}
                style={{
                  fontWeight: 600,
                  fontSize: 10,
                  lineHeight: '140%',
                  letterSpacing: '0.015em',
                  color: '#22335A',
                  backgroundColor: color,
                }}
              />
            </ListItemText>
            <ListItemText style={{ display: 'flex', justifyContent: 'flex-end', width: 100 }}>
              <IconButton
                color="#4519CC"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget)
                  setMenuOpen(true)
                }}
              >
                <MoreVert key={i} />
              </IconButton>
            </ListItemText>
          </ListItem>
        )
      })}
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setMenuOpen(!menuOpen)
            //TODO: Implement adding to favourites
          }}
        >
          Add to Favorites
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuOpen(!menuOpen)
            //TODO: Implement event sharing
          }}
        >
          Share event
        </MenuItem>
      </Menu>
    </List>
  )
}

ListComponent.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      isFavorite: PropTypes.bool,
      status: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.string,
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    }),
  ),
}

export default ListComponent

import * as React from 'react'
import { useState, useEffect } from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import color from '../../util/colors'
import { StyledTableRow, StyledTableCell, StyledTableCellButton } from './DraftTableCard.style'
import { tableRowColor } from '../../util/helpers'
import { convertDateString } from '../../util/date'
import { useNavigate } from 'react-router-dom'
import { useFormGatherings } from '../../util/zustant/store'
import { useAuth } from '../../hooks/useAuth'
import { useEvents } from '../../hooks/useEvents'
import { ArchiveOutlined } from '@mui/icons-material'
import { archiveGathering } from '../../util/events'
import { useTemplateId } from '../../util/zustant/store'
import ModalPrompt from '../ModalPrompt/ModalPrompt'
import { CircularProgress } from '@mui/material'

const DraftTableCard = ({ draftData, indexPosition }) => {
  const [highlighted, setHighlighted] = useState(false)
  const { setFormInfo, formInfo } = useFormGatherings()
  const navigate = useNavigate()
  const { token } = useAuth()
  const { allCollections, setAllCollections } = useEvents()
  const [gatheringData, setGatheringData] = useState()
  const [createdBy, setCreatedBy] = useState('Unknown')
  const { setTemplateId } = useTemplateId()
  const [modalStatus, setModalStatus] = useState(false)
  const [loading, setLoading] = useState('')

  useEffect(() => {
    if (!gatheringData) {
      return
    }
    setFormInfo(gatheringData)
  }, [gatheringData])

  useEffect(() => {
    if (!gatheringData) {
      return
    }

    if (formInfo) {
      navigate('/createGathering')
    }
  }, [gatheringData])

  useEffect(() => {
    if (draftData?.createdBy && allCollections?.user?.length > 0) {
      const found = allCollections.user.find((u) => u.uid === draftData.createdBy)
      setCreatedBy(found?.displayName || found?.email || 'Unknown')
    }
  }, [draftData, allCollections])

  async function archiveDraft() {
    setLoading(draftData.id)
    const archived = await archiveGathering(draftData?.id, token)
    if (archived) {
      const updatedGathering = allCollections.gathering.filter((g) => g.id !== draftData?.id)
      const newCollection = { ...allCollections, gathering: updatedGathering }
      setAllCollections(newCollection)
      setLoading('')
    }
    setLoading('')
  }

  return (
    <StyledTableRow
      hover
      onClick={() => setHighlighted(!highlighted)}
      sx={{
        backgroundColor: tableRowColor(indexPosition),
        borderLeft: `5px solid ${tableRowColor(indexPosition)}`,
      }}
    >
      <StyledTableCell variant="body">
        <div>{createdBy}</div>
      </StyledTableCell>
      <StyledTableCell variant="body">
        <div>{draftData.gatheringDetails?.title}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>{convertDateString(draftData.created)}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>{draftData.updated ? convertDateString(draftData.updated) : 'Not updated'}</div>
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <StyledTableCellButton
          sx={{ textTransform: 'none', color: color.primary }}
          variant="text"
          startIcon={<OpenInNewIcon></OpenInNewIcon>}
          onClick={async () => {
            setGatheringData(draftData)
            setTemplateId('')
          }}
        >
          Edit
        </StyledTableCellButton>
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <StyledTableCellButton
          sx={{ textTransform: 'none', color: color.primary }}
          variant="text"
          onClick={() => {
            setModalStatus(true)
            // Removed deleting, change it for archiveing
            // const deleted = await deleteDraft(draftData?.id, token)
            // if (deleted) {
            //   const updatedGathering = allCollections.gathering.filter(
            //     (g) => g.id !== draftData?.id,
            //   )
            //   const newCollection = { ...allCollections, gathering: updatedGathering }
            //   setAllCollections(newCollection)
            // }
          }}
          startIcon={
            loading === draftData.id ? (
              <CircularProgress color={'inherit'} size={'20px'}></CircularProgress>
            ) : (
              <ArchiveOutlined></ArchiveOutlined>
            )
          }
        >
          {loading !== draftData.id ? 'Archive' : ''}
        </StyledTableCellButton>
        <ModalPrompt
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          descriptionText={`Archive Draft`}
          onClickFunction={archiveDraft}
          variant="archiveGathering"
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default DraftTableCard

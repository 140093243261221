import { logger } from './logger'

const baseUrl = process.env.REACT_APP_BACKEND_URL

export const getGatheringById = async (gatheringId, authToken) => {
  try {
    const response = await fetch(`${baseUrl}/api/admin/gatherings/${gatheringId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: 'follow',
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    logger.error('Gathering By Id Error: ', error)
  }
}

export async function sendApprovalEmail(id, token) {
  if (!id || !token) {
    return
  }
  try {
    const response = await fetch(`${baseUrl}/api/admin/approvals/sendReminderEmail/${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    const data = await response.json()
    return data
  } catch (error) {
    logger.error('Approve Gathering Error: ', error)
  }
}

export async function approveGathering(id, token, setApproved) {
  if (!id || !token) {
    return
  }
  try {
    const response = await fetch(`${baseUrl}/api/admin/approvals/approveGathering/${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    logger.log(response, 'resp')
    if (response.status === 204) {
      logger.log('Gathering successfully approved!')
    } else {
      logger.log('Gathering already approved!')
    }
    setApproved(response.status)
    return response.status
  } catch (error) {
    logger.error('Approve Gathering Error: ', error)
  }
}

export const createGathering = async (gatheringData, authToken, uid, title, activeStep) => {
  if (!gatheringData || !authToken || !uid) {
    return
  }

  const data = {
    status: 'draft',
    createdBy: uid,
    title: title,
    activeStep: activeStep,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...gatheringData,
  }
  try {
    const response = await fetch(`${baseUrl}/api/admin/gatherings/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      redirect: 'follow',
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    logger.error('Create Gathering Error: ', error)
  }
}

export const updateGathering = async (gatheringData, gatheringId, authToken) => {
  const data = {
    ...gatheringData,
  }
  try {
    const response = await fetch(`${baseUrl}/api/admin/gatherings/${gatheringId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      redirect: 'follow',
    })
    const responseData = await response.json()
    return {
      status: response.status,
      data: responseData,
    }
  } catch (error) {
    logger.error('Update Gathering Error: ', error)
  }
}

import styled from 'styled-components'
import Button from '@mui/material/Button'
import color from '../../util/colors'

const ImageUploadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const ImageUploadButton = styled(Button)({
  '&.MuiButtonBase-root': {
    border: '1px dashed var(--uploadDashLine)',
    borderRadius: '6px',
    backgroundColor: color.uploadButton,
    width: '100%',
    height: '212px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    letterSpacing: '0.0025em',
    color: color.title,
    textTransform: 'none',
    boxShadow: 'none',
  },
  '&.MuiButtonBase-root: hover': {
    backgroundColor: color.uploadButton,
    boxShadow: 'none',
  },
})

const ImageButtonWrapper = styled.label`
  width: 100%;
  padding: 1.5rem 0 1.5rem 0;
  z-index: 200;
`

const imageUploadIcon = {
  paddingRight: ' 0.5rem',
  width: '60px',
  height: '39px',
  color: color.uploadButtonIcon,
}

const ImageUploadBanner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: var(--backgroundSecondary);
  padding: 1.5rem;
  box-sizing: border-box;
`

const BannerText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.001em;
  color: var(--title);
  padding-bottom: 0.5rem;
`

const BannerMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`

const BannerMessageIconArea = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 1rem;
`

const BannerMessageTextArea = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`

const BannerMessageText = styled.li`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.004em;
  color: var(--title);
`

const bannerIconStyle = {
  color: color.message,
}

export {
  ImageUploadContainer,
  ImageUploadButton,
  ImageButtonWrapper,
  imageUploadIcon,
  ImageUploadBanner,
  BannerText,
  BannerMessageWrapper,
  BannerMessageIconArea,
  BannerMessageTextArea,
  BannerMessageText,
  bannerIconStyle,
}

import PropTypes from 'prop-types'
import { Card, CardImage, CardContentArea, CardTitleText, CardDateText } from './Card.style'
import { useNavigate } from 'react-router-dom'
import { useFormGatherings } from '../../util/zustant/store'

const MediaCard = ({
  isFavorite = false,
  status = '',
  title = 'Title failed to load',
  image = '',
  id = undefined,
  dateFrom = '',
  dateTo = '',
  isMemberPortal = false,
  gatheringObject, //this adds the favourite and share component for the member portal
}) => {
  const navigate = useNavigate()
  const { setFormInfo } = useFormGatherings()
  return (
    <Card
      onClick={() => {
        if (gatheringObject) {
          setFormInfo({ ...gatheringObject, activeStep: gatheringObject.highestStep })
          navigate('/createGathering')
        }
      }}
    >
      <CardImage src={image} />
      <CardContentArea className="contentArea">
        <CardTitleText>{title}</CardTitleText>
        <CardDateText>{`${dateFrom} - ${dateTo}`}</CardDateText>
      </CardContentArea>
    </Card>
  )
}

MediaCard.propTypes = {
  isFavorite: PropTypes.bool,
  status: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  isMemberPortal: PropTypes.bool,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
}

export default MediaCard

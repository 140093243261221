import color from '../../util/colors'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0;
  border-radius: 8px;
  box-shadow: 0px 61px 57px -52px rgba(0, 0, 0, 0.07);
  min-height: 293px;
  height: fit-content;
  width: 100%;
  max-width: 100%;
  background-color: ${color.backgroundPrimary};
`

export const Header = styled.div`
  min-height: 76px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  color: ${color.backgroundPrimary};
  font-weight: 400;
  font-size: 24px;
  line-height: 33.6px;
  background-color: ${color.primary};
`

export const MainBox = styled.div`
  height: 100%;
  width: 100%;
`
export const MainContent = styled.div`
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media only screen and (max-width: 768px) {
    gap: 1rem;
  }
`

export const Footer = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 25px;
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px;
  height: 100%;
  @media (max-width: 440px) {
    flex-direction: column-reverse;
    gap: 1rem;
  }
`

export const BasicInfoBackButtonStyle = {
  borderRadius: 5,
  borderColor: color.primary,
  color: color.primary,
  '&.MuiButtonBase-root:hover': {
    bgcolor: 'transparent',
    borderColor: color.primary,
  },
}

export const BasicInfoNextButtonStyle = {
  backgroundColor: color.primary,
  borderRadius: 5,
  boxShadow: 'none',
  '&.MuiButtonBase-root:hover': {
    bgcolor: color.primary,
    boxShadow: 'none',
  },
}

export const alertBoxStyle = {
  background: color.notificationBadge,
  color: color.backgroundPrimary,
}

export const alertBoxIcon = {
  color: color.backgroundPrimary,
}

import { Button, Stack } from '@mui/material'
import DateFilter from '../DateFilter/DateFilter'
import { useEffect, useState } from 'react'
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined'
import './GatheringsFilter.css'
import { mapper } from '../../util/filter'
import { FilterButton } from '../StyledButton/StyledButton.style'
import { filterStartGathering } from '../../util/date'
import color from '../../util/colors'
import { experienceHasGathering } from '../../util/experiencesAndGatherings.util'
import { useEvents } from '../../hooks/useEvents'

const GatheringsFilter = ({ setFilteredData, searchData }) => {
  const [filterState, setFilterState] = useState({
    free: false,
    paid: false,
    group: false,
    ministry: false,
    schoolClass: false,
    standAlone: false,
    partOfExperience: false,
    recurringOnly: false,
    adult: false,
    youth: false,
    kids: false,
  })

  const [filterDateType, setFilterDateType] = useState('created')
  const [filterDate, setFilterDate] = useState()
  const { experiences } = useEvents()

  const {
    free,
    paid,
    group,
    ministry,
    schoolClass,
    standAlone,
    partOfExperience,
    recurringOnly,
    adult,
    youth,
    kids,
  } = filterState

  const handleFilterState = (name) => {
    setFilterState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }))
  }

  const gatheringTemplate = [
    { name: 'Stand alone', var: standAlone, func: () => handleFilterState('standAlone') },
    {
      name: 'Part of an experience',
      var: partOfExperience,
      func: () => handleFilterState('partOfExperience'),
    },
    { name: 'Recurring only', var: recurringOnly, func: () => handleFilterState('recurringOnly') },
  ]
  const priceTemplate = [
    { name: 'Free', var: free, func: () => handleFilterState('free') },
    { name: 'Paid', var: paid, func: () => handleFilterState('paid') },
  ]
  const typeTemplate = [
    { name: 'Group', var: group, func: () => handleFilterState('group') },
    { name: 'Ministry', var: ministry, func: () => handleFilterState('ministry') },
    { name: 'Class', var: schoolClass, func: () => handleFilterState('schoolClass') },
  ]
  const excludeTemplate = [
    { name: 'Adult', var: adult, func: () => handleFilterState('adult') },
    { name: 'Youth', var: youth, func: () => handleFilterState('youth') },
    { name: 'Kids', var: kids, func: () => handleFilterState('kids') },
  ]

  const filterGathering = (item) => {
    if (!standAlone && !partOfExperience && !recurringOnly) {
      return true
    }

    return (
      (standAlone && !experienceHasGathering(item.id, experiences)) ||
      (partOfExperience && experienceHasGathering(item.id, experiences)) ||
      (recurringOnly && item?.logisticsInfo?.event?.recurres)
    )
  }

  const filterPrice = (item) => {
    if (!free && !paid) {
      return true // Show all objects when both free and paid filters are false
    }

    return (
      (free && item?.paidEvent?.eventIsPaid?.toLowerCase() === 'free') ||
      (paid && item?.paidEvent?.eventIsPaid?.toLowerCase() === 'paid')
    )
  }

  const filterType = (item) => {
    if (!group && !ministry && !schoolClass) {
      return true // Show all objects when all type filters are false
    }

    return (
      (group && item?.gatheringType?.typeOfGathering?.toLowerCase() === 'group') ||
      (ministry && item?.gatheringType?.typeOfGathering?.toLowerCase() === 'ministry') ||
      (schoolClass && item?.gatheringType?.typeOfGathering?.toLowerCase() === 'class')
    )
  }

  //TODO: We don't have this yet in our gatherings
  // const filterCategory = (item) => {
  //   if (
  //     (adult && item.category === 'adult') ||
  //     (youth && item.category === 'youth') ||
  //     (kids && item.category === 'kids')
  //   ) {
  //     return false
  //   }
  //   return true
  // }

  const filterGatheringData = () => {
    const filtered = searchData?.filter(
      (item) =>
        filterGathering(item) &&
        filterPrice(item) &&
        filterType(item) &&
        // filterCategory(item) &&
        filterStartGathering(item, filterDate, filterDateType),
    )

    setFilteredData && setFilteredData(filtered)
  }

  useEffect(() => {
    filterGatheringData()
  }, [filterDate, filterDateType, filterState, searchData])

  return (
    <Stack
      sx={{ flexWrap: 'wrap' }}
      display={'flex'}
      direction={{
        xs: 'row',
      }}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      gap={3}
      my={3}
    >
      <Stack>
        <div className="gatheringFilterTextArea">Gatherings: </div>
        <Stack justifyContent={'flex-start'}>{mapper(gatheringTemplate)}</Stack>
      </Stack>
      <Stack>
        <div className="gatheringFilterTextArea">Date: </div>
        <DateFilter
          setFilterDateType={setFilterDateType}
          setFilterDate={setFilterDate}
          filterDate={filterDate}
        ></DateFilter>
        <Button
          style={{ color: color.primary }}
          onClick={() => {
            setFilterDate(undefined)
          }}
        >
          Reset date filter
        </Button>
      </Stack>

      <Stack>
        <div className="gatheringFilterTextArea">Free: </div>
        <Stack justifyContent={'flex-start'}>{mapper(priceTemplate)}</Stack>
      </Stack>
      <Stack>
        <div className="gatheringFilterTextArea">Type: </div>
        <Stack alignItems={'flex-start'}>{mapper(typeTemplate)}</Stack>
      </Stack>

      <Stack alignItems={'flex-start'} justifyContent={'center'}>
        <div className="gatheringFilterTextArea">Campus: </div>
        <FilterButton startIcon={<CottageOutlinedIcon />}>Select</FilterButton>
      </Stack>
      <Stack>
        <div className="gatheringFilterTextArea">Exclude: </div>
        <Stack alignItems={'flex-start'}>{mapper(excludeTemplate)}</Stack>
      </Stack>
    </Stack>
  )
}

export default GatheringsFilter

import color from '../../util/colors'

function handleIndicatorColor(tabVariant) {
  if (tabVariant === 'Header') {
    return color.primary
  } else {
    return color.title
  }
}

export { handleIndicatorColor }

import styled from 'styled-components'
import color from '../../util/colors'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: var(--backgroundSecondary);
  padding: 3rem 0 3rem 0;
  box-sizing: border-box;
  row-gap: 1.5rem;
`

export const TextAndButtonsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 0 3rem;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 50px;
  }
`
export const RowTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
`
export const MainText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary);
`

export const SecondaryText = styled.div`
  color: var(--filterText);
  font-weight: 400;
  font-size: 12px;
`

export const ButtonRow = styled.div`
  display: flex;
  column-gap: 20px;
`

export const TemplatesFilterArea = styled.div`
  transition: height 0.5s ease-out, padding 0.7s ease-out;
  height: auto;
  overflow: hidden;
  width: 100%;
  background-color: ${color.filterBackground};
  padding: 2.5rem 2.5rem 2.5rem 3rem;
  box-sizing: border-box;
  ${(props) =>
    props.open === false &&
    `
    padding-top: 0;
  padding-bottom: 0;
  height: 0;
  `}
  @media (max-width: 768px) {
    overflow: 'scroll';
  }
`

export const FilterText = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 32px;
  color: ${color.description};
`
export const formControlStyle = {
  color: color.primary,
  border: `1px solid ${color.primary}`,
}

export const FooterText = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 26px;
`

/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import useProtectedRoute from '../../hooks/useProtectedRoute'
import GatheringsAndExperiencesScreen from '../GatheringsAndExperiencesScreen/GatheringsAndExperiencesScreen'
import CalendarScreen from '../CalendarScreen/CalendarScreen'
import { useEvents } from '../../hooks/useEvents'
import SubHeader from '../../components/SubHeader/SubHeader'
import { MainContainer, ComponentArea } from '../../util/ScreenWrapper.style'
import { useView } from '../../util/zustant/store'
const MainView = () => {
  const [selectedTab, setSelectedTab] = useState('Home')

  const { authenticated, token } = useAuth()
  const { gatherings, experiences, profileData } = useEvents()
  const { setView, view } = useView()
  const [filteredGatherings, setFilteredGatherings] = useState([])
  const [filteredExperiences, setFilteredExperiences] = useState([])

  useEffect(() => {
    setView(1)
  }, [])

  useEffect(() => {
    if (gatherings?.length > 0) {
      setFilteredGatherings(gatherings.filter((g) => g.status === 'published'))
    }
  }, [gatherings])

  useEffect(() => {
    if (experiences?.length > 0) {
      setFilteredExperiences(experiences.filter((e) => e.status === 'published'))
    }
  }, [experiences])

  useProtectedRoute()

  if (!authenticated || !profileData) return <></>

  return (
    <MainContainer>
      {/* Components Area*/}
      <ComponentArea>
        {/* Top Row Area 2*/}
        <SubHeader
          setSelectedTabParent={setSelectedTab}
          activeTabOnParent={selectedTab}
        ></SubHeader>

        {/* Screens Section Area */}
        {view === 1 ? (
          <GatheringsAndExperiencesScreen
            gatherings={filteredGatherings}
            experiences={filteredExperiences}
            token={token}
            loading={gatherings.length === 0 && experiences.length === 0}
          ></GatheringsAndExperiencesScreen>
        ) : (
          <CalendarScreen
            eventData={[...filteredGatherings, ...filteredExperiences]}
          ></CalendarScreen>
        )}
      </ComponentArea>
    </MainContainer>
  )
}

export default MainView

import color from '../../util/colors'

export const ListItemButtonStyle = {
  borderRadius: '5px',
  height: '40px',
  '&.Mui-selected': {
    backgroundColor: color.primary,
  },
  '&.Mui-selected:hover': {
    background: color.primary,
  },
}

import { TagGridContainer, TagGridArea, TagGridNotifText } from './TagGrid.style'
import Tag from '../Tag/Tag'

const TagGrid = ({
  tagPool,
  tagVariant,
  notifText,
  makeQuestionActive,
  setQuestionSaved,
  removeQuestionFromPool,
  setQuestionsPool,
  setActiveQuestion,
  removeTagFromPool,
  setTagPool,
  makeTagActive,
  setActiveTag,
  setTagSaved,
  handleSaveButton,
  uploadArray,
  exampleArray,
  setUploadArray,
  setExampleArray,
  activeTicket,
  eventType,
}) => {
  return (
    <TagGridContainer>
      <TagGridNotifText>{notifText}</TagGridNotifText>
      <TagGridArea>
        {tagPool?.map((tag, i) => {
          return (
            tag && (
              <Tag
                label={'title'}
                key={tag.id}
                tagObject={tag}
                variant={tagVariant}
                makeQuestionActive={makeQuestionActive}
                setQuestionSaved={setQuestionSaved}
                removeQuestionFromPool={removeQuestionFromPool}
                setQuestionsPool={setQuestionsPool}
                setActiveQuestion={setActiveQuestion}
                removeTagFromPool={removeTagFromPool}
                setTagPool={setTagPool}
                makeTagActive={makeTagActive}
                setActiveTag={setActiveTag}
                setTagSaved={setTagSaved}
                handleSaveButton={handleSaveButton}
                uploadArray={uploadArray}
                exampleArray={exampleArray}
                setUploadArray={setUploadArray}
                setExampleArray={setExampleArray}
                index={i}
                activeTicket={activeTicket}
                eventType={eventType}
              ></Tag>
            )
          )
        })}
      </TagGridArea>
    </TagGridContainer>
  )
}

export default TagGrid

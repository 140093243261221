import styled from 'styled-components'
import color from '../../../util/colors'

const PublishContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem 0 1.5rem 0;
`

const DateSelectorArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  column-gap: 30px;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 15px;
  }
`
const TimeSelectorArea = styled(DateSelectorArea)`
  padding-bottom: 3rem;
`

const DateAndTimeSubText = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  width: 100%;
  color: var(--title);
  @media (max-width: 1024px) {
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
`
const alertBoxStyle = {
  background: color.message,
  color: color.backgroundPrimary,
  display: 'flex',
  alignItems: 'center',
}

const alertBoxIcon = {
  color: color.backgroundPrimary,
}

export {
  PublishContainer,
  DateSelectorArea,
  TimeSelectorArea,
  DateAndTimeSubText,
  alertBoxStyle,
  alertBoxIcon,
}

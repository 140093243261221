import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent'
import {
  ImageUploadContainer,
  PreviewArea,
  ImageUploadSubText,
  ImageDetailsSection,
  GatheringName,
  GatheringData,
  ImagePreviewSection,
  detailsIcon,
  stockImageIcon,
  Image,
} from './ImageUpload.style'
import { useFormGatherings } from '../../../util/zustant/store.js'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import ImageIcon from '@mui/icons-material/Image'
import { useState, useEffect } from 'react'

const ImageUpload = ({ authToken }) => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [imageUpload, setImageUpload] = useState(formInfo?.imageUpload ? formInfo?.imageUpload : {})

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.imageUpload = imageUpload
    setFormInfo(newFormInfo)
  }, [imageUpload])

  // Store user uploaded image
  function setImage(image) {
    setImageUpload((info) => {
      info.eventImage = image
      return { ...info }
    })
  }

  const gatheringImage = formInfo?.imageUpload?.eventImage
  const gatheringName = formInfo?.gatheringDetails?.title
  const gatheringDate = formInfo?.logisticsInfo?.event?.startDate
  const gatheringLocation = formInfo?.logisticsInfo?.location
  const isEventPaid = formInfo?.paidEvent?.eventIsPaid

  return (
    <ImageUploadContainer>
      <ImageUploadComponent
        error={
          formInfo?.highestStep === formInfo?.activeStep
            ? false
            : typeof imageUpload.eventImage !== 'string'
        }
        formInfo={formInfo?.imageUpload}
        setImage={setImage}
        authToken={authToken}
      ></ImageUploadComponent>
      <ImageUploadSubText>Preview</ImageUploadSubText>
      <PreviewArea>
        <ImageDetailsSection>
          <GatheringName>{gatheringName ? gatheringName : 'Title'}</GatheringName>
          <GatheringData>
            <CalendarTodayIcon style={detailsIcon} />
            <span>{gatheringDate ? gatheringDate : 'Date'}</span>
          </GatheringData>
          <GatheringData>
            <LocationOnIcon style={detailsIcon} />
            <span>{gatheringLocation ? gatheringLocation : 'Location'}</span>
          </GatheringData>
          <GatheringData>
            <MonetizationOnIcon style={detailsIcon} />
            <span>{`Event paid: ${isEventPaid}`}</span>
          </GatheringData>
        </ImageDetailsSection>
        <ImagePreviewSection>
          {gatheringImage ? (
            <Image src={gatheringImage} alt=""></Image>
          ) : (
            <ImageIcon style={stockImageIcon} />
          )}
        </ImagePreviewSection>
      </PreviewArea>
    </ImageUploadContainer>
  )
}

export default ImageUpload

import styled from 'styled-components'
import color from '../../../util/colors'

const RemindersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const RemindersContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 1024px) {
  }
`

const StyledText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--title);
  display: flex;
  padding: 3rem 0 0.5rem 0;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`

const RowStyledText = styled(StyledText)`
  padding: 0;
  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 1rem;
  }
`

const ReminderCheckRow = styled.div`
  display: flex;
  width: 100%;
  padding: 1.5rem 0 2.3rem 0;
  height: 66px;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
`
const CheckRowSwitchArea = styled.div`
  display: flex;
  width: 7%;
  margin-left: 0.5rem;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    padding: 1rem 0 1rem 0;
    margin-left: 0;
  }
`

const CheckRowBannerArea = styled.div`
  display: flex;
  width: 93%;
  height: 66px;
  justify-content: space-between;
  background-color: var(--backgroundSecondary);
  padding: 0 1.5rem 0 1.5rem;
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 1.5rem;
  }
`

const BannerImageAndTextArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    row-gap: 15px;
  }
`

const BannerImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`
const bannerImageStyle = {
  color: color.primary,
}

const BannerText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.001em;
  color: var(--title);
  margin-left: 15px;
  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
    margin-left: 0;
    justify-content: center;
  }
`

const UtilityButton = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0125em;
  color: var(--primary);
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
  }
`
const utilityIconStyle = {
  color: color.primary,
  fontSize: 20,
}

const TextEditRow = styled.div`
  display: flex;
  width: 93%;
  padding: 0 0 0.5rem 7%;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0;
  }
`

const TextBannerRow = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--backgroundSecondary);
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: var(--title);
  height: 36px;
  border-radius: 6px;
  padding: 5px 5px 5px 15px;
  ${(props) => props.margin && `margin: ${props.margin};`}
  @media (max-width: 1024px) {
    height: fit-content;
    text-align: center;
    padding: 5px;
  }
`

const ReminderDatesContainer = styled.div`
  box-sizing: border-box;
  height: auto;
  width: 93%;
  padding: 2.5rem 2.5rem 2.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  border: 1px solid ${color.borderRest};
  border-radius: 9px;
  row-gap: 40px;
`
const ReminderDatesSectionBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

const ReminderDatesFirstRow = styled.div`
  display: flex;
  column-gap: 10px;
`

const formControlStyle = {
  color: color.primary,
  width: '200px',
  borderRadius: '5px',
}

const textFieldStyle = {
  color: color.primary,
  width: '100px',
  borderRadius: '5px',
}

const RemindersText = styled.div`
  font-size: 16px;
  color: var(--title);
`

export {
  RemindersContainer,
  RemindersContentWrapper,
  StyledText,
  RowStyledText,
  ReminderCheckRow,
  CheckRowSwitchArea,
  CheckRowBannerArea,
  BannerImageAndTextArea,
  BannerImage,
  bannerImageStyle,
  BannerText,
  UtilityButton,
  utilityIconStyle,
  TextEditRow,
  TextBannerRow,
  ReminderDatesContainer,
  ReminderDatesSectionBox,
  ReminderDatesFirstRow,
  formControlStyle,
  textFieldStyle,
  RemindersText,
}

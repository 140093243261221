import { useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import useProtectedRoute from '../../hooks/useProtectedRoute'
import AllExperiences from '../../components/AllExperiences/AllExperiences'
import CalendarScreen from '../CalendarScreen/CalendarScreen'
import { useEvents } from '../../hooks/useEvents'
import SubHeader from '../../components/SubHeader/SubHeader'
import { MainContainer, ComponentArea } from '../../util/ScreenWrapper.style'
import { useView } from '../../util/zustant/store'
const AllExperiencesScreen = () => {
  const [selectedTab, setSelectedTab] = useState('Published')
  const { view } = useView()
  const { authenticated } = useAuth()
  const { profileData, gatherings, experiences } = useEvents()

  useProtectedRoute()

  if (!authenticated || !profileData) return <></>

  return (
    <MainContainer>
      {/* Components Area*/}
      <ComponentArea>
        {/* Top Row Area 2*/}
        <SubHeader
          setSelectedTabParent={setSelectedTab}
          activeTabOnParent={selectedTab}
        ></SubHeader>

        {/* Screens Section Area */}
        {view === 1 ? (
          <AllExperiences gatherings={gatherings} experiences={experiences}></AllExperiences>
        ) : (
          <CalendarScreen eventData={experiences}></CalendarScreen>
        )}
      </ComponentArea>
    </MainContainer>
  )
}

export default AllExperiencesScreen

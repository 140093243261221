import defaultTemplate from '../templates/createGatheringDefault.json'
import rosterOpenTemplate from '../templates/createGatheringRosterOpen.json'
import rosterClosedTemplate from '../templates/createGatheringRosterClosed.json'
import kioskTemplate from '../templates/createGatheringKiosk.json'
import paidEvent from '../templates/createGatheringPaid.json'
import unpaidEvent from '../templates/createGatheringUnpaid.json'
import color from '../../../util/colors'

function checklistConfiguration(eventType, checkinMethod, openGathering) {
  let checklistTemplate = defaultTemplate

  if (checkinMethod === 'roster' && openGathering === 'yes') {
    checklistTemplate = updateCheckinsSubData(defaultTemplate, rosterOpenTemplate)
  } else if (checkinMethod === 'roster' && openGathering === 'no') {
    checklistTemplate = updateCheckinsSubData(defaultTemplate, rosterClosedTemplate)
  } else if (checkinMethod === 'kiosk') {
    checklistTemplate = updateCheckinsSubData(defaultTemplate, kioskTemplate)
  }

  if (eventType === 'Paid') {
    checklistTemplate = updateCheckinsData(defaultTemplate, paidEvent)
  } else {
    checklistTemplate = updateCheckinsData(defaultTemplate, unpaidEvent)
  }

  const checklistConfiguration = createChecklistConfigurationFromTemplate(checklistTemplate)
  return checklistConfiguration
}

function createChecklistConfigurationFromTemplate(template) {
  let index = 1
  const traverseTemplate = (data) => {
    return data.map((item) => {
      const newItem = {
        index: index++,
        title: item.title,
        subData: item.subData,
      }

      if (item?.subData?.length > 0) {
        newItem.subData = traverseTemplate(item.subData)
      }

      return newItem
    })
  }

  const updatedTemplate = template.map((item, titleIndex) => ({
    titleNumber: titleIndex + 1,
    title: item.title,
    data: traverseTemplate(item.data),
  }))

  return updatedTemplate
}

function updateCheckinsSubData(template, newSubData) {
  const checkinsData = template[2].data
  checkinsData[2].subData = newSubData
  return template
}

function updateCheckinsData(template, newData) {
  const checkinsData = template[1]
  checkinsData.data = newData
  return template
}

function getAllTitlesAndIndexes(arr) {
  let result = []

  arr.forEach((obj) => {
    if (obj.data && Array.isArray(obj.data)) {
      const subDataTitlesAndIndexes = getAllTitlesAndIndexes(obj.data)
      result = result.concat(subDataTitlesAndIndexes)
    }

    if (obj.subData && Array.isArray(obj.subData)) {
      const subDataTitlesAndIndexes = getAllTitlesAndIndexes(obj.subData)
      result = result.concat(subDataTitlesAndIndexes)
    }

    const titleIndex = { title: obj.title, index: obj.index }
    if (titleIndex.index !== undefined) {
      result.push(titleIndex)
    }
  })

  return result
}

function handleProgressBadge(currentStep, firstElement, lastElement) {
  if (currentStep >= firstElement && currentStep <= lastElement) {
    return <div className="checklistComponentStatusBadge">IN PROGRESS</div>
  } else if (currentStep > lastElement) {
    return <div className="checklistComponentStatusBadgeComplete">COMPLETED</div>
  } else if (currentStep < firstElement)
    return <div className="checklistComponentStatusBadgeInactive">NOT STARTED</div>
  else return <></>
}

function handleAlertBackgrounds(itemLabel) {
  switch (itemLabel) {
    case 'Reminders':
      return color.uiWarning
    case 'Security':
      return color.uiWarning
    default:
      return color.uiError
  }
}

function prepareDataForAccordion(data) {
  const result = []

  data.forEach((item) => {
    result.push({
      index: item.index,
      title: item.title,
    })

    if (item.subData && item.subData.length > 0) {
      result.push(...prepareDataForAccordion(item.subData))
    }
  })
  return result
}

export {
  checklistConfiguration,
  handleProgressBadge,
  getAllTitlesAndIndexes,
  handleAlertBackgrounds,
  prepareDataForAccordion,
}

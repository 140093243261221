import * as React from 'react'
import Box from '@mui/material/Box'

import Modal from '@mui/material/Modal'

import PropTypes from 'prop-types'
import './EventCreationModal.css'
import CloseIcon from './CloseIcon.svg'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
}

const EventCreationModal = ({ modalStatus, handleFunction }) => {
  return (
    <div>
      <Modal
        open={modalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button className="closeButton" onClick={() => handleFunction(false)}>
            <img src={CloseIcon} alt="SVG Icon for close button"></img>
          </button>
          <div className="modalContainer">
            <div className="mainModalTextArea">
              <div className="modalText">What would you like to create?</div>
            </div>
            <div className="pickBoxArea">
              <div className="pickBox1">
                <div className="pickBoxFirstText">An Experience</div>
                <div className="pickBoxSecondText">
                  'An experience is a collection of events (gatherings). For example: Sunday service
                  is an experience.
                </div>
              </div>
              <div className="pickBox2">
                <div className="pickBoxFirstText">An Gathering</div>
                <div className="pickBoxSecondText">
                  'A gathering is an single event.For example: A small group meeting or a class. An
                  experience is a collection of events (gatherings). Learn More'
                </div>
              </div>
              <button className="mobileButton" onClick={() => handleFunction(false)}>
                <img src={CloseIcon} alt="SVG Icon for close button"></img>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

EventCreationModal.propTypes = {
  modalStatus: PropTypes.bool,
  handleFunction: PropTypes.func,
}

export default EventCreationModal

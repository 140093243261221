import styled from 'styled-components'
import { FormControl } from '@mui/material'
import color from '../../../util/colors'
import { OutlinedInput } from '@mui/material'

export const ExitInputStyle = styled(FormControl)`
  width: 100%;
  svg {
    color: ${color.primary};
    cursor: pointer;
  }
`

export const Input = styled(OutlinedInput)`
  color: ${color.filterText} !important;
`

import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import useProtectedRoute from '../../hooks/useProtectedRoute'
import AllGatherings from '../../components/AllGatherings/AllGatherings'
import CalendarScreen from '../CalendarScreen/CalendarScreen'
import { useEvents } from '../../hooks/useEvents'
import SubHeader from '../../components/SubHeader/SubHeader'
import { MainContainer, ComponentArea } from '../../util/ScreenWrapper.style'
import { useView } from '../../util/zustant/store'

const AllGatheringsScreen = () => {
  const [selectedTab, setSelectedTab] = useState('Published')
  const { view } = useView()
  const { profileData, gatherings, experiences } = useEvents()
  const [filteredGatherings, setFilteredGatherings] = useState([])

  const { authenticated, token } = useAuth()

  useEffect(() => {
    if (gatherings?.length > 0) {
      const filtered = gatherings.filter((g) => g.status === 'published')
      setFilteredGatherings(filtered)
    }
  }, [gatherings])

  useProtectedRoute()

  if (!authenticated || !profileData) return <></>

  return (
    <MainContainer>
      {/* Components Area*/}
      <ComponentArea>
        {/* Top Row Area 2*/}
        <SubHeader
          setSelectedTabParent={setSelectedTab}
          activeTabOnParent={selectedTab}
        ></SubHeader>

        {/* Screens Section Area */}
        {view === 1 ? (
          <AllGatherings
            gatherings={gatherings}
            experiences={experiences}
            token={token}
          ></AllGatherings>
        ) : (
          <CalendarScreen eventData={filteredGatherings}></CalendarScreen>
        )}
      </ComponentArea>
    </MainContainer>
  )
}

export default AllGatheringsScreen

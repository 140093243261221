import './App.css'
import Router from './components/Router/Router'
import Layout from './components/Layout/Layout'
import { LicenseInfo } from '@mui/x-license-pro'
function App() {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE)
  return (
    <div className="App">
      <Layout>
        <Router />
      </Layout>
    </div>
  )
}

export default App

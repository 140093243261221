import { events, groupData } from '../../util/testData'
import CalendarComponent from '../Calendar/Calendar'
import ListComponent from '../ListComponent/ListComponent'
import { useParams } from 'react-router-dom'
import Sidebar from '../Sidebar/Sidebar'
import ProfileComponent from '../ProfileComponent/ProfileComponent'
import { profileComponentProps } from '../../util/testData'
import GatheringTableCard from '../GatheringTableCard/GatheringTableCard'
import ExperienceTableCard from '../ExperienceTableCard/ExperienceTableCard'
import TemplatesTableCard from '../TemplatesTableCard/TemplatesTableCard'
import TableBody from '@mui/material/TableBody'
import TemplateCardGrids from '../TemplateCardGrids/TemplateCardGrids'
import TemplateButton from '../TemplateButton/TemplateButton'
import GroupFrame from '../GroupFrame/GroupFrame'
import GroupGrid from '../GroupGrid/GroupGrid'
import Tag from '../Tag/Tag'
import Kiosk from '../Kiosk/Kiosk'
import Pin from '../Pin/Pin'
import CheckinSetup from '../GatheringCreation/CheckinSetup/CheckinSetup'

const TestingComponent = ({ component }) => {
  const experienceData = events(10)[0]
  const gatheringData = events(10)[0]
  const group = groupData(30)

  function renderContent() {
    switch (component) {
      case 'calendar':
        return <CalendarComponent events={eventData} />
      case 'listComponent':
        return <ListComponent listItems={eventData} />
      case 'sidebar':
        return <Sidebar events={eventData} />
      case 'profile':
        return <ProfileComponent profile={profileComponentProps} notifications={eventData} />
      case 'gatheringTableCard':
        return (
          <div
            style={{
              width: '100vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TableBody>
              <GatheringTableCard
                gatheringData={gatheringData}
                recurring={true}
                parrentExperience={experienceData.title}
              ></GatheringTableCard>
            </TableBody>
          </div>
        )
      case 'experienceTableCard':
        return (
          <div
            style={{
              width: '100vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TableBody>
              <ExperienceTableCard experienceData={experienceData}></ExperienceTableCard>
            </TableBody>
          </div>
        )
      case 'templatesTableCard':
        return (
          <div
            style={{
              width: '100vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TableBody>
              <TemplatesTableCard templateData={experienceData}></TemplatesTableCard>
            </TableBody>
          </div>
        )
      case 'templateCardGrids':
        return (
          <div
            style={{
              width: '100vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TemplateCardGrids gatheringData={eventData}></TemplateCardGrids>
          </div>
        )
      case 'templateButton':
        return (
          <div
            style={{
              width: '90w',
              height: '90vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              columnGap: '2rem',
            }}
          >
            <TemplateButton variant={'draft'} label={'Save Draft'}></TemplateButton>
            <TemplateButton variant={'template'} label={'Make Template'}></TemplateButton>
            <TemplateButton variant={'seeAll'} label={'See All Gatherings'}></TemplateButton>
            <TemplateButton variant={'seeAll'} label={'See All Experiences'}></TemplateButton>
          </div>
        )
      case 'groupFrame':
        return (
          <div
            style={{
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '1rem',
            }}
          >
            <GroupFrame group={group[1]} variant={'group'}></GroupFrame>
            <GroupFrame group={group[2]} variant={'team'}></GroupFrame>
            <GroupFrame group={group[3]} variant={'household'}></GroupFrame>
            <GroupFrame group={group[4]} variant={'individual'}></GroupFrame>
          </div>
        )
      case 'groupGrid':
        return (
          <div
            style={{
              width: '95vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '1rem',
              paddingLeft: '1rem',
              paddingRight: '1rem',
            }}
          >
            <GroupGrid groupName={'Groups'} groupPool={group} groupVariant={'group'}></GroupGrid>
            <GroupGrid
              groupName={'Households'}
              groupPool={group}
              groupVariant={'household'}
            ></GroupGrid>
            <GroupGrid groupName={'Teams'} groupPool={group} groupVariant={'team'}></GroupGrid>
            <GroupGrid
              groupName={'Individuals'}
              groupPool={group}
              groupVariant={'individual'}
            ></GroupGrid>
          </div>
        )
      case 'tag':
        return (
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '1rem',
            }}
          >
            <Tag question={'Question no.1'} variant={'question'}></Tag>
            <Tag question={'Longer question no.2'} variant={'question'}></Tag>
            <Tag question={'Sales Report.PDF'} variant={'document'}></Tag>
            <Tag question={'May Spreadsheet.xls'} variant={'document'}></Tag>
          </div>
        )
      case 'pin':
        return (
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '1rem',
            }}
          >
            <Pin pinText={'Enter your 4-digit PIN:'} kiosk={true}></Pin>
          </div>
        )
      case 'kiosk':
        return (
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Kiosk headerTitle={'Dummy Title for Testing'}></Kiosk>
            <Kiosk headerTitle={'Kiosk component with content'}>
              <CheckinSetup kiosk={true} groupData={group}></CheckinSetup>
            </Kiosk>
          </div>
        )
      default:
        return <></>
    }
  }

  let { limit } = useParams()
  const eventData = events(limit || 5)
  return <div style={{ height: '100vh', width: '100%' }}>{renderContent()}</div>
}

export default TestingComponent

import './CalendarScreen.css'
import CalendarComponent from '../../components/Calendar/Calendar'

const CalendarScreen = ({ eventData }) => {
  return (
    <section className="mainContentArea">
      <div className="calendarComponentArea">
        <CalendarComponent events={eventData} />
      </div>
      {/* Footer Text Area */}
      <div className="footerText">©2023 Church.Ai. All Rights Reserved.</div>
    </section>
  )
}

export default CalendarScreen

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  OccurrenceTitleOccurrence,
  ReoccurringHolder,
  Row,
  RowHolder,
} from './LogisticsInfo.styles'
import { Label } from '../../Form/FormElements.styles.jsx'
import Selector from '../../Form/components/Selector'
import Input from '../../Form/components/Input'
import DateInput from '../../Form/components/DateInput'
import TimeInput from '../../Form/components/TimeInput'
import OccurrenceInterval from './OccurrenceInterval'
import { Radio } from '@mui/material'
import { Hr } from './LogisticsInfo.styles'
import { Checkbox } from '@mui/material'
import dayjs from 'dayjs'
import data from '../../../util/Logistics.json'
import { generateFutureDates } from '../../../util/date'
import CustomCloseIcon from './CustomCloseIcon'
import MyDateCalendar from './CustomDateCalendar'
import { logger } from '../../../util/logger'
function Reoccurring({
  SetFormProperty,
  SetOccurrences,
  formInfo,
  setterOccurrence,
  removeOccurrence,
  occurrences,
  cleanOccurrences,
}) {
  // const [occurrences, setOccurrences] = useState([])
  const [selectDays, selectedDay] = useState([])
  const [isCheck, setIsCheck] = useState(true)
  const [focusedIndex, setFocusedIndex] = useState([])
  const [render, setRender] = useState(false)

  const select = (value) => {
    selectedDay((prev) => [...prev, dayjs(value).format('MMMM DD,YYYY')])
  }

  function findNextDayOfMonth(selectedDate, dayOfWeek) {
    // we need day in order for this to work, can explain if needed but it would be too long to explain it here
    // eslint-disable-next-line
    const [month, day, year] = selectedDate?.split('/').map(Number)
    const targetDay = dayOfWeek % 7

    let date = new Date(year, month - 1, 1) // Set date to the first day of the month

    while (date.getDay() !== targetDay) {
      date.setDate(date.getDate() + 1)
    }

    return date
  }

  function findDateOfMonth(selectedDate, dayOfMonth) {
    // we need day in order for this to work, can explain if needed but it would be too long to explain it here
    // eslint-disable-next-line
    if (selectedDate) {
      const [month, day, year] = selectedDate?.split('/').map(Number)
      const targetDay = Math.min(Math.max(dayOfMonth, 1), 31) // Ensure the dayOfMonth value is between 1 and 31

      const date = new Date(year, month - 1, targetDay)
      return date
    }
  }

  function findDayByOrderAndDayOfWeek(selectedDate, order, dayOfWeek) {
    // we need day in order for this to work, can explain if needed but it would be too long to explain it here
    // eslint-disable-next-line
    if (selectedDate) {
      const [month, day, year] = selectedDate?.split('/').map(Number)
      const targetDay = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ].indexOf(dayOfWeek?.toLowerCase())

      // Set the date to the first day of the selected month and year
      let date = new Date(year, month - 1, 1)
      let count = 0

      while (date.getMonth() === month - 1) {
        if (date.getDay() === targetDay) {
          count++
          if (['first', 'second', 'third', 'fourth'][count - 1] === order) {
            return date
          }
        }
        date.setDate(date.getDate() + 1)
      }
    }

    return null
  }

  const setterFocusedIndex = (index) => {
    setFocusedIndex((prev) => [...prev, index])
  }

  const generateFutureDatesAndSetOccurrences = (date) => {
    return generateFutureDates(
      date ? date : formInfo?.logisticsInfo?.event?.startDate,
      formInfo?.logisticsInfo?.event?.recurres,
      formInfo?.logisticsInfo?.event?.recurrencAmount,
      !isCheck ? formInfo?.logisticsInfo?.event?.order : null,
      !isCheck ? formInfo?.logisticsInfo?.event?.days : null,
      isCheck ? formInfo?.logisticsInfo?.event?.occursOn : null,
    ).map((date, i) => {
      SetOccurrences(date, 'startDate', i)
      SetOccurrences(formInfo?.logisticsInfo?.event?.startTime, 'startTime', i)
      SetOccurrences(date, 'initialStartDate', i)
      return null
    })
  }

  useEffect(() => {
    generateFutureDatesAndSetOccurrences()
  }, [render])

  return (
    <ReoccurringHolder>
      <Row>
        <Label>Start Date</Label>
        <DateInput
          error={formInfo?.logisticsInfo?.checkFields && !formInfo?.logisticsInfo?.event?.startDate}
          onChange={(value) => {
            SetFormProperty('startDate', value, 'event')
            generateFutureDatesAndSetOccurrences()
            setFocusedIndex([])
          }}
          value={formInfo?.logisticsInfo?.event?.startDate}
        />
        <Label>Start Time</Label>
        <TimeInput
          error={formInfo?.logisticsInfo?.checkFields && !formInfo?.logisticsInfo?.event?.startTime}
          onChange={(value) => {
            SetFormProperty('startTime', value, 'event')
            generateFutureDatesAndSetOccurrences()
            setFocusedIndex([])
          }}
          value={formInfo?.logisticsInfo?.event?.startTime}
        />
      </Row>
      <hr style={{ margin: '24px 0px', borderTop: '1px dashed black' }} />
      <Row>
        <Label minWidth={'209px'}>Set recurrence on...</Label>
        <Selector
          onChange={async (value) => {
            SetFormProperty('recurres', value, 'event')
            setFocusedIndex([])
            if (value === 'daily') {
              SetFormProperty('occursOn', 1, 'event')
            }

            if (value === 'custom') {
              cleanOccurrences()
              SetFormProperty('occurrences', [], 'event')
              SetFormProperty('recurrencAmount', null, 'event')
              selectedDay([])
            }

            if (value === 'weekly') {
              SetFormProperty('occursOn', 1, 'event')
              if (
                formInfo?.logisticsInfo?.event?.startDate &&
                formInfo?.logisticsInfo?.event?.occursOn
              ) {
                const newDate = dayjs(
                  findNextDayOfMonth(
                    formInfo?.logisticsInfo?.event?.startDate,
                    formInfo?.logisticsInfo?.event?.occursOn,
                  ),
                ).format('MM/DD/YYYY')
                setFocusedIndex([])
                SetFormProperty('startDate', newDate, 'event')
                generateFutureDatesAndSetOccurrences(newDate)
              }
              setIsCheck(true)
            }

            if (value === 'monthly') {
              if (!formInfo?.logisticsInfo?.event?.order) {
                SetFormProperty('order', 'first', 'event')
              }
              if (!formInfo?.logisticsInfo?.event?.days) {
                SetFormProperty('days', 'monday', 'event')
              }

              SetFormProperty('occursOn', 1, 'event')
              if (
                formInfo?.logisticsInfo?.event?.startDate &&
                formInfo?.logisticsInfo?.event?.occursOn
              ) {
                const newDate = dayjs(
                  findDateOfMonth(
                    formInfo?.logisticsInfo?.event?.startDate,
                    formInfo?.logisticsInfo?.event?.occursOn,
                  ),
                ).format('MM/DD/YYYY')
                setFocusedIndex([])
                SetFormProperty('startDate', newDate, 'event')
                generateFutureDatesAndSetOccurrences(newDate)
              }
              setIsCheck(true)
            }
            setTimeout(() => setRender((r) => !r), 100)
          }}
          value={formInfo?.logisticsInfo?.event?.recurres}
          label=""
          items={data.intervals}
          error={formInfo?.logisticsInfo?.checkFields && !formInfo?.logisticsInfo?.event?.recurres}
        />
      </Row>
      {formInfo?.logisticsInfo?.event?.recurres &&
        formInfo?.logisticsInfo?.event?.recurres !== 'custom' && (
          <Row>
            <Row>
              <Label minWidth={'209px'}>Repeat this many times...</Label>
              <Input
                type="number"
                error={
                  formInfo?.logisticsInfo?.checkFields &&
                  formInfo?.logisticsInfo?.event?.recurres &&
                  !formInfo?.logisticsInfo?.event?.recurrencAmount
                }
                onChange={(value) => {
                  SetFormProperty('recurrencAmount', value, 'event')
                  !formInfo?.logisticsInfo?.event?.occurrences &&
                    SetFormProperty('occurrences', [], 'event')
                  generateFutureDatesAndSetOccurrences()
                  if (value === 0) {
                    selectedDay(() => [])
                  }
                }}
                value={formInfo?.logisticsInfo?.event?.recurrencAmount}
              />
            </Row>
          </Row>
        )}
      {formInfo?.logisticsInfo?.event?.recurres &&
        formInfo?.logisticsInfo?.event?.recurres === 'custom' && (
          <Row>
            <Row style={{ alignItems: 'flex-start' }}>
              <Label minWidth={'209px'}>Quick dates selection:</Label>
              <MyDateCalendar
                select={select}
                selectDays={selectDays}
                SetOccurrences={SetOccurrences}
                SetFormProperty={SetFormProperty}
                formInfo={formInfo}
                occurrences={occurrences}
                event={formInfo?.logisticsInfo?.event}
              />
            </Row>
          </Row>
        )}
      {formInfo?.logisticsInfo?.event?.recurres !== 'custom' &&
        formInfo?.logisticsInfo?.event?.recurres !== 'daily' &&
        formInfo?.logisticsInfo?.event?.recurres && (
          <RowHolder>
            <Row>
              <Label>
                {formInfo?.logisticsInfo?.event?.recurres === 'monthly'
                  ? 'Occurs on:'
                  : 'Occurs each week on:'}
              </Label>
              <Radio
                checked={isCheck}
                onChange={() => {
                  SetFormProperty(
                    'recurrencAmount',
                    formInfo?.logisticsInfo?.event?.recurrencAmount,
                    'event',
                  )
                  if (formInfo?.logisticsInfo?.event?.recurres === 'weekly') {
                    const newDate = dayjs(
                      findNextDayOfMonth(
                        formInfo?.logisticsInfo?.event?.startDate,
                        formInfo?.logisticsInfo?.event?.occursOn,
                      ),
                    ).format('MM/DD/YYYY')
                    SetFormProperty('startDate', newDate, 'event')
                    setFocusedIndex([])
                  }
                  if (formInfo?.logisticsInfo?.event?.recurres === 'monthly') {
                    const newDate = dayjs(
                      findDateOfMonth(
                        formInfo?.logisticsInfo?.event?.startDate,
                        formInfo?.logisticsInfo?.event?.occursOn,
                      ),
                    ).format('MM/DD/YYYY')
                    SetFormProperty('startDate', newDate, 'event')
                    setFocusedIndex([])
                  }

                  setTimeout(() => setRender((r) => !r), 100)
                  setIsCheck(true)
                  generateFutureDatesAndSetOccurrences(formInfo?.logisticsInfo?.event?.startDate)
                }}
              />
              {formInfo?.logisticsInfo?.event?.recurres === 'monthly' && (
                <Input
                  type="number"
                  style={{ width: '150px' }}
                  maxValue={formInfo?.logisticsInfo?.event?.recurres === 'monthly' ? 31 : 7}
                  onChange={(value) => {
                    SetFormProperty('occursOn', value, 'event')
                    if (isCheck) {
                      if (formInfo?.logisticsInfo?.event?.recurres === 'weekly') {
                        const newDate = dayjs(
                          findNextDayOfMonth(formInfo?.logisticsInfo?.event?.startDate, value),
                        ).format('MM/DD/YYYY')
                        SetFormProperty('startDate', newDate, 'event')
                        generateFutureDatesAndSetOccurrences(newDate)
                        setFocusedIndex([])
                      }
                      if (formInfo?.logisticsInfo?.event?.recurres === 'monthly') {
                        const newDate = dayjs(
                          findDateOfMonth(formInfo?.logisticsInfo?.event?.startDate, value),
                        ).format('MM/DD/YYYY')
                        SetFormProperty('startDate', newDate, 'event')
                        generateFutureDatesAndSetOccurrences(newDate)
                        setFocusedIndex([])
                      }
                    }
                  }}
                  value={formInfo?.logisticsInfo?.event?.occursOn}
                />
              )}
              {formInfo?.logisticsInfo?.event?.recurres === 'weekly' && (
                <Selector
                  style={{ width: '150px' }}
                  onChange={(value) => {
                    SetFormProperty('occursOn', value, 'event')
                    if (isCheck) {
                      if (formInfo?.logisticsInfo?.event?.startDate) {
                        const newDate = dayjs(
                          findNextDayOfMonth(formInfo?.logisticsInfo?.event?.startDate, value),
                        ).format('MM/DD/YYYY')
                        SetFormProperty('startDate', newDate, 'event')
                        generateFutureDatesAndSetOccurrences(newDate)
                      }
                      setFocusedIndex([])
                    }
                  }}
                  value={formInfo?.logisticsInfo?.event?.occursOn}
                  label=""
                  items={data.weekly}
                />
              )}
              <Label>
                {formInfo?.logisticsInfo?.event?.recurres === 'monthly'
                  ? 'day'
                  : formInfo?.logisticsInfo?.event?.occursOn > '1'
                  ? ''
                  : ''}{' '}
                {formInfo?.logisticsInfo?.event?.recurres === 'weekly' ? '' : ' of the month'}
              </Label>
            </Row>
          </RowHolder>
        )}
      {formInfo?.logisticsInfo?.event?.recurres === 'monthly' && (
        <RowHolder>
          <Row style={{ marginLeft: '110px' }}>
            <Radio
              checked={!isCheck}
              onChange={() => {
                setIsCheck(false)
                if (!formInfo?.logisticsInfo?.event?.order) {
                  SetFormProperty('order', 'first', 'event')
                }
                if (!formInfo?.logisticsInfo?.event?.days) {
                  SetFormProperty('days', 'monday', 'event')
                }

                if (
                  !formInfo?.logisticsInfo?.event?.order ||
                  !formInfo?.logisticsInfo?.event?.days
                ) {
                  setTimeout(() => {
                    const newDate = dayjs(
                      findDayByOrderAndDayOfWeek(
                        formInfo?.logisticsInfo?.event?.startDate,
                        formInfo?.logisticsInfo?.event?.order,
                        formInfo?.logisticsInfo?.event?.days,
                      ),
                    ).format('MM/DD/YYYY')
                    SetFormProperty('startDate', newDate, 'event')
                    generateFutureDatesAndSetOccurrences(newDate)
                    logger.log('Intial Date', newDate)
                  }, 110)
                }

                if (
                  formInfo?.logisticsInfo?.event?.startDate &&
                  formInfo?.logisticsInfo?.event?.order &&
                  formInfo?.logisticsInfo?.event?.days
                ) {
                  const newDate = dayjs(
                    findDayByOrderAndDayOfWeek(
                      formInfo?.logisticsInfo?.event?.startDate,
                      formInfo?.logisticsInfo?.event?.order,
                      formInfo?.logisticsInfo?.event?.days,
                    ),
                  ).format('MM/DD/YYYY')
                  SetFormProperty('startDate', newDate, 'event')
                  generateFutureDatesAndSetOccurrences(newDate)
                }
                setTimeout(() => setRender((r) => !r), 100)

                setFocusedIndex([])
              }}
            />
            <Selector
              onChange={(value) => {
                SetFormProperty('order', value, 'event')

                if (
                  formInfo?.logisticsInfo?.event?.days &&
                  formInfo?.logisticsInfo?.event?.order &&
                  !isCheck
                ) {
                  const newDate = dayjs(
                    findDayByOrderAndDayOfWeek(
                      formInfo?.logisticsInfo?.event?.startDate,
                      value,
                      formInfo?.logisticsInfo?.event?.days,
                    ),
                  ).format('MM/DD/YYYY')
                  SetFormProperty('startDate', newDate, 'event')
                  generateFutureDatesAndSetOccurrences(newDate)
                  setFocusedIndex([])
                }
              }}
              value={
                formInfo?.logisticsInfo?.event?.order
                  ? formInfo?.logisticsInfo?.event?.order
                  : data.order[0].value
              }
              label=""
              items={data.order}
            />
            <Selector
              onChange={(value) => {
                SetFormProperty('days', value, 'event')

                if (
                  formInfo?.logisticsInfo?.event?.days &&
                  formInfo?.logisticsInfo?.event?.order &&
                  !isCheck
                ) {
                  const newDate = dayjs(
                    findDayByOrderAndDayOfWeek(
                      formInfo?.logisticsInfo?.event?.startDate,
                      formInfo?.logisticsInfo?.event?.order,
                      value,
                    ),
                  ).format('MM/DD/YYYY')
                  SetFormProperty('startDate', newDate, 'event')
                  generateFutureDatesAndSetOccurrences(newDate)
                }
              }}
              value={
                formInfo?.logisticsInfo?.event?.days
                  ? formInfo?.logisticsInfo?.event?.days
                  : data.days[0].value
              }
              label=""
              items={data.days}
            />
            <Label>of the month</Label>
          </Row>
        </RowHolder>
      )}
      {formInfo?.logisticsInfo?.event?.recurres && (
        <OccurrenceInterval
          occurrences={occurrences}
          selectDays={selectDays}
          focusedIndex={focusedIndex}
          setFocusedIndex={setterFocusedIndex}
          setterOccurrence={setterOccurrence}
          formInfo={formInfo}
          SetFormProperty={SetFormProperty}
          event={formInfo?.logisticsInfo?.event}
          order={0}
          SetOccurrences={SetOccurrences}
        />
      )}
      {occurrences?.map((occurence, i) => {
        return (
          <Row>
            <Row style={{ flexDirection: 'column', gap: 0 }}>
              <OccurrenceTitleOccurrence>
                Occurrence {occurence}
                <CustomCloseIcon removeOccurrence={removeOccurrence} i={i} />
              </OccurrenceTitleOccurrence>
              <Hr />
              <Row>
                <Label>Start Date</Label>
                <DateInput
                  key={i}
                  onChange={(value) => {
                    SetOccurrences(value, 'startDate', occurence - 1)

                    if (
                      !focusedIndex?.includes(occurence - 1) &&
                      formInfo?.logisticsInfo?.event?.recurres !== 'custom'
                    ) {
                      setterFocusedIndex(occurence - 1)
                    }
                  }}
                  value={formInfo?.logisticsInfo?.event?.occurrences[occurence - 1]?.startDate}
                />
                <Label>Start Time</Label>
                <TimeInput
                  key={i}
                  onChange={(value) => {
                    SetOccurrences(value, 'startTime', occurence - 1)
                    if (
                      !focusedIndex?.includes(occurence - 1) &&
                      formInfo?.logisticsInfo?.event?.recurres !== 'custom'
                    ) {
                      setterFocusedIndex(occurence - 1)
                    }
                  }}
                  value={
                    formInfo?.logisticsInfo?.event?.occurrences[occurence - 1]?.startTime
                      ? formInfo?.logisticsInfo?.event?.occurrences[occurence - 1]?.startTime
                      : formInfo?.logisticsInfo?.event?.startTime
                  }
                />
                <Checkbox /> <Label>Skip this event:</Label>
              </Row>
            </Row>
          </Row>
        )
      })}
      <Row></Row>
    </ReoccurringHolder>
  )
}

export default Reoccurring

/* eslint-disable no-unused-vars */
import './AllArchived.css'
import NavbarOutlinedButton from '../NavbarOutlinedButton/NavbarOutlinedButton'
import TableComponent from '../TableComponent/TableComponent'
import { useEffect, useState } from 'react'
import SearchComponent from '../SearchComponent/SearchComponent'
import { TemplatesFilterArea, FilterText } from '../AllArchived/AllArchived.styled'

const AllArchived = ({ archives, _token, _setArchives }) => {
  const [_rowsPerPageFromChild, setRowsPerPageFromChild] = useState(5)
  const [_pageFromChild, setPageFromChild] = useState(0)
  const [filteredData, setFilteredData] = useState([])
  const [visibleRows, setVisibleRows] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setFilteredData(archives)
  }, [archives])

  return (
    <div className="allArchivedMainContentArea">
      <div className="allArchivedTextandButtonsArea">
        <div className="allArchivedTextArea">
          <div className="allArchivedSectionMainText">All Archived Gatherings</div>
          <div className="allArchivedSectionSmallText">
            Showing <span style={{ fontWeight: '700' }}>{visibleRows?.length}</span> of{' '}
            {archives.length} Archived
          </div>
        </div>

        <div className="allArchivedButtonArea">
          <NavbarOutlinedButton
            buttonText={'Filters'}
            iconName={open ? 'horizontal line' : 'filter'}
            onClickFunction={() => setOpen((o) => !o)}
          ></NavbarOutlinedButton>
          <NavbarOutlinedButton buttonText={'Options'} iconName={'pencil'}></NavbarOutlinedButton>
        </div>
      </div>

      <TemplatesFilterArea open={open}>
        <FilterText>Filter by archive title:</FilterText>
        <div>
          <SearchComponent
            searchData={filteredData}
            sourceOfTruth={archives}
            setSearchData={setFilteredData}
            setFilteredData={setFilteredData}
            draftFilter
            loading={archives.length === 0}
          ></SearchComponent>
        </div>
      </TemplatesFilterArea>

      <TableComponent
        tableBodyData={filteredData}
        tableDataVariant={'Archived'}
        rowsPerPageArray={[5, 10, 25]}
        setVisibleRows={setVisibleRows}
        headerDataVariant={'Archived'}
        rowsPerPageParentSetter={setRowsPerPageFromChild}
        parrentPageSetter={setPageFromChild}
      ></TableComponent>
      <div className="allArchivedFooterText">
        <div>©2023 Church.Ai. All Rights Reserved.</div>
      </div>
    </div>
  )
}

export default AllArchived

import { useAuth } from '../../hooks/useAuth'
import { MainContainer } from '../../util/ScreenWrapper.style'
import { ComponentArea } from '../GatheringsCreation/GatheringsCreationScreen.style'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getGatheringById } from '../../util/gatherings'
import { useEvents } from '../../hooks/useEvents'

const ApprovalRedirectScreen = () => {
  const { id } = useParams()
  const { token } = useAuth()
  const { profileData } = useEvents()
  const userId = profileData.uid
  const navigate = useNavigate()
  const [screenMessage, setScreenMessage] = useState('You are being redirected...')

  async function checkGatheringAndNavigate(id, token) {
    const gathering = await getGatheringById(id, token)
    if (
      gathering.data.status === 'draft' &&
      gathering.data?.approval?.selectedApprovers?.individual?.uid === userId
    ) {
      navigate('/createGathering', { state: { id: id, token: token } })
    } else {
      if (gathering.data?.approval?.selectedApprovers?.individual?.uid !== userId) {
        setScreenMessage('You are not allowed to approve this gathering. Redirecting...')
        setTimeout(() => {
          navigate('/')
        }, [5000])
      } else if (gathering.data?.approval?.approved || gathering.data?.status !== 'draft') {
        setScreenMessage('This Gathering was already approved or published. Redirecting...')
        setTimeout(() => {
          navigate('/')
        }, [5000])
      }
    }
  }

  useEffect(() => {
    if (id && token && id.length > 5 && userId) {
      checkGatheringAndNavigate(id, token)
    }
  }, [id, token, userId])

  return (
    <MainContainer style={{ height: '100vh' }}>
      <ComponentArea style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        {screenMessage}
      </ComponentArea>
    </MainContainer>
  )
}

export default ApprovalRedirectScreen

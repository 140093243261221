import CircularProgress from '@mui/material/CircularProgress'
import color from '../../util/colors'
import styled from 'styled-components'

export const Loading = styled(CircularProgress)`
  svg {
    color: ${color.primary};
  }
  max-width: 32px;
  max-height: 32px;
`

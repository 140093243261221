import styled from 'styled-components'
import color from '../../util/colors'

const TemplatePreviewMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TemplatePreviewContentArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--backgroundSecondary);
  box-sizing: border-box;
  padding: 2rem 3rem 2rem 3rem;
`

const HeadlineTextAndButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 20px;
  }
`

const HeadlineText = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: var(--primary);
`

const HeadlineButtonWrapper = styled.div`
  width: max-content;
  display: flex;
  column-gap: 25px;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;
  }
`

const HeadlineSubInfo = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.004em;
  color: var(--title);
  column-gap: 20px;
  padding: 2rem 0 2rem 0;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    display: flex;
  }
`

const subInfoIconStyle = {
  color: color.message,
  fontSize: 30,
}

const TemplatePreviewMainContentArea = styled.div`
  display: flex;
  width: 100%;
  column-gap: 3%;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 30px;
    column-gap: 0;
  }
`
const MainContentLeft = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const MainContentRight = styled.div`
  min-width: 290px;
  width: 23%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const EventDetailsPreviewArea = styled.div`
  display: flex;
  width: 100%;
  min-height: 178px;
  border-radius: 8px;
  background-color: var(--backgroundPrimary);
  box-shadow: 0px 16px 31px -27px var(--imageComponentBorderShadow);
  z-index: 100;
  align-items: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`
const GatheringName = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  color: var(--title);
`
const GatheringData = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--description);
  padding-top: 1rem;
  align-items: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    justify-content: center;
  }
`
const ImageDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 1.5rem;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
`

const ImagePreviewSection = styled.div`
  display: flex;
  width: 50%;
  max-height: 195px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 40px;
  background-color: var(--backgroundPrimary);
  @media (max-width: 1200px) {
    justify-content: center;
    margin-right: 0;
    padding-bottom: 30px;
  }
`
const Image = styled.img`
  height: 150px;
  object-fit: contain;
  @media (max-width: 480px) {
    height: 100px;
  }
`

const detailsIcon = {
  color: color.primary,
  paddingRight: '1rem',
}

const stockImageIcon = {
  color: color.fieldColor,
  fontSize: 60,
}

const EventDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 2rem;
  color: var(--previewDescriptionText);
  row-gap: 20px;
  background-color: var(--filterBackground);
  border-radius: 5px;
`
const EventDescriptionMainText = styled.div`
  display: flex;
  width: 100%;
`

const EventDescriptionSubText = styled.div`
  display: flex;
  width: 100%;
  column-gap: 5px;
`
const SubTextHighlight = styled.span`
  color: var(--primary);
`

const RightHeadlineText = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.0152em;
  text-transform: uppercase;
  color: var(--title);
  padding-bottom: 1rem;
`

const HighlightsBox = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 180px;
  background-color: var(--backgroundPrimary);
  box-sizing: border-box;
  padding: 1.5rem;
  border-top: 3px solid;
  ${(props) => props.borderColor && `border-color: ${props.borderColor};`}
`

const IconAndTextBoxTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  color: var(--title);
  column-gap: 10px;
`
const boxIconStyle = {
  color: color.primary,
}

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 30px;
`
export {
  TemplatePreviewMainContainer,
  TemplatePreviewContentArea,
  HeadlineTextAndButtonRow,
  HeadlineText,
  HeadlineButtonWrapper,
  HeadlineSubInfo,
  subInfoIconStyle,
  TemplatePreviewMainContentArea,
  MainContentLeft,
  MainContentRight,
  EventDetailsPreviewArea,
  GatheringName,
  GatheringData,
  ImageDetailsSection,
  ImagePreviewSection,
  Image,
  detailsIcon,
  stockImageIcon,
  EventDescriptionContainer,
  EventDescriptionMainText,
  EventDescriptionSubText,
  SubTextHighlight,
  RightHeadlineText,
  HighlightsBox,
  IconAndTextBoxTitle,
  boxIconStyle,
  TagWrapper,
}

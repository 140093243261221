import styled from 'styled-components'
import color from '../../../util/colors'
const ImageUploadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const ImageUploadSubText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--title);
  padding: 2rem 0 2rem 0;
`

const PreviewArea = styled.div`
  display: flex;
  width: 100%;
  min-height: 178px;
  border: 1px solid var(--borderLine);
  align-items: center;
  border-radius: 8px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const ImageDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 1.5rem;
  box-sizing: border-box;
`

const GatheringName = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  color: var(--title);
`
const GatheringData = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--description);
  padding-top: 1rem;
  align-items: center;
`

const ImagePreviewSection = styled.div`
  display: flex;
  width: 50%;
  height: inherit;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundSecondary);
  @media (max-width: 768px) {
    width: 100%;
  }
`

const detailsIcon = {
  color: color.primary,
  paddingRight: '1rem',
}

const stockImageIcon = {
  color: color.fieldColor,
  fontSize: 60,
}

const Image = styled.img`
  height: 180px;
  object-fit: contain;
  @media (max-width: 480px) {
    height: 100px;
  }
`

export {
  ImageUploadContainer,
  PreviewArea,
  ImageUploadSubText,
  ImageDetailsSection,
  GatheringName,
  GatheringData,
  ImagePreviewSection,
  detailsIcon,
  stockImageIcon,
  Image,
}

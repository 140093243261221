import React, { useState, useRef } from 'react'
import {
  RetrievedFieldsHolder,
  Field,
  FieldHeader,
  FieldLabel,
  FieldBody,
  DefualtField,
} from './Ticket.styles'
import { Label, Row } from './Payments.styles'
import CloseIcon from '@mui/icons-material/Close'

import Dropdown from '../../Form/components/Dropdown'
import Input from '../../Form/components/Input'
import ExitInput from '../../Form/components/ExitInput'
import { PromptFieldCloseIcon, PromptFieldCloseIconLabel } from './PromptField.styles'

function RetrievedFields({
  ticketInfo,
  SetFields,
  RemoveField,
  SetFieldsValues,
  RemoveFieldsValues,
  setOverflowOff,
  order,
  setPaymentsInfo,
  popuStatus,
  setPopuStatus,
  setRequired,
  setChecked,
}) {
  const [showInput, setShowInput] = useState(false)
  const [clickedFieldIndex, setClickedFieldIndex] = useState(null)
  const inputValue = useRef('')
  const [selectedRadio, setSelectedRadio] = useState(null)
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({})

  const fields = ticketInfo?.fields?.length
    ? Array(parseInt(ticketInfo?.fields?.length)).fill('*')
    : []

  const fieldsList = [
    { value: 'New Question', label: 'Add Question' },
    { value: 'prompt', label: 'Add Prompt' },
  ]

  const fieldsQuestionList = [
    { value: 'Checkbox', label: 'Checkbox' },
    { value: 'Text', label: 'Text' },
    { value: 'Select', label: 'Select' },
    { value: 'Number', label: 'Number' },
  ]

  const DefaultFields = ['Name', 'Birthdate', 'Email', 'Phone', 'Gender']

  const handleClick = (index) => {
    setClickedFieldIndex(index)
    setShowInput(true)
  }

  const handleBlur = () => {
    setClickedFieldIndex(null)
    setShowInput(false)
  }

  const handleInputChange = (value) => {
    inputValue.current = value
  }

  const handleInputBlur = (index) => {
    SetFields(inputValue.current, 'prompt', order, index)
    handleBlur()
  }

  const onDuplicate = (order, fieldOrder, valueOrder) => {
    setPaymentsInfo((prevInfo) => {
      const info = JSON.parse(JSON.stringify(prevInfo))
      const duplicatedValue = { ...info.tickets[order].fields[fieldOrder].values[valueOrder] }

      info.tickets[order].fields[fieldOrder].values.splice(valueOrder + 1, 0, duplicatedValue)
      return info
    })
  }

  const PromptField = ({ index }) => {
    if (showInput && clickedFieldIndex === index) {
      return (
        <div style={{ position: 'relative' }}>
          <Input
            autoFocus={true}
            type="text"
            onBlur={() => handleInputBlur(index)}
            onChange={handleInputChange}
            value={ticketInfo?.fields[index]?.prompt}
          />
          <PromptFieldCloseIcon
            onMouseDown={(e) => {
              e.preventDefault()
              RemoveField(order, index)
            }}
          />
        </div>
      )
    } else {
      return (
        <DefualtField onClick={() => handleClick(index)}>
          <FieldLabel>{ticketInfo?.fields[index]?.prompt || 'Click to edit the prompt'}</FieldLabel>
          <PromptFieldCloseIconLabel
            onClick={(e) => {
              e.stopPropagation()
              RemoveField(order, index)
            }}
          />
        </DefualtField>
      )
    }
  }

  return (
    <RetrievedFieldsHolder>
      <Row>
        <Label>Fields to be retrieved</Label>
        <Dropdown
          items={fieldsList}
          setOverflowOff={setOverflowOff}
          onChange={(value) =>
            SetFields(value.value, 'type', order, ticketInfo?.fields?.length ?? -1 + 1)
          }
          setPopuStatus={setPopuStatus}
          popuStatus={popuStatus}
        />
      </Row>
      {DefaultFields.map((value, i) => (
        <DefualtField key={i}>
          <FieldLabel>{value}</FieldLabel>
          <CloseIcon />
        </DefualtField>
      ))}
      {fields.map((value, index) => {
        if (ticketInfo?.fields[index]?.type === 'prompt') {
          return <PromptField index={index} />
        } else {
          return (
            <Field key={index}>
              <FieldHeader>
                <FieldLabel>{ticketInfo?.fields[index]?.type}</FieldLabel>
                <CloseIcon onClick={() => RemoveField(order, index)} />
              </FieldHeader>
              <FieldBody>
                <Input
                  type="text"
                  label="Click to edit the prompt"
                  onChange={(value) => {
                    SetFields(value, 'prompt', order, index)
                  }}
                  value={ticketInfo?.fields[index]?.prompt}
                />
                {ticketInfo?.fields[index]?.values?.length > 0 &&
                  ticketInfo?.fields[index]?.values.map((valueObject, valueIndex) => (
                    <ExitInput
                      object={valueObject}
                      type={valueObject.label}
                      key={valueIndex}
                      setOverflowOff={setOverflowOff}
                      onChange={(value, label) => {
                        SetFieldsValues(value, label, order, index, valueIndex)
                      }}
                      setChecked={(value) => setChecked(value, order, index, valueIndex)}
                      setRequiredSetter={(value) => setRequired(value, order, index, valueIndex)}
                      onExit={() => RemoveFieldsValues(order, index, valueIndex)}
                      value={valueObject.value}
                      isSelected={valueObject?.checked}
                      setIsSelected={() => setSelectedRadio(valueIndex)}
                      onRadioSelect={() => setSelectedRadio(valueIndex)}
                      selectedCheckboxes={selectedCheckboxes}
                      setSelectedCheckboxes={setSelectedCheckboxes}
                      index={valueIndex}
                      onboardingIndex={index}
                      onDuplicate={() => onDuplicate(order, index, valueIndex)}
                      setPopuStatus={setPopuStatus}
                      popuStatus={popuStatus}
                      setPaymentsInfo={setPaymentsInfo}
                      selectedRadio={selectedRadio}
                    />
                  ))}
                {(ticketInfo?.fields[index]?.type === 'Select' ||
                  ticketInfo?.fields[index]?.type === 'New Question') && (
                  <Row padding={true}>
                    <Label>Values</Label>
                    <Dropdown
                      items={fieldsQuestionList}
                      setOverflowOff={setOverflowOff}
                      onChange={(item) => {
                        SetFieldsValues(
                          item.value,
                          item.label,
                          order,
                          index,
                          ticketInfo?.fields[index]?.values?.length ?? 0,
                        )
                      }}
                      setPopuStatus={setPopuStatus}
                      popuStatus={popuStatus}
                    />
                  </Row>
                )}
              </FieldBody>
            </Field>
          )
        }
      })}
    </RetrievedFieldsHolder>
  )
}

export default RetrievedFields

import Sidebar from '../Sidebar/Sidebar'
import Header from '../Header/Header'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import './Layout.css'
const Layout = ({ children }) => {
  const location = useLocation()
  const { authenticated } = useAuth()

  const isTestingComponent = () => {
    return location.pathname.includes('components')
  }

  const shouldRender = () => {
    return !isTestingComponent() && authenticated
  }

  return (
    <>
      {shouldRender() && <Sidebar />}
      <div className="layout">
        {shouldRender() && <Header />}
        {children}
      </div>
    </>
  )
}

export default Layout

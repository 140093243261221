/* eslint-disable no-unused-vars */
import './Checklist.css'
import { useState, useEffect, useRef } from 'react'
import ChecklistAccordion from './ChecklistAccordion'
import { checklistConfiguration, getAllTitlesAndIndexes } from './util/Checklist.util'
import { useFormGatherings } from '../../util/zustant/store'

const Checklist = () => {
  const { formInfo, setFormInfo } = useFormGatherings()
  const { activeStep, highestStep } = formInfo
  const checkinMethod = formInfo?.checkinType?.checkinMethod
  const openGathering = formInfo?.attendees?.openGathering
  const paidEvent = formInfo?.paidEvent?.eventIsPaid
  const TITLE_HEIGHT_PX = 50 + 15
  const TITLE_PADDING_BOTTOM = 20
  const SUBTITLE_HEIGHT_PX = 36
  const [progressBarValue, setProgressBarValue] = useState(130)
  const checklistDataPool = checklistConfiguration(paidEvent, checkinMethod, openGathering)
  const [acordionBoxStates, setAccordionBoxStates] = useState({
    box1state: false,
    box2state: false,
    box3state: false,
    box4state: false,
  })
  const [activeStepLabel, setActiveStepLabel] = useState(
    formInfo.activeStepLabel ? formInfo.activeStepLabel : {},
  )
  const progressBarRef = useRef(null)

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.activeStepLabel = activeStepLabel
    if (!newFormInfo?.highestStepLabel || activeStep >= highestStep) {
      newFormInfo.highestStepLabel = activeStepLabel
    }
    setFormInfo(newFormInfo)
  }, [activeStepLabel])

  //This here checks the if the highestStepLabel is the one that should be as the highest Step and tracks the template change
  useEffect(() => {
    if (checklistDataPool && formInfo?.highestStepLabel && formInfo) {
      const checklistSteps = getAllTitlesAndIndexes(checklistDataPool)
      const stepIndex = checklistSteps.find((s) => s.title === formInfo?.highestStepLabel)?.index
      if (formInfo?.highestStep < stepIndex) {
        let newFormInfo = formInfo
        newFormInfo.highestStep = stepIndex
        setFormInfo(newFormInfo)
      }
    }
  }, [formInfo?.highestStepLabel, checklistDataPool, formInfo])

  //Setting the active label regarding the active step
  useEffect(() => {
    const checklistSteps = getAllTitlesAndIndexes(checklistDataPool)
    const stepLabel = checklistSteps.find((s) => s.index === formInfo?.activeStep)?.title
    setActiveStepLabel(stepLabel)
  }, [formInfo?.activeStep])

  useEffect(() => {
    const allSteps = []
    for (let mainBlockIndex = 0; mainBlockIndex < checklistDataPool.length; mainBlockIndex++) {
      const steps = []

      const stepsStack = [...checklistDataPool[mainBlockIndex].data].reverse()
      while (stepsStack.length > 0) {
        const currentElement = stepsStack.pop()
        steps.push(currentElement)

        if (Array.isArray(currentElement.subData)) {
          const reversedCopy = [...currentElement.subData].reverse()
          stepsStack.push(...reversedCopy)
        }
      }
      allSteps.push(...steps.map((step) => ({ ...step, blockIndex: mainBlockIndex })))
    }

    const currentActiveStepInfo = allSteps[activeStep - 1]
    const currentActiveBlockIndex = currentActiveStepInfo?.blockIndex

    let currentProgresBarHeight = 0

    for (let i = 0; i < currentActiveBlockIndex; i++) {
      const blockKey = `box${i + 1}state`
      const isBlockClosed = acordionBoxStates[blockKey]

      currentProgresBarHeight += TITLE_HEIGHT_PX

      if (!isBlockClosed) {
        const allStepsIsBlock = allSteps.filter((step) => step?.blockIndex === i)
        currentProgresBarHeight += allStepsIsBlock.length * SUBTITLE_HEIGHT_PX
        currentProgresBarHeight += TITLE_PADDING_BOTTOM
      }
    }

    const activeBlockKey = `box${currentActiveBlockIndex + 1}state`
    const isActiveBlockClosed = acordionBoxStates[activeBlockKey]
    currentProgresBarHeight += TITLE_HEIGHT_PX
    if (!isActiveBlockClosed) {
      const allStepsIsBlock = allSteps.filter(
        (step) => step?.blockIndex === currentActiveBlockIndex,
      )
      const stepsUntilCurrentActiveIndex = allStepsIsBlock.filter(
        (step) => step.index <= currentActiveStepInfo.index,
      )
      currentProgresBarHeight += stepsUntilCurrentActiveIndex.length * SUBTITLE_HEIGHT_PX
    }
    setProgressBarValue(currentProgresBarHeight)
  }, [activeStep, acordionBoxStates, checklistDataPool])

  return (
    <div className="checklistComponentMainContainer">
      <div className="checklistComponentMainText">Checklist</div>
      <div className="checklistComponentSectionsContainer">
        <div className="checklistComponentSelectorArea" ref={progressBarRef}>
          <div
            className="checklistComponentSelector"
            style={{ height: `${progressBarValue}px` }}
          ></div>
        </div>
        <div className="checklistComponentContentArea">
          {checklistDataPool.map((checklistItem, i) => {
            return (
              <ChecklistAccordion
                key={checklistItem.titleNumber}
                titleNumber={checklistItem.titleNumber}
                titleLabel={checklistItem.title}
                currentStep={formInfo?.activeStep}
                currentStepLabel={formInfo?.activeStepLabel}
                stepsArray={checklistItem.data}
                stepSetter={setFormInfo}
                setAccordionMenu={setAccordionBoxStates}
                highestStep={highestStep}
                formInfo={formInfo}
              ></ChecklistAccordion>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Checklist

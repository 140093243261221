import TableHead from '@mui/material/TableHead'
import { StyledHeaderTableRow, StyledHeaderTableCell } from './TableComponent.style'
import PropTypes from 'prop-types'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import tableHeaderData from '../../assets/tableHeaderData.json'

const TableComponentHead = (props) => {
  const { order, orderBy, onRequestSort, headerDataVariant } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const headerData = tableHeaderData[headerDataVariant]

  const sortFields = ['title', 'start', 'created', 'label']

  return (
    <TableHead>
      <StyledHeaderTableRow>
        {headerData.map((headCell) => {
          return (
            <StyledHeaderTableCell
              key={headCell.id}
              align={headCell.align}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {sortFields.includes(headCell.id) ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  <UnfoldMoreIcon />
                </div>
              ) : (
                headCell.label
              )}
            </StyledHeaderTableCell>
          )
        })}
      </StyledHeaderTableRow>
    </TableHead>
  )
}

TableComponentHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
}

export default TableComponentHead

import { useState, useEffect } from 'react'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { Row } from './Payments.styles.js'
import { Title } from '../../Form/FormElements.styles.jsx'
import TemplateButton from '../../TemplateButton/TemplateButton.jsx'
import Input from '../../Form/components/Input.jsx'
import Ticket from './Ticket.jsx'

const Payments = () => {
  const { setFormInfo, formInfo } = useFormGatherings()
  let Pi = formInfo?.paymentsInfo ? formInfo?.paymentsInfo : {}
  if (!Pi?.tickets) {
    Pi.tickets = []
  }
  const [paymentsInfo, setPaymentsInfo] = useState(Pi ? Pi : {})
  const [enable, setEnable] = useState(false)

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.paymentsInfo = paymentsInfo
    setFormInfo(newFormInfo)
  }, [JSON.stringify(paymentsInfo.tickets), paymentsInfo.limit])

  function SetTicketProperty(value, property, order) {
    setPaymentsInfo((info) => {
      info.tickets ??= []
      info.tickets[order] ??= {}
      info.tickets[order][property] = value
      return { ...info }
    })
  }

  function SetDiscountCode(value, property, order, codeOrder) {
    setPaymentsInfo((info) => {
      info.tickets[order].discountCodes ??= []
      info.tickets[order].discountCodes[codeOrder] ??= {}
      info.tickets[order].discountCodes[codeOrder][property] = value
      return { ...info }
    })
  }

  function SetFields(value, property, order, fieldOrder) {
    setPaymentsInfo((info) => {
      info.tickets ??= []
      info.tickets[order] ??= {}
      info.tickets[order].fields ??= []
      info.tickets[order].fields[fieldOrder] ??= {}
      info.tickets[order].fields[fieldOrder][property] = value
      return { ...info }
    })
  }

  function SetQuestions(value, property, order, fieldOrder) {
    setPaymentsInfo((info) => {
      info.tickets ??= []
      info.tickets[order] ??= {}
      info.tickets[order].questions ??= []
      info.tickets[order].questions[fieldOrder] ??= {}
      info.tickets[order].questions[fieldOrder][property] = value
      return { ...info }
    })
  }

  function SetFieldsValues(value, label, order, fieldOrder, valueOrder) {
    setPaymentsInfo((prevInfo) => {
      const info = JSON.parse(JSON.stringify(prevInfo)) // Create a deep copy of the state
      info.tickets = info.tickets ?? []
      info.tickets[order] = info.tickets[order] ?? {}
      info.tickets[order].fields = info.tickets[order].fields ?? []
      info.tickets[order].fields[fieldOrder] = info.tickets[order].fields[fieldOrder] ?? {}
      info.tickets[order].fields[fieldOrder].values =
        info.tickets[order].fields[fieldOrder].values ?? []

      // Set both the value and label within the same object
      info.tickets[order].fields[fieldOrder].values[valueOrder] = { value, label }

      return info
    })
  }

  function setRequired(value, order, fieldOrder, valueOrder) {
    setPaymentsInfo((prevInfo) => {
      const info = JSON.parse(JSON.stringify(prevInfo)) // Create a deep copy of the state
      info.tickets = info.tickets ?? []
      info.tickets[order] = info.tickets[order] ?? {}
      info.tickets[order].fields = info.tickets[order].fields ?? []
      info.tickets[order].fields[fieldOrder] = info.tickets[order].fields[fieldOrder] ?? {}
      info.tickets[order].fields[fieldOrder].values =
        info.tickets[order].fields[fieldOrder].values ?? []
      // Set both the value and label within the same object
      info.tickets[order].fields[fieldOrder].values[valueOrder].required = value
      return info
    })
  }

  function setChecked(value, order, fieldOrder, valueOrder) {
    setPaymentsInfo((prevInfo) => {
      const info = JSON.parse(JSON.stringify(prevInfo)) // Create a deep copy of the state
      info.tickets = info.tickets ?? []
      info.tickets[order] = info.tickets[order] ?? {}
      info.tickets[order].fields = info.tickets[order].fields ?? []
      info.tickets[order].fields[fieldOrder] = info.tickets[order].fields[fieldOrder] ?? {}
      info.tickets[order].fields[fieldOrder].values =
        info.tickets[order].fields[fieldOrder].values ?? []
      // Set both the value and label within the same object
      info.tickets[order].fields[fieldOrder].values[valueOrder].checked = value
      return info
    })
  }

  function SetQuestionsValues(value, order, fieldOrder, valueOrder) {
    setPaymentsInfo((info) => {
      info.tickets ??= []
      info.tickets[order] ??= {}
      info.tickets[order].questions ??= []
      info.tickets[order].questions[fieldOrder] ??= {}
      info.tickets[order].questions[fieldOrder].values ??= []
      info.tickets[order].questions[fieldOrder].values[valueOrder] = value
      return { ...info }
    })
  }

  function RemoveFieldsValues(order, fieldOrder, indexToRemove) {
    setPaymentsInfo((info) => {
      info.tickets[order].fields[fieldOrder].values = info.tickets[order].fields[
        fieldOrder
      ].values.filter((_, index) => index !== indexToRemove)
      return { ...info }
    })
  }

  function RemoveQuestionsValues(order, fieldOrder, indexToRemove) {
    setPaymentsInfo((info) => {
      info.tickets[order].questions[fieldOrder].values = info.tickets[order].questions[
        fieldOrder
      ].values.filter((_, index) => index !== indexToRemove)
      return { ...info }
    })
  }

  function RemoveField(order, indexToRemove) {
    setPaymentsInfo((info) => {
      info.tickets[order].fields = info.tickets[order].fields.filter(
        (_, index) => index !== indexToRemove,
      )
      return { ...info }
    })
  }

  function RemoveTicket(indexToRemove) {
    setPaymentsInfo((info) => {
      info.tickets[indexToRemove] = {}
      info.tickets = info.tickets.filter((_, index) => index !== indexToRemove)
      return { ...info }
    })
  }

  function RemoveQuestion(order, indexToRemove) {
    setPaymentsInfo((info) => {
      info.tickets[order].questions = info.tickets[order].questions.filter(
        (_, index) => index !== indexToRemove,
      )
      return { ...info }
    })
  }

  function SetFormProperty(property, value, parrentProperty) {
    setPaymentsInfo((info) => {
      if (parrentProperty) {
        info[parrentProperty] = { ...info[parrentProperty] }
        info[parrentProperty][property] = value
      } else {
        info[property] = value
      }
      return { ...info }
    })
  }

  function SetSponsorship(value, order) {
    setPaymentsInfo((info) => {
      info.tickets[order].sponsorship = value
      return { ...info }
    })
  }

  function createTicket() {
    const limit = parseInt(formInfo?.paymentsInfo?.limit)
    const currentTickets = paymentsInfo?.tickets?.length || 0

    if (
      paymentsInfo.tickets?.length === undefined ||
      (formInfo?.paymentsInfo?.tickets?.length === 0 && limit !== 0)
    ) {
      const numberOfTicketsToAdd = limit - currentTickets
      for (let i = 0; i < numberOfTicketsToAdd; i++) {
        SetTicketProperty(currentTickets + i + 1, 'uid', currentTickets + i)
      }
    } else if (formInfo?.paymentsInfo?.tickets?.length < limit || isNaN(limit)) {
      const numberOfTicketsToAdd = limit - currentTickets
      for (let i = 0; i < numberOfTicketsToAdd; i++) {
        SetTicketProperty(currentTickets + i + 1, 'uid', currentTickets + i)
      }
    }
  }

  useEffect(() => {
    if (
      formInfo?.paymentsInfo?.tickets?.length === undefined ||
      (formInfo?.paymentsInfo?.tickets?.length === 0 &&
        parseInt(formInfo?.paymentsInfo?.limit) !== 0)
    ) {
      setEnable(true)
    } else if (
      formInfo?.paymentsInfo?.tickets?.length < parseInt(formInfo?.paymentsInfo?.limit) ||
      isNaN(parseInt(formInfo?.paymentsInfo?.limit))
    ) {
      setEnable(true)
    } else {
      setEnable(false)
    }

    if (!formInfo?.paymentsInfo?.limit || parseInt(formInfo?.paymentsInfo?.limit) === 0) {
      setEnable(false)
    }
  }, [paymentsInfo])

  const handleNumbericValues = (event) => {
    const inputValue = event
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '') // Remove non-numeric characters
    SetFormProperty('limit', sanitizedValue)
  }

  return (
    <>
      <Row>
        <Title>How many types of tickets do you want to create?</Title>
      </Row>
      <Row>
        <Input
          onChange={(value) => {
            handleNumbericValues(value)
          }}
          error={
            formInfo?.highestStep !== formInfo?.activeStep &&
            (!formInfo?.paymentsInfo?.limit || formInfo?.paymentsInfo?.limit === '')
          }
          value={paymentsInfo?.limit || ''}
          label="Limit"
        />
      </Row>
      <Row justify="space-between">
        <Title>Tickets</Title>

        <TemplateButton
          onClick={createTicket}
          variant={'add'}
          label={'Add New Ticket'}
          disabled={!enable}
        ></TemplateButton>
      </Row>
      {paymentsInfo?.tickets?.length > 0 &&
        paymentsInfo?.tickets.map((v, i) => (
          <Ticket
            formInfo={formInfo}
            key={v.uid}
            order={i}
            SetTicketProperty={SetTicketProperty}
            SetFields={SetFields}
            SetDiscountCode={SetDiscountCode}
            RemoveField={RemoveField}
            RemoveFieldsValues={RemoveFieldsValues}
            SetFieldsValues={SetFieldsValues}
            ticketInfo={paymentsInfo?.tickets ? paymentsInfo?.tickets[i] : {}}
            SetQuestions={SetQuestions}
            SetQuestionsValues={SetQuestionsValues}
            RemoveQuestionsValues={RemoveQuestionsValues}
            RemoveQuestion={RemoveQuestion}
            RemoveTicket={RemoveTicket}
            RegistrationLimit={formInfo?.logisticsInfo?.registration_limit}
            setPaymentsInfo={setPaymentsInfo}
            setChecked={setChecked}
            setRequired={setRequired}
            setSponsorship={SetSponsorship}
          />
        ))}
    </>
  )
}

export default Payments

import color from '../../util/colors'

const checkbox = {
  '&.MuiCheckbox-root': {
    color: color.description,
  },
  '&.Mui-checked': {
    color: color.primary,
  },
}

export { checkbox }

import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  StyledTableRow,
  StyledTableCell,
  StyledTableCellButton,
} from './ArchivedDraftTableCard.style'
import { tableRowColor } from '../../util/helpers'
import { convertDateString, convertSecondsToDateString } from '../../util/date'
import { useEvents } from '../../hooks/useEvents'
import RestorePageIcon from '@mui/icons-material/RestorePage'
import { useAuth } from '../../hooks/useAuth'
import { updateGathering } from '../../util/gatherings'
import { CircularProgress } from '@mui/material'

const ArchivedDraftTableCard = ({ archivedDraftData, indexPosition }) => {
  const [highlighted, setHighlighted] = useState(false)
  const { allCollections, setAllCollections } = useEvents()
  const [archivedBy, setArchivedBy] = useState('')
  const { token } = useAuth()
  const [loading, setLoading] = useState('')

  //Finding the user name of the archiver
  useEffect(() => {
    if (archivedDraftData.archivedBy === 'automation') {
      setArchivedBy('Automation')
    } else if (archivedDraftData.archivedBy && allCollections.user && allCollections.member) {
      const allUsers = allCollections.user.concat(allCollections.member)
      const archived = allUsers.find((u) => u.uid === archivedDraftData.archivedBy)
      setArchivedBy(archived?.displayName || archived?.email || 'Unknown user')
    }
  }, [allCollections, archivedDraftData])

  const renderArchivedDate = () => {
    if (archivedDraftData.dateArchived?._seconds) {
      return convertDateString(convertSecondsToDateString(archivedDraftData.dateArchived?._seconds))
    } else {
      return convertDateString(archivedDraftData.dateArchived)
    }
  }

  return (
    <StyledTableRow
      hover
      onClick={() => setHighlighted(!highlighted)}
      sx={{
        backgroundColor: tableRowColor(indexPosition),
        borderLeft: `5px solid ${tableRowColor(indexPosition)}`,
      }}
    >
      <StyledTableCell variant="body">
        <div>{archivedBy}</div>
      </StyledTableCell>
      <StyledTableCell variant="body">
        <div>{archivedDraftData.gatheringDetails?.title}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>{renderArchivedDate()}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>
          {archivedDraftData.updated ? convertDateString(archivedDraftData.updated) : 'Not updated'}
        </div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <StyledTableCellButton
          startIcon={
            loading === archivedDraftData.id ? (
              <CircularProgress color={'inherit'} size={'20px'}></CircularProgress>
            ) : (
              <RestorePageIcon></RestorePageIcon>
            )
          }
          onClick={async () => {
            setLoading(archivedDraftData?.id)
            const response = await updateGathering(
              { ...archivedDraftData, status: 'draft' },
              archivedDraftData?.id,
              token,
            )
            if (response && response.status === 200) {
              const updatedGathering = allCollections.gathering.filter(
                (g) => g.id !== archivedDraftData?.id,
              )
              const newCollection = { ...allCollections, gathering: updatedGathering }
              setAllCollections(newCollection)
              setLoading('')
            }
            setLoading('')
          }}
        >
          {loading !== archivedDraftData.id ? 'Restore' : ''}
        </StyledTableCellButton>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default ArchivedDraftTableCard

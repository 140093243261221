import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { convertDateString } from '../../util/date'
import { StyledTableRow, StyledTableCell } from './LogisticsTableCard.style'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { CheckboxLabel } from '../GatheringCreation/Logistics/LogisticsInfo.styles'
import { Checkbox } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { tableRowColor } from '../../util/helpers'

const LogisticsTableCard = ({
  templateData,
  indexPosition,
  SetOccurrences,
  setOpenedTab,
  openedTab,
}) => {
  const [highlighted, setHighlighted] = useState(false)

  return (
    <StyledTableRow
      hover
      onClick={() => setHighlighted(!highlighted)}
      sx={{
        backgroundColor: tableRowColor(indexPosition),
        borderLeft: `5px solid ${tableRowColor(indexPosition)}`,
      }}
    >
      <StyledTableCell
        variant="body"
        align="center"
        onClick={() => {
          setOpenedTab((o) => (o === indexPosition ? -1 : indexPosition))
        }}
      >
        {openedTab === indexPosition ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      </StyledTableCell>
      <StyledTableCell variant="body">
        <div>{indexPosition}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>{convertDateString(templateData?.occurrences[indexPosition - 1]?.startDate)}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>
          {templateData?.occurrences[indexPosition - 1]?.startTime
            ? templateData?.occurrences[indexPosition - 1]?.startTime
            : templateData?.startTime}
        </div>
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <CheckboxLabel
          control={
            <Checkbox
              onChange={(v) => {
                SetOccurrences(v.target.checked, 'skip', indexPosition - 1)
              }}
              checked={templateData?.skip}
            />
          }
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

LogisticsTableCard.propTypes = {
  templateData: PropTypes.object,
  indexPosition: PropTypes.number,
}

export default LogisticsTableCard

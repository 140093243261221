import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { TextField } from '@mui/material'
import { LocationInputStyle } from './LocationInput.styles.jsx'

function LocationInput({ value, onChange, label, onBlur, error }) {
  return (
    <LocationInputStyle variant="outlined">
      <TextField
        id="outlined-adornment-password"
        type={'text'}
        value={value}
        onChange={(value) => onChange(value.target.value)}
        endAdornment={<LocationOnIcon />}
        label={label}
        onBlur={(value) => onBlur(value.target.value)}
        error={error}
        helperText={error && 'Location missing or invalid!'}
      ></TextField>
    </LocationInputStyle>
  )
}

export default LocationInput

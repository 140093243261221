import { SelectedAttendeesWrapper } from '../Preview.style'
import GroupFrame from '../../../GroupFrame/GroupFrame'

const GroupInfo = ({ groupData }) => {
  return (
    <>
      {groupData?.group.length > 0 && (
        <>
          {'Groups'}
          <SelectedAttendeesWrapper>
            {groupData?.group.map((group, i) => (
              <GroupFrame key={i} group={group} forSearch={true}></GroupFrame>
            ))}
          </SelectedAttendeesWrapper>
        </>
      )}
      {groupData?.household.length > 0 && (
        <>
          {'Households'}
          <SelectedAttendeesWrapper>
            {groupData?.household.map((group, i) => (
              <GroupFrame key={i} group={group} forSearch={true}></GroupFrame>
            ))}
          </SelectedAttendeesWrapper>
        </>
      )}
      {groupData?.team.length > 0 && (
        <>
          {'Teams'}
          <SelectedAttendeesWrapper>
            {groupData?.team.map((group, i) => (
              <GroupFrame key={i} group={group} forSearch={true}></GroupFrame>
            ))}
          </SelectedAttendeesWrapper>
        </>
      )}
      {groupData?.individual.length > 0 && (
        <>
          {'Individuals'}
          <SelectedAttendeesWrapper>
            {groupData?.individual.map((group, i) => (
              <GroupFrame key={i} group={group} forSearch={true}></GroupFrame>
            ))}
          </SelectedAttendeesWrapper>
        </>
      )}
    </>
  )
}

export default GroupInfo

import {
  ApprovalItemContainer,
  ApprovalActivityItem,
  ApprovalActivityItemFirstRow,
  FirstRowIconArea,
  FirstRowText,
  GroupFrameArea,
  ApprovalActivityItemSecondRow,
  TimestampText,
  StepperWrapper,
  stepperStyle,
} from './ApprovalItem.style'
import { handleIcon, handleRowText } from '../util/ApprovalItem.util'
import Divider from '@mui/material/Divider'
import GroupFrame from '../../../GroupFrame/GroupFrame'

const ApprovalItem = ({ stepper, variant, personData, timeStamp }) => {
  return (
    <ApprovalItemContainer>
      <ApprovalActivityItem>
        <ApprovalActivityItemFirstRow>
          <FirstRowIconArea>{handleIcon(variant)}</FirstRowIconArea>
          <FirstRowText>{handleRowText(variant)}</FirstRowText>
          <GroupFrameArea>
            <GroupFrame forSearch={true} group={personData} forSidebar={false}></GroupFrame>
          </GroupFrameArea>
        </ApprovalActivityItemFirstRow>
        <ApprovalActivityItemSecondRow>
          <StepperWrapper>
            {stepper && <Divider flexItem orientation="vertical" sx={stepperStyle}></Divider>}
          </StepperWrapper>
          <TimestampText>{timeStamp}</TimestampText>
        </ApprovalActivityItemSecondRow>
      </ApprovalActivityItem>
    </ApprovalItemContainer>
  )
}

export default ApprovalItem

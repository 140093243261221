import React from 'react'
import { StyledCard } from './InputFieldModule.styles'
import { Button, FormControlLabel, Checkbox } from '@mui/material'
import { BasicInfoNextButtonStyle } from '../../Form/Form.styles'
import { logger } from '../../../util/logger'
import { CustomFormGroup } from './InputFieldModule.styles'
import colors from '../../../util/colors'
import labels from '../../../constants/InputFieldModuleLabels.json'

function InputFieldModule() {
  return (
    <StyledCard variant="outlined">
      <CustomFormGroup>
        {labels.map((label) => (
          <FormControlLabel
            key={label} // use label as key, assuming all labels are unique
            control={
              <Checkbox
                sx={{
                  '&.Mui-checked': {
                    color: colors.purpleCheckbox,
                  },
                }}
              />
            }
            label={label}
          />
        ))}
      </CustomFormGroup>
      <Button
        sx={BasicInfoNextButtonStyle}
        variant="contained"
        onClick={() => logger.log('Add functionality here')}
      >
        Add Selected
      </Button>
    </StyledCard>
  )
}

export default InputFieldModule

import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import { FormControlLabel, Checkbox } from '@mui/material'
import {
  modalPreviewContainer,
  modalInsertContainer,
  modalCheckboxStyle,
  closeIconStyle,
  CloseButton,
  SubjectBanner,
  PreviewBody,
} from './ModalComponent.style'
import { generateString } from '../util/ModalComponent.util'
import RenderHTML from '../../../util/HtmlStringParser'
import TemplateButton from '../../TemplateButton/TemplateButton'
import infoData from '../assets/inputModal.json'
import { useState, useEffect } from 'react'

const defaultInsertValues = {
  firstName: false,
  lastName: false,
  gathering: false,
  eventDate: false,
  eventTime: false,
}

const ModalPreview = ({
  modalStatus,
  setModalStatus,
  previewString,
  mailSubject,
  modalVariant,
}) => {
  return (
    <Modal open={modalStatus}>
      <Box sx={modalPreviewContainer}>
        <SubjectBanner>{`Subject: ${mailSubject}`}</SubjectBanner>
        <PreviewBody>{RenderHTML(previewString)}</PreviewBody>
        <CloseButton
          onClick={() => {
            setModalStatus((prevState) => ({
              ...prevState,
              [modalVariant]: false,
            }))
          }}
        >
          <CloseIcon style={closeIconStyle} />
        </CloseButton>
      </Box>
    </Modal>
  )
}

const ModalInsert = ({ modalStatus, setModalStatus, modalVariant, onInsertRequest }) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(defaultInsertValues)

  useEffect(() => {
    setSelectedCheckboxes(defaultInsertValues)
  }, [modalStatus])

  return (
    <Modal open={modalStatus}>
      <Box sx={modalInsertContainer}>
        {infoData.configuration.map((checkbox, i) => {
          return (
            <FormControlLabel
              key={i}
              control={<Checkbox sx={modalCheckboxStyle} />}
              label={checkbox.labelName}
              onChange={() => {
                setSelectedCheckboxes((prevState) => ({
                  ...prevState,
                  [checkbox.stateValue]: !selectedCheckboxes[checkbox.stateValue],
                }))
              }}
            />
          )
        })}
        <div
          onClick={() => {
            const checkboxString = generateString(selectedCheckboxes)

            onInsertRequest?.(checkboxString)

            setModalStatus((prevState) => ({
              ...prevState,
              [modalVariant]: false,
            }))
          }}
        >
          <TemplateButton variant={'closeModal'} label={'Add Selected'}></TemplateButton>
        </div>
      </Box>
    </Modal>
  )
}

export { ModalPreview, ModalInsert }

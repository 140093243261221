import styled from 'styled-components'

const GatheringTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem 0 1.5rem 0;
`

export { GatheringTypeContainer }

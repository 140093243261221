import { Drawer as MuiDrawer } from '@mui/material'
import { styled } from '@mui/material'
import {
  ArticleOutlined,
  CancelOutlined,
  ConstructionOutlined,
  ContentCopy,
  CorporateFare,
  EqualizerOutlined,
  ForumOutlined,
  Gavel,
  Groups,
  House,
  HubOutlined,
  LanguageOutlined,
  LocalOffer,
  MotionPhotosAutoOutlined,
  PortraitOutlined,
  RecordVoiceOver,
  Store,
  ThreeP,
  VolunteerActivismOutlined,
  WorkspacePremium,
} from '@mui/icons-material'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import color from './colors'

const getTabIcon = (tab, iconColor = color.primary) => {
  switch (tab.title) {
    case 'Communications':
      return <ForumOutlined style={{ color: iconColor }} />
    case 'Gather':
      return <ConfirmationNumberOutlinedIcon style={{ color: iconColor }} />
    case 'Profiles':
      return <PortraitOutlined style={{ color: iconColor }} />
    case 'Giving':
      return <VolunteerActivismOutlined style={{ color: iconColor }} />
    case 'Online':
      return <LanguageOutlined style={{ color: iconColor }} />
    case 'Automations':
      return <MotionPhotosAutoOutlined style={{ color: iconColor }} />
    case 'Workflows':
      return <HubOutlined style={{ color: iconColor }} />
    case 'Metrics':
      return <EqualizerOutlined style={{ color: iconColor }} />
    case 'Forms':
      return <ArticleOutlined style={{ color: iconColor }} />
    case 'Settings':
      return <ConstructionOutlined style={{ color: iconColor }} />
    case 'Audiences':
      return <ThreeP style={{ color: iconColor }} />
    case 'Groups':
      return <Groups style={{ color: iconColor }} />
    case 'Kiosk':
      return <Store style={{ color: iconColor }} />
    case 'Onboarding':
      return <RecordVoiceOver style={{ color: iconColor }} />
    case 'Duplicates':
      return <ContentCopy style={{ color: iconColor }} />
    case 'Organization':
      return <CorporateFare style={{ color: iconColor }} />
    case 'Campuses':
      return <House style={{ color: iconColor }} />
    case 'Agreements':
      return <Gavel style={{ color: iconColor }} />
    case 'Permissions':
      return <WorkspacePremium style={{ color: iconColor }} />
    case 'Tags':
      return <LocalOffer style={{ color: iconColor }} />
    default:
      return <CancelOutlined style={{ color: iconColor }} />
  }
}

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 0,
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

const ManageActiveBackground = (redirectLink, locationPath) => {
  if (
    redirectLink === '/' ||
    redirectLink === '/experiences' ||
    redirectLink === '/gatherings' ||
    redirectLink === '/drafts' ||
    redirectLink === '/templates' ||
    (redirectLink === '/archivedDrafts' && locationPath === '/')
  ) {
    return color.primary
  } else return false
}

const ManageActiveColor = (activeTab, tabTitle, expandedState, redirectLink, locationPath) => {
  if (activeTab === tabTitle) {
    return color.backgroundPrimary
  } else if (expandedState && redirectLink === locationPath) {
    return color.backgroundPrimary
  } else return color.title
}

export {
  getTabIcon,
  Drawer,
  DrawerHeader,
  closedMixin,
  openedMixin,
  drawerWidth,
  ManageActiveBackground,
  ManageActiveColor,
}

/* eslint-disable no-unused-vars */
import {
  RemindersContainer,
  RemindersContentWrapper,
  StyledText,
  RowStyledText,
  ReminderCheckRow,
  CheckRowSwitchArea,
  CheckRowBannerArea,
  BannerImageAndTextArea,
  BannerImage,
  bannerImageStyle,
  BannerText,
  UtilityButton,
  utilityIconStyle,
  TextEditRow,
  TextBannerRow,
  ReminderDatesContainer,
  ReminderDatesSectionBox,
  ReminderDatesFirstRow,
  formControlStyle,
  textFieldStyle,
  RemindersText,
} from './Reminders.style'
import { useRef } from 'react'
import { StyledSwitch } from './Reminders.muiStyle'
import SelectBasicInfo from '../../SelectBasicInfo/SelectBasicInfo'
import { useFormGatherings } from '../../../util/zustant/store.js'
import infoData from '../../../assets/reminders.json'
import { useState, useEffect } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import EditIcon from '@mui/icons-material/Edit'
import TextEditorForm from '../../TextEditorForm/TextEditorForm'
import { ModalPreview, ModalInsert } from '../../RegistrationConfirmation/components/ModalComponent'
import {
  ButtonsContainer,
  ComponentsWrapper,
} from '../../RegistrationConfirmation/RegistrationConfirmation.styles'
import ButtonWithIcon from '../../RegistrationConfirmation/components/ButtonWithIcon'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import color from '../../../util/colors'

const Reminders = ({ setterPreview }) => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [reminders, setReminders] = useState(formInfo?.reminders ? formInfo.reminders : {})

  const [previewModal, setPreviewModal] = useState({
    welcomeEmail: false,
    confirmationMessage: false,
  })

  const [insertModal, setInsertModal] = useState({
    welcomeEmail: false,
    confirmationMessage: false,
  })

  const [switchStates, setSwitchStates] = useState({
    signUp: reminders?.notification?.signUp ? reminders?.notification?.signUp : false,
    payment: reminders?.notification?.payment ? reminders?.notification?.payment : false,
    cancellation: reminders?.notification?.cancellation
      ? reminders?.notification?.cancellation
      : false,
    promoAlert: reminders?.notification?.promoAlert ? reminders?.notification?.promoAlert : false,
  })
  const [editStates, setEditStates] = useState({
    signUpEdit: false,
    paymentEdit: false,
    cancellationEdit: false,
    promoAlertEdit: false,
  })

  const [reminderState, setReminderState] = useState({
    signUpReminder: reminders?.notification?.signUpReminder
      ? reminders?.notification?.signUpReminder
      : {},
    paymentReminder: reminders?.notification?.paymentReminder
      ? reminders?.notification?.paymentReminder
      : {},
    cancellationReminder: reminders?.notification?.cancellationReminder
      ? reminders?.notification?.cancellationReminder
      : {},
    promoReminder: reminders?.notification?.promoReminder
      ? reminders?.notification?.promoReminder
      : {},
  })

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.reminders = reminders
    setFormInfo(newFormInfo)
  }, [reminders])

  function setUserReminders(value) {
    setReminders((info) => {
      info.userReminders = value
      return { ...info }
    })
  }

  function setReminderEmails(value, variant) {
    setReminders((info) => {
      info[variant] = value
      return { ...info }
    })
  }

  function setNotification(label, value) {
    setReminders((info) => {
      if (!info?.notification) info.notification = {}
      info.notification[label] = value
      return { ...info }
    })
  }

  function setNotificationTime(label, timeValue) {
    setReminders((info) => {
      const updatedInfo = { ...info }
      if (!updatedInfo?.notification) {
        updatedInfo.notification = {}
      }

      updatedInfo.notification[label] = {
        ...updatedInfo.notification[label],
        time: timeValue,
      }

      return { ...updatedInfo }
    })
  }

  function setNotificationPeriod(label, periodValue) {
    setReminders((info) => {
      const updatedInfo = { ...info }
      if (!updatedInfo?.notification) {
        updatedInfo.notification = {}
      }

      updatedInfo.notification[label] = {
        ...updatedInfo.notification[label],
        period: periodValue,
      }

      return { ...updatedInfo }
    })
  }

  function setReminderEmailsSubject(value, subjectVariant) {
    setReminders((info) => {
      info[subjectVariant] = value
      return { ...info }
    })
  }

  function checkError(reminder) {
    switch (reminder.labelTitle) {
      case 'Sign Up Email':
        if (
          formInfo?.reminders?.notification?.signUp &&
          formInfo?.activeStep !== formInfo?.highestStep &&
          (!formInfo?.reminders?.signUpEmailBody ||
            !formInfo?.reminders?.signUpEmailSubject ||
            formInfo?.reminders?.signUpEmailBody === '<p><br></p>' ||
            formInfo?.reminders?.signUpEmailSubject === '')
        ) {
          return true
        }
        return false
      case 'Payment':
        if (
          reminders?.notification?.payment &&
          formInfo?.activeStep !== formInfo?.highestStep &&
          (!reminders?.paymentEmailBody ||
            !reminders?.paymentEmailSubject ||
            reminders?.paymentEmailBody === '<p><br></p>' ||
            reminders?.paymentEmailSubject === '')
        ) {
          return true
        }
        return false
      case 'Cancellation':
        if (
          reminders?.notification?.cancellation &&
          formInfo?.activeStep !== formInfo?.highestStep &&
          (!reminders?.cancellationEmailBody ||
            !reminders?.cancellationEmailSubject ||
            reminders?.cancellationEmailBody === '<p><br></p>' ||
            reminders?.cancellationEmailSubject === '')
        ) {
          return true
        }
        return false
      case 'Promo-Alert':
        if (
          formInfo?.reminders?.notification?.promoAlert &&
          formInfo?.activeStep !== formInfo?.highestStep &&
          (!formInfo?.reminders?.promoAlertEmailBody ||
            !formInfo?.reminders?.promoAlertEmailSubject ||
            formInfo?.reminders?.promoAlertEmailBody === '<p><br></p>' ||
            formInfo?.reminders?.promoAlertEmailSubject === '')
        ) {
          return true
        }
        return false

      default:
        return false
    }
  }

  function checkReminderErrors(reminder) {
    switch (reminder.reminderStates) {
      case 'signUpReminder':
        if (
          formInfo?.reminders?.notification?.signUpReminder?.time &&
          formInfo?.reminders?.notification?.signUpReminder?.time !== '' &&
          formInfo?.reminders?.notification?.signUpReminder?.period &&
          formInfo?.reminders?.notification?.signUpReminder?.period !== ''
        ) {
          return false
        }
        return true
      case 'paymentReminder':
        if (
          formInfo?.reminders?.notification?.paymentReminder?.time &&
          formInfo?.reminders?.notification?.paymentReminder?.time !== '' &&
          formInfo?.reminders?.notification?.paymentReminder?.period &&
          formInfo?.reminders?.notification?.paymentReminder?.period !== ''
        ) {
          return false
        }
        return true
      case 'cancellationReminder':
        if (
          formInfo?.reminders?.notification?.cancellationReminder?.time &&
          formInfo?.reminders?.notification?.cancellationReminder?.time !== '' &&
          formInfo?.reminders?.notification?.cancellationReminder?.period &&
          formInfo?.reminders?.notification?.cancellationReminder?.period !== ''
        ) {
          return false
        }
        return true
      case 'promoAlertReminder':
        if (
          formInfo?.reminders?.notification?.promoAlertReminder?.time &&
          formInfo?.reminders?.notification?.promoAlertReminder?.time !== '' &&
          formInfo?.reminders?.notification?.promoAlertReminder?.period &&
          formInfo?.reminders?.notification?.promoAlertReminder?.period !== ''
        ) {
          return false
        }
        return true
      default:
        return false
    }
  }

  const editorRefs = useRef([])
  const [lastPostitions, setLastPositions] = useState([])

  useEffect(() => {
    editorRefs.current = editorRefs.current.slice(0, infoData.configuration.length)
    setLastPositions(infoData.configuration.map(() => 0))
  }, [infoData.configuration])

  return (
    <RemindersContainer>
      <SelectBasicInfo
        title={infoData.question}
        choices={infoData.choices}
        formInfo={formInfo}
        setUserReminders={setUserReminders}
        variant={'reminders'}
      ></SelectBasicInfo>
      {reminders?.userReminders === 'Yes' && (
        <>
          <StyledText>{'Select types of reminders for your gathering:'}</StyledText>
          {infoData.configuration.map((reminder) => (
            <RemindersContentWrapper key={reminder.switchStateValue} style={{ height: 'auto' }}>
              <ReminderCheckRow>
                <CheckRowSwitchArea>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        checked={switchStates[reminder.switchStateValue]}
                        onChange={() => {
                          setSwitchStates((prevState) => {
                            setNotification(
                              reminder?.switchStateValue,
                              !switchStates[reminder.switchStateValue],
                            )
                            return {
                              ...prevState,
                              [reminder.switchStateValue]: !switchStates[reminder.switchStateValue],
                            }
                          })
                          setEditStates((prevState) => ({
                            ...prevState,
                            [reminder.editStateValue]: false,
                          }))
                        }}
                      />
                    }
                  />
                </CheckRowSwitchArea>
                {switchStates[reminder.switchStateValue] ? (
                  <CheckRowBannerArea style={{ border: checkError(reminder) && '1px solid red' }}>
                    <BannerImageAndTextArea>
                      <BannerImage>
                        <NotificationsActiveIcon style={bannerImageStyle} />
                      </BannerImage>
                      <BannerText>{reminder.bannerTitle}</BannerText>
                    </BannerImageAndTextArea>
                    <UtilityButton
                      onClick={() =>
                        setEditStates((prevState) => {
                          return {
                            ...prevState,
                            [reminder.editStateValue]: !editStates[reminder.editStateValue],
                          }
                        })
                      }
                    >
                      <EditIcon style={utilityIconStyle}></EditIcon>
                      Edit
                    </UtilityButton>
                  </CheckRowBannerArea>
                ) : (
                  <RowStyledText>{reminder.labelTitle}</RowStyledText>
                )}
              </ReminderCheckRow>
              {editStates[reminder.editStateValue] ? (
                <ComponentsWrapper>
                  <TextEditRow>
                    <TextEditorForm
                      error={checkError(reminder)}
                      id={`reminders-${reminder.mailVariant}`}
                      ref={(ref) => {
                        editorRefs.current[reminder.mailVariant] = ref
                      }}
                      variant={reminder.mailVariant}
                      subjectVariant={reminder.subjectVariant}
                      setMailBody={setReminderEmails}
                      onSelectionChange={(range) =>
                        setLastPositions((old) =>
                          old.toSpliced(reminder.mailVariant, 1, range.index),
                        )
                      }
                      mailBody={
                        !reminders?.[reminder.mailVariant]
                          ? reminder.placeholderTextRitch
                          : reminders?.[reminder.mailVariant]
                      }
                      mailSubject={
                        reminders?.[reminder.subjectVariant]
                          ? reminders?.[reminder.subjectVariant]
                          : ''
                      }
                      setMailSubject={setReminderEmailsSubject}
                      placeholderText={reminder.placeholderText}
                    ></TextEditorForm>
                  </TextEditRow>
                  <ReminderDatesContainer>
                    <ReminderDatesSectionBox>
                      <ReminderDatesFirstRow>
                        <RemindersText>{reminder.reminderStateText}</RemindersText>
                      </ReminderDatesFirstRow>
                      <ReminderDatesFirstRow>
                        <TextField
                          error={checkReminderErrors(reminder)}
                          size="small"
                          sx={textFieldStyle}
                          label="Number"
                          type="number"
                          defaultValue={
                            formInfo?.reminders?.notification[reminder.reminderStates]?.time
                              ? formInfo?.reminders?.notification[reminder.reminderStates]?.time
                              : ''
                          }
                          InputProps={{
                            inputProps: { min: 0 },
                            onKeyPress: (event) => {
                              if (event?.key === '-' || event?.key === 'e') {
                                event.preventDefault()
                              }
                            },
                          }}
                          onChange={(e) => {
                            setNotificationTime(reminder?.reminderStates, e.target.value)
                          }}
                        />
                        <FormControl size="small">
                          <Select
                            error={checkReminderErrors(reminder)}
                            sx={formControlStyle}
                            value={
                              formInfo?.reminders?.notification[reminder?.reminderStates]?.period
                                ? formInfo?.reminders?.notification[reminder?.reminderStates]
                                    ?.period
                                : ''
                            }
                            onChange={(e) => {
                              setNotificationPeriod(reminder?.reminderStates, e.target.value)
                            }}
                          >
                            {' '}
                            <MenuItem sx={{ color: color.primary }} value="Minutes">
                              Minutes
                            </MenuItem>
                            <MenuItem sx={{ color: color.primary }} value="Hours">
                              Hours
                            </MenuItem>
                            <MenuItem sx={{ color: color.primary }} value="Days">
                              Days
                            </MenuItem>
                            <MenuItem sx={{ color: color.primary }} value="Months">
                              Months
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </ReminderDatesFirstRow>
                    </ReminderDatesSectionBox>
                  </ReminderDatesContainer>
                  <ButtonsContainer>
                    <ButtonWithIcon
                      variant="modal"
                      setterPreview={setterPreview}
                      onClickFunction={setInsertModal}
                      variantString={reminder.mailVariant}
                      label="Insert Field"
                      icon={<ExitToAppIcon />}
                    />
                    <ButtonWithIcon
                      variant="modal"
                      setterPreview={setterPreview}
                      onClickFunction={setPreviewModal}
                      variantString={reminder.mailVariant}
                      label="Preview"
                      icon={<OpenInNewIcon />}
                    />
                  </ButtonsContainer>
                  <ModalPreview
                    modalStatus={previewModal[reminder.mailVariant]}
                    setModalStatus={setPreviewModal}
                    mailSubject={
                      formInfo?.reminders?.[reminder.subjectVariant]
                        ? formInfo?.reminders?.[reminder.subjectVariant]
                        : ''
                    }
                    previewString={
                      formInfo?.reminders?.[reminder.mailVariant]
                        ? formInfo?.reminders?.[reminder.mailVariant]
                        : reminders.placeholderText
                    }
                    modalVariant={reminder.mailVariant}
                  ></ModalPreview>
                  <ModalInsert
                    modalStatus={insertModal[reminder.mailVariant]}
                    setModalStatus={setInsertModal}
                    modalVariant={reminder.mailVariant}
                    onInsertRequest={(stringToInsert) => {
                      editorRefs.current[reminder.mailVariant]?.editor?.insertText(
                        lastPostitions[0] || 0,
                        stringToInsert,
                      )
                    }}
                  ></ModalInsert>
                </ComponentsWrapper>
              ) : (
                <TextEditRow>
                  <TextBannerRow
                    margin={switchStates[reminder.switchStateValue] ? '0' : '-1.5rem 0 -1.5rem 0'}
                  >
                    {reminder.exampleText}
                  </TextBannerRow>
                </TextEditRow>
              )}
            </RemindersContentWrapper>
          ))}
        </>
      )}
    </RemindersContainer>
  )
}

export default Reminders

import {
  OnboardingContainer,
  CreateOnboardingRow,
  KioskComponentsArea,
  kioskHeaderIcon,
  SkeletonHolder,
} from './Onboarding.style'
import GroupSearch from '../../GroupSearch/GroupSearch'
import onboardingData from '../../../assets/onboarding.json'
import TemplateButton from '../../TemplateButton/TemplateButton'
import Kiosk from '../../Kiosk/Kiosk'
import FlagIcon from '@mui/icons-material/Flag'
import OnboardingSetup from '../OnboardingSetup/OnboardingSetup'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { useState, useEffect } from 'react'
import { sortByNameSimilarity } from '../../../util/utility'
import { useAuth } from '../../../hooks/useAuth'
import Skeleton from '@mui/material/Skeleton'
import { createOnboarding, fetchOnboardings } from '../../../util/onboarding'
import { useOnboarding } from '../../../util/zustant/onboardingStore'

const Onboarding = () => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const { setOnboardingStore } = useOnboarding()
  const { token } = useAuth()
  const [onboarding, setOnboarding] = useState(formInfo?.onboarding ? formInfo.onboarding : [])
  const [onboardings, setOnboardings] = useState([])

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.onboarding = onboarding
    setFormInfo(newFormInfo)
  }, [onboarding])

  useEffect(() => {
    fetchOnboardings(token, setOnboardings)
  }, [token])

  function selectKiosk(kioskData) {
    setOnboarding((info) => {
      if (info.selectedKiosk?.id !== kioskData.id) {
        info.selectedKiosk = kioskData
      } else {
        info.selectedKiosk = {}
      }
      return { ...info }
    })
  }

  function sortOnboardingsBySearch(search) {
    setOnboardings((onboardings) => {
      onboardings = sortByNameSimilarity(onboardings, search)
      return [...onboardings]
    })
  }

  function addOnboarding(ob) {
    const updatedItem = ob
    updatedItem.selected = true
    setOnboarding((oldArray) => [...oldArray, updatedItem])
  }

  function removeOnboarding(ob) {
    let temp = onboarding
    temp = temp.filter((o) => o.id !== ob.id)
    setOnboarding(temp)
  }

  useEffect(() => {
    setOnboardingStore(onboardings)
  }, [onboardings])

  async function handleCreateOnboarding() {
    let data = await createOnboarding(token)
    let format = { ...data.data, id: data.id }
    setOnboardings((onboardings) => {
      onboardings.push(format)
      return [...onboardings]
    })
  }
  return (
    <OnboardingContainer>
      <GroupSearch
        firstRowText={'Select Onboarding process:'}
        variant={'onboarding'}
        onboardingData={onboardings}
        sortOnboardingsBySearch={sortOnboardingsBySearch}
        loading={onboardings.length === 0}
      ></GroupSearch>
      <CreateOnboardingRow>
        {'SELECT FROM EXISTING:'}
        <TemplateButton
          onClick={handleCreateOnboarding}
          variant={'newOnboarding'}
          label={'Create New Onboarding'}
        ></TemplateButton>
      </CreateOnboardingRow>
      <KioskComponentsArea>
        {onboardings?.map((item, i) => (
          <Kiosk
            formInfo={formInfo}
            onboardings={onboardings}
            key={item.id}
            kiosk={item}
            addKiosk={addOnboarding}
            removeKiosk={removeOnboarding}
            headerTitle={item?.name}
            isOnboarding={true}
            headerImage={<FlagIcon style={kioskHeaderIcon}></FlagIcon>}
            selectKiosk={() => selectKiosk(item)}
            selectedKiosk={onboarding?.selectedKiosk?.id === item.id}
          >
            <OnboardingSetup
              dropdownData={onboardingData.DropdownMenuData}
              defaultFields={onboardingData.DefaultFields}
              item={item}
              index={i}
              setOnboardings={setOnboardings}
              token={token}
            ></OnboardingSetup>
          </Kiosk>
        ))}
        {onboardings.length === 0 && (
          <SkeletonHolder>
            <Skeleton variant="rectangular" width={'100%'} height={68} />
            <Skeleton variant="rectangular" width={'100%'} height={68} />
            <Skeleton variant="rectangular" width={'100%'} height={68} />
            <Skeleton variant="rectangular" width={'100%'} height={68} />
            <Skeleton variant="rectangular" width={'100%'} height={68} />
            <Skeleton variant="rectangular" width={'100%'} height={68} />
          </SkeletonHolder>
        )}
      </KioskComponentsArea>
    </OnboardingContainer>
  )
}

export default Onboarding

import { logger } from './logger'

export const checkProductName = async (name, token) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/products/${name}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await resp.json()
    return data.found
  } catch (error) {
    logger.error(`Error checking product name: ${error}`)
    throw new Error("Couldn't check product name")
  }
}

export const checkPromotionCode = async (code, token) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/coupon/${code}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await resp.json()
    return data.found
  } catch (error) {
    logger.error(`Error checking promotion code: ${error}`)
    throw new Error("Couldn't check promotion code")
  }
}

export const createNewProduct = async (productDetails, token) => {
  if (!productDetails?.cost || !productDetails?.name || !token) {
    logger.error('Missing information:', productDetails.cost, productDetails.name, token)
    throw new Error('Missing information for creating a new product')
  }

  const cost = (string) => {
    const numericString = string.replace(/\D/g, '')
    return parseInt(numericString)
  }

  const payload = {
    name: productDetails.name,
    price: { currency: 'USD', unit_amount: cost(productDetails.cost) },
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/products`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    logger.error(`Error creating product: ${error}`)
    throw new Error("Couldn't create new product")
  }
}

export const createNewPromoCode = async (promoCodeDetails, token, productId) => {
  if (!promoCodeDetails?.name || !promoCodeDetails?.percentage || !productId || !token) {
    logger.error(
      'Missing information:',
      promoCodeDetails?.name,
      promoCodeDetails?.percentage,
      token,
    )
    throw new Error('Missing information for creating a new promo code')
  }

  const payload = {
    name: promoCodeDetails?.name,
    percentage: promoCodeDetails?.percentage,
    productId: productId,
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/coupons`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    logger.error(`Error creating promo code: ${error}`)
    throw new Error("Couldn't create new promo code")
  }
}

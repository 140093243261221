import {
  RowRightTextBold,
  RowRightText,
  TicketInfoWrapper,
  TicketTagDataArea,
  TicketTagRow,
  TicketTagInfoText,
  TagContainer,
} from '../Preview.style'
import Tag from '../../../Tag/Tag'

const TicketInfo = ({ ticketData }) => {
  return ticketData.map((ticket) => (
    <TicketInfoWrapper>
      <RowRightTextBold>{ticket?.name}</RowRightTextBold>
      <RowRightText>
        {ticket?.numberOfTickets
          ? `${ticket?.numberOfTickets} Tickets`
          : 'Please specify ticket number on Payments Screen'}
      </RowRightText>
      <TicketTagDataArea>
        <TicketTagRow>
          <TicketTagInfoText>Age Range</TicketTagInfoText>
          <TagContainer>
            <Tag
              variant={'previewTag'}
              previewTag={'ageRange'}
              previewDataLabel={`${ticket?.minAge ? ticket?.minAge : ''} - ${
                ticket?.maxAge ? ticket?.maxAge : ''
              }`}
            ></Tag>
          </TagContainer>
        </TicketTagRow>
        <TicketTagRow>
          <TicketTagInfoText>Gender</TicketTagInfoText>
          <TagContainer>
            <Tag
              variant={'previewTag'}
              previewTag={'gender'}
              previewDataLabel={ticket?.gender}
            ></Tag>
          </TagContainer>
        </TicketTagRow>
        {ticket.sortedGroup.group.length > 0 && (
          <TicketTagRow>
            <TicketTagInfoText>Groups</TicketTagInfoText>
            <TagContainer>
              {ticket.sortedGroup.group.map((group, i) => (
                <Tag
                  key={i}
                  variant={'previewTag'}
                  previewTag={'groups'}
                  previewDataLabel={group.title}
                ></Tag>
              ))}
            </TagContainer>
          </TicketTagRow>
        )}
        {ticket.sortedGroup.team.length > 0 && (
          <TicketTagRow>
            <TicketTagInfoText>Teams</TicketTagInfoText>
            <TagContainer>
              {ticket.sortedGroup.team.map((group, i) => (
                <Tag
                  key={i}
                  variant={'previewTag'}
                  previewTag={'groups'}
                  previewDataLabel={group.title}
                ></Tag>
              ))}
            </TagContainer>
          </TicketTagRow>
        )}
        {ticket.sortedGroup.household.length > 0 && (
          <TicketTagRow>
            <TicketTagInfoText>Households</TicketTagInfoText>
            <TagContainer>
              {ticket.sortedGroup.household.map((group, i) => (
                <Tag
                  key={i}
                  variant={'previewTag'}
                  previewTag={'groups'}
                  previewDataLabel={group.title}
                ></Tag>
              ))}
            </TagContainer>
          </TicketTagRow>
        )}
        {ticket.sortedGroup.individual.length > 0 && (
          <TicketTagRow>
            <TicketTagInfoText>Individuals</TicketTagInfoText>
            <TagContainer>
              {ticket.sortedGroup.individual.map((group, i) => (
                <Tag
                  key={i}
                  variant={'previewTag'}
                  previewTag={'groups'}
                  previewDataLabel={group.title}
                ></Tag>
              ))}
            </TagContainer>
          </TicketTagRow>
        )}
        <TicketTagRow>
          <TicketTagInfoText>Deposit</TicketTagInfoText>
          <TagContainer>
            <Tag
              variant={'previewTag'}
              previewTag={'deposit'}
              previewDataLabel={`$ ${ticket?.minDeposit ? ticket?.minDeposit : ''}`}
            ></Tag>
          </TagContainer>
        </TicketTagRow>
        <TicketTagRow>
          <TicketTagInfoText>Sponsored</TicketTagInfoText>
          <TagContainer>
            <Tag
              variant={'previewTag'}
              previewTag={'sponsored'}
              previewDataLabel={ticket?.sponsorship ? 'Yes' : 'No'}
            ></Tag>
          </TagContainer>
        </TicketTagRow>
        {ticket?.discountCodes && (
          <TicketTagRow>
            <TicketTagInfoText>Discounts</TicketTagInfoText>
            <TagContainer>
              {ticket?.discountCodes?.map((code, i) => (
                <Tag
                  key={i}
                  variant={'previewTagDiscount'}
                  previewTag={'discount'}
                  previewDataLabel={`${code.name} - ${code.percentage}%`}
                ></Tag>
              ))}
            </TagContainer>
          </TicketTagRow>
        )}
      </TicketTagDataArea>
    </TicketInfoWrapper>
  ))
}

export default TicketInfo

import styled from 'styled-components'
import color from '../../../util/colors'

export const Gathering = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: ${color.description};
  width: min-content;
`

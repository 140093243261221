import { useState } from 'react'
import NavbarButton from '../NavbarButton/NavbarButton'
import NavbarOutlinedButton from '../NavbarOutlinedButton/NavbarOutlinedButton'
import TemplatesFilter from './TemplatesFilter/TemplatesFilter'
import AddIcon from '@mui/icons-material/Add'
import SearchComponent from '../SearchComponent/SearchComponent'
import { useNavigate } from 'react-router-dom'
import {
  MainContentArea,
  TextandButtonsArea,
  TextArea,
  SectionMainText,
  ButtonArea,
  CreateGathering,
  TemplatesCreateArea,
  TemplatesInfo,
  TemplatesFilterArea,
  FilterText,
} from './GatheringTemplatesFilter.Styled'
import { useTemplateId } from '../../util/zustant/store'
const GatheringTemplatesFilter = ({ setSearchData, searchData, filter }) => {
  const { setTemplateId } = useTemplateId()
  const [open, setOpen] = useState(false)
  const [filterType, setFilterType] = useState('title')
  const navigate = useNavigate()

  return (
    <MainContentArea>
      {/* First Text Area */}
      <TemplatesCreateArea>
        <TextandButtonsArea>
          <TextArea>
            <SectionMainText>Create a Gathering</SectionMainText>
          </TextArea>

          <ButtonArea>
            <NavbarButton
              buttonText={'See All Gatherings'}
              iconName={'forward'}
              onClickFunction={() => navigate('/gatherings')}
            ></NavbarButton>
            <NavbarButton
              buttonText={'See All Experiences'}
              iconName={'forward'}
              onClickFunction={() => navigate('/experiences')}
            ></NavbarButton>
            <NavbarOutlinedButton
              onClickFunction={() => setOpen((o) => !o)}
              buttonText={'Filters'}
              iconName={open ? 'horizontal line' : 'filter'}
            ></NavbarOutlinedButton>
          </ButtonArea>
        </TextandButtonsArea>
        <TemplatesInfo>Create from Scratch</TemplatesInfo>
        <CreateGathering
          onClick={() => {
            setTemplateId(null)
            navigate('/createGathering')
          }}
        >
          <AddIcon /> Create from Scratch
        </CreateGathering>
        <TemplatesInfo>Use Existing Templates</TemplatesInfo>
      </TemplatesCreateArea>

      <TemplatesFilterArea open={open}>
        <FilterText>Filter gathering templates by title:</FilterText>
        <div>
          <SearchComponent
            filter={filter}
            key={setSearchData}
            searchData={searchData}
            setFilter={setSearchData}
            variant={'template'}
            filterType={filterType}
          ></SearchComponent>
        </div>
        <TemplatesFilter setFilterType={setFilterType} setFilter={setSearchData}></TemplatesFilter>
      </TemplatesFilterArea>
    </MainContentArea>
  )
}

export default GatheringTemplatesFilter

import styled from 'styled-components'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import color from '../../../util/colors'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export const StyledLocalizationProvider = styled(LocalizationProvider)`
  width: 100%;
`
export const StyledDatePicker = styled(DatePicker)`
  background: ${color.backgroundPrimary};
  width: 100%;

  svg {
    color: ${color.primary};
  }
`

import * as React from 'react'
import './ListAccordion.css'
import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledAccordion,
} from './ListAccordion.style'
import { StyledButton } from '../StyledButton/StyledButton.style'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import AddIcon from '@mui/icons-material/Add'
import ExperienceListCard from '../ExperienceListCard/ExperienceListCard'
import GatheringListCard from '../GatheringListCard/GatheringListCard'
import Divider from '@mui/material/Divider'
import color from '../../util/colors'
import { convertDateString } from '../../util/date'
import { useNavigate } from 'react-router-dom'

const ListAccordion = ({ gatheringData, experienceData }) => {
  const [accordionCollapsed, setAccordionCollapsed] = useState(false)
  const navigate = useNavigate()

  return (
    <StyledAccordion
      expanded={accordionCollapsed}
      sx={{ width: '100%', backgroundColor: 'transparent' }}
    >
      <StyledAccordionSummary
        sx={accordionCollapsed ? { boxShadow: '0px 50px 34px -58px rgba(0, 0, 0, 0.07)' } : {}}
      >
        <ExperienceListCard
          experienceData={experienceData}
          onClickEvent={setAccordionCollapsed}
          onClickState={accordionCollapsed}
        ></ExperienceListCard>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <div className="accordionContent">
          <div className="accordionFirstRow">
            <div className="accordiontextArea">
              <div className="leftSideText">Gatherings</div>
            </div>
            <div className="accordionFirstRowButtonsArea">
              <div>
                <StyledButton
                  className="accordionListButton"
                  variant="contained"
                  startIcon={<AddIcon></AddIcon>}
                >
                  Add new gathering
                </StyledButton>
              </div>
            </div>
          </div>
          <div className="accordionSecondRow">
            <div className="accordionSecondRowTabArea">
              <div className="accordionSecondRowTabText1">STARTING DATE AND TIME</div>
              <div className="accordionSecondRowTabText2">CARD</div>
              <div className="accordionSecondRowTabText3">EDIT</div>
              <div className="accordionSecondRowTabText4">ARCHIVE</div>
            </div>
            <div className="accordionSecondRowDividerArea">
              <Divider color={color.divider} sx={{ height: 0.5, width: '100%' }} />
            </div>
            <div className="accordionGatheringListCardsArea">
              {gatheringData?.length > 0 &&
                gatheringData.slice(0, 5).map((gathering) => {
                  return (
                    <GatheringListCard
                      key={gathering.id}
                      date={convertDateString(gathering?.logisticsInfo?.event?.startDate)}
                      time={gathering?.logisticsInfo?.event?.startTime}
                      image={gathering?.imageUpload?.eventImage}
                      title={gathering.title}
                    ></GatheringListCard>
                  )
                })}
            </div>
          </div>
          <div className="accordionFooter">
            <div className="accordionFooterButtonArea">
              <div
                className="accordionFooterButton"
                onClick={() => {
                  navigate('/gatherings', { state: { experienceId: experienceData.id } })
                }}
              >
                See All Gatherings
              </div>
              <Divider color={color.primary} orientation="vertical" flexItem />
              <StyledButton
                className="accordionCloseButton"
                variant="text"
                endIcon={<KeyboardArrowUpIcon></KeyboardArrowUpIcon>}
                onClick={() => setAccordionCollapsed(false)}
              >
                Close
              </StyledButton>
            </div>
          </div>
        </div>
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

ListAccordion.propTypes = {
  gatheringData: PropTypes.oneOfType([PropTypes.array]),
  experienceData: PropTypes.object,
}

export default ListAccordion

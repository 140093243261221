import { logger } from './logger'
import { unifyData } from './utility'

export const fetchOnboardings = async (token, setOnboardings) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/onboarding/`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await resp.json()
    const unify = unifyData(data)
    setOnboardings(unify)
  } catch (error) {
    logger.error(error)
  }
}

export const createOnboarding = async (token) => {
  try {
    let resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/onboarding/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await resp.json()
    logger.log('created: ', data)
    return data
  } catch (error) {
    logger.error(error)
  }
}

export const updateOnboarding = async (token, data) => {
  let id = data.id
  delete data.id
  delete data.updated
  try {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/onboarding/${id}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...data,
      }),
    })
  } catch (error) {
    logger.error(error)
  }
}

export const deleteOnboarding = async (token, id) => {
  try {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/onboarding/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (error) {
    logger.error(error)
  }
}

export const saveOnboarding = async (token, onboardingStore, setOnboardingStore) => {
  logger.log('Saving security label changes...')
  if (onboardingStore) {
    for (const label of onboardingStore) {
      if (label.updated) {
        if (label.id) {
          logger.log('Updating label...', label)
          await updateOnboarding(token, label)
          logger.log('Updated label...', label)
        }
      }
    }

    setOnboardingStore((store) => {
      store.forEach((_, i) => {
        delete store[i].updated
      })
      return [...store]
    })
  }
}

import { handleBackgroundColor, handleLabelColor } from '../../util/groupFrame.util'
import CancelIcon from '@mui/icons-material/Cancel'
import Avatar from '@mui/material/Avatar'
import { StyledMuiChip } from './GroupFrame.style'
import { useState } from 'react'
import GroupSidebar from '../GroupSidebar/GroupSidebar'
import { removeGroupFromArray } from '../../util/groupGrid'

const GroupFrame = ({
  group,
  setSortedGroups,
  setSearchData,
  forSearch,
  forSidebar,
  setSelectedApproversPool,
}) => {
  const [groupSidebar, setGroupSidebar] = useState(false)

  return (
    <>
      <StyledMuiChip
        avatar={
          <Avatar
            alt="Avatar image"
            src={group?.photo}
            sx={{
              border: '1px solid var(--backgroundPrimary)',
            }}
          />
        }
        variant="filled"
        label={group?.title ? group?.title : group?.name}
        sx={{
          backgroundColor: handleBackgroundColor(group?.variant),
          '&.MuiChip-root: hover': { backgroundColor: handleBackgroundColor(group?.variant) },
          color: handleLabelColor(group?.variant),
          '& .MuiChip-deleteIcon': {
            color: handleLabelColor(group?.variant),
          },
          '& .MuiChip-deleteIcon: hover': {
            color: handleLabelColor(group?.variant),
          },
        }}
        deleteIcon={<CancelIcon />}
        onDelete={
          forSearch
            ? null
            : () => {
                if (setSelectedApproversPool) {
                  setSelectedApproversPool({ individual: {} })
                } else {
                  removeGroupFromArray(group?.variant, group?.id, setSortedGroups, setSearchData)
                }
              }
        }
        onClick={() => {
          if (
            group?.variant === 'group' ||
            group?.variant === 'household' ||
            group?.variant === 'team'
          ) {
            setGroupSidebar(true)
          }
        }}
      />
      {!forSidebar && (
        <GroupSidebar
          group={group}
          sidebar={groupSidebar}
          setSidebar={setGroupSidebar}
        ></GroupSidebar>
      )}
    </>
  )
}

export default GroupFrame

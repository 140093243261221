/* eslint-disable no-unused-vars */
import {
  CheckinKioskContainer,
  CheckinKioskMainText,
  CreateKioskRow,
  KioskComponentsArea,
  kioskHeaderIcon,
  SkeletonHolder,
} from './CheckinKiosk.style'
import GroupSearch from '../../GroupSearch/GroupSearch'
import TemplateButton from '../../TemplateButton/TemplateButton'
import Kiosk from '../../Kiosk/Kiosk'
import CheckinSetup from '../CheckinSetup/CheckinSetup'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { useState, useEffect } from 'react'
import { unifyData, sortByNameSimilarity } from '../../../util/utility'
import { useAuth } from '../../../hooks/useAuth'
import BungalowIcon from '@mui/icons-material/Bungalow'
import { logger } from '../../../util/logger'
import Skeleton from '@mui/material/Skeleton'

const CheckinKiosk = ({ groupData }) => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [kiosks, setKiosks] = useState([])
  const { token } = useAuth()

  const [checkinKioskInfo, setCheckinKioskInfo] = useState(
    formInfo?.checkinKioskInfo?.kiosks ? formInfo?.checkinKioskInfo : { kiosks: [] },
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/kiosks/`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const data = await resp.json()
        const unify = unifyData(data)
        setKiosks(unify)
      } catch (error) {
        logger.error(error)
      }
    }

    fetchData()
  }, [token])

  function sortKiosksBySearch(search) {
    setKiosks((kiosks) => {
      kiosks = sortByNameSimilarity(kiosks, search)
      return [...kiosks]
    })
  }

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.checkinKioskInfo = checkinKioskInfo
    setFormInfo(newFormInfo)
  }, [checkinKioskInfo])

  // This will store the user pin
  function setKioskPin(pin) {
    setCheckinKioskInfo((info) => {
      info.kioskPin = pin
      return { ...info }
    })
  }

  // This will store the user selected individuals for screen
  function setSelectedIndividuals(individuals) {
    setCheckinKioskInfo((info) => {
      info.selectedIndividuals = individuals
      return { ...info }
    })
  }

  // This will store the user selected individuals for screen
  function setSearchingData(searchData) {
    setCheckinKioskInfo((info) => {
      info.searchData = searchData
      return { ...info }
    })
  }

  // This will store the user selected date.
  function setDate(selectedDate) {
    setCheckinKioskInfo((info) => {
      info.selectedDate = selectedDate
      return { ...info }
    })
  }

  // This will store the user selected time.
  function setTime(selectedTime) {
    setCheckinKioskInfo((info) => {
      info.selectedTime = selectedTime
      return { ...info }
    })
  }

  function addKiosk(kiosk) {
    setCheckinKioskInfo((k) => {
      k.kiosks[k.kiosks.length] = kiosk
      return { ...k }
    })
  }

  function removeKiosk(kiosk) {
    setCheckinKioskInfo((k) => {
      k.kiosks = k?.kiosks?.filter((k) => k.id !== kiosk.id)
      return { ...k }
    })
  }

  function selectKiosk(kioskData) {
    setCheckinKioskInfo((info) => {
      if (info.selectedKiosk?.id !== kioskData.id) {
        info.selectedKiosk = kioskData
      } else {
        info.selectedKiosk = {}
      }
      return { ...info }
    })
  }

  return (
    <CheckinKioskContainer>
      <CheckinKioskMainText>{'Select kiosk(s)'}</CheckinKioskMainText>
      <GroupSearch
        firstRowText={'Search for kiosk:'}
        variant={'kiosk'}
        kioskData={kiosks.filter((k) => k.name !== '')}
        sortKiosksBySearch={sortKiosksBySearch}
        loading={kiosks.length === 0}
      ></GroupSearch>
      <CreateKioskRow>
        {'SELECT FROM EXISTING KIOSKS:'}
        <TemplateButton
          variant={'newKiosk'}
          label={'Create new Kiosk'}
          onClick={addKiosk}
        ></TemplateButton>
      </CreateKioskRow>

      <KioskComponentsArea>
        {kiosks &&
          kiosks.map((kiosk, index) => {
            return (
              <Kiosk
                formInfo={formInfo}
                headerTitle={kiosk?.name ? kiosk.name : 'Kiosk'}
                headerImage={<BungalowIcon style={kioskHeaderIcon}></BungalowIcon>}
                selectKiosk={() => selectKiosk(kiosk)}
                selectedKiosk={checkinKioskInfo?.selectedKiosk?.id === kiosk.id}
                isCheckinKiosk={true}
              >
                <CheckinSetup
                  formInfo={formInfo.checkinKioskInfo}
                  kioskData={kiosk}
                  kiosk={true}
                  groupData={groupData}
                  setKioskPin={setKioskPin}
                  setSearchingData={setSearchingData}
                  setSelectedIndividuals={setSelectedIndividuals}
                  setDate={setDate}
                  setTime={setTime}
                ></CheckinSetup>
              </Kiosk>
            )
          })}
        {kiosks.length === 0 && (
          <SkeletonHolder>
            <Skeleton variant="rectangular" width={'100%'} height={68} />
            <Skeleton variant="rectangular" width={'100%'} height={68} />
            <Skeleton variant="rectangular" width={'100%'} height={68} />
            <Skeleton variant="rectangular" width={'100%'} height={68} />
            <Skeleton variant="rectangular" width={'100%'} height={68} />
            <Skeleton variant="rectangular" width={'100%'} height={68} />
          </SkeletonHolder>
        )}
      </KioskComponentsArea>
    </CheckinKioskContainer>
  )
}

export default CheckinKiosk

import { ToggleButton } from '@mui/material'
import TodayRoundedIcon from '@mui/icons-material/TodayRounded'
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded'
import { StyledToggleButtonGroup } from './ViewButtonsComponent.style'

import PropTypes from 'prop-types'
import { useView } from '../../util/zustant/store'

const ViewButtonComponent = ({ changeComponent, FirstIcon, SecondIcon }) => {
  //Three possible states: 'tasks', 'list', 'calendar'

  const { setView, view } = useView()

  return (
    <div>
      <StyledToggleButtonGroup
        value={view}
        exclusive
        onChange={(event, newValue) => {
          if (newValue !== null) {
            changeComponent && changeComponent(newValue)
            setView(newValue)
          }
        }}
        aria-label="viewComponents"
      >
        <ToggleButton value={1} aria-label="tasks">
          {FirstIcon ? <FirstIcon /> : <ViewModuleRoundedIcon />}
        </ToggleButton>
        <ToggleButton value={2} aria-label="calendar">
          {SecondIcon ? <SecondIcon /> : <TodayRoundedIcon />}
        </ToggleButton>
      </StyledToggleButtonGroup>
    </div>
  )
}

ViewButtonComponent.propTypes = {
  changeComponent: PropTypes.func,
}

export default ViewButtonComponent

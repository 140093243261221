import styled from 'styled-components'
import color from '../../../util/colors'

const modalPreviewContainer = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '648px',
  minHeight: '394px',
  height: '600px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '2rem 1rem 2rem 1rem',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}

const modalInsertContainer = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '160px',
  rowGap: '18px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  padding: '40px 34px',
  display: 'flex',
  alignItems: 'flexStart',
  flexDirection: 'column',
}

const modalCheckboxStyle = {
  '&.MuiCheckbox-root': {
    color: color.description,
  },
  '&.Mui-checked': {
    color: color.primary,
  },
}

const closeIconStyle = {
  color: color.primary,
}

const CloseButton = styled.div`
  position: absolute;
  top: -40px;
  right: -30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: var(--backgroundPrimary);
  cursor: pointer;
`

const SubjectBanner = styled.div`
  width: 600px;
  height: 76px;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  color: var(--title);
  background-color: var(--previewModalSubjectBanner);
  border-left: 3px solid var(--primary);
  word-break: break-word;
  overflow-y: auto;
`

const PreviewBody = styled.div`
  width: 100%;
  font-style: normal;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem 4rem 3rem 4rem;
  word-break: break-word;
  overflow: scroll;
  height: 100%;
`

export {
  modalPreviewContainer,
  modalInsertContainer,
  modalCheckboxStyle,
  closeIconStyle,
  CloseButton,
  SubjectBanner,
  PreviewBody,
}

import SelectBasicInfo from '../../SelectBasicInfo/SelectBasicInfo'
import selectData from '../../../assets/checkinType.json'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { useState, useEffect } from 'react'
import { isEmptyObject } from '../../../util/utility'

const CheckinType = () => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [checkinType, setCheckinType] = useState(formInfo?.checkinType ? formInfo?.checkinType : {})

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.checkinType = checkinType
    if (checkinType.checkinMethod === 'roster') {
      newFormInfo.missingInformation = formInfo?.missingInformation?.filter(
        (m) => m !== 'Checkins via Kiosk',
      )
      if (
        newFormInfo?.checkinKioskInfo?.selectedKiosk &&
        !isEmptyObject(newFormInfo?.checkinKioskInfo?.selectedKiosk)
      ) {
        delete newFormInfo?.checkinKioskInfo?.selectedKiosk
      }
      if (
        !newFormInfo?.rosterCheckin &&
        !newFormInfo?.rosterCheckinKiosk &&
        formInfo?.activeStep !== formInfo?.highestStep &&
        formInfo?.highestStep >= 6
      ) {
        newFormInfo.missingInformation = [
          ...formInfo?.missingInformation,
          'Checkins via Roster > Open',
          'Checkins via Roster > Open > Kiosk',
        ]
      }
    } else if (checkinType.checkinMethod === 'kiosk') {
      if (newFormInfo?.rosterCheckin && newFormInfo?.rosterCheckinKiosk) {
        delete newFormInfo.rosterCheckin
        delete newFormInfo.rosterCheckinKiosk
      }
      if (
        (!newFormInfo?.checkinKioskInfo?.selectedKiosk ||
          isEmptyObject(newFormInfo?.checkinKioskInfo?.selectedKiosk)) &&
        formInfo?.activeStep !== formInfo?.highestStep &&
        formInfo?.highestStep >= 6
      ) {
        newFormInfo.missingInformation = [...formInfo?.missingInformation, 'Checkins via Kiosk']
      }
    }
    setFormInfo(newFormInfo)
  }, [checkinType])

  function setCheckinMethod(value) {
    setCheckinType((info) => {
      info.checkinMethod = value
      return { ...info }
    })
  }

  return (
    <SelectBasicInfo
      title={selectData.question}
      choices={selectData.choices}
      formInfo={formInfo}
      setCheckinMethod={setCheckinMethod}
      variant={'checkinMethod'}
    ></SelectBasicInfo>
  )
}

export default CheckinType

import color from '../../../util/colors'
import styled from 'styled-components'

const alertBoxStyle = {
  background: color.primary,
  color: color.backgroundPrimary,
}

const alertBoxIcon = {
  color: color.backgroundPrimary,
}

const TicketPickerRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 100px;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0.5rem 1rem;
`

const TicketPickerRowText = styled.div`
  display: flex;
  width: 150px;
`

export { alertBoxStyle, alertBoxIcon, TicketPickerRow, TicketPickerRowText }

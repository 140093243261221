import { logger } from './logger'
import { unifyData } from './utility'

const baseUrl = process.env.REACT_APP_BACKEND_URL

export const getAdminUsers = async (setUsers, token) => {
  try {
    const resp = await fetch(`${baseUrl}/api/admin/user/`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await resp.json()
    const unify = unifyData(data)
    setUsers(() => {
      unify.filter((user) => {
        return user.admin
      })

      return [...unify]
    })
  } catch (error) {
    logger.error(error)
  }
}

export const updateUserRecentTemplates = async (templateId, uid, token) => {
  try {
    const response = await fetch(`${baseUrl}/api/admin/user/recentTemplates`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ templateId: templateId, uid: uid }),
    })
    const responseData = await response.json()
    return { status: response.status, data: responseData }
  } catch (error) {
    logger.error('Update Gathering Error: ', error)
  }
}

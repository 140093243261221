import {
  ApprovalActivityContainer,
  ApprovalActivityDropdownRow,
  DropdownRowIconAndText,
  DropdownMenuWrapper,
  ApprovalActivityBanner,
  ActivityBannerTitle,
  ActivityBannerMessageRow,
  bannerIconStyle,
  ReminderButtonArea,
  dropdownRowIconStyle,
  bannerCloseIcon,
} from './ApprovalActivity.style'
import color from '../../../../util/colors'
import ApprovalItem from './ApprovalItem'
import WarningIcon from '@mui/icons-material/Warning'
import TemplateButton from '../../../TemplateButton/TemplateButton'
import SortIcon from '@mui/icons-material/Sort'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import { useEffect, useState } from 'react'
import { useEvents } from '../../../../hooks/useEvents'
import { convertTimeStamp } from '../../../../util/date'

const ApprovalActivity = ({
  reminderDate,
  setReminderDate,
  formInfo,
  selectedApproversPool,
  setReminderSentTo,
  setReminderActive,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { allCollections } = useEvents()
  const [createdBy, setCreatedBy] = useState({
    id: 0,
    image: '',
    title: '',
    variant: 'individual',
  })

  // eslint-disable-next-line no-unused-vars
  const [assignedTo, setAssignedTo] = useState(null)

  useEffect(() => {
    if (allCollections?.user) {
      const found = allCollections?.user.find((u) => u.uid === formInfo.createdBy)
      setCreatedBy({
        id: found.uid,
        image: '',
        title: found.displayName || found.email,
        variant: 'individual',
      })
    }
  }, [allCollections?.user])

  useEffect(() => {
    if (formInfo?.approval?.reminderSentTo) {
      const foundApprover = formInfo?.approval?.reminderSentTo
      setAssignedTo({
        id: foundApprover?.id,
        image: '',
        title: foundApprover?.displayName || foundApprover?.email,
        variant: 'individual',
      })
    }
  }, [formInfo?.approval?.reminderSentTo])

  return (
    <ApprovalActivityContainer>
      <Divider flexItem sx={{ borderBottomWidth: 1.5, marginBottom: '30px' }}></Divider>
      <ApprovalActivityDropdownRow>
        <DropdownRowIconAndText>
          <SortIcon style={dropdownRowIconStyle} />
          {'Activities'}
        </DropdownRowIconAndText>
        <DropdownMenuWrapper>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={'Most Recent'}
            options={['Most Recent']}
            disableClearable
            disabled
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} />}
            popupIcon={<ArrowDropDownIcon sx={{ color: color.primary }} />}
          />
        </DropdownMenuWrapper>
      </ApprovalActivityDropdownRow>
      {formInfo?.approval?.reminderActive && (
        <ApprovalActivityBanner>
          <ActivityBannerTitle>Reminder</ActivityBannerTitle>
          <ActivityBannerMessageRow>
            <WarningIcon style={bannerIconStyle}></WarningIcon>
            {
              'Would you like to send a reminder as 24 hours have passed, and there is still no approval?'
            }
          </ActivityBannerMessageRow>
          <ReminderButtonArea>
            <TemplateButton
              formInfo={formInfo}
              variant={'approvalReminder'}
              label={'Send Reminder'}
              setReminderDate={setReminderDate}
              setReminderSentTo={setReminderSentTo}
            ></TemplateButton>
            {reminderDate && (
              <ActivityBannerMessageRow>{`Last Reminder Sent: ${convertTimeStamp(
                reminderDate,
              )}`}</ActivityBannerMessageRow>
            )}
          </ReminderButtonArea>
          <CloseIcon style={bannerCloseIcon} onClick={() => setReminderActive(false)} />
        </ApprovalActivityBanner>
      )}

      <ApprovalItem
        stepper={true}
        variant={'created'}
        personData={createdBy}
        timeStamp={convertTimeStamp(formInfo?.created || new Date())}
      ></ApprovalItem>
      {assignedTo && (
        <ApprovalItem
          stepper={false}
          variant={'approval'}
          personData={
            assignedTo || {
              variant: 'individual',
            }
          }
          timeStamp={
            formInfo?.approval?.reminderSent
              ? convertTimeStamp(formInfo?.approval?.reminderSent)
              : 'Approval not sent'
          }
        ></ApprovalItem>
      )}
    </ApprovalActivityContainer>
  )
}

export default ApprovalActivity

import { logger } from '../logger'
import { create } from 'zustand'

const loadPersistedState = (name, defaultValue) => {
  try {
    return JSON.parse(localStorage.getItem(name)) ?? defaultValue
  } catch (error) {
    logger.error(error)
    return {}
  }
}

const savePersistedState = (name, state) => {
  try {
    localStorage.setItem(name, JSON.stringify(state))
  } catch (error) {
    logger.error(error)
    return
  }
}

export const useTemplates = create((set) => ({
  ...loadPersistedState('templates', { templates: [], templatePreview: null, templateHearted: [] }),

  setTemplates: (templates) => {
    set((state) => {
      savePersistedState('templates', { ...state, templates })
      return { ...state, templates }
    })
  },
  setTemplatePreview: (templatePreview) => {
    set((state) => {
      savePersistedState('templates', { ...state, templatePreview })
      return { ...state, templatePreview }
    })
  },

  setTemplateHearted: (templateHearted) => {
    set((state) => {
      savePersistedState('templates', { ...state, templateHearted })
      return { ...state, templateHearted }
    })
  },
}))

import { Button } from '@mui/material'
import styled from 'styled-components'
import colors from '../../../util/colors'

export const StyledButton = styled(Button)`
  color: ${colors.primary}!important;
  text-transform: none !important;

  &:hover {
    background-color: transparent !important;
    opacity: 1 !important;
    color: ${colors.buttonWithIconPrimary}!important;
  }
`

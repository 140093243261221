import * as React from 'react'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PageviewIcon from '@mui/icons-material/Pageview'
import CheckIcon from '@mui/icons-material/Check'
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone'

import { convertDateString } from '../../util/date'

import {
  StyledTableRow,
  StyledTableCell,
  StyledActionsHolder,
  StyledTableCellIconButton,
  StyledTableCellIconButtonHearth,
  StyledTableImageCell,
} from './TemplatesTableCard.style'
import { tableRowColor } from '../../util/helpers'

import { useTemplates } from '../../util/zustant/templateStore'
import { useFormGatherings } from '../../util/zustant/store'
import { useAuth } from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import {
  getTemplate,
  deleteTemplate,
  pickTemplate,
  getAllTemplates,
  heartTemplate,
  getHeartedTemplates,
} from '../../util/templates'
import { useEvents } from '../../hooks/useEvents'
import { useTemplateId } from '../../util/zustant/store'
import PlaceholderImage from '../../assets/placeholderImage.png'
import ModalPrompt from '../ModalPrompt/ModalPrompt'

const TemplatesTableCard = ({ templateData, indexPosition }) => {
  const { setTemplatePreview, setTemplates, setTemplateHearted, templateHearted } = useTemplates()

  const navigate = useNavigate()
  const [highlighted, setHighlighted] = useState(false)
  const [hearted, setHearted] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setFormInfo } = useFormGatherings()
  const { profileData } = useEvents()
  const { setTemplateId } = useTemplateId()
  const userId = profileData.uid
  const { token } = useAuth()
  const [modalStatus, setModalStatus] = useState(false)

  useEffect(() => {
    const findHearted = templateHearted?.find((t) => {
      if (t.templateId === templateData.id) return t
      return null
    })

    getHeartedTemplates(userId, token, setTemplateHearted)
    setLoading(false)
    setHearted(findHearted?.isFavourite ? findHearted.isFavourite : false)
  }, [heartTemplate])

  async function removeTemplate() {
    await deleteTemplate(templateData.id, token)
    getAllTemplates(setTemplates, token)
  }

  return (
    <StyledTableRow
      hover
      onClick={() => setHighlighted(!highlighted)}
      sx={{
        backgroundColor: tableRowColor(indexPosition),
        borderLeft: `5px solid ${tableRowColor(indexPosition)}`,
      }}
    >
      <StyledTableImageCell variant="body">
        <img
          src={templateData?.imageUpload?.eventImage || PlaceholderImage}
          style={{ width: 79, height: 48, borderRadius: 5 }}
          alt="Gathering img"
        ></img>
      </StyledTableImageCell>
      <StyledTableCell variant="body">
        <div>{templateData?.gatheringDetails?.title}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>{convertDateString(templateData?.created)}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>{templateData?.gatheringType?.typeOfGathering}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <StyledActionsHolder>
          <StyledTableCellIconButton
            startIcon={<CheckIcon></CheckIcon>}
            onClick={async () => {
              await pickTemplate(
                templateData.id,
                token,
                setFormInfo,
                navigate,
                userId,
                setTemplateId,
              )
            }}
          >
            Use
          </StyledTableCellIconButton>
          <StyledTableCellIconButton
            onClick={async () => {
              await getTemplate(templateData.id, token, setTemplatePreview, navigate)
            }}
            startIcon={<PageviewIcon></PageviewIcon>}
          >
            Preview
          </StyledTableCellIconButton>
          <StyledTableCellIconButton
            onClick={async () => {
              setTemplateId(templateData.id)
              await getTemplate(templateData.id, token, setTemplatePreview, navigate)
              setFormInfo({ ...templateData, status: 'template' })
              navigate(`/createGathering`)
            }}
            startIcon={<OpenInNewIcon></OpenInNewIcon>}
          >
            Edit
          </StyledTableCellIconButton>
          <StyledTableCellIconButton
            sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
            startIcon={<DeleteForeverOutlinedIcon></DeleteForeverOutlinedIcon>}
            onClick={() => {
              setModalStatus(true)
            }}
          >
            Delete
          </StyledTableCellIconButton>
          <StyledTableCellIconButtonHearth
            onClick={async () => {
              setLoading(true)
              setHearted(!hearted)
              await heartTemplate(templateData.id, userId, token)
              await getHeartedTemplates(userId, token, setTemplateHearted, setLoading)
            }}
            disabled={loading}
            hearted={hearted ? 'true' : 'false'}
            startIcon={<FavoriteTwoToneIcon></FavoriteTwoToneIcon>}
          ></StyledTableCellIconButtonHearth>
        </StyledActionsHolder>
        <ModalPrompt
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          descriptionText={`Delete Template`}
          onClickFunction={removeTemplate}
          variant="deleteTemplate"
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

TemplatesTableCard.propTypes = {
  templateData: PropTypes.object,
  indexPosition: PropTypes.number,
}

export default TemplatesTableCard

import React, { useState, createContext, useContext, useEffect } from 'react'
import { useAuth } from './useAuth'
import { getAllCollections } from '../util/events'
import { useLocation } from 'react-router-dom'
import { logger } from '../util/logger'

const DataContext = createContext(null)

export const DataProvider = ({ children }) => {
  const { token, user } = useAuth()
  const [allCollections, setAllCollections] = useState(null)
  const [searchData, setSearchData] = useState([])
  const [gatherings, setGatherings] = useState([])
  const [experiences, setExperiences] = useState([])
  const [profileData, setProfileData] = useState({})
  const [loading, setLoading] = useState(false)

  const location = useLocation()

  const getProfileData = (user) => {
    setProfileData({
      img: user.photoURL,
      name: user.displayName,
      surname: '',
      role: 'Admin',
      uid: user.uid,
    })
  }

  function prepareSearchData(allCollections, setSearchData) {
    const prepared = Object.keys(allCollections).map((collection) => {
      const collectionName = collection.includes('_new') ? collection.split('_new')[0] : collection
      const collectionObjects = allCollections[collection].map((object) => {
        let newObject = object
        newObject.collection = collectionName
        return newObject
      })
      return { [collection]: collectionObjects }
    })

    const result = prepared.reduce((acc, obj) => {
      const key = Object.keys(obj)[0]
      const value = obj[key]
      acc[key] = value
      return acc
    }, {})

    setSearchData(result)
  }

  useEffect(() => {
    if (user && token) {
      setLoading(true)
      logger.log('Getting new collection data')
      getAllCollections(setAllCollections, token)
      getProfileData(user)
    }
  }, [user, token, location])

  useEffect(() => {
    if (allCollections !== null) {
      prepareSearchData(allCollections, setSearchData)
      setExperiences(allCollections.experience)
      setGatherings(allCollections.gathering)
      setLoading(false)
    }
  }, [allCollections])

  return (
    <DataContext.Provider
      value={{
        allCollections,
        setAllCollections,
        searchData,
        gatherings,
        experiences,
        profileData,
        loading,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export const useEvents = () => useContext(DataContext)

import styled from 'styled-components'

export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  min-width: max-content;

  ${(props) => props.minWidth && `min-width: ${props.minWidth};`};
`
export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0015em;
`

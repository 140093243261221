/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import useProtectedRoute from '../../hooks/useProtectedRoute'
import AllDrafts from '../../components/AllDrafts/AllDrafts'
import CalendarScreen from '../CalendarScreen/CalendarScreen'
import { useEvents } from '../../hooks/useEvents'
import SubHeader from '../../components/SubHeader/SubHeader'
import { MainContainer, ComponentArea } from '../../util/ScreenWrapper.style'
import { useView } from '../../util/zustant/store'

const AllDraftsScreen = () => {
  const [selectedTab, setSelectedTab] = useState('Drafts')
  const [draftData, setDraftData] = useState([])
  const { view } = useView()
  const { profileData, gatherings, experiences, searchData } = useEvents()

  function filterDrafts(gatherings) {
    const filtered = gatherings.filter((g) => g.status === 'draft')
    setDraftData(filtered)
  }

  useEffect(() => {
    if (gatherings?.length > 0) {
      filterDrafts(gatherings)
    }
  }, [gatherings])

  const { authenticated, token } = useAuth()

  useProtectedRoute()

  if (!authenticated || !profileData) return <></>

  return (
    <MainContainer>
      {/* Components Area*/}
      <ComponentArea>
        {/* Top Row Area 2*/}
        <SubHeader
          variant={'createGathering'}
          setSelectedTabParent={setSelectedTab}
          activeTabOnParent={selectedTab}
        ></SubHeader>

        {/* Screens Section Area */}
        {view === 1 ? (
          <AllDrafts drafts={draftData} token={token} setDrafts={setDraftData}></AllDrafts>
        ) : (
          <CalendarScreen eventData={draftData}></CalendarScreen>
        )}
      </ComponentArea>
    </MainContainer>
  )
}

export default AllDraftsScreen

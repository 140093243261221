import color from '../../util/colors'

const ChecklistAccordionIconStyle = {
  fontSize: 17,
  color: color.primary,
  marginLeft: '1.5rem',
  marginRight: '0.7rem',
}

const ChecklistCheckIconStyle = {
  fontSize: 17,
  backgroundColor: color.checklistCompleteBadgeBackground,
  borderRadius: 20,
  color: color.checklistCompleteBadgeColor,
  marginLeft: '1.5rem',
  marginRight: '0.7rem',
}

const ChecklistAlertIconStyle = {
  fontSize: 17,
  borderRadius: 20,
  color: color.backgroundPrimary,
  marginLeft: '1.5rem',
  marginRight: '0.7rem',
}

const ChecklistArrowIconStyle = {
  fontSize: 17,
  color: color.description,
  marginLeft: '1.5rem',
  marginRight: '0.7rem',
}

const StatusHandlerDiv = {
  width: '25%',
  display: 'flex',
  justifyContent: 'center',
}

const alertBoxStyle = {
  background: color.notificationBadge,
  color: color.backgroundPrimary,
}

const alertBoxIcon = {
  color: color.backgroundPrimary,
}

export {
  ChecklistAccordionIconStyle,
  ChecklistCheckIconStyle,
  ChecklistArrowIconStyle,
  StatusHandlerDiv,
  ChecklistAlertIconStyle,
  alertBoxStyle,
  alertBoxIcon,
}

import Button from '@mui/material/Button'
import styled from 'styled-components'
import color from '../../util/colors'

export const ButtonStyle = styled(Button)`
  background: ${color.primary} !important;
  border-radius: 20px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: none !important;
  width: max-content !important;
  text-transform: none !important;
  height: 35px;

  ${(props) =>
    props.disabled &&
    `
  background: ${color.disabled} !important;
  color: ${color.backgroundPrimary} !important;
  `}
`

import React from 'react'
import {
  OccurrenceHolder,
  OccurrenceTitleOccurrence,
  Row,
  Label,
  CheckboxLabel,
} from './LogisticsInfo.styles'
import { Checkbox } from '@mui/material'
import DateInput from '../../Form/components/DateInput'
import TimeInput from '../../Form/components/TimeInput'

import color from '../../../util/colors'

function Occurrence({ order = 1, event, SetOccurrences, open }) {
  return (
    <OccurrenceHolder>
      <Row style={{ flexDirection: 'column', gap: 0 }}>
        <OccurrenceTitleOccurrence>Occurrence {open}</OccurrenceTitleOccurrence>
        <hr
          style={{
            borderTop: `1px dashed ${color.loginPrimary}`,
            height: 0,
            width: '100%',
            marginBottom: '48px',
          }}
        />
      </Row>
      <Row>
        <Label>Start Date</Label>
        <DateInput
          onChange={(v) => SetOccurrences(v, 'startDate', order)}
          value={event?.occurrences[order]?.startDate}
        />
        <Label>Start Time</Label>
        <TimeInput
          onChange={(v) => SetOccurrences(v, 'startTime', order)}
          value={
            event?.occurrences[order]?.startTime
              ? event?.occurrences[order]?.startTime
              : event?.startTime
          }
        />
        <Label textAlign="right">Skip this event:</Label>
        <CheckboxLabel
          control={
            <Checkbox
              onChange={(v) => {
                SetOccurrences(v.target.checked, 'skip', order)
              }}
              checked={event?.occurrences[order]?.skip}
            />
          }
          label=""
        />
      </Row>
    </OccurrenceHolder>
  )
}

export default Occurrence

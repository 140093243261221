import { uploadFileToCloudStorage } from './cloudStorage'

const handleFileUpload = async (event, setFiles, onDrop, authToken) => {
  let uploadedFiles = []
  if (onDrop) {
    uploadedFiles = event.dataTransfer.files
  } else {
    uploadedFiles = event.target.files
  }

  if (uploadedFiles) {
    const formData = new FormData()
    for (let i = 0; i < uploadedFiles.length; i++) {
      formData.append('files', event.target.files[i])
    }

    const response = await uploadFileToCloudStorage(formData, authToken)
    setFiles((prevFiles) => [...prevFiles, ...response.files])
  }
}

async function uploadWaiversToTickets(event, setFiles, onDrop, authToken, activeTicket) {
  let uploadedFiles = []
  if (onDrop) {
    uploadedFiles = event.dataTransfer.files
  } else {
    uploadedFiles = event.target.files
  }

  if (uploadedFiles) {
    const formData = new FormData()
    for (let i = 0; i < uploadedFiles.length; i++) {
      formData.append('files', event.target.files[i])
    }

    const response = await uploadFileToCloudStorage(formData, authToken)
    if (response) {
      for (let i = 0; i < response.files.length; i++) {
        let newTagObject = { ...response.files[i], id: new Date().getTime() + i }
        setFiles((prevState) => {
          if (!prevState.hasOwnProperty(activeTicket.name)) {
            return { ...prevState, [activeTicket.name]: [newTagObject] }
          }
          const existingArray = Array.isArray(prevState[activeTicket.name])
            ? prevState[activeTicket.name]
            : []
          const tagExists = existingArray.some((item) => item.filename === newTagObject.filename)

          if (!tagExists) {
            return {
              ...prevState,
              [activeTicket.name]: [...existingArray, newTagObject],
            }
          }
          return prevState
        })
      }
    }
  }
}

const handleFileRemoval = (id, setFiles, activeTicket) => {
  setFiles((previousFiles) => {
    if (activeTicket) {
      let updatedFiles = { ...previousFiles }
      activeTicket.forEach((activeTicket) => {
        updatedFiles[activeTicket.name] = previousFiles[activeTicket.name].filter(
          (file) => file.id !== id,
        )
      })
      return updatedFiles
    } else {
      const updatedFiles = previousFiles.filter((file) => file.id !== id)
      return updatedFiles
    }
  })
}

const handleDrop = (event, setFiles, authToken) => {
  event.preventDefault()
  handleFileUpload(event, setFiles, true, authToken)
}

const handleDragOver = (event) => {
  event.preventDefault()
}

export { handleFileUpload, handleFileRemoval, handleDrop, handleDragOver, uploadWaiversToTickets }

import styled from 'styled-components'
import color from '../../../../util/colors'

const ApprovalItemContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`

const ApprovalActivityItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1.3rem;
  @media (max-width: 1024px) {
    padding-bottom: 3rem;
  }
`
const ApprovalActivityItemFirstRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 15px;
  }
`

const FirstRowIconArea = styled.div`
  width: 20px;
  padding: 0 1rem 0 1rem;
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
    padding: 0;
  }
`

const firstRowIconStyle = {
  fontSize: '25px',
  color: color.timestamp,
}

const FirstRowText = styled.div`
  width: 150px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: var(--title);
  letter-spacing: 0.004em;
`
const GroupFrameArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  column-gap: 10px;
  align-items: center;
`

const ApprovalActivityItemSecondRow = styled.div`
  display: flex;
  width: 180px;
  align-items: center;
  @media (max-width: 1024px) {
    padding: 0;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
`

const TimestampText = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  color: var(--timestamp);
  padding: 0.5rem 0 0.5rem 3.4rem;
  @media (max-width: 1024px) {
    padding: 0;
  }
`

const StepperWrapper = styled.div`
  position: relative;
  @media (max-width: 1024px) {
    display: none;
  }
`

const stepperStyle = {
  height: 60,
  paddingLeft: '25px',
  position: 'absolute',
  top: '-20px',
}

export {
  ApprovalItemContainer,
  ApprovalActivityItem,
  ApprovalActivityItemFirstRow,
  FirstRowIconArea,
  firstRowIconStyle,
  FirstRowText,
  GroupFrameArea,
  ApprovalActivityItemSecondRow,
  TimestampText,
  StepperWrapper,
  stepperStyle,
}

import styled from 'styled-components'

const TagGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 1.5rem 0;
  width: 100%;
`

const TagGridNotifText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.004em;
  padding-bottom: 1rem;
  color: var(--title);
`

const TagGridArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 1rem;
  row-gap: 1rem;
  column-gap: 2rem;
`
export { TagGridContainer, TagGridNotifText, TagGridArea }

import { RadioGroup, Radio, FormControlLabel } from '@mui/material'
import './SelectBasicInfo.css'
import color from '../../util/colors'
import { useState, useEffect } from 'react'
import { customRadioStyle } from './SelectBasicInfo.style'
import { handleSelectedChoice, handleSelectedType } from '../../util/selectBasicInfo'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'

const SelectBasicInfo = ({
  title,
  choices,
  variant,
  formInfo,
  setPaidEvent,
  setGatheringType,
  paidGatheringType,
  setGatheringPublish,
  setGatheringVisibility,
  setCheckinMethod,
  setPeopleCheck,
  setAlert,
  setUserReminders,
}) => {
  const [selectedChoice, setSelectedChoice] = useState(
    handleSelectedChoice(variant, formInfo, choices),
  )
  const [selectedType, setSelectedType] = useState(handleSelectedType(variant, formInfo, choices))

  useEffect(() => {
    if (variant === 'paidEvent') {
      setPaidEvent(selectedChoice)
    } else if (variant === 'attendees') {
      setGatheringType(selectedChoice)
    } else if (variant === 'publishTime') {
      setGatheringPublish(selectedChoice)
    } else if (variant === 'publishVisibility') {
      setGatheringVisibility(selectedChoice)
    } else if (variant === 'security') {
      setPeopleCheck(selectedChoice)
    } else if (variant === 'reminders') {
      setUserReminders(selectedChoice)
    } else if (variant === 'checkinMethod') {
      setCheckinMethod(selectedChoice)
    }
  }, [selectedChoice])

  useEffect(() => {
    if (variant === 'gatheringType') {
      paidGatheringType(selectedType)
    }
  }, [selectedType])

  return (
    <div className="selectBasicInfoContainer">
      <p className="checkbox-title">{title}</p>
      <RadioGroup
        style={{ padding: '1.5rem 0 1.5rem 0' }}
        value={choices.length < 3 ? selectedChoice : selectedType}
        onChange={(e) => {
          choices.length < 3 ? setSelectedChoice(e.target.value) : setSelectedType(e.target.value)
        }}
        row
        sx={{ justifyContent: 'flex-start', padding: '1rem', flexWrap: 'nowrap' }}
        className="radio-group"
      >
        {choices.map((choice, i) => (
          <div
            key={i}
            className="radio-wrapper"
            onClick={() => {
              choices.length < 3 ? setSelectedChoice(choice.value) : setSelectedType(choice.value)
            }}
            style={{
              backgroundColor: choice.color,
              cursor: 'pointer',
            }}
          >
            <FormControlLabel
              sx={{
                color: color.backgroundPrimary,
                paddingLeft: 0.5,
              }}
              control={<Radio sx={customRadioStyle} />}
              label={choice.title}
              value={choice.value}
            />
            {choice.hasIcon && (
              <div
                className="radio-wrapper-icon"
                onMouseEnter={() =>
                  setAlert((prevState) => ({
                    ...prevState,
                    alertStatus: true,
                    alertMessage: choice.alertMessage,
                    alertBackground: choice.color,
                  }))
                }
              >
                <ErrorRoundedIcon style={{ color: color.backgroundPrimary }} />
              </div>
            )}
          </div>
        ))}
      </RadioGroup>
    </div>
  )
}

export default SelectBasicInfo

import { useState } from 'react'
import NavbarOutlinedButton from '../NavbarOutlinedButton/NavbarOutlinedButton'
import TemplatesFilter from './TemplatesFilter/TemplatesFilter'
import SearchComponent from '../SearchComponent/SearchComponent'
import {
  MainContentArea,
  TextandButtonsArea,
  TextArea,
  SectionMainText,
  ButtonArea,
  TemplatesCreateArea,
  TemplatesInfo,
  TemplatesFilterArea,
  FilterText,
} from './TemplatesViewFilter.Styled'

const TemplatesViewFilter = ({
  setSearchData,
  searchData,
  filter,
  templates,

  visibleRows,
}) => {
  const [open, setOpen] = useState(false)
  const [filterType, setFilterType] = useState('title')

  return (
    <MainContentArea>
      {/* First Text Area */}
      <TemplatesCreateArea>
        <TextandButtonsArea>
          <TextArea>
            <SectionMainText>All templates</SectionMainText>
            <TemplatesInfo>
              Showing <span>{visibleRows?.length} </span> of {templates?.length} templates
            </TemplatesInfo>
          </TextArea>

          <ButtonArea>
            <NavbarOutlinedButton
              onClickFunction={() => setOpen((o) => !o)}
              buttonText={'Filters'}
              iconName={open ? 'horizontal line' : 'filter'}
            ></NavbarOutlinedButton>
          </ButtonArea>
        </TextandButtonsArea>
      </TemplatesCreateArea>

      <TemplatesFilterArea open={open}>
        <FilterText>Filter gathering templates by title:</FilterText>
        <div>
          <SearchComponent
            filter={filter}
            key={setSearchData}
            searchData={searchData}
            setFilter={setSearchData}
            variant={'template'}
            filterType={filterType}
            loading={searchData.length === 0}
          ></SearchComponent>
        </div>
        <TemplatesFilter setFilterType={setFilterType} setFilter={setSearchData}></TemplatesFilter>
      </TemplatesFilterArea>
    </MainContentArea>
  )
}

export default TemplatesViewFilter

import { logger } from './logger'
import { createNewProduct, createNewPromoCode } from './stripe'

export const createThirdPartyObjects = async (formInfo, token) => {
  const { paymentsInfo } = formInfo
  if (paymentsInfo?.tickets?.length > 0) {
    try {
      await Promise.all(
        paymentsInfo.tickets.map(async (ticket) => {
          let product
          if (ticket?.name !== '') {
            product = await createNewProduct(ticket, token)
            if (!product) {
              logger.error("Couldn't create new product")
              throw new Error("Couldn't create new product")
            }
            logger.log(product, 'Successfully created new product')
          }
          if (ticket?.discountCodes?.length > 0 && product?.stripe?.id) {
            await Promise.all(
              ticket?.discountCodes.map(async (promoCode) => {
                if (promoCode?.name !== '') {
                  const promo = await createNewPromoCode(promoCode, token, product?.stripe?.id)
                  if (!promo) {
                    logger.error("Couldn't create new promo code")
                    throw new Error("Couldn't create new promo code")
                  }
                  logger.log(promo, 'Successfully created a new promo code')
                }
              }),
            )
          }
        }),
      )
      return true // Return true if all operations are successful
    } catch (error) {
      logger.error(error)
      return false // Return false if any error occurs
    }
  }
  return true // Return true if there are no tickets (no operations were performed)
}

import styled from 'styled-components'
import Chip from '@mui/material/Chip'

const StyledMuiChip = styled(Chip)({
  '&.MuiChip-root': {
    height: '32px',
    padding: '7px 3.5px 7px 3.5px',
    gap: '8px',
    borderRadius: '50px',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  '&.MuiChip-root .MuiChip-avatar': {
    width: '24.11px',
    height: '24.11px',
    marginLeft: '0px',
  },
  '&.MuiChip-root .MuiChip-label': {
    paddingLeft: '7px',
    fontSize: '10px',
    fontWeight: 600,
  },
  '&.MuiChip-root .MuiChip-deleteIcon': {
    fontSize: '16px',
  },
})

export { StyledMuiChip }

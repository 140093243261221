import { useState, useEffect } from 'react'
import {
  ImageUploadContainer,
  ImageUploadButton,
  imageUploadIcon,
  ImageButtonWrapper,
  ImageUploadBanner,
  BannerText,
  BannerMessageWrapper,
  BannerMessageIconArea,
  BannerMessageTextArea,
  BannerMessageText,
  bannerIconStyle,
} from './ImageUploadComponent.style'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { handleImageUpload, handleDrop, handleDragOver } from '../../util/imageUploadComponent'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const ImageUploadComponent = ({ formInfo, setImage, authToken, error }) => {
  const [uploadedImage, setUploadedImage] = useState(
    formInfo?.eventImage ? formInfo?.eventImage : {},
  )
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setImage(uploadedImage)
  }, [uploadedImage])

  return (
    <ImageUploadContainer>
      <input
        id="image-upload"
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={async (event) => {
          setLoader(true)
          const response = await handleImageUpload(event, setUploadedImage, false, authToken)
          if (response) {
            event.target.value = ''
            setLoader(false)
          }
        }}
      />
      <ImageButtonWrapper
        htmlFor="image-upload"
        onDrop={(event) => handleDrop(event, setUploadedImage)}
        onDragOver={(event) => handleDragOver(event)}
      >
        <ImageUploadButton
          style={{ border: error && '2px solid red' }}
          variant="contained"
          component="span"
        >
          <CloudUploadIcon sx={imageUploadIcon} />
          Browse Images or Drag and Drop to Upload
        </ImageUploadButton>
      </ImageButtonWrapper>
      <ImageUploadBanner>
        <BannerText>{'File format info:'}</BannerText>
        <BannerMessageWrapper>
          <BannerMessageIconArea>
            <ErrorRoundedIcon style={bannerIconStyle} />
          </BannerMessageIconArea>
          <BannerMessageTextArea>
            <BannerMessageText>The image will zoom or crop accordingly.</BannerMessageText>
            <BannerMessageText>Image file format can be either JPG or PNG.</BannerMessageText>
          </BannerMessageTextArea>
        </BannerMessageWrapper>
      </ImageUploadBanner>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </ImageUploadContainer>
  )
}

export default ImageUploadComponent

/* eslint-disable no-unused-vars */
import {
  MainContainer,
  ComponentArea,
  TitleText,
  TopRowArea,
  TopRowButtonArea,
  MainContentArea,
  GatheringScreensArea,
  ChecklistComponentArea,
} from './GatheringsCreationScreen.style'
import { useEffect, useMemo, useState } from 'react'
import SubHeader from '../../components/SubHeader/SubHeader'
import TemplateButton from '../../components/TemplateButton/TemplateButton'
import LogisticsInfo from '../../components/GatheringCreation/Logistics/LogisticsInfo'
import Checklist from '../../components/Checklist/Checklist'
import { useNavigate, useLocation } from 'react-router'
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded'
import { useFormGatherings, useTemplateId } from '../../util/zustant/store'
import Form from '../../components/Form/Form'
import AddDetails from '../../components/AddDetails/AddDetails'
import useProtectedRoute from '../../hooks/useProtectedRoute'
import { useAuth } from '../../hooks/useAuth'
import AdditionalInfo from '../../components/GatheringCreation/AdditionalInfo/AdditionalInfo'
import AtendeesScreen from '../../components/GatheringCreation/Atendees/AtendeesScreen'
import { groupData } from '../../util/testData'
import Payments from '../../components/GatheringCreation/Payments/Payments'
import RegistrationConfirmation from '../../components/RegistrationConfirmation/RegistrationConfirmation'
import { createGathering, getGatheringById, updateGathering } from '../../util/gatherings'
import { createTemplate, updateTemplate } from '../../util/templates'
import { logger } from '../../util/logger'
import CheckinKiosk from '../../components/GatheringCreation/CheckinKiosk/CheckinKiosk'
import ChosePaid from '../../components/GatheringCreation/ChosePaid/ChosePaid'
import GatheringType from '../../components/GatheringCreation/GatheringType/GatheringType'
import Onboarding from '../../components/GatheringCreation/Onboarding/Onboarding'
import Tags from '../../components/GatheringCreation/Tags/Tags'
import Publish from '../../components/GatheringCreation/Publish/Publish'
import ImageUpload from '../../components/GatheringCreation/ImageUpload/ImageUpload'
import UrlSlug from '../../components/GatheringCreation/UrlSlug/UrlSlug'
import Security from '../../components/GatheringCreation/Security/Security'
import Reminders from '../../components/GatheringCreation/Reminders/Reminders'
import Approval from '../../components/GatheringCreation/Approval/Approval'
import CheckinType from '../../components/GatheringCreation/CheckinType/CheckinType'
import RosterCheckin from '../../components/GatheringCreation/RosterCheckin/RosterCheckin'
import RosterCheckinKioskSetup from '../../components/GatheringCreation/RosterCheckinKioskSetup/RosterCheckinKioskSetup'
import Preview from '../../components/GatheringCreation/Preview/Preview'
import { useEvents } from '../../hooks/useEvents'
import { useLabel } from '../../util/zustant/labelStore'
import { saveLabels, updateLabel } from '../../util/labels'
import { useOnboarding } from '../../util/zustant/onboardingStore'
import { saveOnboarding } from '../../util/onboarding'
const GatheringsCreationScreen = () => {
  const [selectedButton, setSelectedButton] = useState('tasks')
  const [selectedTab, setSelectedTab] = useState('Home')
  const [loading, setLoading] = useState(false)
  const [loadingTemplate, setLoadingTemplate] = useState(false)

  useProtectedRoute()

  const [checklistVariant, setChecklistVariant] = useState('default')
  // temporary data
  const groups = groupData(10)

  //TODO:This will be passed into checklist component and used for furhter tracking, after checklist is approved from the QA.

  // zustand store

  const { templateId, setTemplateId } = useTemplateId()
  const { profileData } = useEvents()
  const { labelsStore, setLabelsStore } = useLabel()
  const [previewStatus, setPreviewStatus] = useState(false)
  const userId = profileData.uid

  const { formInfo, setFormInfo } = useFormGatherings()
  const navigate = useNavigate()
  const { authenticated, token } = useAuth()
  const activeStep = formInfo?.activeStep
  const activeStepLabel = formInfo?.activeStepLabel
  const gatheringId = formInfo?.id
  const { onboardingStore, setOnboardingStore } = useOnboarding()
  const { state } = useLocation()
  const gathering = state?.gathering
  const passedToken = state?.token

  function setterPreview() {
    setPreviewStatus((prevState) => !prevState)
  }

  async function saveDraft() {
    setLoading(true)
    if (token) {
      logger.log('loading', loading)
      logger.log('Creating/Updating Gathering...', gatheringId)
      if (gatheringId) {
        let updatedGathering = formInfo
        updateGathering.title = formInfo?.gatheringDetails?.title
        const response = await updateGathering(updateGathering, gatheringId, token)
        setLoading(false)
        logger.log('Updated Gathering...', response?.data)
      } else {
        const response = await createGathering(formInfo, token)
        setFormInfo({
          ...formInfo,
          id: response?.id,
          createdBy: response.data.createdBy,
          created: response.data.created,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        setLoading(false)
        logger.log('Created Gathering...', response?.id)
      }

      if (activeStep === 'Security') saveLabels(token, labelsStore, setLabelsStore)
      if (activeStep === 'Onboarding') saveOnboarding(token, onboardingStore, setOnboardingStore)
      if (activeStep === 'Registration') setterPreview()
    }
  }

  async function saveTemplate() {
    setLoadingTemplate(true)
    if (token) {
      logger.log('loading', loadingTemplate)
      logger.log('Creating/Updating Template...', templateId)
      if (templateId) {
        const response = await updateTemplate(formInfo, templateId, token)
        setLoadingTemplate(false)
        logger.log('Updated Template...', response)
      } else {
        const response = await createTemplate(formInfo, token, userId, activeStep)
        setTemplateId(response?.id)
        setLoadingTemplate(false)
        logger.log('Created Template...', response?.id)
      }
    }
  }

  async function fillInGathering(id) {
    setLoading(true)
    setFormInfo({})
    const gathering = await getGatheringById(id, token)
    setFormInfo(gathering.data)
    setLoading(false)
  }

  useEffect(() => {
    if (!gathering && (!formInfo || !formInfo.activeStep || !formInfo.highestStep)) {
      setFormInfo({ activeStep: 1, highestStep: 1, status: 'draft' })
    } else if (gathering) {
      fillInGathering(gathering, passedToken)
    }
  }, [gathering])

  const forms = [
    {
      stepTitle: 'Basic Info',
      component: <AddDetails firstRowLabel="Title of the gathering" secondRowLabel="Description" />,
    },
    { stepTitle: 'Choose Gathering Type', component: <GatheringType /> },
    { stepTitle: 'Choose Paid or Unpaid', component: <ChosePaid /> },
    { stepTitle: 'Logistics', component: <LogisticsInfo /> },
    {
      stepTitle: 'Attendees',
      component: <AtendeesScreen groupData={groups} token={token} />,
    },
    { stepTitle: 'Payments', component: <Payments /> },
    { stepTitle: 'Additional Info', component: <AdditionalInfo authToken={token} /> },
    {
      stepTitle: 'Registration',
      component: (
        <RegistrationConfirmation setterPreview={setterPreview} previewStatus={previewStatus} />
      ),
    },
    { stepTitle: 'Reminders', component: <Reminders setterPreview={setterPreview} /> },
    { stepTitle: 'Checkins', component: <CheckinType /> },
    {
      stepTitle: 'Checkins via Roster > Open',
      component: <RosterCheckin groupData={groups} authToken={token} />,
    },
    {
      stepTitle: 'Checkins via Roster > Invite only',
      component: <RosterCheckin groupData={groups} inviteOnly={true} />,
    },
    {
      stepTitle: 'Checkins via Roster > Open > Kiosk',
      component: <RosterCheckinKioskSetup />,
    },
    {
      stepTitle: 'Checkins via Roster > Invite only > Kiosk',
      component: <RosterCheckinKioskSetup inviteOnly />,
    },
    { stepTitle: 'Checkins via Kiosk', component: <CheckinKiosk groupData={groups} /> },
    { stepTitle: 'Onboarding', component: <Onboarding /> },
    { stepTitle: 'Security', component: <Security /> },
    { stepTitle: 'Publish', component: <Publish /> },
    { stepTitle: 'Tags', component: <Tags /> },
    { stepTitle: 'Image Upload', component: <ImageUpload authToken={token} /> },
    { stepTitle: 'URL / Slug', component: <UrlSlug /> },
    { stepTitle: 'Approval', component: <Approval saveDraft={saveDraft} /> },
    { stepTitle: 'Preview', component: <Preview /> },
  ]

  if (!authenticated) return <></>

  return (
    <MainContainer>
      <SubHeader
        setSelectedButtonParent={setSelectedButton}
        setSelectedTabParent={setSelectedTab}
        activeTabOnParent={selectedTab}
        SecondIcon={TableRowsRoundedIcon}
      ></SubHeader>
      <ComponentArea>
        <TopRowArea>
          {formInfo?.status !== 'template' ? (
            <TitleText>
              {activeStep < 4
                ? 'Create a gathering'
                : activeStep < 9
                ? 'Add details'
                : 'Confirmation'}
            </TitleText>
          ) : (
            <TitleText>Edit Template</TitleText>
          )}

          <TopRowButtonArea>
            {formInfo?.status !== 'template' && (
              <TemplateButton
                saveDraft={saveDraft}
                variant={'draft'}
                label={'Save Draft'}
                saveLabel={'Draft Saved'}
                loading={loading}
                templateStatus={formInfo?.status}
              ></TemplateButton>
            )}

            <TemplateButton
              saveTemplate={saveTemplate}
              variant={'template'}
              label={
                formInfo?.status !== 'template' && !templateId ? 'Make Template' : 'Save Template'
              }
              loading={loadingTemplate}
            ></TemplateButton>
            <TemplateButton
              onClick={() => navigate('/gatherings')}
              variant={'seeAll'}
              label={'See All Gatherings'}
            ></TemplateButton>
            <TemplateButton
              variant={'seeAll'}
              label={'See All Experiences'}
              onClick={() => navigate('/gatherings')}
            ></TemplateButton>
          </TopRowButtonArea>
        </TopRowArea>
        <MainContentArea>
          <GatheringScreensArea>
            {forms.map(
              (form, index) =>
                activeStepLabel === form.stepTitle && (
                  <Form key={index} title={form.stepTitle} setterPreview={setterPreview}>
                    {form.component}
                  </Form>
                ),
            )}
          </GatheringScreensArea>
          <ChecklistComponentArea>
            <Checklist></Checklist>
          </ChecklistComponentArea>
        </MainContentArea>
      </ComponentArea>
    </MainContainer>
  )
}

export default GatheringsCreationScreen

import { useFormGatherings } from '../../../util/zustant/store.js'
import { useState, useEffect } from 'react'
import QuestionCreation from '../../QuestionCreation/QuestionCreation.jsx'
import FileUpload from '../../FileUpload/FileUpload.jsx'
import Message from '../../Form/components/Message.jsx'
import { TicketPickerRow, TicketPickerRowText } from './AdditionalInfo.style.js'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import color from '../../../util/colors.js'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

const AdditionalInfo = ({ authToken }) => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [additionalInfo, setAdditionalInfo] = useState(
    formInfo?.additionalInfo ? formInfo?.additionalInfo : {},
  )
  const [paymentsInfo, setPaymentsInfo] = useState(
    formInfo?.paymentsInfo ? formInfo?.paymentsInfo : {},
  )
  const ticketPool = formInfo?.paymentsInfo?.tickets
  const [selectedTicket, setSelectedTicket] = useState()
  const paidEvent = formInfo?.paidEvent?.eventIsPaid

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.additionalInfo = additionalInfo
    setFormInfo(newFormInfo)
  }, [additionalInfo])

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.paymentsInfo = paymentsInfo
    setFormInfo(newFormInfo)
  }, [paymentsInfo])

  // Store user uploaded data
  function setUploadedData(data) {
    setAdditionalInfo((info) => {
      info.uploadedData = data
      return { ...info }
    })
  }

  // Store user questions.
  function setUserQuestions(questions) {
    setAdditionalInfo((info) => {
      info.savedQuestions = questions
      return { ...info }
    })
  }

  function addQuestionsToTicket(questions) {
    setPaymentsInfo((info) => {
      info.questions = questions
      return { ...info }
    })
  }

  function addWaiversToTicket(waivers) {
    setPaymentsInfo((info) => {
      info.waivers = waivers
      return { ...info }
    })
  }

  function setWaiverExamples(examples) {
    setAdditionalInfo((info) => {
      info.waiverExamples = examples
      return { ...info }
    })
  }

  return (
    <>
      {paidEvent === 'Paid' &&
      formInfo?.paymentsInfo?.tickets &&
      formInfo?.paymentsInfo?.tickets.length > 0 &&
      formInfo?.paymentsInfo?.tickets[0] &&
      formInfo?.paymentsInfo?.tickets[0].name &&
      formInfo?.paymentsInfo?.tickets[0].name.length !== '' ? (
        <>
          <Message translateY={'-8px'}>
            Select a ticket to which you want to add questions and waivers
          </Message>
          <TicketPickerRow>
            <TicketPickerRowText>Active Ticket</TicketPickerRowText>
            <Autocomplete
              disablePortal
              multiple
              id="combo-box-demo"
              options={ticketPool.map((ticket) => ticket)}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ''}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              sx={{ width: '100%' }}
              onChange={(event, value) => {
                //const ticketObject = ticketPool.find((ticket) => ticket.name === value)
                setSelectedTicket(value)
              }}
              renderInput={(params) => <TextField {...params} label="Select ticket" />}
              popupIcon={<ExpandMoreIcon sx={{ color: color.primary }} />}
            />
          </TicketPickerRow>
        </>
      ) : (
        <Message translateY={'-8px'}>
          {paidEvent === 'Paid' ? (
            'Please return to Payments Screen and fill the required ticket fields'
          ) : (
            <>
              These &nbsp;<strong>questions</strong>&nbsp;and&nbsp;<strong>&nbsp;waivers</strong>
              &nbsp;apply to all tickets and they are&nbsp;
              <strong>OPTIONAL</strong>
            </>
          )}
        </Message>
      )}
      <QuestionCreation
        setUserQuestions={setUserQuestions}
        formInfo={formInfo.additionalInfo}
        activeTicket={selectedTicket}
        addQuestionsToTicket={addQuestionsToTicket}
        ticketInfo={paymentsInfo}
        paidEvent={paidEvent}
      ></QuestionCreation>
      <FileUpload
        formInfo={formInfo.additionalInfo}
        setUploadedData={setUploadedData}
        authToken={authToken}
        waiverExamples={additionalInfo?.waiverExamples}
        setWaiverExamples={setWaiverExamples}
        addWaiversToTicket={addWaiversToTicket}
        waiverInfo={paymentsInfo}
        paidEvent={paidEvent}
        activeTicket={selectedTicket}
      ></FileUpload>
    </>
  )
}

export default AdditionalInfo

import styled from 'styled-components'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

const KioskContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const KioskHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.background && `background: ${props.background};`}
  padding-left: 2rem;
  padding-right: 2rem;
  min-height: 67px;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    row-gap: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`

const KioskHeaderImageArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    row-gap: 10px;
    margin-top: 20px;
  }
`

const KioskHeaderImage = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`

const KioskHeaderTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.001em;
  color: var(--backgroundPrimary);
  display: flex;
  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
`

const KioskHeaderIconAndText = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: var(--backgroundPrimary);
  width: 30%;
  column-gap: 15px;
  padding-right: 1.5rem;
  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
`

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
`

const headerIconStyle = {
  paddingBottom: 0.5,
}

const KioskAccordion = styled(Accordion)({})

const KioskAccordionSummary = styled(AccordionSummary)({
  '&.MuiAccordionSummary-root': {
    padding: 0,
  },
})

const KioskAccordionDetails = styled(AccordionDetails)({
  '&.MuiAccordionDetails-root': {
    minHeight: '120px',
    padding: '1rem',
  },
})

export {
  KioskContainer,
  KioskAccordion,
  KioskAccordionSummary,
  KioskAccordionDetails,
  KioskHeader,
  KioskHeaderImageArea,
  KioskHeaderImage,
  KioskHeaderTitle,
  KioskHeaderIconAndText,
  IconBox,
  headerIconStyle,
}

function saveQrCode(elementId) {
  const svgElement = document.getElementById(elementId)
  const svgString = new XMLSerializer().serializeToString(svgElement)
  const anchor = document.createElement('a')
  anchor.href = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString)
  anchor.download = 'eventQRCode.svg'
  anchor.click()
}

export { saveQrCode }

import styled from '@emotion/styled'
import color from '../../../util/colors'
export const MessageHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: ${color.backgroundSecondary};
  ${($props) => $props.background && `background: ${$props.background};`}
  border-radius: 6px;
  padding: 16px;
  box-sizing: border-box;
  height: max-content;
  ${(props) => props.gap && `gap: ${props.gap};`}

  ${(props) => props.translateY && `transform: translateY(${props.translateY});`}
`
export const IconHolder = styled.div`
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  svg {
    width: 100%;
    height: 100%;
    display: inline-block;
    color: ${color.noColor};
  }
  margin-bottom: auto;
`

export const MessageText = styled.div`
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.004em;
  color: ${color.title};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${($props) => $props.center === true && ` align-items: center;`}
  svg {
    color: ${color.primary};
    margin: 0 4px;
    display: inline-block;
  }
  span {
    padding-top: 4px;
  }
`

export const MessageRow = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  background: ${color.backgroundSecondary};
  ${($props) => $props.background && `background: ${$props.background};`}
  border-radius: 6px;
  box-sizing: border-box;
  height: max-content;
  ${(props) => props.translateY && `transform: translateY(${props.translateY});`}
`

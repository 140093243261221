import styled from 'styled-components'
import color from '../../util/colors'

export const modalPreviewContainer = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '400px',
  minHeight: '150px',
  height: '200px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '2rem 1rem 2rem 1rem',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  rowGap: '30px',
}

export const modalTopIcon = {
  color: color.primary,
  fontSize: '80px',
}

export const DecriptionText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const ButtonRow = styled.div`
  display: flex;
  column-gap: 50px;
`

import React, { useEffect, useRef } from 'react'
import {
  TicketContainer,
  TicketStyle,
  TicketHeader,
  HeaderLabel,
  SettingsHolder,
  Box,
  SettingsWrapper,
  CopyHolder,
  PaddingContainer,
  RadioGroupStyle,
  CheckboxLabel,
} from './Ticket.styles'
import { Divider, Label, Row } from './Payments.styles'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import Input from '../../Form/components/Input'
import Message from '../../Form/components/Message'
import { useState } from 'react'
import NavbarOutlinedButton from '../../NavbarOutlinedButton/NavbarOutlinedButton'
import { Title } from '../../Form/FormElements.styles'
import Selector from '../../Form/components/Selector'
import Copy from '../../Form/components/Copy'
import RetrievedFields from './RetrievedFields'
// import QuestionFields from './QuestionFields'
import { Radio } from '@mui/material'
import Teams from './Teams'
import SummaryBox from './Summary'
import { checkProductName, checkPromotionCode } from '../../../util/stripe'
import { useAuth } from '../../../hooks/useAuth'
import { logger } from '../../../util/logger'
import ModalPrompt from '../../ModalPrompt/ModalPrompt'
import BooleanButton from '../../BooleanButton/BooleanButton'
import { ButtonHolder } from '../Logistics/LogisticsInfo.styles'

function Ticket({
  order,
  SetTicketProperty,
  ticketInfo,
  SetDiscountCode,
  SetFields,
  RemoveField,
  SetFieldsValues,
  RemoveFieldsValues,
  RemoveTicket,
  RegistrationLimit,
  setPaymentsInfo,
  setChecked,
  setRequired,
  formInfo,
  setSponsorship,
}) {
  const [open, setOpen] = useState(false)
  const [animation, setAnimation] = useState(false)
  const MergedGroupData = [
    ...formInfo?.attendees?.selectedAttendees.group,
    ...formInfo?.attendees?.selectedAttendees.household,
    ...formInfo?.attendees?.selectedAttendees.team,
    ...formInfo?.attendees?.selectedAttendees.individual,
  ]

  // eslint-disable-next-line no-unused-vars
  const [groups, setGroups] = useState(
    formInfo?.attendees?.selectedAttendees ? MergedGroupData : [],
  )

  const [overflowOff, setOverflowOff] = useState(false)
  const ticketRef = useRef(null)
  const settingsRef = useRef(null)

  const [productNameError, setProductNameError] = useState(false)
  const [promotionCodeErrors, setPromotionCodeErrors] = useState([])
  const [popuStatus, setPopuStatus] = useState(false)
  const [modalStatus, setModalStatus] = useState(false)

  const { token } = useAuth()

  function generatePercentages(maxValue) {
    const percentages = []
    for (let i = 1; i <= maxValue; i++) {
      percentages.push({ label: `${i}%`, value: i })
    }
    return percentages
  }
  const percentages = generatePercentages(100)

  const elements = ticketInfo?.codeQuantity
    ? Array(parseInt(ticketInfo?.codeQuantity)).fill('*')
    : []

  useEffect(() => {
    if (animation) {
      setOpen((o) => !o)

      setTimeout(() => {
        setAnimation(false)
      }, 900)
    }
  }, [animation])

  const handleNetCostChange = (value) => {
    const netValue = parseFloat((value - value * (2.9 / 100) - 0.3).toFixed(2))
    SetTicketProperty(netValue >= 0 ? `$${netValue}` : '', 'net', order)
    SetTicketProperty(`$${value}`, 'cost', order)
  }

  function handleCostInput(event) {
    const inputValue = event
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '') // Remove non-numeric characters
    const formattedValue = `${sanitizedValue}`
    handleNetCostChange(formattedValue)
  }

  function handleNumberOfTicketsChange(event) {
    const registrationLimit = parseInt(formInfo?.logisticsInfo?.registration_limit)
    SetTicketProperty('', 'numberOfTickets', order)

    if (isNaN(registrationLimit)) {
      // If the registration limit is not defined or not a number, set the number of tickets as provided.
      SetTicketProperty(event, 'numberOfTickets', order)
      return
    }

    const currentTickets = formInfo?.paymentsInfo?.tickets || []
    let totalTicketsSpent = 0

    currentTickets.forEach((ticket) => {
      const numberOfTickets = parseInt(ticket?.numberOfTickets)
      if (!isNaN(numberOfTickets)) {
        totalTicketsSpent += numberOfTickets
      }
    })

    const newNumberOfTickets = parseInt(event)

    const remainingTickets =
      registrationLimit - (totalTicketsSpent - parseInt(order?.numberOfTickets || 0))

    let updatedNumberOfTickets

    if (!isNaN(newNumberOfTickets)) {
      // If the new number of tickets is a valid number, update the number of tickets.
      updatedNumberOfTickets = Math.min(newNumberOfTickets, remainingTickets)
    } else {
      // If the new number of tickets is not a valid number, set the number of tickets to 0.
      updatedNumberOfTickets = ''
    }

    SetTicketProperty(updatedNumberOfTickets, 'numberOfTickets', order)
  }

  function deleteTicket() {
    RemoveTicket(order)
  }

  return (
    <TicketStyle>
      <TicketHeader>
        <HeaderLabel>
          <LocalOfferIcon /> {ticketInfo?.name ? ticketInfo.name : `Ticket ${order + 1}`}
        </HeaderLabel>
        <HeaderLabel
          onClick={() => {
            setModalStatus(true)
          }}
          $pointer={true}
        >
          <DeleteOutlineRoundedIcon /> Remove
        </HeaderLabel>
      </TicketHeader>
      <ModalPrompt
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        descriptionText={`Delete Ticket`}
        onClickFunction={deleteTicket}
        variant="archiveGathering"
      />
      <TicketContainer>
        <PaddingContainer>
          <Row>
            <Label width={'200px'}>Ticket Name</Label>
            <Input
              type="text"
              label="Name"
              error={
                formInfo?.highestStep !== formInfo?.activeStep &&
                (productNameError || !ticketInfo.name)
              }
              helperText={productNameError && 'Product name missing or already in use.'}
              onBlur={async (e) => {
                if (e.target.value !== '') {
                  try {
                    const resp = await checkProductName(e.target.value, token)
                    setProductNameError(resp)
                  } catch (error) {
                    logger.error('An error occured: ', error)
                    setProductNameError(true)
                  }
                } else {
                  setProductNameError(true)
                }
              }}
              onChange={(value) => {
                SetTicketProperty(value, 'name', order)
              }}
              value={ticketInfo?.name || ''}
            />
          </Row>
          <Row>
            <Label width={'200px'}>Cost</Label>
            <Input
              type="text"
              label="Cost"
              error={
                (formInfo?.highestStep !== formInfo?.activeStep && !ticketInfo.cost) ||
                ticketInfo?.cost === '$'
              }
              onChange={(value) => {
                handleCostInput(value)
              }}
              value={ticketInfo?.cost ? `${ticketInfo?.cost}` : ''}
            />
          </Row>
          <Row>
            <Label width={'200px'}>Net</Label>
            <Input
              type="text"
              label={!ticketInfo?.net ? 'Net' : ''}
              disabled={true}
              value={ticketInfo?.net ? `${ticketInfo?.net}` : '$'}
            />
          </Row>
          <Row>
            <Label width={'200px'}></Label>
            <Message translateY={'-8px'}>
              The amount shown is the net to the church (after all transaction fees are taken out)
            </Message>
          </Row>
          <Row>
            <Label width={'200px'}>Number of tickets</Label>
            <Input
              type="number"
              label="Number of tickets"
              // error={formInfo?.paymentsInfo?.checkFields && !ticketInfo?.numberOfTickets}
              onChange={handleNumberOfTicketsChange}
              value={ticketInfo?.numberOfTickets}
              limit={RegistrationLimit}
            />
          </Row>
          <Row>
            <Label width={'200px'}></Label>
            <Message translateY={'-8px'}>
              The amount shown is the maximum number only for this ticket type. It doesn’t limit the
              overall number of tickets. Leave blank for no limits.
            </Message>
          </Row>
          <SummaryBox ticketInfo={ticketInfo} />
          <Row>
            <NavbarOutlinedButton
              onClickFunction={() => {
                setOverflowOff(false)
                setAnimation(true)
              }}
              buttonText={'Advanced Settings'}
              iconName={open ? 'arrow up' : 'arrow down'}
            ></NavbarOutlinedButton>
          </Row>
        </PaddingContainer>
        <SettingsWrapper
          ref={settingsRef}
          open={open}
          height={ticketRef.current?.offsetHeight}
          animation={animation}
          $overflowOff={overflowOff}
        >
          <SettingsHolder ref={ticketRef}>
            <Message>
              Info You can edit advance settings here. Don't enter any value for the options that
              you don’t want to use.
            </Message>
            <Title>Deposit</Title>
            <Box>
              <Row>
                <Label width={'200px'}>Minimum deposit</Label>
                <Input
                  type="number"
                  label="Minimum deposit"
                  onChange={(value) => {
                    SetTicketProperty(value, 'minDeposit', order)
                  }}
                  value={ticketInfo?.minDeposit}
                />
              </Row>
            </Box>
            <Title>Discount codes</Title>
            <Box>
              <Row>
                <Label width={'224px'}>How many discount codes?</Label>
                <Input
                  type="number"
                  label="Quantity"
                  onChange={(value) => {
                    SetTicketProperty(value, 'codeQuantity', order)
                  }}
                  value={ticketInfo?.codeQuantity}
                />
                <Label width={'64px'}>codes</Label>
              </Row>
              {elements.map((element, index) => (
                <Row>
                  <Label width={'64px'}>Code {index + 1}</Label>
                  <Divider width={'70%'}>
                    <Input
                      type="text"
                      label="Code Name"
                      id={'discountCode' + index}
                      error={
                        formInfo?.highestStep !== formInfo?.activeStep &&
                        ticketInfo?.discountCodes &&
                        ticketInfo?.discountCodes?.length > 0 &&
                        (!ticketInfo?.discountCodes ||
                          !ticketInfo?.discountCodes[index]?.name ||
                          ticketInfo?.discountCodes[index]?.name === '')
                      }
                      helperText={
                        formInfo?.highestStep !== formInfo?.activeStep &&
                        promotionCodeErrors[index] &&
                        'Product name missing or already in use.'
                      }
                      onBlur={async (e) => {
                        const value = e.target.value
                        const updatedErrors = [...promotionCodeErrors]

                        if (value !== '') {
                          try {
                            const resp = await checkPromotionCode(value, token)
                            updatedErrors[index] = resp
                          } catch (error) {
                            logger.error('An error occured: ', error)
                            updatedErrors[index] = true
                          }
                        } else {
                          updatedErrors[index] = true
                        }

                        setPromotionCodeErrors([...updatedErrors])
                      }}
                      onChange={(value) => {
                        SetDiscountCode(value, 'name', order, index)
                      }}
                      value={
                        ticketInfo?.discountCodes ? ticketInfo?.discountCodes[index]?.name : ''
                      }
                    />
                  </Divider>
                  <Divider width={'30%'}>
                    <Selector
                      items={percentages}
                      label="Percentage"
                      error={
                        formInfo?.highestStep !== formInfo?.activeStep &&
                        ticketInfo?.discountCodes &&
                        ticketInfo?.discountCodes?.length > 0 &&
                        (!ticketInfo?.discountCodes ||
                          !ticketInfo?.discountCodes[index]?.name ||
                          ticketInfo?.discountCodes[index]?.name === '' ||
                          !ticketInfo?.discountCodes[index]?.percentage)
                      }
                      onChange={(value) => {
                        SetDiscountCode(value, 'percentage', order, index)
                      }}
                      value={
                        ticketInfo?.discountCodes
                          ? ticketInfo?.discountCodes[index]?.percentage
                          : ''
                      }
                    />
                  </Divider>
                  <CopyHolder>
                    <Copy reference={'discountCode' + index} setOverflowOff={setOverflowOff} />
                  </CopyHolder>
                </Row>
              ))}
            </Box>
            <Title>Do you want a Sponsorship for this ticket type?</Title>
            <ButtonHolder>
              <BooleanButton
                setValue={setSponsorship}
                value={formInfo?.paymentsInfo?.tickets[order]?.sponsorship}
                type="Yes"
                variant="ticket"
                order={order}
              />
              <BooleanButton
                setValue={setSponsorship}
                value={formInfo?.paymentsInfo?.tickets[order]?.sponsorship}
                type="No"
                variant="ticket"
                order={order}
              />
            </ButtonHolder>
            {formInfo?.paymentsInfo?.tickets[order]?.sponsorship && (
              <RetrievedFields
                ticketInfo={ticketInfo}
                SetFields={SetFields}
                RemoveField={RemoveField}
                SetFieldsValues={SetFieldsValues}
                RemoveFieldsValues={RemoveFieldsValues}
                setOverflowOff={setOverflowOff}
                order={order}
                setPaymentsInfo={setPaymentsInfo}
                popuStatus={popuStatus}
                setPopuStatus={setPopuStatus}
                setChecked={setChecked}
                setRequired={setRequired}
              />
            )}

            <Title>Ticket Restrictions</Title>
            <Row>
              <Label width={'200px'}>Age range</Label>
              <Divider width="50%">
                <Input
                  type="text"
                  label="Min"
                  onChange={(value) => {
                    SetTicketProperty(value, 'minAge', order)
                  }}
                  value={ticketInfo?.minAge}
                />
              </Divider>
              <Divider width="50%">
                <Input
                  type="text"
                  label="Max"
                  onChange={(value) => {
                    SetTicketProperty(value, 'maxAge', order)
                  }}
                  value={ticketInfo?.maxAge}
                />
              </Divider>
            </Row>
            <Row>
              <Label width={'200px'}></Label>
              <Message translateY={'-8px'} center={true}>
                Info Set the minimum and the maximum age range. Leave blank for no age limitation.
              </Message>
            </Row>
            <Row>
              <Label width={'200px'}>Gender</Label>
              <RadioGroupStyle row defaultValue={undefined} value={ticketInfo?.gender}>
                <CheckboxLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                  onClick={(v) => {
                    return v.target.value === ticketInfo?.gender
                      ? SetTicketProperty(null, 'gender', order)
                      : SetTicketProperty(v.target.value, 'gender', order)
                  }}
                />
                <CheckboxLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                  onClick={(v) => {
                    return v.target.value === ticketInfo?.gender
                      ? SetTicketProperty(null, 'gender', order)
                      : SetTicketProperty(v.target.value, 'gender', order)
                  }}
                />
              </RadioGroupStyle>
            </Row>
            <Row>
              <Label width={'200px'}></Label>
              <Message translateY={'-8px'} center={true}>
                Restrict ticket by member’s gender.{' '}
              </Message>
            </Row>
            <Teams
              groupData={groups}
              SetTicketProperty={SetTicketProperty}
              order={order}
              ticketInfo={ticketInfo}
              setOverflowOff={setOverflowOff}
            />
          </SettingsHolder>
        </SettingsWrapper>
      </TicketContainer>
    </TicketStyle>
  )
}

export default Ticket

import styled from 'styled-components'
import color from '../../util/colors'

const LabelPreviewContainer = styled.div`
  display: flex;
  width: 554px;
  height: 290px;
  padding: 1.8rem;
  box-sizing: border-box;
  justify-content: space-between;
  border: 1px solid var(--borderLine);
  border-radius: 5px;
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    height: fit-content;
  }
`

const KidsLabelDetailsColumn = styled.div`
  display: flex;
  width: 35%;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 100%;
  }
`
const ParrentsLabelDetailsColumn = styled(KidsLabelDetailsColumn)`
  width: auto;
`

const KidsLabelDetailGridColumn = styled.div`
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  min-height: 4rem;
  margin-top: auto;
  justify-content: flex-start;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

const ParentLabelQrColumn = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
`
const KidsLabelDetailGridColumnItem = styled.div`
  display: flex;
  flex-basis: 33%;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
`

const KidsLabelBarcodeAndImageColumn = styled.div`
  display: flex;
  width: 20%;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    row-gap: 20px;
  }
`

const ParentLabelBarcodeAndImageColumn = styled(KidsLabelBarcodeAndImageColumn)`
  width: auto;
`

const LabelMainText = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
`

const LabelSubText = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  padding-bottom: 3rem;
`

const DetailTextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.8rem;
`
const LabelDetailText = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 140%;
`
const LabelDetailSubText = styled(LabelDetailText)`
  font-weight: 400;
  margin-left: 10px;
`

const KidLabelQRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
`
const QrCodeSubText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
`
const qrCodeKidStyle = {
  width: '101px',
  height: '99px',
}

const qrCodeParentStyle = {
  width: '139px',
  height: '136px',
}

const ImageBox = styled.div`
  width: 101px;
  height: 88px;
  left: 240.44px;
  top: 864px;
  border: 1px dashed var(--borderLine3);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  margin-top: auto;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding-top: 1.5rem;
    align-self: center;
  }
`

const ParentLabelImageBox = styled(ImageBox)`
  border: none;
  justify-content: flex-end;
  align-items: flex-end;
`

const Image = styled.img`
  width: 61px;
  height: 60px;
`
const stockImageIcon = {
  color: color.fieldColor,
  fontSize: 60,
}

export {
  LabelPreviewContainer,
  KidsLabelDetailsColumn,
  ParrentsLabelDetailsColumn,
  KidsLabelDetailGridColumn,
  ParentLabelQrColumn,
  KidsLabelDetailGridColumnItem,
  KidsLabelBarcodeAndImageColumn,
  ParentLabelBarcodeAndImageColumn,
  LabelMainText,
  LabelSubText,
  DetailTextWrapper,
  LabelDetailText,
  LabelDetailSubText,
  KidLabelQRCodeContainer,
  qrCodeKidStyle,
  qrCodeParentStyle,
  QrCodeSubText,
  ImageBox,
  ParentLabelImageBox,
  Image,
  stockImageIcon,
}

import styled from 'styled-components'
import { FormControl } from '@mui/material'
import color from '../../../util/colors'
import { FormControlLabel, RadioGroup } from '@mui/material'

export const RadioGroupStyle = styled(RadioGroup)`
  width: 100%;
  gap: 16px;
`

export const CheckboxLabel = styled(FormControlLabel)`
  max-width: 18px;
  color: ${color.unChecked};

  svg[data-testid='CheckBoxIcon'] {
    color: ${color.primary};
  }
  svg[data-testid='RadioButtonUncheckedIcon'] {
    color: ${color.unChecked};
  }
  svg[data-testid='RadioButtonCheckedIcon'] {
    color: ${color.primary};
  }
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  min-height: 56px;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
  }

  ${(props) => props.gap && `gap: ${props.gap};`}

  ${(props) => props.minHeight && `min-height: ${props.minHeight};`}
  ${(props) => props.flexWrap && `flex-wrap: wrap;`}
`

export const RowLogistics = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  min-height: 56px;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1640px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
  }

  ${(props) => props.gap && `gap: ${props.gap};`}

  ${(props) => props.minHeight && `min-height: ${props.minHeight};`}
  ${(props) => props.flexWrap && `flex-wrap: wrap;`}
`

export const LogisticsDivider = styled.div`
  width: ${(props) => props.width || '100%'};

  @media (max-width: 1640px) {
    width: 100%;
  }
`

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;

  ${(props) => props.minWidth && `min-width: ${props.minWidth};`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}

  @media (max-width: 900px) {
    min-width: 0;
  }
`

export const Divider = styled.div`
  display: flex;
  width: 100%;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.minWidth && `min-width: ${props.minWidth};`}
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const InfoHolder = styled.div`
  background: ${color.groupFrameIndividual};
  padding: 5px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  color: ${color.primary};
  letter-spacing: 0.015em;
  text-transform: uppercase;
  svg {
    max-width: 16px;
    max-height: 16px;
  }
`

export const InfoButton = styled.button`
  background: ${(props) => (props.focused ? color.draftButton : color.groupFrameIndividual)};
  padding: 5px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  color: ${(props) => (props.focused ? color.backgroundPrimary : color.primary)};
  letter-spacing: 0.015em;
  text-transform: uppercase;
  border: ${(props) =>
    props.focused
      ? `1px solid ${color.draftButton}`
      : props.active
      ? `1px solid ${color.primary}`
      : 'none'};
  outline: none;
  cursor: pointer;
  svg {
    max-width: 16px;
    max-height: 16px;
  }
`

export const OrderBox = styled.div`
  box-sizing: border-box;
  background: ${(props) => (props.focused ? color.backgroundPrimary : color.primary)};
  max-width: 14px;
  max-height: 14px;
  min-width: 14px;
  min-height: 14px;
  font-size: 8px;
  line-height: 4px;
  padding-top: 1px;
  color: ${(props) => (props.focused ? color.draftButton : color.backgroundPrimary)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`

export const InfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${color.title};
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
`

export const OneTimeHolder = styled.div`
  background: ${color.backgroundSecondary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`

export const ReoccurringHolder = styled(OneTimeHolder)``

export const MessageRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`

export const ButtonHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 25px;
  width: 100%;
`

export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  display: flex;

  min-width: max-content;

  margin-left: auto;

  ${(props) => props.textAlign === 'right' && `justify-content: flex-end;`}
  ${(props) => props.width && `min-width: ${props.width};`}
  ${(props) => props.width && `max-width: ${props.width};`}


  @media (max-width: 900px) {
    min-width: 0;
  }
`

export const LocationInput = styled(FormControl)`
  width: 100%;
  svg {
    color: ${color.primary};
  }
`

export const OccurrenceTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: ${color.title};
  letter-spacing: 0.01em;
  width: 100%;
  text-align: left;
`

export const OccurrenceTitleOccurrence = styled(OccurrenceTitle)`
  background: ${color.primary};
  width: max-content;
  align-self: flex-start;
  color: ${color.backgroundPrimary};
  padding: 5px 20px;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
`

export const OccurrenceHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const RowHolder = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-left: 105px;
`

export const Hr = styled.hr`
  border-top: 1px dashed black;
  height: 0;
  width: 100%;
  margin-bottom: 48px;
`

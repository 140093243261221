import styled from 'styled-components'
import Chip from '@mui/material/Chip'
import color from '../../util/colors'

const StyledTag = styled(Chip)({
  '&.MuiChip-root': {
    height: '32px',
    padding: '0px 6px 0px 6px',
    gap: '8px',
    borderRadius: '50px',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: color.groupFrameIndividual,
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '140%',
    letterSpacing: '0.015em',
    color: color.primary,
  },
  '&.MuiChip-root .MuiChip-avatar': {
    width: '22px',
    height: '22px',
  },
  '&.MuiChip-root .MuiChip-icon': {
    color: color.primary,
    fontSize: 18,
    paddingBottom: 2,
  },
  '&.MuiChip-root .MuiChip-deleteIcon': {
    color: color.primary,
    fontSize: 18,
    paddingBottom: 2,
  },
  '&.MuiChip-root .MuiChip-deleteIcon: hover': {
    color: color.primary,
  },
})

export { StyledTag }

import './ChecklistAccordion.css'
import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import CheckIcon from '@mui/icons-material/Check'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  handleProgressBadge,
  handleAlertBackgrounds,
  prepareDataForAccordion,
} from './util/Checklist.util'
import {
  ChecklistAccordionIconStyle,
  ChecklistCheckIconStyle,
  ChecklistArrowIconStyle,
  StatusHandlerDiv,
  ChecklistAlertIconStyle,
} from './Checklist.style'
import { updateGathering } from '../../util/gatherings'
import { useAuth } from '../../hooks/useAuth'
import { logger } from '../../util/logger'
import { checkIfStepIsMissingData } from '../../util/utility'
import { useFormGatherings } from '../../util/zustant/store'
import { updateTemplate } from '../../util/templates'

const ChecklistAccordion = ({
  titleNumber,
  titleLabel,
  stepsArray,
  currentStep,
  stepSetter,
  setAccordionMenu,
  highestStep,
  currentStepLabel,
}) => {
  const { token } = useAuth()
  const { formInfo } = useFormGatherings()
  const [accordionStatus, setAccordionStatus] = useState(false)
  const firstElement = stepsArray[0].index
  const lastElement = stepsArray[stepsArray.length - 1].index
  const boxOrder = `box${titleNumber}state`
  const indexes = prepareDataForAccordion(stepsArray).map((a) => a.index)

  function activeStepSetter(value) {
    const newFormInfo = { ...formInfo }
    newFormInfo.activeStep = value
    stepSetter(newFormInfo)
  }

  // Opening the accordeons if we move past one to the other
  useEffect(() => {
    if (
      indexes.includes(currentStep) ||
      indexes.includes(highestStep) ||
      indexes[indexes.length - 1] < highestStep
    ) {
      setAccordionStatus(true)
    }
  }, [currentStepLabel])

  return (
    <section style={{ paddingBottom: '20px', cursor: 'pointer' }}>
      <div
        className="checklistComponentAccordionHeader"
        onClick={() => {
          if (
            indexes.includes(currentStep) ||
            indexes.includes(highestStep) ||
            indexes[indexes.length - 1] < highestStep
          ) {
            setAccordionStatus(!accordionStatus)
            setAccordionMenu((prevState) => ({
              ...prevState,
              [boxOrder]: accordionStatus,
            }))
          }
        }}
      >
        <div className="checklistComponentLabelText">
          <div className="checklistComponentLabelNumberIcon">{titleNumber}</div>
          <div style={{ width: '80%' }}>{titleLabel}</div>
          <div>
            {accordionStatus ? (
              <ExpandLessIcon sx={{ fontSize: 10, paddingLeft: '35px' }}></ExpandLessIcon>
            ) : (
              <ExpandMoreIcon sx={{ fontSize: 10, paddingLeft: '35px' }}></ExpandMoreIcon>
            )}
          </div>
        </div>
        {handleProgressBadge(currentStep, firstElement, lastElement)}
      </div>
      {accordionStatus && (
        <section className="checklistComponentAccordionBody">
          {stepsArray.map((checklistItem, i) => {
            return (
              <div key={i}>
                {handleItemStatus(
                  currentStep,
                  checklistItem.index,
                  checklistItem.title,
                  stepSetter,
                  highestStep,
                  formInfo,
                  token,
                  activeStepSetter,
                )}
                {checklistItem.subData.length > 0 ? (
                  <div>
                    {checklistItem.subData.map((subData, i) => {
                      return (
                        <div key={i} style={{ paddingLeft: 40 }}>
                          {handleItemStatus(
                            currentStep,
                            subData.index,
                            subData.title,
                            stepSetter,
                            highestStep,
                            formInfo,
                            token,
                            activeStepSetter,
                          )}
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )
          })}
        </section>
      )}
    </section>
  )
}

async function HandleStepSetter(
  itemIndex,
  formInfo,
  authToken,
  activeStepSetter,
  currentStep,
  highestStep,
) {
  if (itemIndex <= highestStep) {
    activeStepSetter(itemIndex)
    if (formInfo?.status === 'template') {
      const response = await updateTemplate(formInfo, formInfo?.id, authToken)
      logger.log('Updated Template...', response?.id)
    } else if (formInfo?.status === 'draft' || formInfo?.status === 'published') {
      const response = await updateGathering(formInfo, formInfo?.id, authToken)
      logger.log('Updated Gathering...', response?.data?.id)
    }
  } else {
    return
  }
}

function handleItemStatus(
  currentStep,
  itemIndex,
  itemLabel,
  stepSetter,
  highestStep,
  formInfo,
  authToken,
  activeStepSetter,
) {
  if (checkIfStepIsMissingData(itemLabel, formInfo) && itemIndex !== currentStep) {
    return (
      <div
        className="checklistComponentAccordionBodyItemMissingData"
        style={{ backgroundColor: handleAlertBackgrounds(itemLabel) }}
        onClick={() =>
          HandleStepSetter(
            itemIndex,
            formInfo,
            authToken,
            activeStepSetter,
            currentStep,
            highestStep,
          )
        }
      >
        <div style={StatusHandlerDiv}>
          <ReportGmailerrorredIcon sx={ChecklistAlertIconStyle}></ReportGmailerrorredIcon>
        </div>
        <div style={{ width: '70%' }}>{itemLabel}</div>
      </div>
    )
  }
  if (itemIndex === currentStep) {
    return (
      <div
        className="checklistComponentAccordionBodyItemInProgress"
        onClick={() =>
          HandleStepSetter(
            itemIndex,
            formInfo,
            authToken,
            activeStepSetter,
            currentStep,
            highestStep,
          )
        }
      >
        <div style={StatusHandlerDiv}>
          <KeyboardArrowRightIcon sx={ChecklistAccordionIconStyle}></KeyboardArrowRightIcon>
        </div>
        <div style={{ width: '70%' }}>{itemLabel}</div>
      </div>
    )
  } else if (itemIndex < currentStep || itemIndex <= highestStep) {
    return (
      <div
        className="checklistComponentAccordionBodyItemDone"
        onClick={() =>
          HandleStepSetter(
            itemIndex,
            formInfo,
            authToken,
            activeStepSetter,
            currentStep,
            highestStep,
          )
        }
      >
        <div style={{ width: '25%', display: 'flex', justifyContent: 'center' }}>
          <CheckIcon sx={ChecklistCheckIconStyle}></CheckIcon>
        </div>
        <div style={{ width: '70%' }}>{itemLabel}</div>
      </div>
    )
  } else if (itemIndex > currentStep) {
    return (
      <div
        className="checklistComponentAccordionBodyItemDefault"
        onClick={() =>
          HandleStepSetter(
            itemIndex,
            formInfo,
            authToken,
            activeStepSetter,
            currentStep,
            highestStep,
          )
        }
      >
        <div style={StatusHandlerDiv}>
          <KeyboardArrowRightIcon sx={ChecklistArrowIconStyle}></KeyboardArrowRightIcon>
        </div>
        <div style={{ width: '70%' }}>{itemLabel}</div>
      </div>
    )
  }
}

export default ChecklistAccordion

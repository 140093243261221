import {
  StyledTemplateButton,
  templatePreviewHeaderStyle,
} from '../../../components/StyledButton/StyledButton.style'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DeleteIcon from '@mui/icons-material/Delete'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'

import CheckIcon from '@mui/icons-material/Check'
import color from '../../../util/colors'

const TemplatePreviewHeaderButton = ({ variant, label, onClick, hearted, disabled }) => {
  switch (variant) {
    case 'edit':
      return (
        <StyledTemplateButton
          variant="contained"
          startIcon={<OpenInNewIcon />}
          sx={templatePreviewHeaderStyle}
          onClick={onClick}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'delete':
      return (
        <StyledTemplateButton
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={onClick}
          sx={templatePreviewHeaderStyle}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'favor':
      return (
        <StyledTemplateButton
          variant="contained"
          startIcon={hearted ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          sx={templatePreviewHeaderStyle}
          onClick={onClick}
          disabled={disabled}
        >
          {label}
        </StyledTemplateButton>
      )
    case 'useThis':
      return (
        <StyledTemplateButton
          variant="contained"
          startIcon={<CheckIcon />}
          sx={
            (templatePreviewHeaderStyle,
            {
              backgroundColor: color.useTemplate,
              '&:hover': { backgroundColor: color.useTemplate, borderColor: color.useTemplate },
            })
          }
          onClick={onClick}
        >
          {label}
        </StyledTemplateButton>
      )
    default:
      return <>{'Variant not supported'}</>
  }
}

export { TemplatePreviewHeaderButton }

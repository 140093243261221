import { useNavigate } from 'react-router-dom'
import './TemplateCard.css'
import { sidebarValues } from './TemplateCard.util'
import {
  pickTemplate,
  getTemplate,
  deleteTemplate,
  getAllTemplates,
  heartTemplate,
  getHeartedTemplates,
} from '../../util/templates'
import { useStepGatherings } from '../../util/zustant/store'
import { useTemplates } from '../../util/zustant/templateStore'
import { useFormGatherings } from '../../util/zustant/store'
import { useTemplateId } from '../../util/zustant/store'
import { useEffect, useState } from 'react'
import { logger } from '../../util/logger'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import PlaceholderImage from '../../assets/placeholderImage.png'
const TemplateCard = ({ gatheringData, userId, token }) => {
  const { setTemplatePreview, setTemplates, setTemplateHearted, templateHearted } = useTemplates()

  const navigate = useNavigate()
  const { setActiveStep } = useStepGatherings()
  const { setFormInfo } = useFormGatherings()
  const { setTemplateId } = useTemplateId()
  const [hearted, setHearted] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
    const findHearted = templateHearted?.find((t) => {
      if (t.templateId === gatheringData.id) return t
      return null
    })

    setHearted(findHearted?.isFavourite ? findHearted.isFavourite : false)
  }, [templateHearted])

  return (
    <div className="templateCardContainer">
      {/* Image Area */}
      <div className="templateCardImageArea" style={{ paddingTop: '2px' }}>
        <img
          className="templateCardImage"
          style={{ objectFit: 'contain' }}
          src={gatheringData?.imageUpload?.eventImage || PlaceholderImage}
          alt="event"
        ></img>
        <div className="templateCardSidebar">
          {sidebarValues.map((sidebarItem) => (
            <div
              key={sidebarItem.id}
              className="templateCardSidebarItem"
              style={{ borderTopRightRadius: '8px' }}
              onClick={async () => {
                if (sidebarItem.type === 'use') {
                  setActiveStep(1)
                  await pickTemplate(
                    gatheringData.id,
                    token,
                    setFormInfo,
                    navigate,
                    userId,
                    setTemplateId,
                  )
                }

                if (sidebarItem.type === 'preview') {
                  await getTemplate(gatheringData.id, token, setTemplatePreview, navigate)
                }
                logger.log(sidebarItem.type)

                if (sidebarItem.type === 'edit') {
                  setTemplateId(gatheringData.id)
                  await getTemplate(gatheringData.id, token, setTemplatePreview, navigate)
                  setFormInfo({ ...gatheringData, status: 'template' })
                  navigate(`/createGathering`)
                }

                if (sidebarItem.type === 'delete') {
                  await deleteTemplate(gatheringData.id, token)
                  getAllTemplates(setTemplates, token)
                }

                if (sidebarItem.type === 'favorite' && !loading) {
                  setLoading(true)
                  setHearted(!hearted)
                  await heartTemplate(gatheringData.id, userId, token)
                  await getHeartedTemplates(userId, token, setTemplateHearted)
                }
              }}
            >
              {sidebarItem.type === 'favorite' &&
                (hearted ? (
                  <FavoriteIcon className={`templateCardSidebarIcon`} sx={{ fontSize: 17 }} />
                ) : (
                  <FavoriteBorderIcon className={`templateCardSidebarIcon`} sx={{ fontSize: 17 }} />
                ))}
              {sidebarItem.type !== 'favorite' && sidebarItem.icon}
            </div>
          ))}
        </div>
      </div>
      {/* Text Area */}
      <div className="templateCardTextArea">
        <div className="templateCardAnimatedLine"></div>
        <div className="templateCardText">{gatheringData?.gatheringDetails?.title}</div>
      </div>
    </div>
  )
}

export default TemplateCard

import React from 'react'
import { Summary, SummaryRow, SummaryLabel, SummaryRowFlex, SummaryHolder } from './Payments.styles'
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded'
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import Copy from '../../Form/components/Copy'

function SummaryBox({ ticketInfo }) {
  return ticketInfo?.minDeposit ||
    ticketInfo?.discountCodes?.length > 0 ||
    ticketInfo?.sponsorship ||
    (ticketInfo?.minAge && ticketInfo?.maxAge) ||
    ticketInfo?.gender ||
    ticketInfo?.sortedGroup?.group?.length > 0 ||
    ticketInfo?.sortedGroup?.team?.length > 0 ||
    ticketInfo?.sortedGroup?.household?.length > 0 ||
    ticketInfo?.sortedGroup?.individual?.length > 0 ? (
    <Summary>
      {ticketInfo?.minDeposit && (
        <SummaryRow>
          <SummaryLabel>Deposit</SummaryLabel>
          <SummaryRowFlex>
            <SummaryHolder>
              <MonetizationOnRoundedIcon /> ${ticketInfo?.minDeposit}
            </SummaryHolder>
          </SummaryRowFlex>
        </SummaryRow>
      )}
      {ticketInfo?.discountCodes?.length > 0 && (
        <SummaryRow>
          <SummaryLabel>Discounts</SummaryLabel>
          <SummaryRowFlex>
            {ticketInfo?.discountCodes.map((discount, i) => (
              <SummaryHolder>
                <MonetizationOnRoundedIcon /> <div id={'discountSummary' + i}>{discount?.name}</div>
                -<div>{discount?.percentage}%</div>
                <Copy reference={'discountSummary' + i} align={'left'} />
              </SummaryHolder>
            ))}
          </SummaryRowFlex>
        </SummaryRow>
      )}
      {ticketInfo?.sponsorship && (
        <SummaryRow>
          <SummaryLabel>Sponsorsed</SummaryLabel>
          <SummaryRowFlex>
            <SummaryHolder>
              <VolunteerActivismRoundedIcon /> {ticketInfo?.sponsorship}
            </SummaryHolder>
          </SummaryRowFlex>
        </SummaryRow>
      )}
      {ticketInfo?.minAge && ticketInfo?.maxAge && (
        <SummaryRow>
          <SummaryLabel>Age range</SummaryLabel>
          <SummaryRowFlex>
            <SummaryHolder>
              <HourglassEmptyRoundedIcon /> {ticketInfo?.minAge}-{ticketInfo?.maxAge}
            </SummaryHolder>
          </SummaryRowFlex>
        </SummaryRow>
      )}
      {ticketInfo?.gender && (
        <SummaryRow>
          <SummaryLabel>Gender</SummaryLabel>
          <SummaryRowFlex>
            <SummaryHolder>
              <PersonRoundedIcon /> {ticketInfo?.gender}
            </SummaryHolder>
          </SummaryRowFlex>
        </SummaryRow>
      )}
      {ticketInfo?.sortedGroup?.group?.length > 0 && (
        <SummaryRow>
          <SummaryLabel>Groups</SummaryLabel>
          <SummaryRowFlex>
            {ticketInfo?.sortedGroup?.group?.map((group, i) => (
              <SummaryHolder>
                <PersonRoundedIcon /> {group.title}
              </SummaryHolder>
            ))}
          </SummaryRowFlex>
        </SummaryRow>
      )}

      {ticketInfo?.sortedGroup?.team?.length > 0 && (
        <SummaryRow>
          <SummaryLabel>Teams</SummaryLabel>
          <SummaryRowFlex>
            {ticketInfo?.sortedGroup?.team?.map((group, i) => (
              <SummaryHolder>
                <PersonRoundedIcon /> {group.title}
              </SummaryHolder>
            ))}
          </SummaryRowFlex>
        </SummaryRow>
      )}

      {ticketInfo?.sortedGroup?.household?.length > 0 && (
        <SummaryRow>
          <SummaryLabel>Households</SummaryLabel>
          <SummaryRowFlex>
            {ticketInfo?.sortedGroup?.household?.map((group, i) => (
              <SummaryHolder>
                <PersonRoundedIcon /> {group.title}
              </SummaryHolder>
            ))}
          </SummaryRowFlex>
        </SummaryRow>
      )}

      {ticketInfo?.sortedGroup?.individual?.length > 0 && (
        <SummaryRow>
          <SummaryLabel>Individuals</SummaryLabel>
          <SummaryRowFlex>
            {ticketInfo?.sortedGroup?.individual?.map((group, i) => (
              <SummaryHolder>
                <PersonRoundedIcon /> {group.title}
              </SummaryHolder>
            ))}
          </SummaryRowFlex>
        </SummaryRow>
      )}
    </Summary>
  ) : null
}

export default SummaryBox

import {
  SecuritySetupContainer,
  RowContainer,
  StyledText,
  TextFieldArea,
  ImageUploadRow,
  ImageUploadText,
  ImageUploadArea,
  ImageBox,
  ImageUploadButtonSection,
  ImageButtonWrapper,
  Image,
  stockImageIcon,
  LabelWrapper,
  DeleteIconWrapper,
  deleteIconStyle,
} from './SecuritySetup.style'
import LabelPreview from '../../LabelPreview/LabelPreview'
import { handleImageUpload } from '../../../util/securitySetup'
import TextField from '@mui/material/TextField'
import TemplateButton from '../../TemplateButton/TemplateButton'
import ImageIcon from '@mui/icons-material/Image'
import DeleteIcon from '@mui/icons-material/Delete'
import { useRef, useState } from 'react'
import labels from '../../../assets/label.json'
import ModalPrompt from '../../ModalPrompt/ModalPrompt'

const SecuritySetup = ({
  index,
  labelName,
  setLabelName,
  labelDescription,
  setLabelDescription,
  uploadedImage,
  setUploadedImage,
  removeUploadedImage,
  setSecurity,
  deleteLabel,
  id,
  setCloseLabels,
  closeLabels,
}) => {
  const templateButtonRef = useRef(null)
  const fileInputRef = useRef(null)
  const [modalStatus, setModalStatus] = useState(false)

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const labelConfig = labels.labelConfig

  async function removeSecurityPrompt() {
    await deleteLabel(id, setCloseLabels)
  }

  return (
    <SecuritySetupContainer>
      <RowContainer>
        <StyledText>{'Name'}</StyledText>
        <TextFieldArea>
          <TextField
            sx={{ width: '100%' }}
            id="outlined-basic"
            label={'Name'}
            variant="outlined"
            value={labelName}
            onChange={(e) => setLabelName(e.target.value, index)}
          />
        </TextFieldArea>
      </RowContainer>
      <RowContainer>
        <StyledText>{'Description'}</StyledText>
        <TextFieldArea>
          <TextField
            sx={{ width: '100%' }}
            id="outlined-basic"
            label={'Description'}
            variant="outlined"
            value={labelDescription}
            onChange={(e) => setLabelDescription(e.target.value, index)}
            multiline
            rows={3}
          />
        </TextFieldArea>
      </RowContainer>
      <ImageUploadRow>
        <ImageUploadText>Edit Image</ImageUploadText>
        <ImageUploadArea>
          <ImageBox>
            {uploadedImage ? (
              <Image src={uploadedImage} alt=""></Image>
            ) : (
              <ImageIcon style={stockImageIcon} />
            )}
          </ImageBox>
          <input
            ref={fileInputRef}
            id={index}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(event) => handleImageUpload(event, setUploadedImage, index)}
          />
          <ImageUploadButtonSection>
            <ImageButtonWrapper htmlFor={index}>
              <TemplateButton
                variant={'uploadImage'}
                label={'Upload'}
                ref={templateButtonRef}
                refFunc={handleButtonClick}
              ></TemplateButton>
            </ImageButtonWrapper>

            <TemplateButton
              variant={'removeImage'}
              label={'Remove'}
              uploadedImageIndex={index}
              removeUploadedImage={removeUploadedImage}
            ></TemplateButton>
          </ImageUploadButtonSection>
        </ImageUploadArea>
      </ImageUploadRow>
      {labelConfig.map((label) => (
        <>
          <StyledText>{label.labelHeaderText}</StyledText>
          <LabelWrapper>
            <LabelPreview variant={label.labelVariant} labelImage={uploadedImage}></LabelPreview>
          </LabelWrapper>
        </>
      ))}
      <DeleteIconWrapper>
        <DeleteIcon
          style={deleteIconStyle}
          onClick={() => {
            setModalStatus(true)
          }}
        ></DeleteIcon>
      </DeleteIconWrapper>
      <ModalPrompt
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        descriptionText={`Delete Security Label`}
        onClickFunction={removeSecurityPrompt}
        variant="deleteLabel"
      />
    </SecuritySetupContainer>
  )
}

export default SecuritySetup

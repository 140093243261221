import { FormControlLabel, Checkbox } from '@mui/material'
import { checkbox } from '../components/GatheringsFilter/GatheringsFilter.style'
import color from './colors'

const mapper = (arr, variant) => {
  return arr.map((obj, index) => {
    return (
      <FormControlLabel
        sx={{
          color: color.filterText,
          whiteSpace: 'nowrap',
          minWidth: 'min-content',
        }}
        key={index}
        control={
          <Checkbox
            checked={obj.var}
            onChange={(e) => {
              if (variant === 'templates') {
                if (e.target.checked === true) {
                  obj.func(obj.type)
                  if (obj.type === 'favorite') {
                    obj.setFilter({ favorite: true })
                  } else {
                    obj.setFilter(null)
                  }
                } else {
                  obj.func('title')
                  obj.setFilter(null)
                }
              } else {
                obj.func(obj.var)
              }
            }}
            sx={checkbox}
          />
        }
        label={obj.name}
      />
    )
  })
}

export { mapper }

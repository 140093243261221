/* eslint-disable no-unused-vars */
import GroupSearch from '../../GroupSearch/GroupSearch'
import GroupGrid from '../../GroupGrid/GroupGrid'
import {
  RosterInviteOnlyContainer,
  GroupGridContainer,
  PrimaryText,
  SecondaryText,
  DataGridContainer,
  RosterDataGrid,
  RosterDataGridToolbar,
} from './RosterCheckin.style'
import { useState, useEffect } from 'react'
import dataGridConfig from '../../../assets/rosterDataGridDefinition.json'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { unifyData } from '../../../util/utility'
import { logger } from '../../../util/logger'

function getError(activeStepLabel, formInfo, rosterCheckin) {
  switch (activeStepLabel) {
    case 'Checkins via Roster > Open':
      if (formInfo?.highestStep === formInfo?.activeStep) {
        return false
      } else {
        if (
          !(
            rosterCheckin?.selectedGroups?.group?.length > 0 ||
            rosterCheckin?.selectedGroups?.household?.length > 0 ||
            rosterCheckin?.selectedGroups?.individual?.length > 0 ||
            rosterCheckin?.selectedGroups?.team?.length > 0
          )
        ) {
          return true
        } else {
          return false
        }
      }
    case 'Checkins via Roster > Invite only':
      if (
        rosterCheckin?.selectedGroupsInviteOnly?.group?.length > 0 ||
        rosterCheckin?.selectedGroupsInviteOnly?.household?.length > 0 ||
        rosterCheckin?.selectedGroupsInviteOnly?.individual?.length > 0 ||
        rosterCheckin?.selectedGroupsInviteOnly?.team?.length > 0
      ) {
        return false
      } else {
        return true
      }

    default:
      break
  }
}

const RosterCheckin = ({ groupData, inviteOnly, authToken }) => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [rosterCheckin, setRosterCheckin] = useState(
    formInfo?.rosterCheckin ? formInfo?.rosterCheckin : {},
  )

  const [members, setMembers] = useState([])
  const MergedGroupData = [
    ...formInfo?.attendees?.selectedAttendees.group,
    ...formInfo?.attendees?.selectedAttendees.household,
    ...formInfo?.attendees?.selectedAttendees.team,
    ...formInfo?.attendees?.selectedAttendees.individual,
  ]

  const [searchData, setSearchData] = useState(
    formInfo?.attendees?.selectedAttendees ? MergedGroupData : [],
  )
  const [sortedGroup, setSortedGroups] = useState(
    inviteOnly
      ? formInfo?.rosterCheckin?.selectedGroupsInviteOnly
        ? formInfo?.rosterCheckin?.selectedGroupsInviteOnly
        : {
            group: [],
            team: [],
            individual: [],
            household: [],
          }
      : formInfo?.rosterCheckin?.selectedGroups
      ? formInfo?.rosterCheckin?.selectedGroups
      : {
          group: [],
          team: [],
          individual: [],
          household: [],
        },
  )
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/attendees`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      const data = await resp.json()
      const unify = unifyData(data)
      const pickedGroups = [
        ...sortedGroup.group,
        ...sortedGroup.team,
        ...sortedGroup.individual,
        ...sortedGroup.household,
      ]

      const updatedUnify = unify.filter((group) => {
        return !pickedGroups.some((pickedGroup) => group.id === pickedGroup.id)
      })
      setSearchData(updatedUnify)
    } catch (error) {
      logger.error(error)
    }
  }

  useEffect(() => {
    let Members = []

    formInfo?.attendees?.selectedAttendees.group?.forEach((group) => {
      group.members?.forEach((member) => {
        member.Group = group?.name
        Members.push(member)
      })
    })

    formInfo?.attendees?.selectedAttendees.team?.forEach((team) => {
      if (team) {
        team.members?.forEach((member) => {
          if (member) {
            member.Team = team?.name
            Members.push(member)
          }
        })
      }
    })

    formInfo?.attendees?.selectedAttendees.individual?.forEach((member) => {
      Members.push(member)
    })

    // Merge duplicates
    Members = Members.reduce((accumulator, currentMember) => {
      const foundMember = accumulator.find((member) => member.name === currentMember.name)

      if (foundMember) {
        Object.keys(currentMember).forEach((key) => {
          if (foundMember.hasOwnProperty(key)) {
            if (Array.isArray(foundMember[key])) {
              foundMember[key] = [...new Set([...foundMember[key], ...currentMember[key]])]
            } else if (typeof foundMember[key] === 'object' && foundMember[key] !== null) {
              foundMember[key] = { ...foundMember[key], ...currentMember[key] }
            } else {
            }
          } else {
            foundMember[key] = currentMember[key]
          }
        })
      } else {
        accumulator.push({ ...currentMember })
      }

      return accumulator
    }, [])

    Members.forEach((member, i) => {
      if (!member.User) member.User = member.name
      if (member.Selected === undefined) member.Selected = false
      sortedGroup.group.forEach((group) => {
        if (group.name === member.Group) member.Selected = true
      })
      sortedGroup.team.forEach((team) => {
        if (team.name === member.Team) member.Selected = true
      })
      sortedGroup.household.forEach((household) => {
        if (household.name === member.Household) member.Selected = true
      })

      sortedGroup.individual.forEach((individual) => {
        if (individual.fullname === member.name) member.Selected = true
      })
      if (member.Group === undefined) member.Group = '-'
      if (member.Team === undefined) member.Team = '-'
      if (member.Household === undefined) member.Household = '-'
      member.id = i
    })

    setMembers(Members)

    let newFormInfo = formInfo
    newFormInfo.rosterCheckin = rosterCheckin
    setFormInfo(newFormInfo)
  }, [rosterCheckin])

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [authToken])

  useEffect(() => {
    setLoading(false)
  }, [searchData])

  useEffect(() => {
    setRosterCheckin((info) => {
      if (inviteOnly) {
        info.selectedGroupsInviteOnly = sortedGroup
      } else info.selectedGroups = sortedGroup
      return { ...info }
    })
  }, [sortedGroup])

  return (
    <RosterInviteOnlyContainer>
      <PrimaryText>Checkin Team</PrimaryText>
      <GroupSearch
        error={getError(formInfo?.activeStepLabel, formInfo, rosterCheckin)}
        firstRowText={'Select who will be checking in attendees:'}
        groupData={searchData}
        setSearchData={setSearchData}
        setSortedGroups={setSortedGroups}
        variant={'group'}
        loading={loading}
      ></GroupSearch>
      <GroupGridContainer>
        {sortedGroup.group.length > 0 && (
          <GroupGrid
            groupName={'Groups'}
            selectedGroups={sortedGroup.group}
            setSortedGroups={setSortedGroups}
            setSearchData={setSearchData}
          />
        )}
        {sortedGroup.household.length > 0 && (
          <GroupGrid
            groupName={'Households'}
            selectedGroups={sortedGroup.household}
            setSortedGroups={setSortedGroups}
            setSearchData={setSearchData}
          />
        )}
        {sortedGroup.team.length > 0 && (
          <GroupGrid
            groupName={'Teams'}
            selectedGroups={sortedGroup.team}
            setSortedGroups={setSortedGroups}
            setSearchData={setSearchData}
          />
        )}
        {sortedGroup.individual.length > 0 && (
          <GroupGrid
            groupName={'Individuals'}
            selectedGroups={sortedGroup.individual}
            setSortedGroups={setSortedGroups}
            setSearchData={setSearchData}
          />
        )}
      </GroupGridContainer>
      {inviteOnly && (
        <>
          <PrimaryText>Attendees</PrimaryText>
          <SecondaryText>
            List of members, groups, households and teams invited to the event.
          </SecondaryText>
          <DataGridContainer>
            <RosterDataGrid
              columns={dataGridConfig.columns}
              rows={members}
              slots={{ toolbar: RosterDataGridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pagination
            />
          </DataGridContainer>
        </>
      )}
    </RosterInviteOnlyContainer>
  )
}

export default RosterCheckin

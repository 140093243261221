import styled from '@emotion/styled'
import color from '../../../util/colors'
// timestamp
export const ConfirmationTitle = styled.h1`
  color: ${color.textLabel};
  font-size: 28px;
  font-weight: 400;
  min-height: 0px;
  height: fit-content;
  max-width: 420px;
  word-break: break-word;
  overflow-y: auto;
`

export const PreviewBodyConfirmation = styled.div`
margin-top: 2rem;
  width: 100%;
  font-style: normal;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center:
  padding: 3rem 4rem 3rem 4rem;
  color: ${color.timestamp};
  text-align: center;
  gap: 4px;
  min-height: 0px;
  height: fit-content;
  max-height: 224px;
  max-width: 420px;
  word-break: break-word;
  overflow-y: auto;

  p {
  .ql-size-small {
    font-size: 0.75em;


  }
  .ql-size-large {
    font-size: 1.5em;


  }

  .ql-size-huge {
    font-size: 2.5em;

  }
}
`

export const ConfirmationSentTitle = styled.h2`
  color: ${color.textLabel};
  font-size: 16px;
  font-weight: 500;
`

export const ConfirmationSentBody = styled.div`
  color: ${color.timestamp};
  font-size: 16px;
  font-weight: 300;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const ConfirmationButton = styled.button`
  border: none;
  outline: none;
  color: ${color.primary};
  background: transparent;
  font-size: 16px;
  position: relative;
  top: 0;
  cursor: pointer;
`
export const ConfirmationDivider = styled.div`
  background-color: transparent;
  margin-top: ${(props) => props.marginTop || '0px'};
`

export const modalPreviewContainerConfirmation = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxWidth: '444px',
  width: '100%',
  height: 'fit-content',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '2rem 1rem 2rem 1rem',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}

import { updateGathering } from './gatherings'
import { logger } from './logger'
import { updateUserRecentTemplates } from './user'
import { unifyData } from './utility'

const baseUrl = process.env.REACT_APP_BACKEND_URL

export const createGathering = async (gatheringData, authToken, uid, title, activeStep) => {
  if (!gatheringData || !authToken || !uid) {
    return
  }

  const data = {
    status: 'draft',
    createdBy: uid,
    title: title,
    activeStep: activeStep,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...gatheringData,
  }

  try {
    const response = await fetch(`${baseUrl}/api/admin/gatherings/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      redirect: 'follow',
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    logger.error('Create Gathering Error: ', error)
  }
}

export const deleteTemplate = async (id, token, event) => {
  try {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/template/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    event && event()
  } catch (error) {
    logger.error(error)
  }
}

export const getTemplate = async (templateId, token, setTemplatePreview, navigate) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/template/${templateId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    const data = await resp.json()
    data.data.id = data.id
    const unify = data.data
    setTemplatePreview(unify)
    navigate('/templatePreview')
  } catch (error) {
    logger.error(error)
  }
}

export const pickTemplate = async (
  templateId,
  token,
  setFormInfo,
  navigate,
  uid,
  setTemplateId,
) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/template/${templateId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    const data = await resp.json()
    const unify = data.data
    for (const key of ['id', 'title']) {
      if (unify.hasOwnProperty(key)) {
        delete unify[key]
      }
    }

    const response = await createGathering(
      unify,
      token,
      uid,
      `Created from template - ${new Date().getTime()}`,
      1,
    )
    if (response?.id) {
      setTemplateId('')
      const tempFormInfo = {
        ...unify,
        activeStep: 1,
        id: response.id,
        status: 'draft',
        title: `Created from template - ${new Date().getTime()}`,
        created: new Date().toISOString(),
        createdBy: uid,
        gatheringDetails: {
          ...unify.gatheringDetails,
          title: `Created from template - ${new Date().getTime()}`,
        },
      }
      setFormInfo(tempFormInfo)

      logger.log('Created gathering from template with ID:', response?.id)
      updateGathering(tempFormInfo, response?.id, token)
      updateUserRecentTemplates(templateId, uid, token)
    }
    navigate('/createGathering')
  } catch (error) {
    logger.error(error)
  }
}

export const createTemplate = async (gatheringData, authToken, uid, activeStep) => {
  if (!gatheringData || !authToken || !uid) {
    return
  }

  const data = {
    status: 'template',
    createdBy: uid,
    title: gatheringData?.gatheringDetails?.title,
    activeStep: activeStep,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    id: null,
    ...gatheringData,
  }
  try {
    const response = await fetch(`${baseUrl}/api/admin/template/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      redirect: 'follow',
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    logger.error('Create Gathering Error: ', error)
  }
}

export const heartTemplate = async (templateId, uid, authToken) => {
  if (!templateId || !authToken || !uid) {
    return
  }
  let data = {
    userId: uid,
    templateId: templateId,
  }
  try {
    const response = await fetch(`${baseUrl}/api/admin/template/heart`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      redirect: 'follow',
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    logger.error('Create Gathering Error: ', error)
  }
}

export const getHeartedTemplates = async (uid, authToken, setTemplateHearted, setLoading) => {
  if (!authToken || !uid) {
    return
  }

  try {
    const response = await fetch(`${baseUrl}/api/admin/template/heart/${uid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    })
    let responseData = await response.json()
    responseData = unifyData(responseData)
    setTemplateHearted(responseData)
    setLoading && setLoading(false)
    return responseData
  } catch (error) {
    logger.error('Create Gathering Error: ', error)
  }
}

export const updateTemplate = async (gatheringData, templateId, authToken) => {
  const data = {
    ...gatheringData,
  }
  try {
    const response = await fetch(`${baseUrl}/api/admin/template/${templateId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      redirect: 'follow',
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    logger.error('Update Gathering Error: ', error)
  }
}

export const getAllTemplates = async (setTemplates, token) => {
  try {
    const resp = await fetch(`${baseUrl}/api/admin/template/`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await resp.json()
    const unify = unifyData(data)
    setTemplates(unify)
  } catch (error) {
    logger.error(error)
  }
}

/* eslint-disable no-unused-vars */
import {
  CheckinSetupContainer,
  CheckinSetupMainText,
  CheckinSetupAltSubText,
  OptionalDivider,
  Divider,
  DateAndTimeSelectorArea,
  SelectIndividualsArea,
  DateAndTimeWrapper,
  ButtonAndTextRow,
  RowTextArea,
  RowButtonArea,
  RowText,
} from './RosterCheckinKioskSetup.style'
import UrlComponent from '../../UrlComponent/UrlComponent'
import Pin from '../../Pin/Pin'
import GroupGrid from '../../GroupGrid/GroupGrid'
import DateInput from '../../Form/components/DateInput'
import TimeInput from '../../Form/components/TimeInput'
import BooleanButton from '../../BooleanButton/BooleanButton'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { useEffect, useState } from 'react'
import { individualsPool } from '../../../util/testData'

const RosterCheckinKioskSetup = ({ inviteOnly }) => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [rosterCheckinKiosk, setRosterCheckinKiosk] = useState(
    formInfo?.rosterCheckinKiosk ? formInfo?.rosterCheckinKiosk : {},
  )
  const [individuals, setIndividuals] = useState(individualsPool(5))
  const [selectedIndividualsPool, setSelectedIndividualsPool] = useState(
    inviteOnly
      ? formInfo?.rosterCheckin?.selectedGroupsInviteOnly
        ? formInfo?.rosterCheckin?.selectedGroupsInviteOnly
        : []
      : formInfo?.rosterCheckin?.selectedGroups
      ? formInfo?.rosterCheckin?.selectedGroups
      : [],
  )

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.rosterCheckinKiosk = rosterCheckinKiosk
    setFormInfo(newFormInfo)
  }, [rosterCheckinKiosk])

  function setKioskPin(pin) {
    setRosterCheckinKiosk((info) => {
      info.kioskPin = pin
      return { ...info }
    })
  }

  function setDate(selectedDate) {
    setRosterCheckinKiosk((info) => {
      info.selectedDate = selectedDate
      return { ...info }
    })
  }

  function setTime(selectedTime) {
    setRosterCheckinKiosk((info) => {
      info.selectedTime = selectedTime
      return { ...info }
    })
  }

  function setIncludePin(value) {
    setRosterCheckinKiosk((info) => {
      info.pinIncluded = value
      return { ...info }
    })
  }

  function setUrl(value) {
    setRosterCheckinKiosk((info) => {
      info.url = value
      return { ...info }
    })
  }

  useEffect(() => {
    setUrl('https://www.google.com/')
  }, [])

  return (
    <CheckinSetupContainer>
      <CheckinSetupMainText>Setup</CheckinSetupMainText>
      <UrlComponent variant={'checkinUrl'} urlString={'https://www.google.com/'}></UrlComponent>
      <ButtonAndTextRow>
        <RowTextArea>
          <RowText> {'Will you need a Protected Identity Number (PIN) ?'}</RowText>
        </RowTextArea>
        <RowButtonArea>
          <BooleanButton
            setValue={setIncludePin}
            value={formInfo?.rosterCheckinKiosk?.pinIncluded}
            type="Yes"
          />
          <BooleanButton
            setValue={setIncludePin}
            value={formInfo?.rosterCheckinKiosk?.pinIncluded}
            type="No"
          />
        </RowButtonArea>
      </ButtonAndTextRow>
      {formInfo?.rosterCheckinKiosk?.pinIncluded && (
        <Pin
          pinText={`Kiosk's 4-digit PIN:`}
          kiosk={true}
          error={
            formInfo?.highestStep === formInfo?.activeStep
              ? false
              : formInfo?.rosterCheckinKiosk?.kioskPin === ''
          }
          pinNumber={
            formInfo?.rosterCheckinKiosk?.kioskPin ? formInfo?.rosterCheckinKiosk?.kioskPin : ''
          }
          setKioskPin={setKioskPin}
        ></Pin>
      )}

      <OptionalDivider>
        OPTIONAL:
        <Divider></Divider>
      </OptionalDivider>
      <SelectIndividualsArea>
        {selectedIndividualsPool?.group?.length > 0 && (
          <GroupGrid
            groupName={'Groups'}
            selectedGroups={selectedIndividualsPool.group}
            setSortedGroups={setSelectedIndividualsPool}
            setSearchData={setIndividuals}
          />
        )}
        {selectedIndividualsPool?.team?.length > 0 && (
          <GroupGrid
            groupName={'Teams'}
            selectedGroups={selectedIndividualsPool.team}
            setSortedGroups={setSelectedIndividualsPool}
            setSearchData={setIndividuals}
          />
        )}
        {selectedIndividualsPool?.household?.length > 0 && (
          <GroupGrid
            groupName={'Households'}
            selectedGroups={selectedIndividualsPool.household}
            setSortedGroups={setSelectedIndividualsPool}
            setSearchData={setIndividuals}
          />
        )}
        {selectedIndividualsPool?.group?.length > 0 && (
          <GroupGrid
            groupName={'Individuals'}
            selectedGroups={selectedIndividualsPool.individual}
            setSortedGroups={setSelectedIndividualsPool}
            setSearchData={setIndividuals}
          />
        )}
      </SelectIndividualsArea>
      <DateAndTimeSelectorArea>
        <CheckinSetupAltSubText>Date & Time to send Checkin link:</CheckinSetupAltSubText>
        <DateAndTimeWrapper>
          <DateInput onChange={setDate}></DateInput>
          <TimeInput onChange={setTime}></TimeInput>
        </DateAndTimeWrapper>
      </DateAndTimeSelectorArea>
    </CheckinSetupContainer>
  )
}

export default RosterCheckinKioskSetup

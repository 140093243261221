import styled from 'styled-components'
import color from '../../util/colors'

export const TemplatesFilterArea = styled.div`
  transition: height 0.5s ease-out, padding 0.7s ease-out;
  overflow: hidden;
  width: 100%;
  height: auto;
  background-color: ${color.filterBackground};
  padding: 2.5rem 2.5rem 2.5rem 3rem;
  box-sizing: border-box;
  ${(props) =>
    props.open === false &&
    `
    padding-top: 0;
  padding-bottom: 0;
  height: 0;
  `}
`

export const FilterText = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 32px;
  color: ${color.description};
`

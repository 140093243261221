import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import color from '../../util/colors'

const StyledTableRow = styled(TableRow)({
  '&.MuiTableRow-root:hover': {
    backgroundColor: color.backgroundPrimary,
    borderLeft: `5px solid ${color.tableHover}`,
  },
  width: '100%',
})

const StyledTableCell = styled(TableCell)({
  borderBottom: 0,
  color: color.description,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '140%',
  letterSpacing: ' 0.0015em',
  paddingLeft: '3rem',
})

const StyledTableCellButton = styled(Button)`
  width: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  text-transform: none;
  color: ${color.primary};
  margin-left: 50px;

  &.MuiButton-root:hover {
    color: ${color.tableHover};
    background-color: transparent;
  }

  @media (max-width: 1024px) {
    margin-left: 8px;
  }
`

export { StyledTableRow, StyledTableCell, StyledTableCellButton }

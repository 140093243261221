import { StyledTag } from './Tag.style'
import ClearIcon from '@mui/icons-material/Clear'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  HandleVariant,
  HandleTemplateVariant,
  HandlePreviewTag,
  HandleEventType,
  handleWaiversToTickets,
  TransferExampleWaivers,
} from './util/Tag.utill'
import color from '../../util/colors'
import { handleFileRemoval } from '../../util/fileUpload'
const Tag = ({
  tagObject,
  variant,
  templateVariant,
  makeQuestionActive,
  setQuestionSaved,
  removeQuestionFromPool,
  setQuestionsPool,
  setActiveQuestion,
  removeTagFromPool,
  setTagPool,
  makeTagActive,
  setActiveTag,
  setTagSaved,
  handleSaveButton,
  previewDataLabel,
  previewTag,
  uploadArray,
  exampleArray,
  setUploadArray,
  setExampleArray,
  label,
  index,
  experienceName,
  activeTicket,
  eventType,
}) => {
  switch (variant) {
    case 'question':
      return (
        <StyledTag
          icon={HandleVariant(variant)}
          variant="filled"
          label={tagObject.question}
          deleteIcon={<ClearIcon />}
          onDelete={() => {
            removeQuestionFromPool(tagObject.id, setQuestionsPool, activeTicket)
          }}
          onClick={() => {
            makeQuestionActive(tagObject, setActiveQuestion)
            setQuestionSaved(false)
          }}
        />
      )
    case 'exampleQuestion':
      return (
        <StyledTag
          icon={HandleVariant(variant)}
          variant="filled"
          label={tagObject.question}
          deleteIcon={<ClearIcon />}
          onDelete={() => {}}
          onClick={() => {
            makeQuestionActive(tagObject, setActiveQuestion)
            setQuestionSaved(false)
          }}
        />
      )
    case 'document':
      return (
        <StyledTag
          icon={HandleVariant(variant)}
          variant="filled"
          label={tagObject.filename}
          deleteIcon={<ClearIcon />}
          onDelete={() => {
            if (activeTicket && tagObject) {
              handleFileRemoval(tagObject.id, setUploadArray, activeTicket)
            } else {
              setUploadArray((prevTag) => {
                prevTag = prevTag.filter((_, i) => i !== index)

                if (tagObject.example) {
                  setExampleArray((tags) => {
                    tags[tags.length] = tagObject
                    return [...tags]
                  })
                }
                return [...prevTag]
              })
            }
          }}
          onClick={() => {
            fetch(tagObject.fileUrl, {
              method: 'GET',
              responseType: 'blob',
            })
              .then((response) => response.blob())
              .then((blob) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = tagObject.filename
                link.click()
              })
          }}
        />
      )
    case 'exampleWaiver':
      return (
        <StyledTag
          disabled={eventType === 'Paid' && !activeTicket}
          icon={HandleVariant(variant)}
          variant="filled"
          label={tagObject.filename}
          deleteIcon={<ClearIcon />}
          onDelete={() => {
            setExampleArray((prevTag) => {
              const updatedTags = prevTag.filter((tag) => tag.filename !== tagObject.filename)
              return updatedTags
            })
          }}
          onClick={() => {
            if (activeTicket && tagObject) {
              handleWaiversToTickets(tagObject, setUploadArray, activeTicket)
            } else {
              TransferExampleWaivers(
                exampleArray,
                tagObject,
                setExampleArray,
                uploadArray,
                setUploadArray,
              )
            }
          }}
        />
      )
    case 'gathering':
      return (
        <StyledTag
          variant="filled"
          label={label ? tagObject[label] : tagObject.name}
          onClick={() => {}}
        />
      )
    case 'tags':
      return (
        <StyledTag
          variant="filled"
          label={tagObject.name}
          onDelete={() => {
            removeTagFromPool(tagObject.id, setTagPool)
          }}
          onClick={() => {
            makeTagActive(tagObject, setActiveTag)
            setTagSaved(false)
            handleSaveButton(false)
          }}
        />
      )
    case 'gatheringTemplate':
      return (
        <StyledTag
          icon={HandleTemplateVariant(templateVariant)}
          variant="filled"
          label={label ? tagObject[label] : tagObject.title}
        />
      )
    case 'partOfExperience':
      return (
        <StyledTag
          icon={HandleTemplateVariant(templateVariant)}
          variant="filled"
          label={experienceName}
        />
      )
    case 'previewTag':
      return (
        <StyledTag icon={HandlePreviewTag(previewTag)} variant="filled" label={previewDataLabel} />
      )
    case 'previewTagDiscount':
      return (
        <StyledTag
          icon={HandlePreviewTag(previewTag)}
          variant="filled"
          label={previewDataLabel}
          deleteIcon={<ContentCopyIcon />}
          onDelete={() => {}}
        />
      )
    case 'previewTagEventType':
      return (
        <StyledTag
          icon={HandlePreviewTag(previewTag)}
          variant="filled"
          label={previewDataLabel}
          style={{
            backgroundColor: HandleEventType(previewDataLabel),
            color: color.backgroundPrimary,
          }}
        />
      )
    case 'searchComponentTags':
      return <StyledTag variant="filled" label={tagObject.name} onClick={() => {}} />
    default:
      return <></>
  }
}

export default Tag

import styled from 'styled-components'
import color from '../../../util/colors'

const CheckinKioskContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;
`

const CheckinKioskMainText = styled.div`
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  color: var(--title);
`

const CreateKioskRow = styled.div`
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.0152em;
  text-transform: uppercase;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
  color: var(--title);
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`

const KioskComponentsArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 3rem;
`

const SkeletonHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const kioskHeaderIcon = {
  color: color.primary,
}
export {
  CheckinKioskContainer,
  CheckinKioskMainText,
  CreateKioskRow,
  KioskComponentsArea,
  kioskHeaderIcon,
  SkeletonHolder,
}

/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material'
import { ReactComponent as Logo } from '../../assets/ChurchAi.svg'
import { Dashboard, ExpandLess, ExpandMore } from '@mui/icons-material'
import './Sidebar.css'
import sidebarData from '../../assets/sidebarData.json'
import React from 'react'
import {
  getTabIcon,
  Drawer,
  DrawerHeader,
  ManageActiveBackground,
  ManageActiveColor,
} from '../../util/sidebarUtil'
import color from '../../util/colors'
import { ListItemButtonStyle } from './Sidebar.styles'
import { useNavigate, useLocation } from 'react-router-dom'

const createDefaultStates = () => {
  const defaultExpandedState = {}
  sidebarData.tabs.forEach((tab) => {
    defaultExpandedState[tab.title] = false
  })
  return defaultExpandedState
}

const Sidebar = () => {
  const [expand, setExpand] = useState(createDefaultStates())
  const [open, setOpen] = useState(false)
  const [locationPath, setLocationPath] = useState('/')
  const [activeTab, setActiveTab] = useState('Gather')

  const navigate = useNavigate()
  const location = useLocation()

  const handleExpand = (tab) => {
    if (expand[tab.title]) {
      setExpand((prevState) => ({
        ...prevState,
        [tab.title]: false,
      }))
    } else {
      setExpand((prevState) => ({
        ...prevState,
        [tab.title]: true,
      }))
    }
  }

  useEffect(() => {
    setLocationPath(location.pathname)
  }, [location])

  return (
    <Box sx={{ display: 'flex' }} className="sidebar">
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            borderWidth: 0,
            minWidth: '65px',
            boxShadow: '19px 4px 85px -51px #00000024',
          },
        }}
        variant="permanent"
        open={open}
      >
        <DrawerHeader
          onClick={() => {
            setOpen(!open)
            setExpand(createDefaultStates())
          }}
        >
          <div className="headerIcon">
            <Logo />
          </div>
          {open && <div className="titleText">Church.ai</div>}
        </DrawerHeader>
        <List sx={{ padding: '10px' }}>
          <ListItemButton
            sx={{
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              marginBottom: '10px',
              ...ListItemButtonStyle,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1 : 'auto',
                justifyContent: 'center',
              }}
            >
              <Dashboard style={{ color: color.primary }} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              className="dropdownText"
              primary={'Dashboard'}
              sx={{
                opacity: open ? 1 : 0,
                fontWeight: 500,
              }}
            >
              Dashboard
            </ListItemText>
          </ListItemButton>
          {sidebarData.tabs.map((tab, i) => (
            <ListItem
              key={i}
              disablePadding
              sx={{
                display: 'block',
                marginBottom: '10px',
                border: tab.children.length > 0 && !open ? `1px solid ${color.divider}` : 'none',
                borderRadius: tab.children.length > 0 ? '5px' : '0px',
                backgroundColor: tab.children.length > 0 && open ? color.sidebarBackground : 'none',
              }}
            >
              <ListItemButton
                disableRipple
                selected={expand[tab.title] && tab.redirectLink === locationPath}
                onClick={() => {
                  handleExpand(tab)
                  if (tab.redirectLink !== '') {
                    navigate(tab.redirectLink)
                  }
                }}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  ...ListItemButtonStyle,
                  backgroundColor: ManageActiveBackground(tab.redirectLink, locationPath),
                  '&.MuiButtonBase-root: hover': {
                    backgroundColor: ManageActiveBackground(tab.redirectLink, locationPath),
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {getTabIcon(
                    tab,
                    ManageActiveColor(
                      activeTab,
                      tab.title,
                      expand[tab.title],
                      tab.redirectLink,
                      locationPath,
                    ),
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className="dropdownText"
                  primary={tab.title}
                  sx={{
                    opacity: open ? 1 : 0,
                    fontWeight: 500,
                    color: ManageActiveColor(
                      activeTab,
                      tab.title,
                      expand[tab.title],
                      tab.redirectLink,
                      locationPath,
                    ),
                  }}
                />
                {tab.children.length > 0 &&
                  open &&
                  (expand[tab.title] && tab.redirectLink === locationPath ? (
                    <ExpandLess
                      style={{
                        color: ManageActiveColor(
                          activeTab,
                          tab.title,
                          expand[tab.title],
                          tab.redirectLink,
                          locationPath,
                        ),
                      }}
                      className="dropdownArrow"
                    />
                  ) : (
                    <ExpandMore
                      className="dropdownArrow"
                      style={{
                        color: ManageActiveColor(
                          activeTab,
                          tab.title,
                          expand[tab.title],
                          tab.redirectLink,
                          locationPath,
                        ),
                      }}
                    />
                  ))}
              </ListItemButton>
              {tab.children.length > 0 && (
                <Collapse in={expand[tab.title]} timeout="auto" unmountOnExit>
                  <List sx={{ display: 'block', padding: '5px' }} component="div" disablePadding>
                    {tab.children.map((event, i) => {
                      return (
                        <ListItemButton
                          key={i}
                          sx={{
                            justifyContent: open ? 'initial' : 'center',
                            width: '100%',
                            height: '2.5rem',
                            ...ListItemButtonStyle,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: '30px',
                              display: 'flex',
                              justifyContent: open ? 'flex-start' : 'center',
                            }}
                          >
                            {getTabIcon(event)}
                          </ListItemIcon>
                          <ListItemText
                            className="dropdownText"
                            disableTypography
                            primary={event.title}
                            sx={{
                              opacity: open ? 1 : 0,
                              fontWeight: 500,
                            }}
                          />
                        </ListItemButton>
                      )
                    })}
                  </List>
                </Collapse>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  )
}

export default Sidebar

import {
  ApprovalContainer,
  DateSAndTimeSlectorArea,
  DateAndTimeSubText,
  DateAndTimeWrapper,
} from './Approval.style'
import ApprovalActivity from './components/ApprovalActivity'
import DateInput from '../../Form/components/DateInput'
import TimeInput from '../../Form/components/TimeInput'
import GroupSearch from '../../GroupSearch/GroupSearch'
import GroupGrid from '../../GroupGrid/GroupGrid'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { useState, useEffect } from 'react'
import { getAdminUsers } from '../../../util/user'
import { useAuth } from '../../../hooks/useAuth'

const Approval = ({ saveDraft }) => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [approval, setApproval] = useState(formInfo?.approval ? formInfo.approval : {})
  const { token } = useAuth()
  const [adminUsers, setAdminUsers] = useState([])

  const [selectedApproversPool, setSelectedApproversPool] = useState(
    formInfo?.approval?.selectedApprovers
      ? formInfo?.approval?.selectedApprovers
      : {
          individual: {},
        },
  )

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.approval = approval
    setFormInfo(newFormInfo)
    saveDraft()
  }, [approval])

  useEffect(() => {
    getAdminUsers(setAdminUsers, token)
  }, [])

  function setDueDate(value) {
    setApproval((info) => {
      info.dueDate = value
      return { ...info }
    })
  }

  function setDueTime(value) {
    setApproval((info) => {
      info.dueTime = value
      return { ...info }
    })
  }

  function setRemindedSent(value) {
    setApproval((info) => {
      info.reminderSent = value
      return { ...info }
    })
  }
  function setReminderSentTo(value) {
    setApproval((info) => {
      info.reminderSentTo = value
      return { ...info }
    })
  }
  function setReminderActive(value) {
    setApproval((info) => {
      info.reminderActive = value
      return { ...info }
    })
  }

  useEffect(() => {
    setApproval((info) => {
      info.selectedApprovers = selectedApproversPool
      return { ...info }
    })
  }, [selectedApproversPool])

  return (
    <ApprovalContainer>
      <GroupSearch
        firstRowText={'Find the approver for your gathering:'}
        groupData={adminUsers}
        setSearchData={() => {}}
        setSortedGroups={() => {}}
        setSelectedApproversPool={setSelectedApproversPool}
        variant={'gatheringApprover'}
      ></GroupSearch>
      {selectedApproversPool.individual && selectedApproversPool.individual?.id && (
        <GroupGrid
          groupName={'Individuals'}
          selectedGroups={[selectedApproversPool.individual]}
          setSortedGroups={() => {}}
          setSearchData={() => {}}
          variant={'gatheringApprover'}
          setSelectedApproversPool={setSelectedApproversPool}
        />
      )}
      <DateSAndTimeSlectorArea>
        <DateAndTimeSubText>{'Approval due date'}</DateAndTimeSubText>
        <DateAndTimeWrapper>
          <DateInput
            onChange={setDueDate}
            value={formInfo?.approval?.dueDate ? formInfo?.approval?.dueDate : ''}
          ></DateInput>
          <TimeInput
            onChange={setDueTime}
            value={formInfo?.approval?.dueTime ? formInfo?.approval?.dueTime : ''}
          ></TimeInput>
        </DateAndTimeWrapper>
      </DateSAndTimeSlectorArea>
      <ApprovalActivity
        selectedApproversPool={selectedApproversPool}
        formInfo={formInfo}
        reminderDate={formInfo?.approval?.reminderSent ? formInfo?.approval?.reminderSent : ''}
        setReminderDate={setRemindedSent}
        setReminderSentTo={setReminderSentTo}
        setReminderActive={setReminderActive}
      ></ApprovalActivity>
    </ApprovalContainer>
  )
}

export default Approval

import React from 'react'
import { Select, MenuItem, InputLabel } from '@mui/material'
import { LocationInputStyle } from './Selector.styles'
function Selector({ value, onChange, items, label, error }) {
  return (
    <LocationInputStyle variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <Select
        value={value ? value : ''}
        label={label}
        error={error}
        onChange={(value) => onChange(value.target.value)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </LocationInputStyle>
  )
}

export default Selector

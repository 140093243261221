/* eslint-disable no-unused-vars */
import { useState, useRef, useLayoutEffect, useEffect } from 'react'
import { Autocomplete, Chip, InputAdornment } from '@mui/material'
import { TextField, IconButton } from '@mui/material'
import { FilterList, SearchRounded } from '@mui/icons-material'
import GlobalSearchFilter from '../GlobalSearchFilter/GlobalSearchFilter'
import { Popper } from '@mui/material'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import useSizeObserver from '@react-hook/resize-observer'
import GroupFrame from '../GroupFrame/GroupFrame'
import Tag from '../Tag/Tag'
import { handleGroupVariants } from '../../util/groupGrid'
import { logger } from '../../util/logger'
import ExperiencesFilter from '../ExperiencesFilter/ExperiencesFilter'
import GatheringsFilter from '../GatheringsFilter/GatheringsFilter'
import color from '../../util/colors'
import { filterEventsByTitle, removeInvalidObjects } from '../../util/events'
import { useFormGatherings } from '../../util/zustant/store'
import { useNavigate } from 'react-router-dom'
import { Loading } from './SearchComponent.styled'
const useSize = (target) => {
  const [size, setSize] = useState()

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target])

  useSizeObserver(target, (entry) => setSize(entry.contentRect))
  return size?.width
}

const SearchComponent = ({
  searchData,
  setSearchData,
  filters,
  variant,
  placeholderText,
  setSortedGroups,
  label,
  addTagToThePool,
  setTagPool,
  sortKiosksBySearch,
  sortLabelsBySearch,
  experiencesFilter,
  gatheringsFilter,
  sortOnboardingsBySearch,
  setFilteredData,
  sourceOfTruth,
  setFilter,
  filterType,
  draftFilter,
  filterData,
  loading,
  error,
  setSelectedApproversPool,
}) => {
  const [filterBox, setFilterBox] = useState({
    global: false,
    experiences: true,
    gatherings: true,
  })
  const [globalFilter, setGlobalFilter] = useState({
    audience: false,
    campus: false,
    experience: false,
    gathering: false,
    group: false,
    profile: false,
    tag_new: false,
  })
  const [preparedSearchData, setPreparedSearchData] = useState([])
  const [event, setEvent] = useState(false)
  const search = useRef(null)
  const popperWidth = useSize(search)
  const [gatheringObject, setGatheringObject] = useState()
  const { setFormInfo, formInfo } = useFormGatherings()
  //Commented out because this was slowing down the page, and this functionality isn't needed in partiuclar
  // const SortFunction = sortKiosksBySearch || sortLabelsBySearch || sortOnboardingsBySearch
  const navigate = useNavigate()

  function handleGroupObjects(option) {
    const filteredGroupsPool = searchData?.filter((item) => option.id !== item.id)
    setSearchData(filteredGroupsPool)
    handleGroupVariants(option.variant, option, setSortedGroups)
    if (setSelectedApproversPool) {
      setSelectedApproversPool({ individual: option })
    }
  }

  useEffect(() => {
    const isFilterEmpty = Object.values(globalFilter).every((value) => !value)
    if (isFilterEmpty) {
      const flatData = Object.values(searchData).flat()
      setPreparedSearchData(removeInvalidObjects(flatData))
    } else {
      const filteredValues = Object.entries(globalFilter).reduce((filtered, [key, value]) => {
        if (value && filterData.hasOwnProperty(key)) {
          const filteredItems = filterData[key]
          return [...filtered, ...filteredItems]
        }
        return filtered
      }, [])

      setPreparedSearchData(removeInvalidObjects(filteredValues))
    }
  }, [searchData, filterData, globalFilter])

  useEffect(() => {
    //Set check if object is gathering object, assuming that all of the gathering objects will have gathering details
    //Also here is a check for published status since we currently don't have a way to show published events
    if (!gatheringObject?.gatheringDetails) {
      return
    }
    setFormInfo(gatheringObject)
    navigate('/createGathering')
  }, [gatheringObject])

  return (
    <ClickAwayListener
      onClickAway={() => {
        setEvent(false)
      }}
    >
      <div style={{ width: '100%' }}>
        <Autocomplete
          ref={search}
          open={event}
          onInputChange={(event, value) => {
            if (experiencesFilter) {
              filterEventsByTitle(sourceOfTruth, setSearchData, value, setFilteredData, false)
            }
            if (gatheringsFilter) {
              filterEventsByTitle(sourceOfTruth, setSearchData, value, setFilteredData, true)
            }
          }}
          disablePortal
          openOnFocus
          sx={{
            '& .MuiOutlinedInput-root': {
              paddingRight: '10px!important',
            },
          }}
          id="main-search"
          freeSolo
          options={
            searchData?.length > 0
              ? filters
                ? preparedSearchData?.map((option) => option)
                : searchData?.map((option) => option)
              : []
          }
          PopperComponent={(props) => {
            return (
              <Popper
                {...props}
                style={{
                  width: popperWidth,
                  top: 50,
                  paddingTop: filterBox.global ? '3rem' : 0,
                  zIndex: 1000,
                }}
                placement="bottom-start"
              />
            )
          }}
          renderOption={(props, option) => {
            if (variant === 'groupSearch') {
              return (
                <li {...props} key={option.id} onClick={() => handleGroupObjects(option)}>
                  <GroupFrame group={option} forSearch={true}></GroupFrame>
                </li>
              )
            } else if (variant === 'users') {
              option.variant = 'individual'
              option.name = option.displayName || option.email
              return (
                <li {...props} key={option.id} onClick={() => handleGroupObjects(option)}>
                  <GroupFrame group={option} forSearch={true}></GroupFrame>
                </li>
              )
            } else if (variant === 'tag') {
              return (
                <li
                  {...props}
                  key={option.id}
                  onClick={() => {
                    addTagToThePool(option, setTagPool)
                  }}
                >
                  <Tag tagObject={option} variant={'searchComponentTags'}></Tag>
                </li>
              )
            } else if (variant === 'kiosk') {
              return (
                <li
                  {...props}
                  key={option.id}
                  onClick={() => {
                    sortKiosksBySearch(option.name)
                  }}
                >
                  {label ? option[label] : option.title}
                </li>
              )
            } else if (variant === 'onboarding') {
              return (
                <li
                  {...props}
                  key={option.id}
                  onClick={() => {
                    sortOnboardingsBySearch(option.name)
                  }}
                >
                  {label ? option[label] : option.title}
                </li>
              )
            } else if (variant === 'label') {
              return (
                <li
                  {...props}
                  key={option.id}
                  onClick={() => {
                    sortLabelsBySearch(option.name)
                  }}
                >
                  {label ? option[label] : option.title}
                </li>
              )
            } else if (variant === 'template') {
              return (
                <li
                  {...props}
                  key={option.id}
                  onClick={() => {
                    logger.log(option)
                    setFilter(
                      filterType === 'title'
                        ? { title: option?.gatheringDetails?.title }
                        : { label: option?.gatheringDetails?.label },
                    )
                  }}
                >
                  {filterType === 'title'
                    ? option?.gatheringDetails?.title
                    : option?.gatheringDetails?.label}
                </li>
              )
            } else {
              return (
                <li
                  style={{ display: 'flex', gap: '15px', width: '100%' }}
                  {...props}
                  key={option.id}
                  onClick={() => {
                    if (option.id) {
                      setGatheringObject(option)
                    }
                  }}
                >
                  <div style={{ width: '70%' }}>
                    {option.title || option.firstname || option.name}
                  </div>
                  <div
                    style={{
                      width: '30%',
                      textAlign: 'end',
                    }}
                  >
                    <Chip
                      style={{ textTransform: 'uppercase', color: color.primary }}
                      label={option.collection}
                    ></Chip>
                  </div>
                </li>
              )
            }
          }}
          getOptionLabel={(option) =>
            option[label] || option.firstname || option.title || option.name || ''
          }
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              placeholder={placeholderText}
              id="standard-bare"
              variant="outlined"
              onChange={(e) => {
                setEvent(true)
                if (experiencesFilter) {
                  filterEventsByTitle(
                    sourceOfTruth,
                    setSearchData,
                    e.target.value,
                    setFilteredData,
                    false,
                  )
                }
                if (gatheringsFilter || draftFilter) {
                  filterEventsByTitle(
                    sourceOfTruth,
                    setSearchData,
                    e.target.value,
                    setFilteredData,
                    true,
                  )
                }
                if (setFilter) {
                  e.target.value === ''
                    ? setFilter(null)
                    : setFilter(
                        filterType === 'title'
                          ? { title: e.target.value }
                          : { label: e.target.value },
                      )
                }

                // SortFunction ? SortFunction(e.target.value) : logger.log(e.target.value)
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      {loading ? <Loading /> : <SearchRounded style={{ color: color.primary }} />}
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {filters && (
                      <IconButton
                        onClick={() => {
                          setFilterBox((prevState) => ({
                            ...prevState,
                            global: !prevState.global,
                          }))
                        }}
                      >
                        <FilterList style={{ color: color.primary }} />
                      </IconButton>
                    )}
                    {experiencesFilter && (
                      <IconButton
                        onClick={() => {
                          setFilterBox((prevState) => ({
                            ...prevState,
                            experiences: !prevState.experiences,
                          }))
                        }}
                      >
                        <FilterList style={{ color: color.primary }} />
                      </IconButton>
                    )}
                    {gatheringsFilter && (
                      <IconButton
                        onClick={() => {
                          setFilterBox((prevState) => ({
                            ...prevState,
                            gatherings: !prevState.gatherings,
                          }))
                        }}
                      >
                        <FilterList style={{ color: color.primary }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {filters && (
          <div style={{ display: !filterBox.global ? 'none' : 'block' }}>
            <GlobalSearchFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            ></GlobalSearchFilter>
          </div>
        )}
        {experiencesFilter && (
          <div style={{ display: !filterBox.experiences ? 'none' : 'block' }}>
            <ExperiencesFilter
              searchData={searchData}
              setFilteredData={setFilteredData}
            ></ExperiencesFilter>
          </div>
        )}
        {gatheringsFilter && (
          <div style={{ display: !filterBox.gatherings ? 'none' : 'block' }}>
            <GatheringsFilter
              searchData={searchData}
              setFilteredData={setFilteredData}
            ></GatheringsFilter>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default SearchComponent

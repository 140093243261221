import { logger } from './logger'

const baseUrl = process.env.REACT_APP_BACKEND_URL

export const createKiosk = async (authToken, gatheringId, data) => {
  if (!authToken) {
    return
  }

  data.gatheringId = gatheringId
  data.pin = data.kioskPin
  delete data.kioskPin
  delete data.pinIncluded

  data.name = data?.name ? data.name : ''

  try {
    const response = await fetch(`${baseUrl}/api/admin/kiosk/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      redirect: 'follow',
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    logger.error('Create Kiosk Error: ', error)
    return false
  }
}

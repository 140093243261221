import React from 'react'
import { OccurrenceHolder, Row, OrderBox, InfoButton } from './LogisticsInfo.styles'
import { Label } from '../../Form/FormElements.styles.jsx'
import { Checkbox } from '@mui/material'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import { formatTimeOccurencSmallPreview } from '../../../util/date'
import { generateFutureDates } from '../../../util/date'
import dayjs from 'dayjs'
import { logger } from '../../../util/logger'

const DaysRow1 = ['Mondays', 'Wednesdays', 'Fridays', 'Sundays']
const DaysRow2 = ['Tuesdays', 'Thursdays', 'Saturdays']

function OccurrenceInterval({
  event,
  setterOccurrence,
  formInfo,
  occurrences,
  selectDays,
  setFocusedIndex,
  focusedIndex,
}) {
  // const [focusedIndex, setFocusedIndex] = useState(null)

  return (
    <OccurrenceHolder>
      {event?.startDate && event?.startTime && event?.recurrencAmount && (
        <Row minHeight={'0'}>
          <Row minHeight={'0'} flexWrap={true}>
            {event?.recurres !== 'custom' && (
              <Label minWidth={'209px'}>
                {event?.recurrencAmount} {event?.recurres} recurrences at:
              </Label>
            )}
            {event?.recurres === 'custom' && (
              <Label minWidth={'209px'}>
                {selectDays?.length} {event?.recurres} recurrences at:
              </Label>
            )}
            {formInfo?.logisticsInfo?.event?.recurres !== 'custom' &&
              formInfo?.logisticsInfo?.event?.occurrences?.length > 0 &&
              generateFutureDates(event?.startDate, event?.recurres, event?.recurrencAmount)?.map(
                (date, i) => {
                  return (
                    <button
                      onClick={() => {
                        if (
                          dayjs(
                            formInfo?.logisticsInfo?.event?.occurrences[i]?.initialStartDate,
                          ).format('MMMM DD,YYYY') !==
                            dayjs(formInfo?.logisticsInfo?.event?.occurrences[i]?.startDate).format(
                              'MMMM DD,YYYY',
                            ) ||
                          formInfo?.logisticsInfo?.event?.startTime !==
                            formInfo?.logisticsInfo?.event?.occurrences[i]?.startTime
                        ) {
                          logger.log(
                            'StartDate',
                            formInfo?.logisticsInfo?.event?.occurrences[i].initialStartDate,
                          )
                          logger.log(
                            'StartDateOccur',
                            dayjs(formInfo?.logisticsInfo?.event?.occurrences[i]?.startDate).format(
                              'MMMM DD,YYYY',
                            ),
                          )
                          setFocusedIndex(i)
                        }

                        if (!occurrences?.includes(i + 1)) {
                          setterOccurrence(i + 1)
                        }
                      }}
                      style={{ border: 'none', outline: 'none', background: 'transparent' }}
                    >
                      <InfoButton
                        focused={focusedIndex?.includes(i)}
                        active={occurrences?.includes(i + 1)}
                        key={i}
                      >
                        <OrderBox
                          focused={focusedIndex?.includes(i)}
                          active={occurrences?.includes(i + 1)}
                        >
                          {i + 1}
                        </OrderBox>{' '}
                        {event?.occurrences.length < 1
                          ? date
                          : dayjs(event?.occurrences[i]?.startDate).format('MMMM DD,YYYY')}{' '}
                        <AccessTimeFilledIcon />{' '}
                        {formatTimeOccurencSmallPreview(
                          event?.occurrences[i]?.startTime
                            ? event?.occurrences[i]?.startTime
                            : event?.startTime,
                        )}
                      </InfoButton>
                    </button>
                  )
                },
              )}
            {formInfo?.logisticsInfo?.event?.recurres === 'custom' &&
              formInfo?.logisticsInfo?.event?.occurrences?.length > 0 &&
              formInfo?.logisticsInfo?.event?.occurrences?.map((date, i) => {
                return (
                  <button
                    onClick={() => {
                      if (!occurrences?.includes(i + 1)) {
                        setterOccurrence(i + 1)
                      }
                    }}
                    style={{ border: 'none', outline: 'none' }}
                    key={i}
                  >
                    <InfoButton
                      focused={focusedIndex?.includes(i)}
                      active={occurrences?.includes(i + 1)}
                      key={i}
                    >
                      <OrderBox
                        focused={focusedIndex?.includes(i)}
                        active={occurrences?.includes(i + 1)}
                      >
                        {i + 1}
                      </OrderBox>{' '}
                      {event?.occurrences.length < 1
                        ? date
                        : dayjs(event?.occurrences[i]?.startDate).format('MMMM DD,YYYY')}{' '}
                      <AccessTimeFilledIcon />{' '}
                      {event?.occurrences[i]?.startTime
                        ? formatTimeOccurencSmallPreview(event?.occurrences[i]?.startTime)
                        : formatTimeOccurencSmallPreview(event?.startTime)}
                    </InfoButton>
                  </button>
                )
              })}{' '}
          </Row>{' '}
        </Row>
      )}
      {formInfo?.logisticsInfo?.event?.recurres === 'daily' && (
        <Row minHeight={'0'} flexWrap={true}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '10px' }}>
            <Label>Exclude:</Label>
            {DaysRow1.map((day, i) => (
              <div key={i} style={{ gridColumn: i + 2 }}>
                <Checkbox />
                {day}
              </div>
            ))}
            {DaysRow2.map((day, i) => (
              <div key={i} style={{ gridColumn: i + 2, gridRow: 2 }}>
                <Checkbox />
                {day}
              </div>
            ))}
          </div>
        </Row>
      )}
    </OccurrenceHolder>
  )
}

export default OccurrenceInterval

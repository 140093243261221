/* eslint-disable no-unused-vars */
import { Button } from '@mui/material'
// import './Logistics.css'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { BasicInfoBackButtonStyle, BasicInfoNextButtonStyle } from './Form.styles'
import {
  Container,
  Header,
  MainBox,
  MainContent,
  Footer,
  FooterContent,
  alertBoxStyle,
  alertBoxIcon,
} from './Form.styles'
import { useFormGatherings } from '../../util/zustant/store'
import { useNavigate } from 'react-router'
import { useAuth } from '../../hooks/useAuth'
import { updateGathering, createGathering, sendApprovalEmail } from '../../util/gatherings'
import { logger } from '../../util/logger'
import { useEvents } from '../../hooks/useEvents'
import { useCallback, useEffect, useState } from 'react'
import { useLabel } from '../../util/zustant/labelStore'
import { saveLabels } from '../../util/labels'
import { useOnboarding } from '../../util/zustant/onboardingStore'
import { saveOnboarding } from '../../util/onboarding'
import { validateTitle } from '../../util/events'
import { checkMissingInformation, isEmptyObject } from '../../util/utility'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import ReportIcon from '@mui/icons-material/Report'
import { createThirdPartyObjects } from '../../util/publish'
import { createKiosk } from '../../util/kiosks'
import { updateTemplate } from '../../util/templates'

//This is an enumeration for the locked screens
const lockedSteps = {
  Logistics: 'logisticsInfo',
  'Basic Info': 'gatheringDetails',
  'Choose Paid or Unpaid': 'paidEvent',
}

function useDebounce(callback, delay) {
  const [timer, setTimer] = useState(null)

  const debouncedCallback = useCallback(
    (...args) => {
      clearTimeout(timer)
      setTimer(setTimeout(() => callback(...args), delay))
    },
    [callback, delay],
  )

  return debouncedCallback
}

function checkAllTicketValues(tickets) {
  return tickets.every((ticket) => {
    const isValidDiscountCode = ticket.discountCodes
      ? ticket.discountCodes.every(
          (code) =>
            code.name !== undefined &&
            code.name !== null &&
            code.name !== '' &&
            code.percentage !== undefined &&
            code.percentage !== null &&
            code.percentage !== '',
        )
      : true
    return (
      ticket.name !== undefined &&
      ticket.name !== null &&
      ticket.name !== '' &&
      ticket.cost !== undefined &&
      ticket.cost !== null &&
      ticket.cost !== '' &&
      ticket.cost !== '$' &&
      ticket.net !== undefined &&
      ticket.net !== null &&
      ticket.net !== '' &&
      isValidDiscountCode
      // ticket.numberOfTickets !== undefined &&
      // ticket.numberOfTickets !== null &&
      // ticket.numberOfTickets !== ''
    )
  })
}

export function checkErrorFlags(formInfo, setMissingInformation) {
  switch (formInfo?.activeStepLabel) {
    case 'Basic Info':
      if (
        formInfo?.gatheringDetails?.description &&
        formInfo?.gatheringDetails?.description !== ''
      ) {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      } else {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Choose Gathering Type':
      if (
        formInfo?.gatheringType?.typeOfGathering &&
        formInfo?.gatheringType?.typeOfGathering !== ''
      ) {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      } else {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Attendees':
      if (formInfo?.attendees?.openGathering === 'no') {
        if (
          formInfo?.attendees?.selectedAttendees?.group?.length > 0 ||
          formInfo?.attendees?.selectedAttendees?.individual?.length > 0 ||
          formInfo?.attendees?.selectedAttendees?.team?.length > 0 ||
          formInfo?.attendees?.selectedAttendees?.household?.length > 0
        ) {
          checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
        } else {
          checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
        }
      } else {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Payments':
      if (
        formInfo?.paymentsInfo?.tickets?.length > 0 &&
        checkAllTicketValues(formInfo?.paymentsInfo?.tickets)
      ) {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      } else {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Registration':
      if (
        !formInfo?.registration?.confirmationMessage ||
        formInfo?.registration?.confirmationMessage === '<p><br></p>' ||
        !formInfo?.registration?.welcomeEmail ||
        formInfo?.registration?.welcomeEmail === '<p><br></p>' ||
        !formInfo?.registration?.signUpEmailSubject ||
        formInfo?.registration?.signUpEmailSubject === '' ||
        !formInfo?.registration?.paymentEmailSubject ||
        formInfo?.registration?.paymentEmailSubject === ''
      ) {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      } else {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Reminders':
      if (formInfo?.reminders?.userReminders === 'Yes') {
        if (
          (formInfo?.reminders?.cancellationEmailBody &&
            formInfo?.reminders?.notification?.cancellation &&
            formInfo?.reminders?.cancellationEmailSubject &&
            formInfo?.reminders?.cancellationEmailBody !== '<p><br></p>' &&
            formInfo?.reminders?.cancellationEmailSubject !== '') ||
          (formInfo?.reminders?.paymentEmailBody &&
            formInfo?.reminders?.notification?.payment &&
            formInfo?.reminders?.paymentEmailSubject &&
            formInfo?.reminders?.paymentEmailBody !== '<p><br></p>' &&
            formInfo?.reminders?.paymentEmailSubject !== '') ||
          (formInfo?.reminders?.promoAlertEmailBody &&
            formInfo?.reminders?.notification?.promoAlert &&
            formInfo?.reminders?.promoAlertEmailSubject &&
            formInfo?.reminders?.promoAlertEmailBody !== '<p><br></p>' &&
            formInfo?.reminders?.promoAlertEmailSubject !== '') ||
          (formInfo?.reminders?.signUpEmailBody &&
            formInfo?.reminders?.notification?.signUp &&
            formInfo?.reminders?.signUpEmailSubject &&
            formInfo?.reminders?.signUpEmailBody !== '<p><br></p>' &&
            formInfo?.reminders?.signUpEmailSubject !== '' &&
            formInfo?.reminders?.notification?.signUpReminder?.time &&
            formInfo?.reminders?.notification?.signUpReminder?.time !== '' &&
            formInfo?.reminders?.notification?.signUpReminder?.period &&
            formInfo?.reminders?.notification?.signUpReminder?.period !== '' &&
            formInfo?.reminders?.notification?.paymentReminder?.time &&
            formInfo?.reminders?.notification?.paymentReminder?.time !== '' &&
            formInfo?.reminders?.notification?.paymentReminder?.period &&
            formInfo?.reminders?.notification?.paymentReminder?.period !== '' &&
            formInfo?.reminders?.notification?.cancellationReminder?.time &&
            formInfo?.reminders?.notification?.cancellationReminder?.time !== '' &&
            formInfo?.reminders?.notification?.cancellationReminder?.period &&
            formInfo?.reminders?.notification?.cancellationReminder?.period !== '' &&
            formInfo?.reminders?.notification?.promoAlertReminder?.time &&
            formInfo?.reminders?.notification?.promoAlertReminder?.time !== '' &&
            formInfo?.reminders?.notification?.promoAlertReminder?.period &&
            formInfo?.reminders?.notification?.promoAlertReminder?.period !== '')
        ) {
          checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
        } else {
          checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
        }
      } else {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Checkins via Kiosk':
      if (
        formInfo?.checkinKioskInfo?.selectedKiosk &&
        !isEmptyObject(formInfo?.checkinKioskInfo?.selectedKiosk)
      ) {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      } else {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Checkins via Roster > Invite only':
      if (
        formInfo?.rosterCheckin?.selectedGroupsInviteOnly?.group?.length > 0 ||
        formInfo?.rosterCheckin?.selectedGroupsInviteOnly?.household?.length > 0 ||
        formInfo?.rosterCheckin?.selectedGroupsInviteOnly?.individual?.length > 0 ||
        formInfo?.rosterCheckin?.selectedGroupsInviteOnly?.team?.length > 0
      ) {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      } else {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Checkins via Roster > Open':
      if (
        formInfo?.rosterCheckin?.selectedGroups?.group?.length > 0 ||
        formInfo?.rosterCheckin?.selectedGroups?.household?.length > 0 ||
        formInfo?.rosterCheckin?.selectedGroups?.individual?.length > 0 ||
        formInfo?.rosterCheckin?.selectedGroups?.team?.length > 0
      ) {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      } else {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Checkins via Roster > Open > Kiosk':
      if (formInfo?.rosterCheckinKiosk?.url && formInfo?.rosterCheckinKiosk?.url !== '') {
        if (formInfo?.rosterCheckinKiosk?.pinIncluded) {
          if (
            formInfo?.rosterCheckinKiosk?.kioskPin &&
            formInfo?.rosterCheckinKiosk?.kioskPin !== ''
          ) {
            checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
          } else {
            checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
          }
        } else {
          checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
        }
      } else {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Checkins via Roster > Invite only > Kiosk':
      if (formInfo?.rosterCheckinKiosk?.url && formInfo?.rosterCheckinKiosk?.url !== '') {
        if (formInfo?.rosterCheckinKiosk?.pinIncluded) {
          if (
            formInfo?.rosterCheckinKiosk?.kioskPin &&
            formInfo?.rosterCheckinKiosk?.kioskPin !== ''
          ) {
            checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
          } else {
            checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
          }
        } else {
          checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
        }
      } else {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Onboarding':
      if (
        formInfo?.onboarding?.selectedKiosk &&
        !isEmptyObject(formInfo?.onboarding?.selectedKiosk)
      ) {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      } else {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Security':
      if (formInfo?.security?.peopleCheck === 'Yes') {
        if (
          formInfo?.security?.selectedLabel &&
          !isEmptyObject(formInfo?.security?.selectedLabel)
        ) {
          checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
        } else {
          checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
        }
      } else {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      }

      break
    case 'Image Upload':
      if (typeof formInfo?.imageUpload?.eventImage === 'string') {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      } else {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    case 'Publish':
      if (
        formInfo?.publish?.publishGathering === 'later' &&
        (!formInfo?.publish?.startDate ||
          formInfo?.publish?.startDate === 'Invalid Date' ||
          !formInfo?.publish?.startTime ||
          formInfo?.publish?.startTime === 'Invalid Date')
      ) {
        checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
      } else {
        checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
      }
      break
    // case 'Choose Paid or Unpaid':
    //   if (formInfo?.paidEvent?.eventIsPaid && formInfo?.paidEvent?.eventIsPaid !== '') {
    //     checkMissingInformation(false, setMissingInformation, formInfo?.activeStepLabel)
    //   } else {
    //     checkMissingInformation(true, setMissingInformation, formInfo?.activeStepLabel)
    //   }
    //   break
    default:
      break
  }
}

const Form = ({ children, title, setterPreview }) => {
  const { token } = useAuth()
  const { profileData, gatherings } = useEvents()
  const { formInfo, setFormInfo } = useFormGatherings()
  const gatheringTitle = formInfo?.gatheringDetails?.title
  const userId = profileData.uid
  const navigate = useNavigate()
  const { highestStep, activeStep, activeStepLabel } = formInfo
  const [continueDisabled, setContinueDisabled] = useState(false)
  const { labelsStore, setLabelsStore } = useLabel()
  const [missingInformation, setMissingInformation] = useState(formInfo?.missingInformation || [])

  const { onboardingStore, setOnboardingStore } = useOnboarding()
  const [popup, setPopup] = useState(false)
  const [popupMessage, setPopupMessage] = useState('')
  const gatheringId = formInfo?.id
  const navigation = useNavigate()

  const debouncedOnClick = useDebounce(() => {
    if (continueDisabled) {
      const temp = formInfo
      temp[lockedSteps[formInfo?.activeStepLabel]].checkFields = true
      setFormInfo(temp)
    } else {
      handleContinueButtonClick()
    }
  }, 500)

  //This is checking for steps that are skippable/not skippable
  function checkStep(stepName, formInfo) {
    switch (stepName) {
      case 'Basic Info':
        if (
          (formInfo?.id && formInfo?.gatheringDetails?.title !== '') ||
          (formInfo?.gatheringDetails?.title !== '' &&
            formInfo?.gatheringDetails?.title &&
            validateTitle(formInfo?.gatheringDetails?.title, gatherings))
        ) {
          setContinueDisabled(false)
        } else {
          setContinueDisabled(true)
        }
        break
      case 'Logistics':
        if (
          // formInfo?.logisticsInfo?.registration_limit &&
          // parseInt(formInfo?.logisticsInfo?.registration_limit) > 0 &&
          formInfo?.logisticsInfo?.language &&
          formInfo?.logisticsInfo?.location &&
          !formInfo?.logisticsInfo?.invalidLocation
        ) {
          if (
            formInfo?.logisticsInfo?.recurring &&
            formInfo?.logisticsInfo?.event?.recurrencAmount &&
            formInfo?.logisticsInfo?.event?.recurrencAmount !== '' &&
            formInfo?.logisticsInfo?.event?.recurres &&
            formInfo?.logisticsInfo?.event?.recurres !== '' &&
            formInfo?.logisticsInfo?.event?.startDate &&
            formInfo?.logisticsInfo?.event?.startDate !== 'Invalid Date' &&
            formInfo?.logisticsInfo?.event?.startTime &&
            formInfo?.logisticsInfo?.event?.startTime !== 'Invalid Date'
          ) {
            setContinueDisabled(false)
          } else {
            if (
              formInfo?.logisticsInfo?.event?.startDate &&
              formInfo?.logisticsInfo?.event?.startDate !== 'Invalid Date' &&
              formInfo?.logisticsInfo?.event?.endDate &&
              formInfo?.logisticsInfo?.event?.endDate !== 'Invalid Date' &&
              formInfo?.logisticsInfo?.event?.startTime &&
              formInfo?.logisticsInfo?.event?.startTime !== 'Invalid Date' &&
              formInfo?.logisticsInfo?.event?.endTime !== 'Invalid Date' &&
              formInfo?.logisticsInfo?.event?.endTime
            ) {
              setContinueDisabled(false)
            } else {
              setContinueDisabled(true)
            }
          }
        } else {
          setContinueDisabled(true)
        }
        break
      // case 'Payments':
      //   if (
      //     formInfo?.paymentsInfo?.tickets?.length > 0 &&
      //     checkAllTicketValues(formInfo?.paymentsInfo?.tickets)
      //   ) {
      //     setContinueDisabled(false)
      //   } else {
      //     setContinueDisabled(true)
      //   }
      //   break
      case 'Preview':
        setContinueDisabled(true)
        break
      case 'Choose Paid or Unpaid':
        if (formInfo?.paidEvent?.eventIsPaid.length > 0) {
          setContinueDisabled(false)
        } else {
          setContinueDisabled(true)
        }
        break
      default:
        break
    }
  }

  //This is checking for flagging the required fields
  useEffect(() => {
    checkErrorFlags(formInfo, setMissingInformation)
  }, [
    formInfo?.activeStepLabel,
    formInfo?.gatheringDetails?.description,
    formInfo?.attendees?.openGathering,
    formInfo?.attendees?.selectedAttendees?.group,
    formInfo?.attendees?.selectedAttendees?.individual,
    formInfo?.attendees?.selectedAttendees?.team,
    formInfo?.attendees?.selectedAttendees?.household,
    formInfo?.registration?.confirmationMessage,
    formInfo?.registration?.welcomeEmail,
    formInfo?.registration?.signUpEmailSubject,
    formInfo?.registration?.paymentEmailSubject,
    formInfo?.reminders?.userReminders,
    formInfo?.reminders?.cancellationEmailBody,
    formInfo?.reminders?.cancellationEmailSubject,
    formInfo?.reminders?.paymentEmailBody,
    formInfo?.reminders?.paymentEmailSubject,
    formInfo?.reminders?.promoAlertEmailBody,
    formInfo?.reminders?.promoAlertEmailSubject,
    formInfo?.reminders?.signUpEmailBody,
    formInfo?.reminders?.signUpEmailSubject,
    formInfo?.reminders?.notification?.signUpReminder,
    formInfo?.reminders?.notification?.paymentReminder,
    formInfo?.reminders?.notification?.cancellationReminder,
    formInfo?.reminders?.notification?.promoAlertReminder,
    formInfo?.checkinKioskInfo?.selectedKiosk,
    formInfo?.rosterCheckin?.selectedGroupsInviteOnly,
    formInfo?.rosterCheckin?.selectedGroups,
    formInfo?.rosterCheckinKiosk?.url,
    formInfo?.rosterCheckinKiosk?.pinIncluded,
    formInfo?.rosterCheckinKiosk?.kioskPin,
    formInfo?.onboarding?.selectedKiosk,
    formInfo?.imageUpload?.eventImage,
    formInfo?.paymentsInfo,
    formInfo?.security?.peopleCheck,
    formInfo?.security?.selectedLabel,
    formInfo?.publish,
    formInfo?.gatheringType,
  ])

  useEffect(() => {
    checkStep(formInfo?.activeStepLabel, formInfo)
  }, [{ ...formInfo }])

  useEffect(() => {
    setFormInfo({
      ...formInfo,
      missingInformation: missingInformation,
    })
  }, [missingInformation])

  const handleContinueButtonClick = async () => {
    let StepName = formInfo?.activeStepLabel

    if (token && (formInfo?.status === 'draft' || formInfo?.status === 'published')) {
      if (activeStep === 1 && !continueDisabled) {
        if (formInfo?.id) {
          const response = updateGathering(formInfo, formInfo?.id, token)
          handleStepLogic()
          if (response?.data?.id) {
            logger.log('Updated Gathering...', response?.data?.id)
          } else {
            setPopup(true)
          }
        } else {
          const response = await createGathering(
            formInfo,
            token,
            userId,
            gatheringTitle,
            activeStep,
          )
          if (response?.id) {
            setFormInfo({
              ...formInfo,
              id: response?.id,
              createdBy: response?.data?.createdBy,
              activeStep: 2,
            })
            logger.log('Created Gathering...', response?.id)
          } else {
            logger.error('Failed to create gathering')
            setPopup(true)
          }
        }
      } else if (activeStep > 1 && formInfo?.id && !continueDisabled) {
        const response = updateGathering(formInfo, formInfo?.id, token)
        handleStepLogic()

        if (response?.data?.id) {
          logger.log('Updated Gathering...', response?.data?.id)
        } else {
          setPopupMessage('Failed to update gathering')
          setPopup(true)
        }
      }

      if (StepName === 'Security') saveLabels(token, labelsStore, setLabelsStore)
      if (StepName === 'Onboarding') saveOnboarding(token, onboardingStore, setOnboardingStore)
      if (StepName === 'Registration') setterPreview()
    } else if (token && formInfo?.status === 'template') {
      const response = updateTemplate(formInfo, formInfo?.id, token)
      handleStepLogic()
      logger.log(response, 'response of template update')
      if (response.id) {
        logger.log('Updated template...', response?.id)
      } else {
        setPopupMessage('Failed to update template')
        setPopup(true)
      }
    }
  }

  const handleBackButtonClick = () => {
    activeStep > 1 ? setFormInfo({ ...formInfo, activeStep: activeStep - 1 }) : navigate(-1)
  }

  function getPublishButtonText(formInfo) {
    const startDate =
      formInfo?.publish?.startDate !== 'Invalid Date' ? formInfo?.publish?.startDate : '-'
    const startTime =
      formInfo?.publish?.startTime !== 'Invalid Date' ? formInfo?.publish?.startTime : '-'
    if (formInfo?.approval?.selectedApprovers?.individual?.uid) {
      if (formInfo?.approval?.selectedApprovers?.individual?.uid === userId) {
        if (formInfo?.publish?.publishGathering === 'later') {
          return `Approve and Publish at ${startDate} ${startTime}`
        } else {
          return 'Approve and Publish'
        }
      } else {
        return 'Send for Approval'
      }
    } else if (formInfo?.publish?.publishGathering === 'later') {
      return `Publish at ${startDate} ${startTime}`
    } else {
      return 'Publish'
    }
  }

  function handleStepLogic() {
    setFormInfo({
      ...formInfo,
      activeStep: activeStep + 1,
    })
  }

  useEffect(() => {
    if (formInfo?.highestStep <= formInfo.activeStep || !formInfo?.highestStep) {
      setFormInfo({
        ...formInfo,
        highestStep: activeStepLabel === 'Preview' ? activeStep : Math.max(highestStep, activeStep),
      })
    }
  }, [formInfo?.highestStep])

  return (
    <Container>
      <Header>
        <p style={{ paddingLeft: '2rem' }}>{title ? title : 'Basic Info'}</p>
      </Header>
      <MainBox>
        <MainContent> {children}</MainContent>
      </MainBox>
      <Footer>
        <FooterContent>
          <Button
            sx={BasicInfoBackButtonStyle}
            startIcon={<ArrowBack />}
            variant="outlined"
            onClick={() => {
              handleBackButtonClick()
            }}
          >
            Back
          </Button>
          {formInfo?.activeStepLabel === 'Preview' && formInfo?.status === 'draft' ? (
            <Button
              sx={BasicInfoNextButtonStyle}
              endIcon={<ArrowForward />}
              variant="contained"
              onClick={async () => {
                if (
                  formInfo?.missingInformation.length === 0 ||
                  formInfo?.missingInformation.every(
                    (item) => item === 'Reminders' || item === 'Security',
                  )
                ) {
                  try {
                    let success
                    if (
                      formInfo?.paymentsInfo?.tickets?.length > 0 &&
                      formInfo?.paidEvent?.eventIsPaid === 'Paid'
                    ) {
                      success = await createThirdPartyObjects(formInfo, token)
                    }
                    if (formInfo?.rosterCheckinKiosk) {
                      const successKiosk = await createKiosk(
                        token,
                        gatheringId,
                        formInfo?.rosterCheckinKiosk,
                      )
                      logger.log(successKiosk, 'kiosk response')
                    }
                    if (
                      success ||
                      !formInfo?.paymentsInfo?.tickets ||
                      formInfo?.paymentsInfo?.tickets?.length === 0 ||
                      formInfo?.paidEvent?.eventIsPaid !== 'Paid'
                    ) {
                      if (
                        formInfo?.approval?.selectedApprovers?.individual?.uid &&
                        Object.keys(formInfo?.approval?.selectedApprovers?.individual).length > 0
                      ) {
                        if (formInfo?.approval?.selectedApprovers?.individual?.uid === userId) {
                          if (formInfo?.publish?.publishGathering !== 'later') {
                            const newApproval = formInfo?.approval
                            newApproval.approved = true
                            await updateGathering(
                              {
                                ...formInfo,
                                status: 'published',
                                publishedDate: new Date(),
                                approval: newApproval,
                              },
                              gatheringId,
                              token,
                            )
                          } else {
                            const newApproval = formInfo?.approval
                            newApproval.approved = true
                            await updateGathering(
                              {
                                ...formInfo,
                                approval: newApproval,
                              },
                              gatheringId,
                              token,
                            )
                          }
                        } else {
                          await sendApprovalEmail(formInfo?.id, token)
                        }
                      } else if (formInfo?.publish?.publishGathering !== 'later') {
                        await updateGathering(
                          { ...formInfo, status: 'published', publishedDate: new Date() },
                          gatheringId,
                          token,
                        )
                      } else if (formInfo?.publish?.publishGathering === 'later') {
                        await updateGathering(
                          {
                            ...formInfo,
                            readyForPublish: true,
                          },
                          gatheringId,
                          token,
                        )
                      }
                    } else {
                      logger.error('Error while creating third-party objects.')
                      setPopup(true)
                    }
                    navigation('/')
                  } catch (error) {
                    logger.error('An error occurred:', error)
                    setPopup(true)
                    return
                  }
                } else {
                  setPopupMessage('Please fill in all the required fields before publishing.')
                  setPopup(true)
                }
              }}
            >
              {getPublishButtonText(formInfo)}
            </Button>
          ) : (formInfo.status === 'draft' || formInfo.status === 'template') &&
            formInfo?.activeStepLabel !== 'Preview' ? (
            <Button
              sx={BasicInfoNextButtonStyle}
              endIcon={<ArrowForward />}
              variant="contained"
              onClick={debouncedOnClick}
            >
              Continue
            </Button>
          ) : (
            <></>
          )}
        </FooterContent>
      </Footer>
      <Snackbar open={popup} autoHideDuration={6000} onClose={() => setPopup(false)}>
        <Alert
          severity="info"
          sx={alertBoxStyle}
          iconMapping={{
            info: <ReportIcon style={alertBoxIcon}></ReportIcon>,
          }}
        >
          {activeStep === 1 && !formInfo?.id ? 'Failed to create gathering' : popupMessage}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default Form

import { logger } from './logger'
import { unifyData } from './utility'

export const fetchLabels = async (token, setLabels) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/labels/`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await resp.json()
    const unify = unifyData(data)
    setLabels(unify)
  } catch (error) {
    logger.error(error)
  }
}

export const createLabel = async (token) => {
  try {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/labels/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (error) {
    logger.error(error)
  }
}

export const updateLabel = async (token, data) => {
  let id = data.id
  delete data.id
  delete data.updated
  try {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/labels/${id}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...data,
      }),
    })
  } catch (error) {
    logger.error(error)
  }
}

export const deleteLabel = async (token, id) => {
  try {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/labels/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (error) {
    logger.error(error)
  }
}

export const saveLabels = async (token, labelsStore, setLabelsStore) => {
  logger.log('Saving security label changes...')
  if (labelsStore) {
    for (const label of labelsStore) {
      if (label.updated) {
        if (label.id) {
          logger.log('Updating label...', label)
          await updateLabel(token, label)
          logger.log('Updated label...', label)
        }
      }
    }

    setLabelsStore((store) => {
      store.forEach((_, i) => {
        delete store[i].updated
      })
      return [...store]
    })
  }
}

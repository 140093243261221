import './GlobalSearchFilter.css'
import filterFields from './GlobalSearchFilterFields.json'
import { StyledFormControlLabel, StyledCheckbox } from './GlobalSearchFilter.style'

const GlobalSearchFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <div className="globalSearchFilterContainer">
      <div className="globalSearchFilterMainText">Search filters:</div>
      <div className="globalSearchFilterCheckboxArea">
        {filterFields.map((field) => {
          return (
            <StyledFormControlLabel
              style={{ zIndex: 2000 }}
              disableTypography
              key={field.id}
              control={
                <StyledCheckbox
                  onChange={() =>
                    setGlobalFilter((prevState) => ({
                      ...prevState,
                      [field.id]: !globalFilter[field.id],
                    }))
                  }
                  checked={globalFilter[field.id]}
                />
              }
              label={field.name}
            />
          )
        })}
      </div>
    </div>
  )
}

export default GlobalSearchFilter

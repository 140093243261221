import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import ArticleIcon from '@mui/icons-material/Article'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload'
import { convertDateString } from '../../util/date'
import color from '../../util/colors'
import {
  StyledTableRow,
  StyledTableCell,
  StyledTableCellButton,
  StyledTableCellIconButton,
  StyledTableImageCell,
} from './ExperienceTableCard.style'
import { tableRowColor } from '../../util/helpers'
import { ArchiveOutlined } from '@mui/icons-material'
import ModalPrompt from '../ModalPrompt/ModalPrompt'

const ExperienceTableCard = ({ experienceData, indexPosition }) => {
  const [highlighted, setHighlighted] = useState(false)
  const [modalStatus, setModalStatus] = useState(false)

  return (
    <StyledTableRow
      hover
      onClick={() => setHighlighted(!highlighted)}
      sx={{
        backgroundColor: tableRowColor(indexPosition),
        borderLeft: `5px solid ${tableRowColor(indexPosition)}`,
      }}
    >
      <StyledTableImageCell variant="body">
        <img
          src={experienceData.image}
          style={{ width: 79, height: 48, borderRadius: 5 }}
          alt="Gathering img"
        ></img>
      </StyledTableImageCell>
      <StyledTableCell variant="body">
        <div>{experienceData.title}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>{convertDateString(experienceData?.start)}</div>
      </StyledTableCell>
      <StyledTableCell align="left" variant="body">
        <div>{convertDateString(experienceData?.created)}</div>
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <StyledTableCellButton
          sx={{ textTransform: 'none', color: color.primary }}
          variant="text"
          startIcon={<OpenInNewIcon></OpenInNewIcon>}
        >
          Edit
        </StyledTableCellButton>
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <StyledTableCellButton
          sx={{ textTransform: 'none', color: color.primary }}
          variant="text"
          startIcon={<ArchiveOutlined></ArchiveOutlined>}
          onClick={() => {
            setModalStatus(true)
          }}
        >
          Archive
        </StyledTableCellButton>
        <ModalPrompt
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          descriptionText={`Archive Experience`}
        />
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <div>{experienceData?.gatherings?.length || 0}</div>
      </StyledTableCell>
      <StyledTableCell align="center" variant="body">
        <StyledTableCellIconButton
          sx={{ textTransform: 'none', color: color.primary }}
          startIcon={<SignalCellularAltIcon></SignalCellularAltIcon>}
        />
        <StyledTableCellIconButton
          sx={{ textTransform: 'none', color: color.primary }}
          startIcon={<ArticleIcon></ArticleIcon>}
        />
        <StyledTableCellIconButton
          sx={{ textTransform: 'none', color: color.primary }}
          startIcon={<SimCardDownloadIcon></SimCardDownloadIcon>}
        />
      </StyledTableCell>
    </StyledTableRow>
  )
}

ExperienceTableCard.propTypes = {
  experienceData: PropTypes.object,
  indexPosition: PropTypes.number,
}

export default ExperienceTableCard

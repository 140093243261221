import { useState, useEffect, useRef } from 'react'
import Message from '../Form/components/Message'
import {
  ButtonsContainer,
  ComponentsWrapper,
  FooterText,
  FooterBox,
} from './RegistrationConfirmation.styles'
import ButtonWithIcon from './components/ButtonWithIcon'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useFormGatherings } from '../../util/zustant/store'
import infoData from '../../assets/registration.json'
import TextEditorForm from '../TextEditorForm/TextEditorForm'
import { ModalPreview, ModalInsert } from './components/ModalComponent'
import { ModalPreviewConfirmation } from './components/ConfirmationModalComponent'

function RegistrationConfirmation({ previewStatus, setterPreview }) {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [registration, setRegistration] = useState(
    formInfo.registration ? formInfo.registration : {},
  )
  const [previewModal, setPreviewModal] = useState({
    welcomeEmail: false,
    confirmationMessage: false,
  })

  const [insertModal, setInsertModal] = useState({
    welcomeEmail: false,
    confirmationMessage: false,
  })

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.registration = registration
    setFormInfo(newFormInfo)
  }, [registration])

  function setEmailSubject(value, subjectVariant) {
    setRegistration((info) => {
      info[subjectVariant] = value
      return { ...info }
    })
  }

  function setEmail(value, variant) {
    setRegistration((info) => {
      info[variant] = value
      return { ...info }
    })
  }

  const editorRefs = useRef([])
  const [lastPostitions, setLastPositions] = useState([])

  useEffect(() => {
    editorRefs.current = editorRefs.current.slice(0, infoData.configuration.length)
    setLastPositions(infoData.configuration.map(() => 0))
  }, [infoData.configuration])

  function getError(type) {
    if (type.mailVariant === 'welcomeEmail') {
      if (
        !registration?.welcomeEmail ||
        registration?.welcomeEmail === '<p><br></p>' ||
        !registration?.signUpEmailSubject ||
        registration?.signUpEmailSubject === ''
      ) {
        return true
      }
      return false
    } else {
      if (
        !registration?.confirmationMessage ||
        registration?.confirmationMessage === '<p><br></p>' ||
        !registration?.paymentEmailSubject ||
        registration?.paymentEmailSubject === ''
      ) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <>
      {infoData.configuration.map((email, i) => {
        return (
          <ComponentsWrapper key={i}>
            <Message gap="16px" title="Info">
              {email.bannerMessage}
            </Message>
            <TextEditorForm
              error={formInfo?.highestStep === formInfo?.activeStep ? false : getError(email)}
              ref={(ref) => {
                editorRefs.current[i] = ref
              }}
              id={`reminders-${email.mailVariant}`}
              previewStatus={previewStatus}
              variant={email.mailVariant}
              subjectVariant={email.subjectVariant}
              mailBody={
                !registration?.[email.mailVariant]
                  ? email.placeholderTextRitch
                  : registration?.[email.mailVariant]
              }
              setMailBody={setEmail}
              mailSubject={
                registration?.[email.subjectVariant] ? registration?.[email.subjectVariant] : ''
              }
              setMailSubject={setEmailSubject}
              placeholderText={email.placeholderText}
              onSelectionChange={(range) => {
                setLastPositions((old) => old.toSpliced(i, 1, range.index))
              }}
            ></TextEditorForm>
            <ButtonsContainer>
              <ButtonWithIcon
                onClickFunction={setInsertModal}
                variantString={email.mailVariant}
                setterPreview={setterPreview}
                label="Insert Field"
                icon={<ExitToAppIcon />}
                variant="modal"
              />
              <ButtonWithIcon
                onClickFunction={setPreviewModal}
                variantString={email.mailVariant}
                setterPreview={setterPreview}
                label="Preview"
                icon={<OpenInNewIcon />}
                variant="modal"
              />
            </ButtonsContainer>
            {email.mailVariant === 'confirmationMessage' ? (
              <ModalPreviewConfirmation
                modalStatus={previewModal[email.mailVariant]}
                setModalStatus={setPreviewModal}
                modalVariant={email.mailVariant}
                mail={registration}
              />
            ) : (
              <ModalPreview
                modalStatus={previewModal[email.mailVariant]}
                setModalStatus={setPreviewModal}
                mailSubject={
                  formInfo?.registration?.[email.subjectVariant]
                    ? formInfo?.registration?.[email.subjectVariant]
                    : ''
                }
                previewString={
                  formInfo?.registration?.[email.mailVariant]
                    ? formInfo?.registration?.[email.mailVariant]
                    : email.placeholderText
                }
                modalVariant={email.mailVariant}
              ></ModalPreview>
            )}
            <ModalInsert
              modalStatus={insertModal[email.mailVariant]}
              setModalStatus={setInsertModal}
              modalVariant={email.mailVariant}
              onInsertRequest={(stringToInsert) => {
                editorRefs.current[i]?.editor?.insertText(lastPostitions[i] || 0, stringToInsert)
              }}
            ></ModalInsert>
          </ComponentsWrapper>
        )
      })}
      <FooterText>{'Church Pass Delivery'}</FooterText>
      <FooterBox>
        {
          'Church.AI automatically generates a QR code, an unique identifier for your gathering. You will use it to checkin people.'
        }
      </FooterBox>
    </>
  )
}

export default RegistrationConfirmation

import * as React from 'react'
import './NavbarOutlinedButton.css'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import Person2Icon from '@mui/icons-material/Person2'
import ForwardIcon from '@mui/icons-material/Forward'
import color from '../../util/colors'
import { DomainDisabledOutlined, FilterList } from '@mui/icons-material'
import CreateIcon from '@mui/icons-material/Create'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'

const iconSwitcher = (iconName) => {
  switch (iconName) {
    case 'person':
      return <PersonIcon />
    case 'person2':
      return <Person2Icon />
    case 'forward':
      return <ForwardIcon />
    case 'filter':
      return <FilterList />
    case 'pencil':
      return <CreateIcon />
    case 'horizontal line':
      return <HorizontalRuleIcon />
    case 'arrow down':
      return <KeyboardArrowDownOutlinedIcon />
    case 'arrow up':
      return <KeyboardArrowUpOutlinedIcon />
    case 'experience disabled':
      return <DomainDisabledOutlined />
    default:
      return <PeopleAltIcon />
  }
}

const NavbarOutlinedButton = ({ buttonText, iconName, onClickFunction }) => {
  return (
    <Button
      sx={{
        border: `1px solid ${color.primary}`,
        borderRadius: '20px',
        color: color.primary,
        fontWeight: '500',
        fontSize: '14px',
        textTransform: 'none',
      }}
      variant="outlined"
      startIcon={iconSwitcher(iconName)}
      onClick={onClickFunction}
    >
      {buttonText}
    </Button>
  )
}

NavbarOutlinedButton.propTypes = {
  buttonText: PropTypes.string,
  iconName: PropTypes.string,
  onClickFunction: PropTypes.func,
}

export default NavbarOutlinedButton

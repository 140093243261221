import proptypes from 'prop-types'
import { logger } from './logger'

const unifyData = (array) => {
  let data = array.map((item) => {
    if (item.data.title !== '') {
      return {
        id: item.id,
        ...item.data,
      }
    }
    return undefined
  })
  data = data.filter((item) => item)
  return data
}

export function filterEventsByTitle(searchData, setSearchData, value, setFilteredData, gathering) {
  let filteredData
  if (gathering) {
    filteredData = searchData.filter((d) =>
      d.gatheringDetails?.title.toLowerCase().includes(value.toLowerCase()),
    )
  } else {
    filteredData = searchData.filter((d) => d.title.toLowerCase().includes(value.toLowerCase()))
  }
  if (filteredData.length > 0) {
    setSearchData(filteredData)
    setFilteredData(filteredData)
  } else {
    setSearchData(searchData)
    setFilteredData(searchData)
  }
}

export function removeInvalidObjects(array) {
  return array.filter((item) => {
    const { title, firstname, name } = item
    return (title || firstname || name) && Object.keys(item).length > 0
  })
}

function connectGatheringsAndExperiences(gatherings, experiences) {
  const gatheringMap = new Map(gatherings?.map((gathering) => [gathering.id, gathering]))

  for (const experience of experiences) {
    if (experience?.gatherings?.length > 0) {
      const connectedGatherings = experience.gatherings
        ? experience.gatherings?.map((gatheringId) => gatheringMap.get(gatheringId)).filter(Boolean)
        : []
      experience.gatherings = connectedGatherings
    }
  }
}
export const getEventsData = async (setEventData, token) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/allEvents`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await resp.json()
    const cleanedGatherings = unifyData(data.gatherings)
    const cleanedExperiences = unifyData(data.experiences)
    connectGatheringsAndExperiences(cleanedGatherings, cleanedExperiences)
    const events = { gatherings: cleanedGatherings, experiences: cleanedExperiences }
    setEventData(events)
  } catch (error) {
    logger.error(error)
  }
}

export const getAllCollections = async (setAllCollections, token) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/admin/allCollections`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await resp.json()
    const events = {}
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        events[key] = unifyData(data[key])
      }
    }
    connectGatheringsAndExperiences(events.gathering, events.experience)
    setAllCollections(events)
  } catch (error) {
    logger.error(error)
  }
}

export const validateTitle = (title, gatherings) => {
  const found = gatherings.find((g) => g.title === title)
  return found !== undefined ? false : true
}

export const deleteDraft = async (draftId, token) => {
  if (!draftId) {
    logger.error('Draft ID not provided')
    return
  }
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/gatherings/draft/${draftId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    const { response } = await resp.json()
    return response.deleted
  } catch (error) {
    logger.error(error)
  }
}

export const archiveGathering = async (gatheringId, token) => {
  if (!gatheringId) {
    logger.error('Gathering ID not provided')
    return
  }
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/gatherings/${gatheringId}/archive`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return resp?.status === 204
  } catch (error) {
    logger.error(error)
  }
}

getEventsData.propTypes = {
  serSearchData: proptypes.func,
}

import { useEffect } from 'react'
import { auth } from '../util/firebase'
import { useNavigate, useLocation } from 'react-router-dom'

export default function useProtectedRoute() {
  const navigation = useNavigate()
  const location = useLocation()

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdTokenResult()
        if (!idToken.claims.admin) {
          navigation('/login')
        } else if (user && location.pathname.includes('login')) {
          navigation('/')
        }
      } else {
        navigation('/login')
      }
    })
  }, [])
}

import { useEffect, useState } from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { Divider } from '@mui/material'
import { Stack } from '@mui/material'
import { FormControlLabel, Checkbox } from '@mui/material'
import { checkBoxStyle } from './DateFilter.style'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { customSlotProps } from './DateFilter.style'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { forwardRef } from 'react'
import { DatePickerButton } from '../StyledButton/StyledButton.style'

const filterEnum = { 1: 'created', 2: 'start', 3: 'lastThrough' }

const CustomActions = (props, filter, handleFilter, labels) => {
  const { className } = props
  const [selected, setSelected] = useState(filter)

  const handleChange = (number) => {
    handleFilter(number)
    setSelected(number)
  }

  labels = labels ? labels : ['By date created', 'By start date', 'Last through']

  return (
    <Stack className={className} sx={{ padding: '0px 24px' }}>
      <Divider sx={{ minWidth: '100%', borderStyle: 'dashed', marginBottom: '12px' }}></Divider>
      {labels.map((el, index) => {
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selected === index + 1}
                onChange={() => {
                  handleChange(index + 1)
                }}
                sx={checkBoxStyle}
              />
            }
            label={el}
          />
        )
      })}
    </Stack>
  )
}

const ButtonField = forwardRef((props, ref) => {
  const { setOpen, label, id, disabled, inputProps: { 'aria-label': ariaLabel } = {} } = props

  return (
    <DatePickerButton
      startIcon={<EventNoteIcon />}
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label ?? 'Pick a date'}
    </DatePickerButton>
  )
})

const ButtonDatePicker = (props) => {
  const { className, filter, setFilter } = props
  const [open, setOpen] = useState(false)

  const { labels } = props
  return (
    <DateRangePicker
      calendars={1}
      className={className}
      slots={{
        field: ButtonField,
        ...props.slots,
        actionBar: (props) => CustomActions(props, filter, (number) => setFilter(number), labels),
      }}
      slotProps={{
        ...customSlotProps,
        ...props.slotProps,

        field: { setOpen, label: props.label },
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  )
}

const DateFilter = ({ labels, label, setFilterDate, setFilterDateType, filterSetter }) => {
  const [date, setDate] = useState()
  const [filter, setFilter] = useState(1)

  useEffect(() => {
    setFilterDate?.(date)
    if (date) {
      filterSetter && filterSetter({ dates: date })
    }
  }, [date])

  useEffect(() => {
    setFilterDateType?.(filterEnum[filter])
  }, [filter])

  //TODO:The currently selected day is still wrong color
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          sx={{ paddingTop: 0, display: 'flex', justifyContent: 'center' }}
          components={['DatePicker']}
        >
          <ButtonDatePicker
            filter={filter}
            setFilter={(number) => setFilter(number)}
            views={['day']}
            value={undefined} //added this so that react knows this is a controlled component
            onChange={(value) => {
              setDate(value)
            }}
            showDaysOutsideCurrentMonth
            label={label ? label : 'Pick a date'}
            labels={labels}
          ></ButtonDatePicker>
        </DemoContainer>
      </LocalizationProvider>
    </>
  )
}

export default DateFilter

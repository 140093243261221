import { useState } from 'react'
import { Tabs } from '@mui/material'
import PropTypes from 'prop-types'
import tabData from '../../assets/tabsData.json'
import { handleIndicatorColor } from './TabsComponent.util'
import { HeaderStyledTab, MainViewStyledTab } from './TabsComponent.style'
import { Popper, Paper, MenuList, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

const TabsComponent = ({ tabVariant, tabSelectorFunction, activeTabOnPage }) => {
  let tabs = tabData[tabVariant]
  const navigate = useNavigate()
  const [selected, setSelected] = useState(activeTabOnPage)
  const [anchorElement, setAnchorElement] = useState(null)
  const [popper, setPopper] = useState(false)
  const [hoveredElement, setHoveredElement] = useState('')
  const smallScreen = useMediaQuery('(max-width: 480px)')

  return (
    <Tabs
      orientation={smallScreen ? 'vertical' : 'horizonal'}
      TabIndicatorProps={{
        style: { backgroundColor: handleIndicatorColor(tabVariant) },
      }}
      value={selected}
      onChange={(event, newValue) => {
        setSelected(newValue)
        tabSelectorFunction(newValue)
      }}
    >
      {tabVariant === 'Header' &&
        tabs.map((tab, i) => (
          <HeaderStyledTab
            value={tab.name}
            key={i}
            onMouseOver={(event) => {
              setAnchorElement(event.currentTarget)
              setPopper(true)
              setHoveredElement(tab.name)
            }}
            label={tab.name}
            onClick={() => {
              navigate(tab.route)
            }}
          ></HeaderStyledTab>
        ))}
      {tabVariant !== 'Header' &&
        tabs.map((tab, i) => <MainViewStyledTab value={tab} key={i} label={tab} />)}

      <Popper
        open={popper}
        anchorEl={anchorElement}
        onMouseLeave={() => {
          setAnchorElement(null)
          setPopper(false)
          setHoveredElement(null)
        }}
      >
        <Paper>
          <MenuList>
            {tabVariant === 'Header' &&
              tabs.map((item) => {
                const subRoutes = item.subRoutes
                if (subRoutes && item.name === 'Published' && hoveredElement === 'Published') {
                  return subRoutes.map((item) => (
                    <MenuItem
                      key={item.name}
                      onClick={() => {
                        setAnchorElement(null)
                        setPopper(false)
                        navigate(item.route)
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))
                }
                return []
              })}
          </MenuList>
        </Paper>
      </Popper>
    </Tabs>
  )
}

TabsComponent.propTypes = {
  tabVariant: PropTypes.string,
  tabSelectorFunction: PropTypes.func,
}

export default TabsComponent

import styled from 'styled-components'
import color from '../../../util/colors'

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const kioskHeaderIcon = {
  color: color.primary,
}

const EditBanner = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.001em;
  background-color: var(--backgroundSecondary);
  box-sizing: border-box;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  margin: 1rem 0 1rem 0;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 10px;
    height: fit-content;
  }
`
const RowWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 1rem 0 3rem 0;
  }
`
const ImageRow = styled.img`
  width: 100%;
  height: 217px;
  object-fit: contain;
  border-radius: 5px;
`
const RowWrapperForTag = styled(RowWrapper)`
  align-items: center;
`

const RowLeftText = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  width: 220px;
  margin-right: 50px;
  color: var(--title);
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin-right: 0;
  }
`

const RowRightTextBold = styled(RowLeftText)`
  display: flex;
  font-weight: 600;
  width: 90%;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 0;
`

const RowRightText = styled(RowRightTextBold)`
  display: flex;
  font-weight: 400;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

const SelectedAttendeesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  padding: 1.5rem 0 1.5rem 0;
`

const TicketInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TicketTagDataArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 0 3rem 0;
  row-gap: 15px;
`

const TicketTagRow = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
  align-items: flex-start;
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
  }
`
const TicketTagInfoText = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  display: flex;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  width: 200px;
  margin-top: 8px;
  @media (max-width: 1024px) {
    justify-content: center;
    margin-top: 15px;
  }
`
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`

const AdditionalInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`

const expandListButtonContainer = {
  paddingTop: '1rem',
  borderTop: '1px dashed var(--dashLine)',
  width: '180px',
}

const expandListButtonStyle = {
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '140%',
  letterSpacing: '0.235em',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
}
export {
  PreviewContainer,
  kioskHeaderIcon,
  EditBanner,
  RowWrapper,
  RowWrapperForTag,
  RowLeftText,
  RowRightTextBold,
  RowRightText,
  SelectedAttendeesWrapper,
  TicketInfoWrapper,
  TicketTagDataArea,
  TicketTagRow,
  TicketTagInfoText,
  TagContainer,
  AdditionalInfoWrapper,
  ImageRow,
  expandListButtonContainer,
  expandListButtonStyle,
}

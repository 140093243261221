/* eslint-disable no-unused-vars */
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import {
  CheckinURLContainer,
  CheckinURLText,
  CheckinLinkArea,
  CheckinLinkBanner,
  URLBox,
  bannerIcon,
  formIcon,
  alertBoxStyle,
  alertBoxIcon,
} from './UrlComponent.style'
import { useState } from 'react'

const UrlComponent = ({ variant, urlString }) => {
  const [url, setUrl] = useState(urlString)
  const [popup, setPopup] = useState(false)

  switch (variant) {
    case 'checkinUrl':
      return (
        <CheckinURLContainer>
          <CheckinURLText>Checkin URL</CheckinURLText>
          <CheckinLinkArea>
            <FormControl variant="outlined">
              <URLBox
                endAdornment={
                  <InputAdornment position="end">
                    <OpenInNewIcon
                      style={formIcon}
                      onClick={() => {
                        window.open(url, '_blank', 'noreferrer')
                      }}
                    />
                    <ContentCopyIcon
                      style={formIcon}
                      onClick={() => {
                        navigator.clipboard.writeText(url)
                        setPopup(true)
                      }}
                    />
                  </InputAdornment>
                }
                value={url}
              />

              <CheckinLinkBanner>
                <PriorityHighIcon style={bannerIcon} />
                Send this auto-generate check in URL to the check in team
              </CheckinLinkBanner>
            </FormControl>
          </CheckinLinkArea>
          <Snackbar open={popup} autoHideDuration={6000} onClose={() => setPopup(false)}>
            <Alert
              severity="info"
              sx={alertBoxStyle}
              iconMapping={{
                info: <ContentCopyIcon style={alertBoxIcon}></ContentCopyIcon>,
              }}
            >
              Copied to clipboard
            </Alert>
          </Snackbar>
        </CheckinURLContainer>
      )
    case 'slugUrl':
      return (
        <CheckinURLContainer>
          <CheckinURLText>Slug for your gathering</CheckinURLText>
          <CheckinLinkArea>
            <FormControl variant="outlined">
              <URLBox
                endAdornment={
                  <InputAdornment position="end">
                    <ContentCopyIcon
                      style={formIcon}
                      onClick={() => {
                        navigator.clipboard.writeText(url)
                        setPopup(true)
                      }}
                    />
                  </InputAdornment>
                }
                value={url}
              />
            </FormControl>
          </CheckinLinkArea>
          <Snackbar open={popup} autoHideDuration={6000} onClose={() => setPopup(false)}>
            <Alert
              severity="info"
              sx={alertBoxStyle}
              iconMapping={{
                info: <ContentCopyIcon style={alertBoxIcon}></ContentCopyIcon>,
              }}
            >
              Copied to clipboard
            </Alert>
          </Snackbar>
        </CheckinURLContainer>
      )
    default:
      return <></>
  }
}

export default UrlComponent

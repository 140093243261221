import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
import color from '../../util/colors'

const StyledFormControlLabel = styled(FormControlLabel)({
  '&.MuiFormControlLabel-root': {
    color: color.filterText,
    paddingRight: '0.2rem',
    fontSize: 'calc(4px + 0.5vw)',
  },
})

const StyledCheckbox = styled(Checkbox)({
  '&.MuiCheckbox-root': {
    color: color.filterText,
  },
  '&.MuiCheckbox-root.Mui-checked': {
    color: color.primary,
  },
})

export { StyledFormControlLabel, StyledCheckbox }

import React from 'react'

function RenderHTML(htmlString) {
  const tempContainer = document.createElement('div')
  tempContainer.innerHTML = htmlString

  let keyCounter = 0

  const imageStyle = {
    maxWidth: '300px',
    maxHeight: '300px',
    resize: 'both',
    overflow: 'auto',
  }

  function processNode(node) {
    const props = {} // Initialize the props object here to hold various attributes, including style.
    let style = {} // Initialize the style object to hold styles.

    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const tagName = node.tagName.toLowerCase()
      const alignment = node.getAttribute('class')
      switch (alignment) {
        case 'ql-align-right':
          style = { ...style, 'text-align': 'right' }
          break
        case 'ql-align-center':
          style = { ...style, 'text-align': 'center' }
          break
        case 'ql-align-left':
          style = { ...style, 'text-align': 'left' }
          break

        default:
          break
      }

      keyCounter++

      if (tagName === 'br') {
        return <br key={keyCounter} />
      } else if (tagName === 'img') {
        props.src = node.getAttribute('src')
        props.style = { ...style, ...imageStyle } // Merge alignment and image styles
        return <img key={keyCounter} {...props} alt="Preview" />
      } else if (tagName === 'svg') {
        props.dangerouslySetInnerHTML = { __html: node.outerHTML }
        props.style = { ...style, ...imageStyle } // Merge alignment and image styles
        return <div key={keyCounter} {...props} />
      } else if (tagName === 'span' && node.style.color) {
        style = { ...style, color: node.style.color } // Merge existing style with color style
        return (
          <span key={keyCounter} style={style}>
            {processChildren(node)}
          </span>
        )
      } else if (tagName === 'strong' || tagName === 'b') {
        return (
          <strong key={keyCounter} style={style}>
            {processChildren(node)}
          </strong>
        )
      } else {
        // For other elements, merge existing style with color and background styles
        style = { ...style, color: node.style.color, backgroundColor: node.style.backgroundColor }
        props.style = style
        props.key = keyCounter
        return React.createElement(tagName, props, processChildren(node))
      }
    }
  }

  function processChildren(parentNode) {
    const childElements = []

    for (let i = 0; i < parentNode.childNodes.length; i++) {
      const childNode = parentNode.childNodes[i]
      childElements.push(processNode(childNode))
    }

    return childElements
  }

  const elements = processChildren(tempContainer)

  return <div style={{ width: '100%', height: '100%' }}>{elements}</div>
}

export default RenderHTML

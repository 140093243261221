import styled from 'styled-components'
import color from '../../../util/colors'

const SecurityContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CheckinSetupMainText = styled.div`
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  padding: 3rem 0 3rem 0;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`
const Divider = styled.div`
  padding: 1rem 0 1rem 0;
`
const TextAndButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.0152em;
  text-transform: uppercase;
  color: var(--title);
  padding: 4rem 0 4rem 0;
  @media (max-width: 1024px) {
    justify-content: center;
    flex-direction: column;
    row-gap: 30px;
  }
`

const SkeletonHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const kioskHeaderIcon = {
  color: color.primary,
}

export {
  SecurityContainer,
  CheckinSetupMainText,
  Divider,
  TextAndButtonRow,
  kioskHeaderIcon,
  SkeletonHolder,
}

import React from 'react'
import { StyledButton } from './ButtonWithIcon.styles'
const ButtonWithIcon = ({
  label,
  icon,
  onClickFunction,
  variantString,
  setterPreview,
  dropdown,
  setDropdown,
  variant,
}) => {
  if (variant === 'dropdown') {
    return (
      <StyledButton
        variant="text"
        startIcon={icon}
        onClick={() => {
          setDropdown(!dropdown)
        }}
      >
        {label}
      </StyledButton>
    )
  } else if (variant === 'modal')
    return (
      <StyledButton
        variant="text"
        startIcon={icon}
        onClick={() => {
          setterPreview(true)
          onClickFunction((prevState) => ({
            ...prevState,
            [variantString]: true,
          }))
        }}
      >
        {label}
      </StyledButton>
    )
}

export default ButtonWithIcon

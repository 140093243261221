import styled from 'styled-components'
import color from '../../../util/colors'
import { FormControlLabel, RadioGroup } from '@mui/material'

export const RadioGroupStyle = styled(RadioGroup)`
  width: 100%;
  gap: 8px;
`

export const CheckboxLabel = styled(FormControlLabel)`
  max-width: max-content;
  color: ${color.unChecked};

  svg[data-testid='CheckBoxIcon'] {
    color: ${color.primary};
  }
  svg[data-testid='RadioButtonUncheckedIcon'] {
    color: ${color.unChecked};
  }
  svg[data-testid='RadioButtonCheckedIcon'] {
    color: ${color.primary};
  }
`

export const Box = styled.div`
  background: ${color.backgroundSecondary};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
`
export const TicketContainer = styled.div`
  padding: 48px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`

export const PaddingContainer = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`

export const TicketStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.primary};
  div {
    box-sizing: border-box;
  }
`
export const TicketHeader = styled.div`
  width: 100%;
  background: ${color.primary};
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
`

export const HeaderLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0015em;
  color: ${color.backgroundPrimary};
  svg {
    color: ${color.backgroundPrimary};
    margin-right: 8px;
  }
  ${(props) => props.$pointer === true && `cursor: pointer;`}
`

export const DefualtField = styled.div`
  padding: 10px 8px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${color.fieldColor};
  border-radius: 4px;
  svg {
    color: ${color.groupFrameHousehold};
  }
`

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.fieldColor};
  border-radius: 4px;
`
export const FieldHeader = styled.div`
  padding: 10px 8px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
    color: ${color.primary};
  }
`

export const FieldBody = styled.div`
  width: 100%;
  padding: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`

export const FieldLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: ${color.textLabel};
  display: flex;
  align-items: center;
`

export const SettingsHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 24px;
`

export const SettingsWrapper = styled.div`
  transform: translateY(-16px);
  transition: height 0.5s ease-in-out;
  padding: 0 32px;
  overflow: hidden;
  height: auto;
  ${(props) => props.open === false && `height: 0px;`}
  ${(props) => props.open === true && `height: ${props.height}px;`}
  ${(props) => props.animation === false && props.open === true && `height: auto;`}
  ${(props) => props.animation === false && props.open === false && `height: 0px;`}


  ${(props) => props.$overflowOff !== false && `overflow: visible;`}
`

export const CopyHolder = styled.div`
  min-width: 37px;
  min-height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.backgroundPrimary};
  border-radius: 4px;
  margin-right: 8px;
`

export const Add = styled.div`
  display: relative;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${color.primary};
  svg {
    width: 20px;
    height: 20px;
    color: ${color.primary};
  }
  cursor: pointer;
`

export const RetrievedFieldsHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

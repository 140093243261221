import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import color from '../../util/colors'

const StyledTableRow = styled(TableRow)({
  width: '100%',
})

const StyledTableCell = styled(TableCell)({
  borderBottom: 0,
  color: color.description,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '140%',
  letterSpacing: ' 0.0015em',
  paddingLeft: '3rem',
  width: 'max-content',
})

const StyledTableImageCell = styled(TableCell)({
  borderBottom: 0,
  display: 'flex',
  paddingLeft: '3rem',
})

const StyledActionsHolder = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: 'max-content',
})

const StyledTableCellIconButton = styled(Button)({
  padding: 0,
  minWidth: 0,
  marginRight: '45px',
  textTransform: 'none',
  color: color.primary,
  '&.MuiButton-root': {
    fontWeight: 400,
    fontSize: '12px',
    color: color.title,
  },
  svg: {
    width: 'max-content',
  },
  path: {
    fill: color.primary,
    transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
  },

  '&.MuiButton-root:hover': {
    color: color.tableHover,
    backgroundColor: 'transparent',
    path: {
      fill: color.tableHover,
    },
  },
})

const StyledTableCellIconButtonHearth = styled(StyledTableCellIconButton)(({ hearted }) => ({
  padding: '0',
  width: 'max-content',
  minWidth: '30px',
  span: {
    marginRight: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    svg: {
      marginLeft: '3px',
    },
  },
  'path[opacity=".3"]': { display: 'none' },

  ...(hearted
    ? {
        '& .MuiSvgIcon-root': {
          color: color.hearted,
          stroke: color.hearted,
          'path[opacity=".3"]': { display: 'inline', opacity: 1 },
          path: {
            fill: color.hearted,
          },
        },
        '&.MuiSvgIcon-root:hover': {
          color: color.tableHover,
          stroke: color.tableHover,
          'path[opacity=".3"]': { display: 'inline', opacity: 1, color: color.tableHover },
        },
        '&.MuiButton-root:hover': {
          color: color.tableHover,
          stroke: color.tableHover,
          path: {
            fill: color.tableHover,
            stroke: color.tableHover,
          },
        },
      }
    : {}),
}))

const highlightBorderStyle = {
  width: 5,
  height: 50,
  background: color.tableHover,
}

export {
  StyledTableRow,
  StyledTableCell,
  highlightBorderStyle,
  StyledActionsHolder,
  StyledTableCellIconButton,
  StyledTableCellIconButtonHearth,
  StyledTableImageCell,
}

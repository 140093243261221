const color = {
  primary: '#4519cc',
  title: '#22335A',
  divider: '#D9D7D7',
  description: '#38476b',
  singleTab: '#586482',
  tabSelected: '#8D95AA',
  filterText: '#4E5C7B',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#f8f7fa',
  notificationBadge: '#DC5050',
  loginPrimary: '#000000DE',
  tableRowPrimary: '#F6F3F9',
  tableRowSecondary: '#F8F7FA',
  tableHover: '#EB9E9E',
  dateNumber: '#0F2552',
  filterColor: '#4E5C7B',
  sidebarBackground: '#F9F9F9',
  checklist: '#F6F4FD',
  checklistCompleteBadgeBackground: '#e6fbf1',
  checklistCompleteBadgeColor: '#20c374',
  basicInfoYesButton: '#F3D16C',
  basicInfoNoButton: '#3BB3E7',
  basicInfoGroup: '#E06464',
  filterBackground: '#FDFDFD',
  hearted: '#ECCF7E',
  boxShadow: 'rgba(0,0,0,0.05)',
  tableCardSidebar: '#E47777',
  tableCardHover: '#FBFBFD',
  draftButton: '#EFC23B',
  draftSaved: '#20C374',
  disabled: '#DBDBDB',
  yesColor: '#F3D16C',
  noColor: '#3BB3E7',
  groupFrameHousehold: '#A28CE5',
  groupFrameIndividual: '#EDECF1',
  uploadButton: '#f3f3f3',
  unChecked: 'rgba(34, 51, 90, 0.5)',
  fieldColor: 'rgba(34, 51, 90, 0.25)',
  uploadButtonIcon: 'rgba(69, 25, 204, 0.25)',
  summaryBox: 'rgba(248, 247, 250, 0.5)',
  borderRest: '#22335a40',
  checkmark: '#5EB762',
  message: '#51BCE9',
  textLabel: '#82848b',
  buttonWithIconPrimary: '#1f1f1f',
  purpleCheckbox: '#2B00AF',
  timestamp: '#a5acb8',
  orangeBorder: '#CC7A19',
  useTemplate: 'rgba(94, 183, 98, 1)',
  accent: '#C5ACE6',
  uiWarning: '#EBB30A',
  charcoal: '#C8CCD6',
  lightPurple: '#C9BFEB',
  uiError: '#d83d3d',
  class: '#cc6019',
  ministry: '#cc8819',
  group: '#cc19b2',
  experience: '#4519cc',
  lighterExperience: '#5830D1',
}

export default color

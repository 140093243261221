import React, { useMemo } from 'react'
import { DateCalendar } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TextField } from '@mui/material'
import color from '../../../util/colors'
import dayjs from 'dayjs'

const MyDateCalendar = ({ select, SetOccurrences, selectDays, SetFormProperty }) => {
  const date = useMemo(() => dayjs(), [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        sx={{
          backgroundColor: color.backgroundPrimary,
          alignSelf: 'flex-end',
          border: `1px solid ${color.primary}`,
          borderRadius: '8px',
        }}
        onChange={(value) => {
          select(value)
          SetFormProperty('recurrencAmount', selectDays?.length + 1, 'event')
          SetOccurrences(value, 'startDate', selectDays?.length)
        }}
        value={date}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  )
}

export default MyDateCalendar

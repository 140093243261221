/* eslint-disable no-unused-vars */
import './TemplateCardGrids.css'
import TemplateCard from '../TemplateCard/TemplateCard'
import Pagination from '@mui/material/Pagination'
import { useState, useEffect } from 'react'
import {
  preparePageNumberForPaginationComponent,
  calculatePageCountForPaginationComponent,
  calculateGridItems,
} from '../../util/pagination'
import { useWindowWidth } from '@react-hook/window-size'
import { useTemplates } from '../../util/zustant/templateStore'
import { getAllTemplates, getHeartedTemplates } from '../../util/templates'
import { useAuth } from '../../hooks/useAuth'
import { useEvents } from '../../hooks/useEvents'
import { FilterTemplates } from '../../util/utility'

const TemplateCardGrids = ({ gatheringData, filter }) => {
  const windowWidth = useWindowWidth()
  const [gridItemsPerPage, setRowsPerPage] = useState(calculateGridItems(windowWidth))
  const [page, setPage] = useState(0)
  const { templates, setTemplates, setTemplateHearted, templateHearted } = useTemplates()
  const { token } = useAuth()
  const { profileData, allCollections } = useEvents()
  const userId = profileData.uid
  const [recentlyUsed, setRecentlyUsed] = useState([])

  useEffect(() => {
    getAllTemplates(setTemplates, token)
    getHeartedTemplates(userId, token, setTemplateHearted)
  }, [])

  const pageCount = calculatePageCountForPaginationComponent(templates.length, gridItemsPerPage)

  const favoriteTemplates = templates.filter((template) => {
    templateHearted.map((templateHearted) => {
      if (template.id === templateHearted.templateId) {
        template.isFavourite = templateHearted.isFavourite
      }
      return false
    })
    return template.isFavourite === true
  })

  let filtered = FilterTemplates(templates, filter, favoriteTemplates)

  const visibleRows =
    filtered?.length === 0 || !filtered
      ? templates?.slice(page * gridItemsPerPage, page * gridItemsPerPage + gridItemsPerPage)
      : filtered?.slice(page * gridItemsPerPage, page * gridItemsPerPage + gridItemsPerPage)

  useEffect(() => {
    setRowsPerPage(calculateGridItems(windowWidth))
  }, [windowWidth])

  const handleChangePageInsidePaginationComponent = (event, newPage) => {
    setPage(newPage - 1)
  }

  function sortTemplatesLastUsedByUser(currentUser, templates) {
    let recentlyUsedTemplatesForUser = currentUser?.recentlyUsedTemplates
    if (!currentUser || !recentlyUsedTemplatesForUser) return []
    recentlyUsedTemplatesForUser = recentlyUsedTemplatesForUser.sort((a, b) => {
      return b?.lastUsed?.localeCompare(a?.lastUsed)
    })
    let filteredData = recentlyUsedTemplatesForUser.map((template) =>
      templates.find((t) => t.id === template.templateId),
    )
    filteredData = filteredData.filter((f) => f)
    setRecentlyUsed(filteredData)
  }

  useEffect(() => {
    if (allCollections?.user && allCollections?.template_new) {
      sortTemplatesLastUsedByUser(
        allCollections?.user.find((u) => u.uid === userId),
        allCollections?.template_new,
      )
    }
  }, [allCollections])

  return (
    <div className="templateCardGridsContainer">
      {/* Title Text and Button Area */}
      {filtered?.length === 0 && (
        <div className="templateCardGridsTextAndButtonArea">
          <div className="templateCardGridsText">Favorites</div>
        </div>
      )}
      {/* Favorites Area */}
      {filtered?.length === 0 && (
        <div className="templateCardGridsArea">
          {favoriteTemplates.map((gathering) => {
            return (
              <div className="templateCardGridsCard">
                <TemplateCard
                  gatheringData={gathering}
                  userId={userId}
                  token={token}
                ></TemplateCard>
              </div>
            )
          })}
        </div>
      )}
      {/* Recently Used Area */}
      {recentlyUsed?.length > 0 && (
        <div className="templateCardGridsTextAndButtonArea">
          <div className="templateCardGridsText">Recently Used</div>
        </div>
      )}
      {recentlyUsed?.length > 0 && (
        <div className="templateCardGridsArea">
          {recentlyUsed.slice(0, 5).map((gathering) => {
            return (
              <div className="templateCardGridsCard">
                <TemplateCard
                  gatheringData={gathering}
                  userId={userId}
                  token={token}
                ></TemplateCard>
              </div>
            )
          })}
        </div>
      )}
      {/* All templates area */}

      <div className="templateCardGridsTextAndButtonArea">
        <div className="templateCardGridsText">All Templates</div>
      </div>

      <div className="templateCardGridsArea">
        {visibleRows?.map((gathering) => {
          return (
            <div className="templateCardGridsCard">
              <TemplateCard gatheringData={gathering} userId={userId} token={token}></TemplateCard>
            </div>
          )
        })}
      </div>

      {/* Pagination Component Area */}
      {templates.length > 9 ? (
        <div className="paginationComponentArea">
          <div>
            <Pagination
              defaultPage={0}
              count={pageCount}
              page={preparePageNumberForPaginationComponent(page)}
              onChange={handleChangePageInsidePaginationComponent}
            />
          </div>
          {pageCount >= 10 ? (
            <div className="paginationAlertBox">
              Too many templates? Try <span className="paginationAlertSpanElement">List View</span>
              instead ?
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default TemplateCardGrids

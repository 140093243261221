import { auth } from './firebase'

const BACKEND = process.env.REACT_APP_BACKEND_URL
const RETURN_URL = process.env.REACT_APP_CALLBACK_URL

export const requestCode = async (email, tmpNonce) => {
  const res = await fetch(BACKEND + '/api/admin/verification', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type: 'email',
      target: email,
      intent: 'login',
      nonce: tmpNonce,
    }),
  })
  return res.ok
}

export const verifyCode = async (code, email, nonce) => {
  const res = await fetch(BACKEND + '/api/admin/verification', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type: 'email',
      target: email,
      code: code,
      nonce: nonce,
    }),
  })

  return res.json()
}

export const signInWithEmailLink = async (email, verificationId) => {
  const response = await fetch(BACKEND + '/api/admin/emaillink', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      verification_id: verificationId,
      settings: {
        url: RETURN_URL,
        handleCodeInApp: true,
      },
    }),
  })

  const responseData = await response.json()

  if (responseData.status === 'valid' && auth.isSignInWithEmailLink(responseData.link)) {
    const res = await auth.signInWithEmailLink(email, responseData.link)
    return res
  }
}

import styled from 'styled-components'
import { keyframes } from 'styled-components'
import color from '../../../util/colors'

export const CopyStyle = styled.div`
  color: ${color.primary};
  height: 24px;
  cursor: pointer;
`

export const ChecmarkStyle = styled.div`
  color: ${color.checkmark} !important;
  svg {
    color: ${color.checkmark} !important;
  }
  height: 24px;
`

const fadeInOut = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
`
export const Message = styled.div`
  opacity: 0;
  min-width: max-content;
  color: ${color.backgroundPrimary};
  background: ${color.message};
  box-shadow: 0px 7px 15px -8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 14px;
  animation: ${fadeInOut} 1.5s linear;
  box-sizing: border-box;
`

export const MessageHolder = styled.div`
  position: relative;
  top: -64px;
  left: 50%;
  ${(props) => props.align === 'left' && `left: 0;`}
  ${(props) => props.align === 'left' && `top: -32px;`}

  max-width: 0;
  max-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

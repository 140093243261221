import { Tab, styled } from '@mui/material'
import color from '../../util/colors'

const HeaderStyledTab = styled(Tab)({
  '&.Mui-selected': {
    color: color.title,
  },
  color: color.tabSelected,
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '14px',
  textTransform: 'none',
  minWidth: 0,
  padding: 0,
  marginRight: 30,
})

const MainViewStyledTab = styled(Tab)({
  '&.Mui-selected': {
    color: color.singleTab,
  },
  color: color.tabSelected,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '18px',
  textTransform: 'none',
  minWidth: 0,
  padding: 0,
  marginRight: 30,
})

const formControlStyle = {
  color: color.primary,
  border: '',
}

export { HeaderStyledTab, MainViewStyledTab, formControlStyle }

export function getExperienceFromGathering(gatheringId, experiences) {
  if (gatheringId !== '"') {
    let data = experiences.find((x) => x?.gatherings?.find((g) => g.id === gatheringId))
    if (data) {
      return data.title
    } else {
      return ''
    }
  }
}

export function experienceHasGathering(gatheringId, experiences) {
  if (gatheringId) {
    const result = experiences.find((experience) => {
      return experience.gatherings?.some((gathering) => gathering.id === gatheringId)
    })

    if (result) {
      return true
    }
  }

  return false
}

import { logger } from './logger'

export const validateAddress = async (address) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address,
  )}&key=${apiKey}`

  try {
    const response = await fetch(apiUrl, { method: 'GET' })
    const data = await response.json()

    if (data.results.length > 0) {
      // Address is valid
      return data.results[0]
    } else {
      // Address is invalid
      return false
    }
  } catch (error) {
    logger.error('Error validating address:', error)
    // Handle error
    return false
  }
}

import {
  PublishContainer,
  DateSelectorArea,
  DateAndTimeSubText,
  alertBoxStyle,
  alertBoxIcon,
} from './Publish.style'
import DateInput from '../../Form/components/DateInput'
import TimeInput from '../../Form/components/TimeInput'
import Message from '../../Form/components/Message'
import SelectBasicInfo from '../../SelectBasicInfo/SelectBasicInfo'
import publishData from '../../../assets/publish.json'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { useState, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'

const Publish = () => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [publish, setPublish] = useState(formInfo?.publish ? formInfo.publish : {})
  const [alert, setAlert] = useState({
    alertStatus: false,
    alertMessage: '',
    alertBackground: '',
  })

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.publish = publish
    setFormInfo(newFormInfo)
  }, [publish])

  //  Set time for publish gathering
  function setGatheringPublish(value) {
    setPublish((info) => {
      info.publishGathering = value
      return { ...info }
    })
  }

  // Set visibility for published gathering
  function setGatheringVisibility(value) {
    setPublish((info) => {
      info.gatheringVisibility = value
      return { ...info }
    })
  }

  // Set start date.
  function setStartDate(value) {
    setPublish((info) => {
      info.startDate = value
      return { ...info }
    })
  }

  // Set start time.
  function setStartTime(value) {
    setPublish((info) => {
      info.startTime = value
      return { ...info }
    })
  }

  return (
    <PublishContainer>
      <Message translateY={'-8px'}>
        {
          'Publish means that people can register for your gathering. Your gathering will not be live or active until you publish it.'
        }
      </Message>
      <SelectBasicInfo
        title={publishData.first[0].question}
        choices={publishData.first[0].choices}
        variant={'publishTime'}
        setGatheringPublish={setGatheringPublish}
        formInfo={formInfo}
      ></SelectBasicInfo>
      {formInfo?.publish?.publishGathering === 'later' && (
        <>
          <DateSelectorArea>
            <DateAndTimeSubText>{'Schedule your publish:'}</DateAndTimeSubText>
            <DateInput
              label={'Starts on:'}
              onChange={setStartDate}
              value={formInfo?.publish?.startDate ? formInfo?.publish?.startDate : ''}
            ></DateInput>
            <TimeInput
              label={'Start Time'}
              onChange={setStartTime}
              value={formInfo?.publish?.startTime ? formInfo?.publish?.startTime : ''}
            ></TimeInput>
          </DateSelectorArea>
        </>
      )}

      <SelectBasicInfo
        title={publishData.second[0].question}
        choices={publishData.second[0].choices}
        variant={'publishVisibility'}
        setGatheringVisibility={setGatheringVisibility}
        formInfo={formInfo}
        setAlert={setAlert}
      ></SelectBasicInfo>
      <Snackbar
        open={alert.alertStatus}
        autoHideDuration={4000}
        onClose={() =>
          setAlert((prevState) => ({
            ...prevState,
            alertStatus: false,
          }))
        }
      >
        <Alert
          severity="info"
          sx={alertBoxStyle}
          style={{ background: alert.alertBackground }}
          iconMapping={{
            info: <ErrorRoundedIcon style={alertBoxIcon}></ErrorRoundedIcon>,
          }}
        >
          {alert.alertMessage}
        </Alert>
      </Snackbar>
    </PublishContainer>
  )
}

export default Publish

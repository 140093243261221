import styled from 'styled-components'
import Button from '@mui/material/Button'
import color from '../../util/colors'

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const HeaderTextArea = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--title);
  display: flex;
  justify-content: flex-start;
  padding-top: 3rem;
  width: 98%;
`

const FileUploadButton = styled(Button)({
  '&.MuiButtonBase-root': {
    border: '1px dashed var(--uploadDashLine)',
    borderRadius: '6px',
    backgroundColor: color.uploadButton,
    width: '100%',
    height: '100%',
    maxWidth: '683px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    letterSpacing: '0.0025em',
    color: color.title,
    textTransform: 'none',
  },
  '&.MuiButtonBase-root: hover': {
    backgroundColor: color.uploadButton,
  },
})

const FileUploadArea = styled.div`
  padding-top: 3rem;
  width: 98%;
  height: 213px;
  display: flex;
  justify-content: center;
`

const FileButtonWrapper = styled.label`
  width: 100%;
  height: 100%;
  max-width: 683px;
`

const fileUploadIcon = {
  paddingRight: ' 0.5rem',
  width: '60px',
  height: '39px',
  color: color.uploadButtonIcon,
}

const TagGridArea = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 95%;
`

export {
  FileUploadContainer,
  HeaderTextArea,
  FileUploadArea,
  FileButtonWrapper,
  FileUploadButton,
  fileUploadIcon,
  TagGridArea,
}

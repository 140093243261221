import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import {
  ThemeProvider,
  createTheme,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  Modal,
} from '@mui/material'
import './ProfileComponent.css'
import HelpIcon from '@mui/icons-material/Help'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { useState } from 'react'
import Logout from '@mui/icons-material/Logout'
import { TextField } from '@mui/material'
import { Button } from '@mui/material'
import { SwitchAccount } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { auth } from '../../util/firebase'
import { ProfileRow, ProfileSection } from './ProfileComponent.styled'

const theme = createTheme({
  typography: {
    profile: {
      fontSize: 14,
      fontWeight: 400,
    },
    support: {
      fontSize: 20,
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: '#1F1F1F',
    },
    secondary: {
      main: '#22335A40',
    },
    icon: {
      main: '#4519CC',
    },
  },
})

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 500,
  bgcolor: '#FFFFFF',
  border: '1px solid #000',
  padding: '20px',
  borderRadius: '10px',
}

const PaperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 21,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}

const selectOptions = ['topic1', 'topic2', 'topic3']

const ProfileComponent = ({ profile, notifications }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const open = Boolean(anchorEl)
  const [topicValue, setTopicValue] = useState('')
  const [descriptionValue, setDescriptionValue] = useState('')

  const handleClose = () => setAnchorEl(null)

  //TODO: implement submit funciton
  const handleSubmit = () => alert('submit')

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ProfileRow>
          <ProfileSection order={2}>
            <IconButton sx={{ marginRight: '5px' }}>
              <HelpIcon color="icon" fontSize="large"></HelpIcon>
            </IconButton>
            <IconButton sx={{ marginRight: '5px' }} onClick={() => setOpenModal(true)}>
              <SupportAgentIcon color="icon" fontSize="large"></SupportAgentIcon>
            </IconButton>
          </ProfileSection>
          <ProfileSection>
            <Stack direction={'column'} alignItems={'flex-end'} spacing={0.5}>
              <Typography
                color={'primary'}
                variant="profile"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {profile.name} {profile.surname}
              </Typography>
              <Typography color={'secondary'} variant="profile">
                {process.env.REACT_APP_APP_ENV}
              </Typography>
            </Stack>
            <IconButton
              onClick={(ev) => setAnchorEl(ev.currentTarget)}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar src={profile.img} sx={{ height: 60, width: 60 }} />
              {notifications && notifications.length > 0 ? (
                <div className="profileComponentNotificationBadge">
                  {notifications.length > 99 ? 99 : notifications.length}
                  {notifications.length > 99 && (
                    <div className="profileComponentNotificationBadgeOverflow">+</div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </IconButton>
          </ProfileSection>
        </ProfileRow>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={PaperProps}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            sx={{ paddingLeft: '30px', paddingRight: '30px' }}
            onClick={async () => {
              handleClose()
              await auth.signOut()
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Sign out
          </MenuItem>
          <MenuItem
            sx={{ paddingLeft: '30px', paddingRight: '30px' }}
            onClick={() => {
              handleClose()
              //TODO: implement swap to member portal functionality
              alert('Swap to member portal')
            }}
          >
            <ListItemIcon>
              <SwitchAccount fontSize="small" />
            </ListItemIcon>
            Swap to member portal
          </MenuItem>
        </Menu>

        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Stack direction={'column'} sx={modalStyle} spacing={3}>
            <Typography color={'primary'} variant={'support'}>
              Contact Support
            </Typography>
            <TextField
              value={topicValue}
              onChange={(e) => setTopicValue(e.target.value)}
              select
              label="Topic"
            >
              {selectOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              multiline
              rows={4}
              label="Description"
              value={descriptionValue}
              onChange={(e) => setDescriptionValue(e.target.value)}
            ></TextField>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Button onClick={() => setOpenModal(false)} variant="text">
                CANCEL
              </Button>
              <Button onClick={handleSubmit} variant="contained">
                SUBMIT
              </Button>
            </Stack>
          </Stack>
        </Modal>
      </ThemeProvider>
    </div>
  )
}

ProfileComponent.propTypes = {
  profile: PropTypes.object,
  notifications: PropTypes.array,
}

export default ProfileComponent

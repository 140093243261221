import {
  PinContainer,
  PinTextBox,
  PinEntryArea,
  PinBox,
  PinBoxEditButton,
  editIcon,
} from './Pin.style'
import EditIcon from '@mui/icons-material/Edit'
import UndoIcon from '@mui/icons-material/Undo'
import { useEffect, useState } from 'react'
import { validatePinInput, deconstructPin, pinEnum } from '../../util/pin'

const Pin = ({ pinText, pinNumber, kiosk, setKioskPin, error }) => {
  const [pin, setPin] = useState(pinNumber || '')
  const [previousPin, setPreviousPin] = useState(pinNumber)
  const [pinValues, setPinValues] = useState({
    first: '',
    second: '',
    third: '',
    fourth: '',
  })
  const [editPin, setEditPin] = useState(false)

  useEffect(() => {
    deconstructPin(pin, setPinValues)
  }, [])

  useEffect(() => {
    const values = Object.values(pinValues)
    setPin(values.join(''))
    setKioskPin(values.join(''))
  }, [pinValues])

  useEffect(() => {
    if (pin.length === 4) {
      setPreviousPin(pin)
      setEditPin(false)
    }
  }, [pin])

  return (
    <PinContainer>
      <PinTextBox>{pinText}</PinTextBox>
      <PinEntryArea>
        {[...new Array(4)].map((x, i) => {
          return (
            <PinBox
              style={{ border: error && 'solid 1px red', borderRadius: '5px' }}
              key={i}
              inputMode="numeric"
              onChange={(e) => {
                setPinValues((prevState) => ({
                  ...prevState,
                  [pinEnum[i + 1]]: validatePinInput(e.target.value),
                }))
              }}
              error={isNaN(parseInt(pinValues[pinEnum[i + 1]]))}
              value={pinValues[pinEnum[i + 1]]}
              disabled={!editPin}
            ></PinBox>
          )
        })}
        {kiosk && (
          <>
            <PinBoxEditButton
              onClick={() => {
                setPinValues({ first: '', second: '', third: '', fourth: '' })
                setEditPin(true)
              }}
            >
              <EditIcon style={editIcon}></EditIcon>
              Change
            </PinBoxEditButton>
            {editPin && (
              <PinBoxEditButton
                onClick={() => {
                  deconstructPin(previousPin, setPinValues)
                  setEditPin(false)
                }}
              >
                <UndoIcon style={editIcon}></UndoIcon>
                Undo
              </PinBoxEditButton>
            )}
          </>
        )}
      </PinEntryArea>
    </PinContainer>
  )
}

export default Pin

import { firstRowIconStyle } from '../components/ApprovalItem.style'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

function handleRowText(variant) {
  switch (variant) {
    case 'created':
      return 'Gathering created by'
    case 'approval':
      return 'Approval was sent to'
    default:
      return ''
  }
}

function handleIcon(variant) {
  switch (variant) {
    case 'created':
      return <NoteAddIcon style={firstRowIconStyle} />
    case 'approval':
      return <AccountCircleIcon style={firstRowIconStyle} />
    default:
      return ''
  }
}

export { handleRowText, handleIcon }

import { useEffect, useState } from 'react'
import './SubHeader.css'
import TabsComponent from '../../components/TabsComponent/TabsComponent'
import ViewButtonComponent from '../../components/ViewButtonsComponent/ViewButtonsComponent'
import NavbarButton from '../../components/NavbarButton/NavbarButton'
import { useNavigate } from 'react-router-dom'
import {
  useFormGatherings,
  useStepGatherings,
  useIdGathering,
  initialGatheringId,
  initialState,
} from '../../util/zustant/store'

const SubHeader = ({
  setSelectedButtonParent,
  setSelectedTabParent,
  activeTabOnParent,
  SecondIcon,
  variant,
}) => {
  const navigate = useNavigate()
  const { resetForm } = useFormGatherings()
  const { resetStep } = useStepGatherings()
  const { resetGatheringId } = useIdGathering()
  const formInfo = useFormGatherings((state) => state.formInfo)
  const activeStep = useStepGatherings((state) => state.activeStep)
  const gatheringId = useStepGatherings((state) => state.gatheringId)

  const [markNewGatheringCreation, setMarkNewGatheringCreation] = useState()

  function createNewGathering() {
    resetForm()
    resetStep()
    resetGatheringId()
    setMarkNewGatheringCreation(true)
  }

  useEffect(() => {
    if (!markNewGatheringCreation) {
      return
    }

    const isFormEmpty = Object.keys(formInfo).length === 0
    if (
      isFormEmpty &&
      gatheringId === initialGatheringId.gatheringId &&
      activeStep === initialState.activeStep
    ) {
      navigate('/gatherings/templates')
    }
  }, [markNewGatheringCreation, gatheringId, activeStep, formInfo])

  return (
    <section className="subHeaderRow">
      {/* Left Tab Component */}
      <div className="subHeaderTabsComponentArea">
        <TabsComponent
          tabVariant={'Header'}
          tabSelectorFunction={setSelectedTabParent}
          activeTabOnPage={activeTabOnParent}
        />
      </div>
      {/* View and Navbar Button Components */}
      <div className="subHeaderButtonsArea">
        <ViewButtonComponent changeComponent={setSelectedButtonParent} SecondIcon={SecondIcon} />
        <NavbarButton
          onClickFunction={() => {
            createNewGathering()
          }}
          iconName={'add'}
          buttonText={'Experience'}
          disabled={true}
        ></NavbarButton>
        <NavbarButton
          onClickFunction={() => {
            createNewGathering()
          }}
          iconName={'add'}
          buttonText={'Gathering'}
          disabled={false}
        ></NavbarButton>
      </div>
    </section>
  )
}

export default SubHeader

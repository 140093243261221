import { ToggleButtonGroup, styled } from '@mui/material'
import color from '../../util/colors'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    color: color.primary,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    border: '1px solid transparent',
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: '20px',
      border: '1px solid transparent',
    },
    '&:first-of-type': {
      borderRadius: '20px',
    },
    '&.Mui-selected': {
      color: color.primary,
      border: `1px solid ${color.primary}`,
      backgroundColor: color.backgroundSecondary,
    },
  },
}))

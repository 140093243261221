import React from 'react'
import { InputStyle } from './InputStyle.jsx'

const Input = ({
  type,
  value,
  onChange,
  label,
  id,
  limit,
  onBlur,
  error,
  helperText,
  children,
  autoFocus,
  style,
  maxValue,
  disabled,
}) => {
  return (
    <InputStyle
      disabled={disabled}
      maxValue={maxValue}
      style={style}
      autoFocus={autoFocus || false}
      type={type ? type : 'text'}
      label={label}
      helperText={helperText || ''}
      error={error || false}
      value={value}
      onBlur={onBlur || (() => {})}
      onChange={(v) => {
        maxValue
          ? onChange(maxValue >= v.target.value ? v.target.value : maxValue)
          : onChange(v.target.value)
      }}
      id={id}
      InputProps={
        type === 'number' ? { inputProps: { min: 0, max: !isNaN(limit) ? limit : '' } } : {}
      }
    >
      {children}
    </InputStyle>
  )
}

export default Input

import styled from 'styled-components'

export const SkeletonHolder = styled.div`
  display: flex;
  padding-left: 48px;
  flex-wrap: wrap;
  width: 100%;
  gap: 96px;
  height: calc(100% + 0.5vw);
`

export const RowSkeleton = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
`

export const skeletonCardStyle = {
  display: 'flex',
  flexShrink: 0,
  borderRadius: '10px',
  width: '248px',
  height: '200px',
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: var(--backgroundSecondary);
  padding: 1.5rem 2rem 3rem 3rem;
  box-sizing: border-box;
  row-gap: 1.5rem;
`
export const SectionTitleText = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: var(--primary);
`

export const SectionTabAndButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 50px;
  }
`
export const CardsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 45px;
  padding: 1.5rem 0 3rem 0;
`
export const CardWrapper = styled.div`
  display: flex;
  width: 248px;
  height: 198px;
  flex-shrink: 0;
`
export const AccordeonArea = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  @media (max-width: 1024px) {
    row-gap: 250px;
  }
`
export const FooterText = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 26px;
`

import { useEffect, useMemo, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import ExperienceTableCard from '../ExperienceTableCard/ExperienceTableCard'
import GatheringTableCard from '../GatheringTableCard/GatheringTableCard'
import DraftTableCard from '../DraftTableCard/DraftTableCard'
import ArchivedDraftTableCard from '../ArchivedDraftTableCard/ArchivedDraftTableCard'
import PropTypes from 'prop-types'
import TableComponentHead from './TableComponentHead'
import TemplatesTableCard from '../TemplatesTableCard/TemplatesTableCard'
import Occurrence from '../GatheringCreation/Logistics/Occurrence'
import Pagination from '@mui/material/Pagination'
import { StyledTablePagination, paginationDiv, BoxStyle, TableStyle } from './TableComponent.style'
import {
  preparePageNumberForPaginationComponent,
  calculatePageCountForPaginationComponent,
  stableSort,
  getComparator,
} from '../../util/pagination'
import { getExperienceFromGathering } from '../../util/experiencesAndGatherings.util'
import { useEvents } from '../../hooks/useEvents'
import LogisticsTableCard from '../LogisticsTableCard/LogisticsTableCard'
import { Row } from './TableComponent.styled'
import { TableCell, TableRow } from '@mui/material'
import { tableRowColor } from '../../util/helpers'

const TableComponent = ({
  tableBodyData,
  tableDataVariant,
  rowsPerPageArray,
  rowsPerPageParentSetter,
  parrentPageSetter,
  setNumberOfRows,
  headerDataVariant,
  SetOccurrences,
  setVisibleRows,
  event,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageArray ? rowsPerPageArray[0] : 5)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('title')
  const [openedTab, setOpenedTab] = useState(-1)

  const elements = event?.recurrencAmount ? Array(parseInt(event?.recurrencAmount)).fill('*') : []
  // All experineces array.
  if (event) {
    tableBodyData = elements
  }

  const { experiences } = useEvents()

  const visibleRows = useMemo(
    () =>
      stableSort(tableBodyData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, tableBodyData],
  )

  const handleChangePageInsidePaginationComponent = (event, newPage) => {
    setPage(newPage - 1)
  }

  const sortOrder = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const changePageInTablePagination = (event, newPage) => {
    setPage(newPage)
  }

  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    rowsPerPageParentSetter && rowsPerPageParentSetter(parseInt(event.target.value, 10))
    parrentPageSetter(page)
    setPage(0)
    if (setNumberOfRows) setNumberOfRows(parseInt(event.target.value, 10))
  }

  useEffect(() => {
    setVisibleRows && setVisibleRows(visibleRows)
  }, [visibleRows])

  const HandleTableData = (dataVariant) => {
    if (dataVariant === 'Experiences') {
      return (
        <TableBody>
          {visibleRows.map((event, index) => {
            return (
              <ExperienceTableCard key={index} experienceData={event} indexPosition={index + 1} />
            )
          })}
        </TableBody>
      )
    } else if (dataVariant === 'Templates') {
      return (
        <TableBody>
          {visibleRows.map((event, index) => {
            return <TemplatesTableCard key={index} templateData={event} indexPosition={index + 1} />
          })}
        </TableBody>
      )
    } else if (dataVariant === 'Logistics') {
      return (
        <TableBody>
          {visibleRows.map((starts, index) => {
            let realIndex = page * rowsPerPage + index
            return (
              <>
                <LogisticsTableCard
                  key={realIndex}
                  templateData={event || {}}
                  indexPosition={realIndex + 1}
                  SetOccurrences={SetOccurrences}
                  setOpenedTab={setOpenedTab}
                  openedTab={openedTab}
                />

                <TableRow
                  sx={{
                    backgroundColor: tableRowColor(index + 1),
                    ':hover': {
                      backgroundColor: tableRowColor(index + 1) + '!important',
                      borderLeft: 'none !important',
                    },
                  }}
                >
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0, padding: 0, paddingRight: 2 }}
                    colSpan={5}
                  >
                    <Row open={openedTab === realIndex + 1}>
                      <Occurrence
                        open={realIndex + 1}
                        event={event}
                        order={realIndex}
                        SetOccurrences={SetOccurrences}
                      />{' '}
                    </Row>
                  </TableCell>
                </TableRow>
              </>
            )
          })}
        </TableBody>
      )
    } else if (dataVariant === 'Gatherings') {
      return (
        <TableBody>
          {visibleRows.map((event, index) => {
            return (
              <GatheringTableCard
                key={index}
                gatheringData={event}
                indexPosition={index + 1}
                recurring={event.recurring}
                parentExperience={getExperienceFromGathering(event.id, experiences)}
              />
            )
          })}
        </TableBody>
      )
    } else if (dataVariant === 'Drafts') {
      return (
        <TableBody>
          {visibleRows.map((event, index) => {
            return <DraftTableCard key={index} draftData={event} indexPosition={index + 1} />
          })}
        </TableBody>
      )
    } else if (dataVariant === 'Archived') {
      return (
        <TableBody>
          {visibleRows.map((event, index) => {
            return (
              <ArchivedDraftTableCard
                key={index}
                archivedDraftData={event}
                indexPosition={index + 1}
              />
            )
          })}
        </TableBody>
      )
    }
  }

  return (
    <Box sx={BoxStyle}>
      <TableContainer component={Paper}>
        <Table sx={TableStyle}>
          <TableComponentHead
            order={order}
            orderBy={orderBy}
            onRequestSort={sortOrder}
            headerDataVariant={headerDataVariant}
          />
          {HandleTableData(tableDataVariant)}
        </Table>
      </TableContainer>
      <div style={paginationDiv}>
        <Pagination
          defaultPage={0}
          count={calculatePageCountForPaginationComponent(tableBodyData.length, rowsPerPage)}
          page={preparePageNumberForPaginationComponent(page)}
          onChange={handleChangePageInsidePaginationComponent}
        />
        <StyledTablePagination
          rowsPerPageOptions={rowsPerPageArray}
          count={tableBodyData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={changePageInTablePagination}
          onRowsPerPageChange={changeRowsPerPage}
          component="div"
        />
      </div>
    </Box>
  )
}

TableComponent.propTypes = {
  experienceData: PropTypes.array,
  rowsPerPage: PropTypes.array,
}

export default TableComponent

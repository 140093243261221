import { faker } from '@faker-js/faker'

export const events = (limit = 10) => {
  function makeArray(length, content) {
    return Array.from({ length }, content)
  }
  const events = []
  for (let i = 0; i < limit; i++) {
    const titleText = faker.random.word()
    events.push({
      id: i,
      time: '09:00 am',
      isFavourite: faker.datatype.boolean(),
      image: faker.image.city(),
      start: faker.date.future(50),
      end: faker.date.soon(limit),
      title: titleText.replace(/^./, titleText[0].toUpperCase()) + ' Gathering',
      status: i % 2 ? 'draft' : 'published',
      description: faker.company.catchPhrase(),
      dateFrom: faker.date.between('2023-05-01', '2030-01-01'),
      dateTo: faker.date.between('2023-05-01', '2030-01-01'),
      created: faker.date.between('2023-05-01', '2030-01-01'),
      gatherings: makeArray(20, faker.random.word),
      label: faker.random.word(),
    })
  }
  return events
}

export const profileComponentProps = {
  img: faker.image.avatar(),
  name: faker.name.firstName(),
  surname: faker.name.lastName(),
  role: 'Admin',
}

function makeMembersArray(length) {
  let members = []
  for (let i = 0; i < length; i++) {
    const fullName = faker.random.words(2)
    const avatar = faker.image.avatar(50, 50)
    members.push({ title: fullName, image: avatar, variant: 'individual' })
  }
  return members
}

export const rosterDataGridFakeData = (length) => {
  let data = []
  let bool = [true, false]
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * bool.length)
    const fullName = faker.random.words(2)
    const randomWord = faker.random.words(1)
    data.push({
      id: i,
      User: fullName,
      Group: randomWord,
      Household: randomWord,
      Team: randomWord,
      Selected: bool[randomIndex],
    })
  }
  return data
}

export const groupData = (limit) => {
  const gropuVariants = ['group', 'team', 'household', 'individual']
  const groups = []
  for (let i = 0; i < limit; i++) {
    const randomIndex = Math.floor(Math.random() * gropuVariants.length)
    groups.push({
      id: i,
      image: faker.image.avatar(80, 80),
      title: faker.name.jobType(),
      variant: gropuVariants[randomIndex],
      members: makeMembersArray(15),
    })
  }
  return groups
}

export const individualsPool = (limit) => {
  const groups = []
  for (let i = 0; i < limit; i++) {
    groups.push({
      id: i,
      image: faker.image.avatar(80, 80),
      title: faker.random.words(2),
      variant: 'individual',
    })
  }
  return groups
}

export const singleIndividual = () => {
  const individual = {
    id: 99,
    image: faker.image.avatar(80, 80),
    title: 'Dummy Person',
    variant: 'individual',
  }
  return individual
}

export const questionData = (limit) => {
  const questionVariants = ['Textual', 'Numeric']
  const questions = []
  for (let i = 0; i < limit; i++) {
    const randomIndex = Math.floor(Math.random() * questionVariants.length)
    questions.push({
      id: i,
      question: faker.random.words(4),
      hint: faker.random.word(),
      type: questionVariants[randomIndex],
    })
  }
  return questions
}

export const searchData = [
  { title: faker.company.bsBuzz().toUpperCase() + ' Gathering' },
  { title: faker.company.bsBuzz().toUpperCase() + ' Gathering' },
  { title: faker.company.bsBuzz().toUpperCase() + ' Gathering' },
  { title: faker.company.bsBuzz().toUpperCase() + ' Experience' },
  { title: faker.company.bsBuzz().toUpperCase() + ' Gathering' },
  { title: faker.company.bsBuzz().toUpperCase() + ' Gathering' },
  { title: faker.company.bsBuzz().toUpperCase() + ' Experience' },
  { title: faker.company.bsBuzz().toUpperCase() + ' Gathering' },
  { title: faker.company.bsBuzz().toUpperCase() + ' Experience' },
  { title: faker.company.bsBuzz().toUpperCase() + ' Gathering' },
]

export const dummyReocurrence = [
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
  '20/20/23 - 05:00 PM',
]

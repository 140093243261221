import React from 'react'
import { OneTimeHolder, Row, Divider } from './LogisticsInfo.styles'
import { Label } from '../../Form/FormElements.styles.jsx'
import DateInput from '../../Form/components/DateInput.jsx'
import TimeInput from '../../Form/components/TimeInput'
function NoReoccurring({ SetFormProperty, formInfo }) {
  return (
    <OneTimeHolder>
      <Row>
        <Divider width={'50%'}>
          <Row>
            <Label minWidth={'100px'}>Start Date</Label>
            <DateInput
              onChange={(value) => SetFormProperty('startDate', value, 'event')}
              value={formInfo?.logisticsInfo?.event?.startDate}
              label=""
              error={
                formInfo?.logisticsInfo?.checkFields &&
                (!formInfo?.logisticsInfo?.event?.startDate ||
                  formInfo?.logisticsInfo?.event?.startDate === 'Invalid Date')
              }
            />
          </Row>
        </Divider>
        <Divider width={'50%'}>
          <Row>
            <Label minWidth={'100px'}>Start Time</Label>
            <TimeInput
              onChange={(value) => SetFormProperty('startTime', value, 'event')}
              value={formInfo?.logisticsInfo?.event?.startTime}
              label=""
              error={
                formInfo?.logisticsInfo?.checkFields &&
                (!formInfo?.logisticsInfo?.event?.startTime ||
                  formInfo?.logisticsInfo?.event?.startTime === 'Invalid Date')
              }
            />
          </Row>
        </Divider>
      </Row>
      <Row>
        <Divider width={'50%'}>
          <Row>
            <Label minWidth={'100px'}>End Date</Label>
            <DateInput
              onChange={(value) => SetFormProperty('endDate', value, 'event')}
              value={formInfo?.logisticsInfo?.event?.endDate}
              label=""
              error={
                formInfo?.logisticsInfo?.checkFields &&
                (!formInfo?.logisticsInfo?.event?.endDate ||
                  formInfo?.logisticsInfo?.event?.endDate === 'Invalid Date')
              }
            />
          </Row>
        </Divider>
        <Divider width={'50%'}>
          <Row>
            <Label minWidth={'100px'}>End Time</Label>
            <TimeInput
              onChange={(value) => SetFormProperty('endTime', value, 'event')}
              value={formInfo?.logisticsInfo?.event?.endTime}
              label=""
              error={
                formInfo?.logisticsInfo?.checkFields &&
                (!formInfo?.logisticsInfo?.event?.endTime ||
                  formInfo?.logisticsInfo?.event?.endTime === 'Invalid Date')
              }
            />
          </Row>
        </Divider>
      </Row>
    </OneTimeHolder>
  )
}

export default NoReoccurring

import color from '../util/colors'

const handleBackgroundColor = (variant) => {
  switch (variant) {
    case 'group':
      return color.primary
    case 'team':
      return color.basicInfoNoButton
    case 'household':
      return color.groupFrameHousehold
    case 'individual':
      return color.groupFrameIndividual
    default:
      return color.primary
  }
}

const handleLabelColor = (variant) => {
  switch (variant) {
    case 'group':
      return color.backgroundPrimary
    case 'team':
      return color.backgroundPrimary
    case 'household':
      return color.backgroundPrimary
    case 'individual':
      return color.primary
    default:
      return color.backgroundPrimary
  }
}

export { handleBackgroundColor, handleLabelColor }

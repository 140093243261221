import styled from 'styled-components'

const StyledText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--title);
  width: 30%;
  display: flex;
  padding-left: 3rem;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding-left: 0;
    padding-bottom: 1.5rem;
  }
`

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
  ${(props) => props.background && `background: ${props.background};`}
`

const SecondRowContainer = styled(RowContainer)`
  align-items: flex-start;
`

const TextFieldArea = styled.div`
  width: 70%;
  padding-right: 3rem;
  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`

const errorModalStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid var(--primary)',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

export { RowContainer, SecondRowContainer, StyledText, TextFieldArea, errorModalStyle }

import { logger } from './logger'
const baseUrl = process.env.REACT_APP_BACKEND_URL

export const uploadFileToCloudStorage = async (formData, authToken) => {
  try {
    const response = await fetch(`${baseUrl}/api/admin/fileUpload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    })

    if (response.ok) {
      const data = await response.json()
      return data
    }
  } catch (error) {
    logger.error('Error', error)
  }
}

/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import {
  TemplatePreviewMainContainer,
  TemplatePreviewContentArea,
  HeadlineTextAndButtonRow,
  HeadlineText,
  HeadlineButtonWrapper,
  HeadlineSubInfo,
  subInfoIconStyle,
  TemplatePreviewMainContentArea,
  MainContentLeft,
  MainContentRight,
  EventDetailsPreviewArea,
  ImageDetailsSection,
  Image,
  GatheringName,
  GatheringData,
  ImagePreviewSection,
  detailsIcon,
  stockImageIcon,
  EventDescriptionContainer,
  EventDescriptionSubText,
  SubTextHighlight,
  RightHeadlineText,
  HighlightsBox,
  IconAndTextBoxTitle,
  boxIconStyle,
  TagWrapper,
} from './TemplatePreview.style'
import SubHeader from '../../components/SubHeader/SubHeader'
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded'
import { TemplatePreviewHeaderButton } from './util/TemplatePreview.util'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import ImageIcon from '@mui/icons-material/Image'
import StarIcon from '@mui/icons-material/Star'
import ListAltIcon from '@mui/icons-material/ListAlt'
import HdrStrongIcon from '@mui/icons-material/HdrStrong'
import { useFormGatherings, useIdGathering } from '../../util/zustant/store'
import Tag from '../../components/Tag/Tag'
import color from '../../util/colors'
import { useNavigate } from 'react-router-dom'
import { useTemplates } from '../../util/zustant/templateStore'
import { useAuth } from '../../hooks/useAuth'
import { deleteTemplate } from '../../util/templates'
import { useEvents } from '../../hooks/useEvents'
import { useStepGatherings } from '../../util/zustant/store'
import { pickTemplate, getTemplate, heartTemplate, getHeartedTemplates } from '../../util/templates'
import { useTemplateId } from '../../util/zustant/store'
import { getExperienceFromGathering } from '../../util/experiencesAndGatherings.util'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
const TemplatePreview = () => {
  const { token } = useAuth()
  const { templatePreview, setTemplatePreview, templateHearted, setTemplateHearted } =
    useTemplates()
  const [selectedButton, setSelectedButton] = useState('tasks')
  const [selectedTab, setSelectedTab] = useState('Home')
  const { profileData } = useEvents()
  const userId = profileData.uid
  const navigate = useNavigate()
  const { setFormInfo } = useFormGatherings()
  const { setGatheringId } = useIdGathering()
  const { setActiveStep } = useStepGatherings()
  const { setTemplateId } = useTemplateId()
  const [hearted, setHearted] = useState(false)
  const [loading, setLoading] = useState(false)
  const { experiences } = useEvents()

  useEffect(() => {
    setLoading(false)
    const findHearted = templateHearted?.find((t) => {
      if (t?.templateId === templatePreview?.id) return t
      return null
    })

    setHearted(findHearted ? findHearted?.isFavourite : false)
  }, [templateHearted])

  const isPartOfExperience = getExperienceFromGathering(templatePreview?.id, experiences)

  const higlightTitle =
    templatePreview?.checkinType?.checkinMethod === 'kiosk'
      ? 'Onboarding via Kiosk'
      : 'Onboarding via Roster'

  const hightlight = {
    title: higlightTitle,
    variant: 'gatheringTemplate',
    templateVariant: 'gatheringTemplateHighlights',
  }

  const cancellation = {
    title: 'Cancellation reminder',
    variant: 'gatheringTemplate',
    templateVariant: 'gatheringTemplateHighlights',
  }
  const payment = {
    title: 'Payment reminder',
    variant: 'gatheringTemplate',
    templateVariant: 'gatheringTemplateHighlights',
  }
  const promoAlert = {
    title: 'Promo alert',
    variant: 'gatheringTemplate',
    templateVariant: 'gatheringTemplateHighlights',
  }
  const signUp = {
    title: 'Signup reminder',
    variant: 'gatheringTemplate',
    templateVariant: 'gatheringTemplateHighlights',
  }

  return (
    <TemplatePreviewMainContainer>
      <SubHeader
        setSelectedButtonParent={setSelectedButton}
        setSelectedTabParent={setSelectedTab}
        activeTabOnParent={selectedTab}
        SecondIcon={TableRowsRoundedIcon}
      ></SubHeader>
      <TemplatePreviewContentArea>
        <HeadlineTextAndButtonRow>
          <HeadlineText
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(-1)
            }}
          >
            {'< Back to Gathering Creation'}
          </HeadlineText>
          <HeadlineButtonWrapper>
            <TemplatePreviewHeaderButton
              variant={'edit'}
              label={'Edit'}
              onClick={async () => {
                await setTemplateId(templatePreview?.id)
                await getTemplate(templatePreview?.id, token, setTemplatePreview)
                setFormInfo(templatePreview)
                navigate(`/createGathering`)
              }}
            />
            <TemplatePreviewHeaderButton
              onClick={async (e) =>
                await deleteTemplate(templatePreview?.id, token, navigate('/templates'))
              }
              variant={'delete'}
              label={'Delete'}
            />
            <TemplatePreviewHeaderButton
              onClick={async () => {
                setLoading(true)
                setHearted(!hearted)
                await heartTemplate(templatePreview?.id, userId, token)
                getHeartedTemplates(userId, token, setTemplateHearted)
              }}
              disabled={loading}
              hearted={hearted}
              variant={'favor'}
              label={'Favor'}
            />
            <TemplatePreviewHeaderButton
              onClick={async () => {
                setActiveStep(2)
                await pickTemplate(
                  templatePreview?.id,
                  token,
                  setFormInfo,
                  navigate,
                  userId,
                  setGatheringId,
                )
              }}
              variant={'useThis'}
              label={'Use This Template'}
            />
          </HeadlineButtonWrapper>
        </HeadlineTextAndButtonRow>
        <HeadlineSubInfo>
          <ErrorRoundedIcon style={subInfoIconStyle} />
          {`This is a preview of "${templatePreview?.gatheringDetails?.title}"`}
        </HeadlineSubInfo>
        <TemplatePreviewMainContentArea>
          <MainContentLeft>
            <EventDetailsPreviewArea>
              <ImageDetailsSection>
                <GatheringName>{templatePreview?.gatheringDetails?.title}</GatheringName>
                <GatheringData>
                  <CalendarTodayIcon style={detailsIcon} />
                  <span>{`Date: ${templatePreview?.logisticsInfo?.event?.startDate || ''}, ${
                    templatePreview?.logisticsInfo?.event?.startTime || ''
                  }`}</span>
                </GatheringData>
                <GatheringData>
                  <LocationOnIcon style={detailsIcon} />
                  <span>{`Location: ${templatePreview?.logisticsInfo?.location || ''}`}</span>
                </GatheringData>
                <GatheringData>
                  <MonetizationOnIcon style={detailsIcon} />
                  <span>{`Event Pricing: ${
                    templatePreview?.paidEvent?.eventIsPaid === 'Paid' ? 'Paid' : 'Free'
                  }`}</span>
                </GatheringData>
              </ImageDetailsSection>
              <ImagePreviewSection>
                {templatePreview?.imageUpload?.eventImage ? (
                  <Image src={templatePreview?.imageUpload?.eventImage} alt=""></Image>
                ) : (
                  <ImageIcon style={stockImageIcon} />
                )}
              </ImagePreviewSection>
            </EventDetailsPreviewArea>
            <EventDescriptionContainer>
              {templatePreview?.gatheringDetails?.description && (
                <EventDescriptionSubText>
                  <SubTextHighlight>{'Description:'}</SubTextHighlight>
                  {templatePreview?.gatheringDetails?.description}
                </EventDescriptionSubText>
              )}
              {templatePreview?.gatheringType?.typeOfGathering && (
                <EventDescriptionSubText>
                  <SubTextHighlight>{'Type of Gathering:'}</SubTextHighlight>
                  {templatePreview?.gatheringType?.typeOfGathering}
                </EventDescriptionSubText>
              )}
              {templatePreview?.attendees?.openGathering && (
                <EventDescriptionSubText>
                  <SubTextHighlight>{'Who:'}</SubTextHighlight>
                  {templatePreview?.attendees?.openGathering === 'yes'
                    ? 'Anyone'
                    : 'Closed Gathering'}
                </EventDescriptionSubText>
              )}
              {templatePreview?.logisticsInfo?.location && (
                <EventDescriptionSubText>
                  <SubTextHighlight>{'Where:'}</SubTextHighlight>
                  {templatePreview?.logisticsInfo?.location}
                </EventDescriptionSubText>
              )}
              {templatePreview?.paymentsInfo?.tickets && (
                <EventDescriptionSubText>
                  <SubTextHighlight>{'Cost:'}</SubTextHighlight>
                  {templatePreview?.paymentsInfo?.tickets.length > 0 && (
                    <TagWrapper style={{ paddingTop: 0, marginLeft: '10px' }}>
                      {templatePreview?.paymentsInfo?.tickets.map((ticket) => (
                        <Tag
                          variant={'previewTag'}
                          previewDataLabel={`${ticket.name} - ${ticket.cost}`}
                        ></Tag>
                      ))}
                    </TagWrapper>
                  )}
                </EventDescriptionSubText>
              )}
            </EventDescriptionContainer>
          </MainContentLeft>
          <MainContentRight>
            <RightHeadlineText>{'Key features of this template'}</RightHeadlineText>
            <HighlightsBox borderColor={color?.primary}>
              <IconAndTextBoxTitle>
                <StarIcon style={boxIconStyle} />
                {'Highlights:'}
              </IconAndTextBoxTitle>
              <TagWrapper>
                <Tag
                  variant={'previewTag'}
                  previewDataLabel={
                    templatePreview?.logisticsInfo?.recurring
                      ? 'Recurring Event'
                      : 'Non-Recurring Event'
                  }
                ></Tag>
                <Tag
                  variant={hightlight?.variant}
                  templateVariant={hightlight?.templateVariant}
                  tagObject={hightlight}
                ></Tag>
                {templatePreview?.reminders?.userReminders === 'Yes' &&
                  templatePreview?.reminders?.notification?.cancellation && (
                    <Tag
                      variant={'gatheringTemplate'}
                      templateVariant={'gatheringTemplateHighlights'}
                      tagObject={cancellation}
                    ></Tag>
                  )}
                {templatePreview?.reminders?.userReminders === 'Yes' &&
                  templatePreview?.reminders?.notification?.promoAlert && (
                    <Tag
                      variant={'gatheringTemplate'}
                      templateVariant={'gatheringTemplateHighlights'}
                      tagObject={promoAlert}
                    ></Tag>
                  )}
                {templatePreview?.reminders?.userReminders === 'Yes' &&
                  templatePreview?.reminders?.notification?.payment && (
                    <Tag
                      variant={'gatheringTemplate'}
                      templateVariant={'gatheringTemplateHighlights'}
                      tagObject={payment}
                    ></Tag>
                  )}
                {templatePreview?.reminders?.userReminders === 'Yes' &&
                  templatePreview?.reminders?.notification?.signUp && (
                    <Tag
                      variant={'gatheringTemplate'}
                      templateVariant={'gatheringTemplateHighlights'}
                      tagObject={signUp}
                    ></Tag>
                  )}
              </TagWrapper>
            </HighlightsBox>
            {templatePreview?.additionalInfo?.uploadedData.length > 0 && (
              <HighlightsBox borderColor={color?.orangeBorder}>
                <IconAndTextBoxTitle>
                  <ListAltIcon style={boxIconStyle} />
                  {'Forms:'}
                </IconAndTextBoxTitle>
                <TagWrapper>
                  {templatePreview?.additionalInfo?.uploadedData?.map((tag) => (
                    <Tag
                      variant={'gatheringTemplate'}
                      templateVariant={'gatheringTemplateForms'}
                      tagObject={tag}
                      label={'filename'}
                    ></Tag>
                  ))}
                </TagWrapper>
              </HighlightsBox>
            )}
            {isPartOfExperience.length > 0 && (
              <HighlightsBox borderColor={color.checkmark}>
                <IconAndTextBoxTitle>
                  <HdrStrongIcon style={boxIconStyle} />
                  {'Part of an Experience:'}
                </IconAndTextBoxTitle>
                <TagWrapper>
                  <Tag
                    variant={'partOfExperience'}
                    templateVariant={'gatheringTemplateHighlights'}
                    experienceName={isPartOfExperience}
                  ></Tag>
                </TagWrapper>
              </HighlightsBox>
            )}
            {templatePreview?.additionalInfo?.savedQuestions &&
              templatePreview?.additionalInfo?.savedQuestions.length > 0 && (
                <HighlightsBox borderColor={color.checkmark}>
                  <IconAndTextBoxTitle>
                    <QuestionMarkIcon style={boxIconStyle} />
                    {'Questions:'}
                  </IconAndTextBoxTitle>
                  <TagWrapper>
                    {templatePreview?.additionalInfo?.savedQuestions?.map((question) => (
                      <Tag variant={'previewTag'} previewDataLabel={question.question}></Tag>
                    ))}
                  </TagWrapper>
                </HighlightsBox>
              )}
            {templatePreview?.tags?.tagPool && templatePreview?.tags?.tagPool.length > 0 && (
              <HighlightsBox borderColor={color.checkmark}>
                <IconAndTextBoxTitle>
                  <LocalOfferIcon style={boxIconStyle} />
                  {'Tags:'}
                </IconAndTextBoxTitle>
                <TagWrapper>
                  {templatePreview?.tags?.tagPool?.map((tag) => (
                    <Tag variant={'previewTag'} previewDataLabel={tag.name}></Tag>
                  ))}
                </TagWrapper>
              </HighlightsBox>
            )}
          </MainContentRight>
        </TemplatePreviewMainContentArea>
      </TemplatePreviewContentArea>
    </TemplatePreviewMainContainer>
  )
}

export default TemplatePreview

import './CreateFromScratchButton.css'
import { useNavigate } from 'react-router-dom'

const CreateFromScratchButton = ({ navigationRoute }) => {
  let navigate = useNavigate()
  return (
    <div
      onClick={() => navigate(`/${navigationRoute}`)}
      className="createFromScratchButtonContainer"
    >
      + Create From Scratch
    </div>
  )
}

export default CreateFromScratchButton

import {
  FileUploadContainer,
  HeaderTextArea,
  FileUploadArea,
  FileButtonWrapper,
  FileUploadButton,
  fileUploadIcon,
  TagGridArea,
} from './FileUpload.style'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useState, useEffect } from 'react'
import TagGrid from '../TagGrid/TagGrid'
import {
  handleFileUpload,
  handleDrop,
  handleDragOver,
  uploadWaiversToTickets,
} from '../../util/fileUpload'
import waiverExamples from '../../assets/exampleWaivers.json'

const FileUpload = ({
  formInfo,
  setUploadedData,
  authToken,
  setWaiverExamples,
  addWaiversToTicket,
  waiverInfo,
  paidEvent,
  activeTicket,
}) => {
  useEffect(() => {
    if (paidEvent === 'Paid' && waiverInfo?.waivers) {
      setFiles(waiverInfo?.waivers)
    } else if (paidEvent !== 'Paid' && formInfo?.uploadedData) {
      setFiles(formInfo?.uploadedData)
    } else {
      setFiles([])
    }
  }, [])

  const [files, setFiles] = useState()
  const [exampleArray, setExampleArray] = useState(waiverExamples.example)

  useEffect(() => {
    if (activeTicket && paidEvent === 'Paid') {
      addWaiversToTicket(files)
    } else {
      setUploadedData(files)
    }
  }, [files])

  useEffect(() => {
    setWaiverExamples(exampleArray)
  }, [exampleArray])

  return (
    <FileUploadContainer>
      <input
        disabled={paidEvent === 'Paid' && !activeTicket}
        id="file-upload"
        type="file"
        accept="application/pdf"
        multiple
        style={{ display: 'none' }}
        onChange={(event) => {
          if (activeTicket && paidEvent === 'Paid') {
            uploadWaiversToTickets(event, setFiles, false, authToken, activeTicket)
          } else {
            handleFileUpload(event, setFiles, false, authToken)
            event.target.value = ''
          }
        }}
      />
      <HeaderTextArea>Add a waiver form:</HeaderTextArea>
      <FileUploadArea>
        <FileButtonWrapper
          htmlFor="file-upload"
          onDrop={(event) => handleDrop(event, setFiles, true, authToken)}
          onDragOver={(event) => handleDragOver(event)}
        >
          <FileUploadButton variant="contained" component="span">
            <CloudUploadIcon sx={fileUploadIcon} />
            {paidEvent === 'Paid' && !activeTicket
              ? 'Please select a valid ticket'
              : 'Browse Files or Drag and Drop to Upload'}
          </FileUploadButton>
        </FileButtonWrapper>
      </FileUploadArea>
      {paidEvent === 'Paid' && activeTicket ? (
        activeTicket.map((ticket) => (
          <TagGridArea>
            {files[ticket.name] && files[ticket.name].length > 0 && (
              <TagGrid
                tagPool={files[ticket.name]}
                tagVariant={'document'}
                notifText={`Uploads for ticket: ${ticket.name}`}
                setUploadArray={setFiles}
                setExampleArray={setExampleArray}
                activeTicket={activeTicket}
              ></TagGrid>
            )}
          </TagGridArea>
        ))
      ) : paidEvent !== 'Paid' ? (
        <TagGridArea>
          {files && files.length > 0 && (
            <TagGrid
              tagPool={files}
              tagVariant={'document'}
              notifText={'Uploads:'}
              setUploadArray={setFiles}
              setExampleArray={setExampleArray}
            ></TagGrid>
          )}
        </TagGridArea>
      ) : (
        <></>
      )}
      {formInfo?.waiverExamples && (
        <TagGridArea>
          <TagGrid
            tagPool={formInfo?.waiverExamples}
            tagVariant={'exampleWaiver'}
            notifText={'Examples you can use (see below hints):'}
            uploadArray={files}
            exampleArray={exampleArray}
            setUploadArray={setFiles}
            setExampleArray={setExampleArray}
            activeTicket={activeTicket}
            eventType={paidEvent}
          ></TagGrid>
        </TagGridArea>
      )}
    </FileUploadContainer>
  )
}

export default FileUpload

import CheckIcon from '@mui/icons-material/Check'
import PageviewIcon from '@mui/icons-material/Pageview'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DeleteIcon from '@mui/icons-material/Delete'
import FavoriteIcon from '@mui/icons-material/Favorite'

const sidebarValues = [
  {
    id: 1,
    icon: <CheckIcon className="templateCardSidebarIcon" sx={{ fontSize: 17 }} />,
    type: 'use',
  },
  {
    id: 2,
    icon: <PageviewIcon className="templateCardSidebarIcon" sx={{ fontSize: 17 }} />,
    type: 'preview',
  },
  {
    id: 3,
    icon: <OpenInNewIcon className="templateCardSidebarIcon" sx={{ fontSize: 17 }} />,
    type: 'edit',
  },
  {
    id: 4,
    icon: <DeleteIcon className="templateCardSidebarIcon" sx={{ fontSize: 17 }} />,
    type: 'delete',
  },
  {
    id: 5,
    icon: <FavoriteIcon className="templateCardSidebarIcon" sx={{ fontSize: 17 }} />,
    type: 'favorite',
  },
]

export { sidebarValues }

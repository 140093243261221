import styled from 'styled-components'

const QuestionCreationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const HeaderTextArea = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  color: var(--title);
  display: flex;
  justify-content: flex-start;
  width: 98%;
`

const TextFieldArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  width: 98%;
  row-gap: 1rem;
  @media (max-width: 768px) {
  }
`

const TagGridArea = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 98%;
`

const QuestionCreationButtonArea = styled.div`
  display: flex;
  width: 98%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  justify-content: flex-end;
`

export {
  QuestionCreationContainer,
  HeaderTextArea,
  TextFieldArea,
  TagGridArea,
  QuestionCreationButtonArea,
}

import { GatheringTypeContainer } from './GatheringType.style'
import SelectBasicInfo from '../../SelectBasicInfo/SelectBasicInfo'
import infoData from '../../../assets/choseGatheringType.json'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { useState, useEffect } from 'react'

const GatheringType = () => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [gatheringType, setGatheringType] = useState(
    formInfo?.gatheringType ? formInfo?.gatheringType : {},
  )

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.gatheringType = gatheringType
    setFormInfo(newFormInfo)
  }, [gatheringType])

  // This will store user selected type of gathering.
  function setTypeOfGathering(type) {
    setGatheringType((info) => {
      info.typeOfGathering = type
      return { ...info }
    })
  }

  return (
    <GatheringTypeContainer>
      <SelectBasicInfo
        title={infoData.question}
        choices={infoData.choices}
        formInfo={formInfo}
        paidGatheringType={setTypeOfGathering}
        variant={'gatheringType'}
      ></SelectBasicInfo>
    </GatheringTypeContainer>
  )
}

export default GatheringType

import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import color from '../../../util/colors'

export const PromptFieldCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  color: ${color.primary};
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`
export const PromptFieldCloseIconLabel = styled(CloseIcon)`
  cursor: pointer;
  color: ${color.primary}!important;
  z-index: 100;
`

import GroupGrid from '../../../GroupGrid/GroupGrid'

const AttendeeGroups = ({ selectedAttendeeGroups, forSearch }) => {
  return (
    <>
      {selectedAttendeeGroups?.group.length > 0 && (
        <GroupGrid
          groupName={'Groups'}
          forSearch={forSearch}
          selectedGroups={selectedAttendeeGroups?.group}
        />
      )}
      {selectedAttendeeGroups?.team.length > 0 && (
        <GroupGrid
          groupName={'Teams'}
          forSearch={forSearch}
          selectedGroups={selectedAttendeeGroups?.team}
        />
      )}
      {selectedAttendeeGroups?.household.length > 0 && (
        <GroupGrid
          groupName={'Household'}
          forSearch={forSearch}
          selectedGroups={selectedAttendeeGroups?.household}
        />
      )}
      {selectedAttendeeGroups?.individual.length > 0 && (
        <GroupGrid
          groupName={'Individuals'}
          forSearch={forSearch}
          selectedGroups={selectedAttendeeGroups?.individual}
        />
      )}
    </>
  )
}

export default AttendeeGroups

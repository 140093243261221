import { useState, useEffect } from 'react'
import LocationInput from '../../Form/components/LocationInput.jsx'
import { useFormGatherings, useView } from '../../../util/zustant/store.js'
import BooleanButton from '../../BooleanButton/BooleanButton.jsx'
import {
  ButtonHolder,
  Row,
  RowLogistics,
  MessageRow,
  LogisticsDivider,
} from './LogisticsInfo.styles'
import { Label } from '../../Form/FormElements.styles.jsx'
import Message from '../../Form/components/Message.jsx'
import Selector from '../../Form/components/Selector.jsx'
import NoReoccurring from './NoReoccurring.js'
import Reoccurring from './Reoccuring.js'
import { useEvents } from '../../../hooks/useEvents.jsx'
import TableComponent from '../../TableComponent/TableComponent.jsx'
import { validateAddress } from '../../../util/addressChecker.js'
import Input from '../../Form/components/Input.jsx'
import TemplateButton from '../../TemplateButton/TemplateButton.jsx'

const LogisticsInfo = () => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const { view, setView } = useView()
  const [error, setError] = useState(false)
  const [occurrences, setOccurrences] = useState([])

  const setterOccurrence = (i) => {
    setOccurrences((prev) => [...prev, i])
  }

  const cleanOccurrences = () => {
    setOccurrences([])
  }

  const removeOccurrence = (indexToRemove) => {
    setOccurrences(occurrences.filter((_, index) => index !== indexToRemove))
  }

  const [logisticsInfo, setLogisticsInfo] = useState(
    formInfo.logisticsInfo ? formInfo.logisticsInfo : {},
  )

  const { gatherings } = useEvents()

  useEffect(() => {
    let newFormInfo = formInfo

    newFormInfo.logisticsInfo = logisticsInfo

    setFormInfo(newFormInfo)
  }, [logisticsInfo, formInfo, setFormInfo])

  function SetRecuring(value) {
    setLogisticsInfo((info) => {
      info.recurring = value
      return { ...info }
    })
  }

  function flagInvalidLocation(value) {
    setLogisticsInfo((info) => {
      info.invalidLocation = value
      return { ...info }
    })
  }

  function SetOccurrences(value, property, order) {
    setLogisticsInfo((info) => {
      info ??= {}
      info.event ??= {}
      info.event.occurrences ??= []
      info.event.occurrences[order] ??= {}
      info.event.occurrences[order][property] = value
      return { ...info }
    })
  }

  function SetFormProperty(property, value, parrentProperty) {
    setLogisticsInfo((info) => {
      if (parrentProperty) {
        info[parrentProperty] = { ...info[parrentProperty] }
        info[parrentProperty][property] = value
      } else {
        info[property] = value
      }
      return { ...info }
    })
  }

  const handleNumbericValues = (event) => {
    const inputValue = event
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '') // Remove non-numeric characters
    SetFormProperty('registration_limit', sanitizedValue)
  }

  return (
    <>
      <RowLogistics>
        <LogisticsDivider width="21%">
          <Label>Location</Label>
        </LogisticsDivider>
        <LogisticsDivider width="79%">
          <LocationInput
            value={formInfo?.logisticsInfo?.location}
            onChange={(value) => {
              SetFormProperty('location', value)
            }}
            error={
              error ||
              (formInfo?.logisticsInfo?.checkFields && error) ||
              (formInfo?.logisticsInfo?.checkFields && !logisticsInfo?.location)
            }
            label="Location"
            onBlur={async () => {
              const data = await validateAddress(logisticsInfo.location)
              if (data) {
                setError(false)
                SetFormProperty('location', data.formatted_address)
                flagInvalidLocation(false)
              } else {
                setError(true)
                flagInvalidLocation(true)
              }
            }}
          />
        </LogisticsDivider>
      </RowLogistics>
      <RowLogistics>
        <LogisticsDivider width="21%">
          <Label>What Language?</Label>
        </LogisticsDivider>
        <LogisticsDivider width="79%">
          <Selector
            value={formInfo?.logisticsInfo?.language}
            onChange={(value) => SetFormProperty('language', value)}
            label="Language"
            error={formInfo?.logisticsInfo?.checkFields && !formInfo?.logisticsInfo?.language}
            items={[
              { label: 'English', value: 'English' },
              { label: 'Italian', value: 'Italian' },
            ]}
          />
        </LogisticsDivider>
      </RowLogistics>

      <RowLogistics>
        <LogisticsDivider width="21%">
          <Label>Registration Limit (Optional)</Label>
        </LogisticsDivider>
        <LogisticsDivider width="79%">
          <Input
            value={formInfo?.logisticsInfo?.registration_limit}
            label={'Limit'}
            onChange={(value) => {
              handleNumbericValues(value)
            }}
            // error={
            //   formInfo?.logisticsInfo?.checkFields && !formInfo?.logisticsInfo?.registration_limit
            // }
          />
        </LogisticsDivider>
      </RowLogistics>
      <MessageRow>
        <Row>
          <Message>
            Info Not the capacity of the venue, but how many people can register for the gathering
          </Message>
        </Row>
      </MessageRow>
      <Row>
        <Label>Is this a reoccurring event?</Label>
        <ButtonHolder>
          <BooleanButton
            setValue={SetRecuring}
            value={formInfo?.logisticsInfo?.recurring}
            type="Yes"
          />
          <BooleanButton
            setValue={SetRecuring}
            value={formInfo?.logisticsInfo?.recurring}
            type="No"
          />
        </ButtonHolder>
      </Row>
      {(view === 1 || view === 2 || !formInfo?.logisticsInfo?.event?.recurrencAmount) && (
        <>
          {formInfo?.logisticsInfo?.recurring && (
            <Message hideIcon={true} translateY="8px">
              <div style={{ display: 'flex', gap: '16px' }}>
                <TemplateButton
                  variant={'defaultDisplay'}
                  active={view === 1 ? true : false}
                  label={'DEFAULT DISPLAY'}
                  onClick={() => {
                    setView(1)
                  }}
                ></TemplateButton>
                <TemplateButton
                  variant={'listDisplay'}
                  active={view === 2 ? true : false}
                  label={'LIST PREVIEW'}
                  onClick={() => {
                    setView(2)
                    setOccurrences([])
                    setTimeout(() => {
                      window.scrollTo({
                        top: document.documentElement.scrollHeight - window.innerHeight,
                        behavior: 'smooth',
                      })
                    }, 0)
                  }}
                ></TemplateButton>
              </div>
            </Message>
          )}
          {formInfo?.logisticsInfo?.recurring && (
            <Reoccurring
              SetFormProperty={SetFormProperty}
              formInfo={formInfo}
              SetOccurrences={SetOccurrences}
              setterOccurrence={setterOccurrence}
              occurrences={occurrences}
              removeOccurrence={removeOccurrence}
              cleanOccurrences={cleanOccurrences}
            />
          )}
        </>
      )}
      {view === 2 &&
        formInfo?.logisticsInfo?.recurring &&
        formInfo?.logisticsInfo?.event?.recurrencAmount &&
        formInfo?.logisticsInfo?.event?.occurrences && (
          <TableComponent
            tableBodyData={gatherings}
            event={formInfo?.logisticsInfo?.event}
            tableDataVariant={'Logistics'}
            rowsPerPageArray={[20, 30, 40]}
            headerDataVariant={'Logistics'}
            SetOccurrences={SetOccurrences}
          ></TableComponent>
        )}
      {!formInfo?.logisticsInfo?.recurring && (
        <NoReoccurring SetFormProperty={SetFormProperty} formInfo={formInfo} />
      )}
    </>
  )
}

export default LogisticsInfo

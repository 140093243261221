import styled from 'styled-components'
import { FormControlLabel } from '@mui/material'

import color from '../../util/colors'

export const customRadioStyle = {
  height: '40px',
  marginLeft: '3px',
  color: color.backgroundPrimary,
  '&, &.Mui-checked': {
    color: color.backgroundPrimary,
  },
}

export const RadioWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 20px;
  height: 100%;
  flex-basis: 374px;
  flex-grow: 1;
  min-width: 0;
  width: 100%;
  color: white;
  ${(props) => props.type === 'Yes' && `background-color: ${color.yesColor};`}
  ${(props) => props.type === 'No' && `background-color: ${color.noColor};`}
  cursor: pointer;
`

export const RadialButton = styled(FormControlLabel)`
  padding-left: 5px;
`

import {
  SecurityContainer,
  Divider,
  TextAndButtonRow,
  kioskHeaderIcon,
  SkeletonHolder,
} from './Security.style'
import SelectBasicInfo from '../../SelectBasicInfo/SelectBasicInfo'
import infoData from '../../../assets/security.json'
import Message from '../../Form/components/Message'
import GroupSearch from '../../GroupSearch/GroupSearch'
import TemplateButton from '../../TemplateButton/TemplateButton'
import Kiosk from '../../Kiosk/Kiosk'
import SecuritySetup from '../SecuritySetup/SecuritySetup'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { useFormGatherings } from '../../../util/zustant/store.js'
import { useState, useEffect } from 'react'
import { sortByNameSimilarity } from '../../../util/utility'
import { useAuth } from '../../../hooks/useAuth'
import { useLabel } from '../../../util/zustant/labelStore'
import { createLabel, deleteLabel, fetchLabels } from '../../../util/labels'
import Skeleton from '@mui/material/Skeleton'

const Security = () => {
  const { setFormInfo, formInfo } = useFormGatherings()
  const [security, setSecurity] = useState(formInfo?.security ? formInfo?.security : {})
  const [labels, setLabels] = useState([])
  const { setLabelsStore } = useLabel()
  const [closeLabels, setCloseLabels] = useState(false)
  const { token } = useAuth()

  useEffect(() => {
    fetchLabels(token, setLabels)
  }, [token])

  useEffect(() => {
    setLabelsStore(labels)
  }, [labels])

  useEffect(() => {
    let newFormInfo = formInfo
    newFormInfo.security = security
    setFormInfo(newFormInfo)
  }, [security])

  // This will store boolean value based on the people beeing checked or not.
  function setPeopleCheck(value) {
    setSecurity((info) => {
      info.peopleCheck = value
      return { ...info }
    })
  }

  function setLabelName(value, index) {
    setLabels((labels) => {
      labels[index].name = value
      labels[index].updated = true
      return [...labels]
    })
  }

  function setLabelDescription(value, index) {
    setLabels((labels) => {
      labels[index].labelDescription = value
      labels[index].updated = true

      return [...labels]
    })
  }

  function setUploadedImage(image, index) {
    setLabels((labels) => {
      labels[index].labelImage = image
      labels[index].updated = true

      return [...labels]
    })
  }

  function removeUploadedImage(index) {
    setLabels((labels) => {
      labels[index].labelImage = null
      labels[index].updated = true

      return [...labels]
    })
  }

  function sortLabelsBySearch(search) {
    setLabels((kiosks) => {
      kiosks = sortByNameSimilarity(kiosks, search)
      return [...kiosks]
    })
  }

  async function insertLabel() {
    await createLabel(token)
    await fetchLabels(token, setLabels)
  }

  async function deleteLabelFunc(id, setCloseLabels) {
    await deleteLabel(token, id)
    await fetchLabels(token, setLabels)
    setCloseLabels((c) => !c)
  }
  function selectKiosk(kioskData) {
    setSecurity((info) => {
      if (info.selectedLabel?.id !== kioskData.id) {
        info.selectedLabel = kioskData
      } else {
        info.selectedLabel = {}
      }
      return { ...info }
    })
  }

  return (
    <SecurityContainer>
      <SelectBasicInfo
        title={infoData.question}
        choices={infoData.choices}
        formInfo={formInfo}
        setPeopleCheck={setPeopleCheck}
        variant={'security'}
      ></SelectBasicInfo>
      <Divider></Divider>
      {security?.peopleCheck === 'Yes' && (
        <>
          <Message translateY={'-8px'}>{'Printing label is needed for secure check in.'}</Message>
          <Divider></Divider>
          <GroupSearch
            firstRowText={'Search for label you want to use:'}
            variant={'label'}
            sortLabelsBySearch={sortLabelsBySearch}
            labelData={labels}
            loading={labels?.length === 0}
          ></GroupSearch>

          <TextAndButtonRow>
            {'Select from Recent Selections:'}
            <TemplateButton
              variant={'newLabel'}
              label={'Create New Label'}
              insertNewLabel={insertLabel}
              setSecurity={setSecurity}
            ></TemplateButton>
          </TextAndButtonRow>
          {(labels?.length > 0 &&
            labels.map((v, i) => {
              return (
                <Kiosk
                  isSecurity={true}
                  key={v.id}
                  formInfo={formInfo}
                  headerTitle={v.name}
                  headerImage={<LocalOfferIcon style={kioskHeaderIcon}></LocalOfferIcon>}
                  labels={labels}
                  closeLabels={closeLabels}
                  selectKiosk={() => selectKiosk(v)}
                  selectedKiosk={security?.selectedLabel?.id === v.id}
                >
                  <SecuritySetup
                    key={i}
                    index={i}
                    id={v.id}
                    labelName={v?.name}
                    setLabelName={setLabelName}
                    labelDescription={v?.labelDescription}
                    setLabelDescription={setLabelDescription}
                    // TODO: this is slowing rendering as we are fetching none existing image uploadedImage={v?.labelImage}
                    uploadedImage={null}
                    setUploadedImage={setUploadedImage}
                    removeUploadedImage={removeUploadedImage}
                    setSecurity={setSecurity}
                    deleteLabel={deleteLabelFunc}
                    setCloseLabels={setCloseLabels}
                  ></SecuritySetup>
                </Kiosk>
              )
            })) || (
            <SkeletonHolder>
              <Skeleton variant="rectangular" width={'100%'} height={68} />
              <Skeleton variant="rectangular" width={'100%'} height={68} />
              <Skeleton variant="rectangular" width={'100%'} height={68} />
              <Skeleton variant="rectangular" width={'100%'} height={68} />
              <Skeleton variant="rectangular" width={'100%'} height={68} />
              <Skeleton variant="rectangular" width={'100%'} height={68} />
            </SkeletonHolder>
          )}
        </>
      )}
    </SecurityContainer>
  )
}

export default Security

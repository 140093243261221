import styled from 'styled-components'
import { FormControl } from '@mui/material'
import color from '../../../util/colors'

export const LocationInputStyle = styled(FormControl)`
  width: 100%;
  svg {
    color: ${color.primary};
  }
`

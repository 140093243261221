/* eslint-disable no-unused-vars */
import './AllTemplates.css'
import TableComponent from '../TableComponent/TableComponent'
import { useEffect, useState } from 'react'
import { useTemplates } from '../../util/zustant/templateStore'
import { getAllTemplates, getHeartedTemplates } from '../../util/templates'
import { useEvents } from '../../hooks/useEvents'
import { FilterTemplates } from '../../util/utility'
import TemplatesViewFilter from './TemplatesViewFilter'

const AllTemplates = ({ token, setDrafts }) => {
  const { templates, setTemplates, setTemplateHearted, templateHearted } = useTemplates()
  const [rowsPerPageFromChild, setRowsPerPageFromChild] = useState(5)
  const [pageFromChild, setPageFromChild] = useState(0)
  const [visibleRows, setVisibleRows] = useState([])
  const [filter, setFilter] = useState(null)
  const { profileData } = useEvents()
  const userId = profileData.uid

  useEffect(() => {
    getAllTemplates(setTemplates, token)
    getHeartedTemplates(userId, token, setTemplateHearted)
  }, [token])

  const favoriteTemplates = templates.filter((template) => {
    templateHearted.map((templateHearted) => {
      if (template.id === templateHearted.templateId) {
        template.isFavourite = templateHearted.isFavourite
      }
      return false
    })
    return template.isFavourite === true
  })

  let filtered = FilterTemplates(templates, filter, favoriteTemplates)

  return (
    <div className="allTemplatesMainContentArea">
      <TemplatesViewFilter
        data={filtered?.length === 0 || !filtered ? templates : filtered}
        templates={templates}
        rowsPerPageFromChild={rowsPerPageFromChild}
        filter={filter}
        searchData={templates}
        setSearchData={setFilter}
        visibleRows={visibleRows}
      />

      <TableComponent
        tableBodyData={filtered?.length === 0 || !filtered ? templates : filtered}
        tableDataVariant={'Templates'}
        rowsPerPageArray={[5, 10, 25]}
        headerDataVariant={'Templates'}
        rowsPerPageParentSetter={setRowsPerPageFromChild}
        parrentPageSetter={setPageFromChild}
        setVisibleRows={setVisibleRows}
      ></TableComponent>
      {/* Second Text Area */}

      <div className="allTemplatesFooterText">
        <div>©2023 Church.Ai. All Rights Reserved.</div>
      </div>
    </div>
  )
}

export default AllTemplates
